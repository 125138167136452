import React from 'react';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import appRoutes from 'routes/appRoutes';
import VacancyPresenter from 'presenters/VacancyPresenter';
import Icon from 'components/Icon';

import useStyles from './styles';

const VacancyCard = props => {
  const { vacancy } = props;

  const classes = useStyles();

  return (
    <Link to={appRoutes.vacancyPath(vacancy.id)} className={classes.link}>
      <Card key={VacancyPresenter.id(vacancy)} variant="outlined">
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Typography variant="h5" gutterBottom className={classes.vacancyName}>
              {VacancyPresenter.title(vacancy)}
            </Typography>
            <div className={classes.icons}>
              {VacancyPresenter.icons(vacancy).map(icon => (
                <Icon key={icon} name={icon} />
              ))}
            </div>
          </div>

          <Typography classes={{ root: classes.description }}>{VacancyPresenter.shortDescription(vacancy)}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

VacancyCard.propTypes = {
  vacancy: VacancyPresenter.shape().isRequired,
};

export default VacancyCard;
