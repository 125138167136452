/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import StudentCourseLessonsRepository from 'repositories/StudentCourseLessonsRepository';

import { createRestSlice } from 'utils/RestSlice';

const lessonsRestSlice = createRestSlice({
  resource: 'lesson',
  repository: StudentCourseLessonsRepository,
  slices: ['loadLessons'],
});

const initialState = {
  ...lessonsRestSlice.initialState,
};

const lessonsSlice = createSlice({
  name: 'userLessons',
  initialState,
  reducers: {
    ...lessonsRestSlice.reducers,
  },
});

export default lessonsSlice.reducer;

export const lessonsHooks = lessonsRestSlice.hooks(lessonsSlice, 'userLessons');
