import React from 'react';

import AppBar from '@material-ui/core/AppBar';

import useStyles from './styles';
import { GuestHeaderMenu } from './components/HeaderMenu';

const GuestHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" classes={{ root: classes.header }} elevation={0}>
      <GuestHeaderMenu />
    </AppBar>
  );
};

export default GuestHeader;
