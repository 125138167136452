import {
  apiV1AdminUsersPath,
  apiV1AdminUserPath,
  apiV1AdminUserImpersonatePath,
  stopImpersonateApiV1AdminUsersPath,
  bulkUpdateApiV1AdminUsersPath,
} from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiV1AdminUsersPath();
    return FetchHelpers.get(path, params);
  },
  show(userId, params) {
    const path = apiV1AdminUserPath(userId);
    return FetchHelpers.get(path, params);
  },
  update(userId, params) {
    const path = apiV1AdminUserPath(userId);
    return FetchHelpers.put(path, params);
  },
  destroy(userId) {
    const path = apiV1AdminUserPath(userId);
    return FetchHelpers.delete(path);
  },
  impersonate(id) {
    const path = apiV1AdminUserImpersonatePath(id);
    return FetchHelpers.post(path);
  },
  stopImpersonate() {
    const path = stopImpersonateApiV1AdminUsersPath();
    return FetchHelpers.post(path);
  },
  bulkUpdate(params) {
    const path = bulkUpdateApiV1AdminUsersPath();
    return FetchHelpers.post(path, params);
  },
};
