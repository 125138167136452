import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';

import UserPresenter from 'presenters/UserPresenter';

import useStyles from './styles';

const DefaultAvatar = props => {
  const { user, className } = props;
  const classes = useStyles();

  return (
    <>
      {UserPresenter.hasAvatar(user) ? (
        <Avatar
          alt="avatar"
          src={UserPresenter.smallAvatarUrl(user)}
          classes={{ root: clsx(classes.avatar, className) }}
        />
      ) : (
        <Avatar alt="avatar" classes={{ root: clsx(classes.avatar, className) }}>
          {UserPresenter.initials(user)}
        </Avatar>
      )}
    </>
  );
};

DefaultAvatar.propTypes = {
  user: PropTypes.shape().isRequired,
  className: PropTypes.string.isRequired,
};

export default DefaultAvatar;
