import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grid: {
    gap: theme.spacing(1),
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gap: theme.spacing(4),
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  avatar: {
    height: 112,
    width: 112,
    fontSize: theme.typography.pxToRem(60),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      height: 148,
      width: 148,
    },
  },

  fullName: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '-0.15px',
    fontWeight: 600,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(34),
      paddingTop: theme.spacing(2),
    },
  },

  email: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.common.brandingBlack,
    letterSpacing: '-0.15px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    '& svg': {
      fill: theme.palette.common.gray8,
    },
  },
}));

export default useStyles;
