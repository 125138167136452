import storeModuleCreators from 'utils/store';
import AdminGroupUsersRepository from 'repositories/AdminGroupUsersRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const {
  slice: sliceGroupUsersIn,
  selectors: selectorsGroupUsersIn,
  useActions: useActionsGroupUsersIn,
} = createLoadActionStoreModule({
  slicePath: ['adminGroupUsers'],
  repositoryAction: AdminGroupUsersRepository.inGroup,
  responseKey: 'users',
});

export const {
  slice: sliceGroupUsersOut,
  selectors: selectorsGroupUsersOut,
  useActions: useActionsGroupUsersOut,
} = createLoadActionStoreModule({
  slicePath: ['adminGroupUsers'],
  repositoryAction: AdminGroupUsersRepository.outGroup,
  responseKey: 'users',
});

export const bulkCreateAdminGroupUsers = (groupId, ids) => AdminGroupUsersRepository.bulkCreate(groupId, ids);
export const destroyAdminGroupUser = (groupId, id) => AdminGroupUsersRepository.destroy(groupId, id);

export default sliceGroupUsersOut.reducer;
