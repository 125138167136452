const authModes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
};

export const isSignIn = mode => mode === authModes.SIGN_IN;
export const isSignUp = mode => mode === authModes.SIGN_UP;
export const isForgotPassword = mode => mode === authModes.FORGOT_PASSWORD;
export const isUpdatePassword = mode => mode === authModes.UPDATE_PASSWORD;

export default authModes;
