import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Backdrop, Box, Card, Container } from '@material-ui/core';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { pageHooks } from 'store/pageSlice';
import MarkdownContent from 'components/MarkdownContent';

import PagePresenter from 'presenters/PagePresenter';

import useStyles from './styles';

const Page = () => {
  const classes = useStyles();
  const { slug } = useParams();

  const {
    loadPage,
    resetPage,
    page: { item: page, loadingStatus },
  } = pageHooks.use();

  useEffect(() => {
    loadPage(slug);

    return () => {
      resetPage();
    };
  }, [slug]);

  const { isIdle, isPending, isFailed } = useFetchStatus(loadingStatus);

  if (isIdle || isPending) {
    return (
      <Container className={classes.root} component="main" maxWidth="lg">
        <Box display="flex" justifyContent="center">
          Loading
        </Box>
      </Container>
    );
  }

  if (isFailed) {
    return <Backdrop open>Error</Backdrop>;
  }

  return (
    <Container className={classes.root} component="main" maxWidth="lg">
      {PagePresenter.title(page) && <h1 className={classes.title}>{PagePresenter.title(page)}</h1>}

      <Card>
        <MarkdownContent className={classes.content} content={PagePresenter.content(page) || ''} />
      </Card>
    </Container>
  );
};

export default Page;
