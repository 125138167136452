import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { map } from 'ramda';

import { TabList, TabContext, TabPanel } from '@material-ui/lab';
import { Typography, Tab, Breadcrumbs } from '@material-ui/core';

import Link from 'components/RouterLink';
import LastActivities from 'sharedContainers/Admin/LastActivities';
import useGanttProgressData from 'hooks/admin/useGanttProgressData';

import appRoutes from 'routes/appRoutes';

import GanttChart from './components/GanttChart';
import AllLessons from './components/AllLessons';

const PANEL_NAME = {
  codeReview: 'codeReview',
  ganttChart: 'ganttChart',
  allLessons: 'allLessons',
};

const UserCourse = () => {
  const [currentTab, setCurrentTab] = useState(PANEL_NAME.codeReview);
  const { t } = useTranslation();
  const { userId, courseId } = map(Number, useParams());

  const {
    ganttProgressData,
    isLoading: isGanttProgressDataLoading,
    loadGanttProgressData,
    resetGanttProgressData,
  } = useGanttProgressData();

  const params = { progressAttributes: { courseId } };

  const handleChange = (events, newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    loadGanttProgressData(userId, params);
    return () => {
      resetGanttProgressData();
    };
  }, [courseId]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={appRoutes.adminUserPath(userId)}>
          <Typography variant="h5" gutterBottom>
            {t('adminCoursesTable.courses')}
          </Typography>
        </Link>
        <Typography variant="h5" gutterBottom>
          {ganttProgressData?.courseName || courseId}
        </Typography>
      </Breadcrumbs>
      <TabContext value={currentTab}>
        <TabList
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="user progress"
          variant="fullWidth"
        >
          <Tab value={PANEL_NAME.codeReview} label={t('adminUserPage.codeReview')} />
          <Tab value={PANEL_NAME.ganttChart} label={t('adminUserPage.ganttChart')} />
          <Tab value={PANEL_NAME.allLessons} label={t('adminUserPage.allLessons')} />
        </TabList>
        <TabPanel value={PANEL_NAME.codeReview}>
          <LastActivities courseId={courseId} userId={userId} />
        </TabPanel>
        <TabPanel value={PANEL_NAME.ganttChart}>
          <GanttChart chartData={ganttProgressData} isLoading={isGanttProgressDataLoading} />
        </TabPanel>
        <TabPanel value={PANEL_NAME.allLessons}>
          <AllLessons courseId={courseId} userId={userId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UserCourse;
