import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  id: PropTypes.number,
  name: PropTypes.string,
  totalStudentsCount: PropTypes.number,
  monthStudentsCount: PropTypes.number,
  completedStudentsCount: PropTypes.number,
});
