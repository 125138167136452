import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
  },

  paper: {
    padding: 30,
  },

  outlined: {
    border: `1px solid ${theme.palette.common.blue7}`,
  },
}));

export default useStyles;
