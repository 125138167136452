import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    padding: [16, 24, 24],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      display: 'flex',
      flexDirection: 'column',
      width: 540,
      padding: [32, 58, 32],
    },
  },

  logo: {
    height: 40,
    width: 119,
    textAlign: 'center',
    marginBottom: 16,
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,

    '&.MuiCardContent-root': {
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
}));

export default useStyles;
