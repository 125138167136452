import {
  apiV1AdminCourseLessonsPath,
  apiV1AdminCourseLessonPath,
  updateAllApiV1AdminCourseLessonsPath,
  bulkUpdateApiV1AdminCourseLessonsPath,
} from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(courseId, attrs) {
    const path = apiV1AdminCourseLessonsPath(courseId, attrs);
    return FetchHelpers.get(path);
  },

  show(courseId, id) {
    const path = apiV1AdminCourseLessonPath(courseId, id);
    return FetchHelpers.get(path);
  },

  create(courseId, lesson) {
    const path = apiV1AdminCourseLessonsPath(courseId);
    return FetchHelpers.post(path, { lesson });
  },

  update(courseId, id, lesson) {
    const path = apiV1AdminCourseLessonPath(courseId, id);
    return FetchHelpers.put(path, { lesson });
  },

  updateAll(courseId, lessons) {
    const path = updateAllApiV1AdminCourseLessonsPath(courseId);
    return FetchHelpers.put(path, { course_lessons_attributes: lessons });
  },

  destroy(courseId, id) {
    const path = apiV1AdminCourseLessonPath(courseId, id);
    return FetchHelpers.delete(path);
  },

  bulkUpdate(courseId, params) {
    const path = bulkUpdateApiV1AdminCourseLessonsPath(courseId);
    return FetchHelpers.post(path, params);
  },
};
