import storeModuleCreators from 'utils/store';
import AdminUserNoteRepository from 'repositories/User/AdminUserNoteRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminUserNotes'],
  repositoryAction: AdminUserNoteRepository.load,
  responseKey: 'notes',
});

export const createAdminUserNote = (userId, note) => AdminUserNoteRepository.create(userId, note);
export const updateAdminUserNote = (userId, id, note) => AdminUserNoteRepository.update(userId, id, note);
export const destroyAdminUserNote = (userId, id, note) => AdminUserNoteRepository.destroy(userId, id, note);

export default slice.reducer;
