import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [24, 16, 33],
    maxWidth: '1100px',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      padding: ['3.6vw', 0],
    },
  },

  title: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 700,
    margin: [24, 0, 16],

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(35),
      margin: [24, 0],
    },
  },

  content: {
    padding: 32,

    '& h3': {
      color: theme.palette.common.brandingBlack,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(23),
      margin: [0, 0, 8],
    },

    '& p': {
      color: theme.palette.common.navyBlue,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      marginBottom: 32,
    },

    '& ul': {
      color: theme.palette.common.navyBlue,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      marginBottom: 32,
      '&:last-child': {
        marginBottom: 0,
      },
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      '& h3': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(26),
        margin: [0, 0, 24],
      },
      '& p': {
        marginBottom: 40,
      },
      '& ul': {
        marginBottom: 40,
      },
    },
  },
}));

export default useStyles;
