import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';

import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminCourses from 'hooks/admin/useCourses';
import { calculateNextOrder } from 'utils/calculateNextOrder';
import { camelize } from 'utils/strings';
import { courseStatesList as courseStates } from 'utils/courseStates';

import ModalConfirmation from 'components/ModalConfirmation';
import CourseModal from './components/CourseModal';
import Courses from './components/Courses';

const CoursesPanel = () => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [hasRemoveFailed, setHasRemoveFailed] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [courseState, setCourseState] = useState('');

  const { currentUser } = useCurrentUser();
  const {
    courses,
    isLoading,
    loadAdminCourses,
    resetAdminCourses,
    createCourse,
    importCourse,
    updateCourse,
    destroyCourse,
  } = useAdminCourses();

  const handleLoadCourses = (newSearchValue = courseName) =>
    loadAdminCourses({
      q: {
        nameCont: newSearchValue,
        stateEq: courseState,
      },
    });

  const debouncedCoursesLoading = useDebouncedCallback(() => handleLoadCourses(courseName), 500);

  const handleChangeSearchValue = ({ target: { value } }) => setCourseName(value);
  const handleChangeStateValue = ({ target: { value } }) => setCourseState(value);

  const handleFiltersReset = () => {
    setCourseName('');
    setCourseState('');
  };

  useEffect(() => {
    debouncedCoursesLoading();
    return () => {
      resetAdminCourses();
    };
  }, [courseName, courseState]);

  const initialOrder = 0;
  const nextOrder = isEmpty(courses) || isNil(courses) ? initialOrder : calculateNextOrder(courses);

  const handleCourseModalOpen =
    (courseData = null) =>
    () => {
      setModalOpen(true);
      if (courseData) {
        setCurrentCourse(courseData);
      }
    };

  const handleCourseModalClose = () => {
    setModalOpen(false);
    setCurrentCourse(null);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentCourse(null);
  };
  const handleModalDeleteFailedClose = () => {
    setHasRemoveFailed(false);
    handleModalDeleteClose();
  };

  const loadCoursesAndCloseModal = () => {
    loadAdminCourses();
    handleCourseModalClose();
  };

  const handleModalOpenDestroy =
    (courseData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (courseData) {
        setCurrentCourse(courseData);
      }
    };

  const handleCourseCreate = values => createCourse(values).then(() => loadCoursesAndCloseModal());

  const handleCourseImport = e => importCourse(e.target.files[0]).then(() => loadAdminCourses());

  const handleCourseUpdate = values => updateCourse(currentCourse.id, values).then(() => loadCoursesAndCloseModal());

  const handleCourseDestroy = async () => {
    try {
      await destroyCourse(currentCourse.id);
      handleModalDeleteClose();
      loadAdminCourses();
    } catch (error) {
      setHasRemoveFailed(true);
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="nameCont"
              label={t('adminCoursesTable.filters.name')}
              value={courseName}
              onChange={handleChangeSearchValue}
              error={false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="state">{t('adminCoursesTable.filters.state')}</InputLabel>
              <Select
                name="stateEq"
                label={t('adminCoursesTable.filters.state')}
                value={courseState}
                onChange={handleChangeStateValue}
              >
                {courseStates.map(state => (
                  <MenuItem value={state} key={state}>
                    {t(`adminCoursesTable.filters.states.${camelize(state)}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {UserPresenter.hasMentorAccess(currentUser) && (
          <Grid item container justifyContent="space-between">
            <Grid item container xs={6} spacing={2}>
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleCourseModalOpen()}>
                  {t('adminCreateCourseButton.createCourse')}
                </Button>
              </Grid>
              <Grid item>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="application/json"
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleCourseImport}
                  />

                  <Button color="primary" variant="outlined" component="span">
                    {t('adminCreateCourseButton.importCourse')}
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={handleFiltersReset}>
                {t('adminUsersFilters.resetButton')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      {(isNil(courses) || isLoading) && (
        <Box p={3}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {!isEmpty(courses) && !isNil(courses) && (
        <Courses courses={courses} onModalOpen={handleCourseModalOpen} onModalOpenDestroy={handleModalOpenDestroy} />
      )}

      <CourseModal
        course={currentCourse}
        nextOrder={nextOrder}
        isModalOpen={isModalOpen}
        onCourseModalClose={handleCourseModalClose}
        onCourseCreate={handleCourseCreate}
        onCourseUpdate={handleCourseUpdate}
      />

      {!hasRemoveFailed ? (
        <ModalConfirmation
          isOpen={isModalDeleteOpen}
          onClose={handleModalDeleteClose}
          onConfirm={handleCourseDestroy}
          title={t('adminCoursesTable.deleteCourseModal.title')}
          content={t('adminCoursesTable.deleteCourseModal.content')}
        />
      ) : (
        <ModalConfirmation
          isOpen={isModalDeleteOpen}
          isNoChoiceProvided={hasRemoveFailed}
          onClose={hasRemoveFailed ? handleModalDeleteFailedClose : handleModalDeleteClose}
          onConfirm={hasRemoveFailed ? handleModalDeleteFailedClose : handleModalDeleteClose}
          title={t('adminCoursesTable.deleteCourseModal.title')}
          content={
            hasRemoveFailed
              ? t('adminCoursesTable.deleteCourseModal.failedContent')
              : t('adminCoursesTable.deleteCourseModal.content')
          }
        />
      )}
    </Box>
  );
};

export default CoursesPanel;
