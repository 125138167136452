import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createTheory,
  updateTheory,
  destroyTheory,
  updateTheories,
  bulkUpdate,
} from 'store/admin/theoriesSlice';

const useTheories = () => {
  const { load: loadAdminTheories, reset: resetAdminTheories, clearLoadingError } = useLoadActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const theories = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    theories,
    loadAdminTheories,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminTheories,
    createTheory,
    updateTheory,
    destroyTheory,
    updateTheories,
    bulkUpdate,
  };
};

export default useTheories;
