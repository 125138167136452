import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const GlobalStyles = props => {
  const { children } = props;
  return children || null;
};

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

GlobalStyles.defaultProps = {
  children: null,
};

export default withStyles(styles)(GlobalStyles);
