import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, Divider, Typography, Paper, Button, Fade, useMediaQuery, useTheme } from '@material-ui/core';
import appRoutes from 'routes/appRoutes';

import UserPresenter from 'presenters/UserPresenter';
import useCurrentUser from 'hooks/useCurrentUser';
import DefaultAvatar from 'components/DefaultAvatar';

import useStyles from './styles';

const UserProfile = () => {
  const { currentUser } = useCurrentUser();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const mobileProfile = (
    <Fade in appear exit timeout={{ enter: 500, exit: 500 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <Typography className={classes.title}>{t('profile.profile')}</Typography>
            <Grid container direction="column" className={classes.profileInfo}>
              <Grid container spacing={2}>
                <Grid item container direction="column" alignItems="center" spacing={1}>
                  <Grid item>
                    <DefaultAvatar user={currentUser} className={classes.avatar} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.fullName} color="textPrimary">
                      {UserPresenter.fullName(currentUser)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" className={classes.divider} />
              <Grid container direction="column" className={classes.userDataBlock}>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.firstName')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.firstName(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.lastName')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.lastName(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.email')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.email(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.password')}:</Typography>
                  <Typography className={classes.dataContent}>****************</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.githubAccount')}:</Typography>
                  <Typography className={classes.dataContent}>
                    github.com/{UserPresenter.githubAccount(currentUser)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.contact')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.contact(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.phone')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.phone(currentUser)}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.buttonsBlock}>
              <Grid item xs={12}>
                <Link to={appRoutes.userEditProfilePath()} className={classes.link}>
                  <Button variant="contained" color="primary" fullWidth className={classes.button}>
                    {t('profile.editProfile')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );

  const desktopProfile = (
    <Fade in={matchesDesktop} appear exit timeout={{ enter: 500 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={9}>
          <Paper className={classes.paper}>
            <Grid container className={classes.profileInfo}>
              <Grid container item className={classes.logoNameBlock}>
                <Grid item>
                  <DefaultAvatar user={currentUser} className={classes.avatar} />
                </Grid>
                <Grid item>
                  <Typography className={classes.fullName} color="textPrimary">
                    {UserPresenter.fullName(currentUser)}
                  </Typography>
                </Grid>
                <Grid container className={classes.buttonsBlock}>
                  <Link to={appRoutes.userEditProfilePath()} className={classes.link}>
                    <Button variant="contained" color="primary" fullWidth className={classes.button}>
                      {t('profile.editProfile')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Divider flexItem className={classes.divider} />
              <Grid container item direction="column" md={7} className={classes.userDataBlock}>
                <Typography className={classes.title}>{t('profile.profile')}</Typography>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.email')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.email(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.password')}:</Typography>
                  <Typography className={classes.dataContent}>****************</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.githubAccount')}:</Typography>
                  <Typography className={classes.dataContent}>
                    github.com/{UserPresenter.githubAccount(currentUser)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.contact')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.contact(currentUser)}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataLabel}>{t('profile.phone')}:</Typography>
                  <Typography className={classes.dataContent}>{UserPresenter.phone(currentUser)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );

  return (
    <>
      {matchesMobile && mobileProfile}
      {matchesDesktop && desktopProfile}
    </>
  );
};

export default UserProfile;
