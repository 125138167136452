import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Route, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { map } from 'ramda';

import { Container, Tabs, Tab, useMediaQuery, useTheme, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import exerciseTypes from 'utils/exerciseTypes';
import appRoutes from 'routes/appRoutes';
import useCourse from 'sharedContainers/Course';

import CoursePresenter from 'presenters/CoursePresenter';

import ScrollButton from 'components/ScrollButton';
import CourseTitle from 'components/CourseTitle';

import { LessonsList, LessonTitle, PracticesList, TheoriesList, CourseIntroduction } from './components';

import useStyles from './styles';

const Exercises = props => {
  const { exercise } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId, lessonId, practiceId, theoryId } = map(Number, useParams());
  const { course, loadCourse, resetCourse } = useCourse();

  const history = useHistory();

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  useEffect(() => {
    loadCourse(courseId);
    return () => {
      resetCourse();
    };
  }, [courseId]);

  const theoriesPath = appRoutes.courseLessonTheoriesPath(courseId, lessonId);
  const practicesPath = appRoutes.courseLessonPracticesPath(courseId, lessonId);
  const introductionPath = appRoutes.courseIntroductionPath(courseId);
  const lessonsPath = appRoutes.courseLessonsPath(courseId, lessonId);

  useEffect(() => {
    localStorage.setItem('currentCourseId', courseId);
  }, []);

  const renderTabs = () => {
    const theoryClasses = clsx({
      [classes.tabItem]: true,
      [classes.tabSelected]: exercise === exerciseTypes.theory,
    });
    const practiceClasses = clsx({
      [classes.tabItem]: true,
      [classes.tabSelected]: exercise === exerciseTypes.practice,
    });

    return (
      <Tabs
        className={classes.tabs}
        value={exercise}
        indicatorColor="primary"
        TabIndicatorProps={{
          className: classes.indicator,
        }}
        color="primary"
        variant={!matchesLaptop ? 'fullWidth' : 'standard'}
      >
        <Tab
          value={exerciseTypes.theory}
          component={Link}
          className={theoryClasses}
          to={theoriesPath}
          label={t('studentLesson.theory')}
        />
        <Tab
          value={exerciseTypes.practice}
          component={Link}
          className={practiceClasses}
          to={practicesPath}
          label={t('studentLesson.practice')}
        />
      </Tabs>
    );
  };

  const laptopExercises = (
    <>
      <div className={classes.sidebar}>
        <CourseTitle courseId={courseId} />
        <LessonsList courseId={courseId} lessonId={lessonId} />
      </div>

      <Container disableGutters>
        <div className={classes.wrapper}>
          <Route path={introductionPath}>
            <CourseIntroduction />
          </Route>
          {lessonId && (
            <>
              <LessonTitle courseId={courseId} lessonId={lessonId} />
              {renderTabs()}
            </>
          )}
          <Route path={practicesPath}>
            <PracticesList courseId={courseId} lessonId={lessonId} practiceId={practiceId} />
          </Route>
          <Route path={theoriesPath}>
            <TheoriesList courseId={courseId} lessonId={lessonId} theoryId={theoryId} />
          </Route>
        </div>
      </Container>
    </>
  );

  const mobileExercises = (
    <>
      <Route path={introductionPath}>
        <Button onClick={() => history.goBack()} className={classes.backLink}>
          <ArrowBackIcon fontSize="inherit" />
          {CoursePresenter.name(course)}
        </Button>
        <CourseIntroduction />
      </Route>

      <Route path={[theoriesPath, practicesPath]}>
        <Link to={lessonsPath} className={classes.backLink}>
          <ArrowBackIcon fontSize="inherit" />
          {CoursePresenter.name(course)}
        </Link>
        {lessonId && (
          <>
            <LessonTitle courseId={courseId} lessonId={lessonId} />
            {renderTabs()}
          </>
        )}
      </Route>

      <Route path={theoriesPath}>
        <TheoriesList courseId={courseId} lessonId={lessonId} theoryId={theoryId} />
      </Route>

      <Route path={practicesPath}>
        <PracticesList courseId={courseId} lessonId={lessonId} practiceId={practiceId} />
      </Route>
    </>
  );

  return (
    <div className={classes.container}>
      {matchesLaptop && laptopExercises}
      {matchesMobile && mobileExercises}

      <ScrollButton styles={classes.scrollButton} />
    </div>
  );
};

Exercises.propTypes = {
  exercise: PropTypes.oneOf(Object.values(exerciseTypes)),
};

Exercises.defaultProps = {
  exercise: null,
};

export default Exercises;
