import * as yup from 'yup';

import lessonStates from 'utils/lessonStates';
import { AVAILABLE_LESSON_STATES_REGEX } from 'utils/regularExpressions';
import LessonPresenter from 'presenters/LessonPresenter';

const validationFields = {
  name: yup.string().required('validations.lesson.name.required'),
  requiredTime: yup
    .number()
    .typeError('validations.lesson.requiredTime.type')
    .positive('validations.lesson.requiredTime.positive')
    .integer('validations.lesson.requiredTime.integer')
    .required('validations.lesson.requiredTime.required'),
  state: yup.string().matches(AVAILABLE_LESSON_STATES_REGEX, { message: 'validations.lesson.state.invalid' }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (lesson, nextOrder) => ({
  name: LessonPresenter.name(lesson) || '',
  order: LessonPresenter.order(lesson) || nextOrder,
  requiredTime: LessonPresenter.requiredTime(lesson) || 1,
  interviewTrigger: LessonPresenter.interviewTrigger(lesson) || false,
  state: lesson ? LessonPresenter.state(lesson) : lessonStates.draft,
  availableStates: [lessonStates.published, lessonStates.archived, lessonStates.draft, lessonStates.internalPublished],
});
