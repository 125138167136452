import { apiV1SessionPath, destroyGoogleApiV1SessionPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = apiV1SessionPath();
    return FetchHelpers.post(path, params);
  },
  destroy() {
    const path = apiV1SessionPath();
    return FetchHelpers.delete(path);
  },
  unlinkAccount() {
    const path = destroyGoogleApiV1SessionPath();
    return FetchHelpers.put(path);
  },
};
