import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { isNil, map } from 'ramda';

import { Container, Paper, Backdrop } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUser from 'hooks/admin/useUser';

import appRoutes from 'routes/appRoutes';
import UserSettings from './containers/UserSettings';
import UserCourses from './containers/UserCourses';
import UserCourse from './containers/UserCourse';
import UserNotes from './containers/UserNotes';

import useStyles from './styles';

const UserPage = () => {
  const { loadUser, user } = useUser();
  const { userId } = map(Number, useParams());
  const classes = useStyles();

  useEffect(() => {
    loadUser(userId);
  }, []);

  if (isNil(user)) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Container>
      <div className={classes.root}>
        <Paper elevation={0} variant="outlined" classes={{ root: classes.paper, outlined: classes.outlined }}>
          <UserSettings user={user} userId={userId} />
        </Paper>
      </div>

      <div className={classes.root}>
        <Paper elevation={0} variant="outlined" classes={{ root: classes.paper, outlined: classes.outlined }}>
          <Route path={appRoutes.adminUserPath(':userId')} exact>
            <UserCourses user={user} userId={userId} />
          </Route>

          <Route path={appRoutes.adminUserCoursePath(':userId', ':courseId')} exact>
            <UserCourse user={user} userId={userId} />
          </Route>
        </Paper>
      </div>

      <div className={classes.root}>
        <Paper elevation={0} variant="outlined" classes={{ root: classes.paper, outlined: classes.outlined }}>
          <UserNotes />
        </Paper>
      </div>
    </Container>
  );
};

export default UserPage;
