import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createAdminUserCourse,
  updateAdminUserCourse,
} from 'store/admin/user/userCoursesSlice';

const useUserCourse = () => {
  const { load: loadAdminUserCourses, reset: resetAdminUserCourses, clearLoadingError } = useLoadActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const userCourses = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    userCourses,
    loadAdminUserCourses,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminUserCourses,
    createAdminUserCourse,
    updateAdminUserCourse,
  };
};

export default useUserCourse;
