import React from 'react';
import { Switch, Redirect } from 'react-router';

import { useMediaQuery, useTheme } from '@material-ui/core';

import AppRoute from 'layouts/components/AppRoute';

import Authenticator from 'containers/Authenticator';
import Vacancies from 'containers/Guest/Vacancies';
import Vacancy from 'containers/Guest/Vacancy';
import Page from 'containers/Guest/Page';
import Courses from 'containers/Guest/Courses';

import Error404 from 'components/Error404';

import authModes from 'utils/authModes';

import appRoutes from 'routes/appRoutes';

const NotAuthenticatedUserSwitch = () => {
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  return (
    <Switch>
      <AppRoute path={appRoutes.coursesPath()} exact layout={matchesMobile ? 'dashboard' : 'auth'}>
        <Courses />
      </AppRoute>
      <AppRoute path={appRoutes.vacanciesPath()} exact layout="dashboard">
        <Vacancies />
      </AppRoute>
      <AppRoute path={appRoutes.vacancyPath(':vacancyId')} exact layout="dashboard">
        <Vacancy />
      </AppRoute>
      <AppRoute path={appRoutes.pagePath(':slug')} exact layout="dashboard">
        <Page />
      </AppRoute>
      <AppRoute
        key={appRoutes.forgotPasswordPath()}
        path={appRoutes.forgotPasswordPath()}
        render={props => <Authenticator {...props} mode={authModes.FORGOT_PASSWORD} />}
        layout="auth"
      />
      <AppRoute
        key={appRoutes.updatePasswordPath()}
        path={appRoutes.updatePasswordPath()}
        render={props => <Authenticator {...props} mode={authModes.UPDATE_PASSWORD} />}
        layout="auth"
      />
      <AppRoute
        key={appRoutes.signInPath()}
        path={appRoutes.signInPath()}
        render={props => <Authenticator {...props} mode={authModes.SIGN_IN} />}
        layout="auth"
      />
      <AppRoute
        key={appRoutes.signUpPath()}
        path={appRoutes.signUpPath()}
        render={props => <Authenticator {...props} mode={authModes.SIGN_UP} />}
        layout="auth"
      />
      <AppRoute key={appRoutes.googleOauthPath()} path={appRoutes.googleOauthPath()} layout="auth" />
      <AppRoute path={appRoutes.error404Path()} exact layout="dashboard">
        <Error404 />
      </AppRoute>
      <Redirect to={appRoutes.signInPath()} />
    </Switch>
  );
};

export default NotAuthenticatedUserSwitch;
