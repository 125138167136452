import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty, map, split, without, append, not, uniqBy, insertAll, length } from 'ramda';

import { Grid, Box, TextField, Button, Typography, Chip } from '@material-ui/core';

import UserPresenter from 'presenters/UserPresenter';

import UsersTable from 'containers/Admin/containers/DashboardPage/components/UsersPanel/components/UsersTable';

import useGroupUsers from 'hooks/admin/useGroupUsers';

import { DEFAULT_ROWS_PER_PAGE, FIRST_PAGE } from 'utils/pagination';

const GroupUsers = () => {
  const { t } = useTranslation();
  const { groupId } = map(Number, useParams());
  const [searchEmails, setSearchEmails] = useState('');
  const [sort, setSort] = useState('created_at asc');
  const [page, setPage] = useState(FIRST_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSortChange = ({ target: { value } }) => setSort(value);
  const handlePerPageChange = newValue => setPerPage(newValue);
  const handlePageChange = newValue => setPage(newValue);

  const {
    loadGroupUsersOut,
    resetGroupUsersOut,
    outGroupUsers,
    isOutGroupLoading: loading,
    outGroupLoadingError: loadingError,
    outGroupMeta: meta,
    bulkCreateAdminGroupUsers,
  } = useGroupUsers();

  const handleLoadData = (newSearchValue = searchEmails) =>
    loadGroupUsersOut(groupId, {
      q: {
        s: sort,
        emailIn: split(';', newSearchValue),
      },
      page,
      perPage,
    });

  const debouncedDataLoading = useDebouncedCallback(newSearchValue => handleLoadData(newSearchValue), 500);

  const handleChangeSearchValue = ({ target: { value: newSearchValue } }) => {
    const searchDependingDataLoadingAction = isEmpty(newSearchValue) ? handleLoadData : debouncedDataLoading;

    setSearchEmails(newSearchValue);
    searchDependingDataLoadingAction(newSearchValue);
    setPage(FIRST_PAGE);
  };

  useEffect(() => {
    handleLoadData();
    return () => {
      resetGroupUsersOut();
    };
  }, [sort, page, perPage]);

  const handleDeselect = user => () => {
    setSelectedUsers(without([user], selectedUsers));
  };

  const handleSelect = user => e => {
    if (e.target.checked) {
      setSelectedUsers(append(user, selectedUsers));
    } else {
      handleDeselect(user)();
    }
  };

  const handleSelectAll = () => {
    if (length(outGroupUsers) === length(selectedUsers)) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(outGroupUsers);
    }
  };

  const handleBulkPick = () => {
    const uniqUsers = uniqBy(UserPresenter.id, insertAll(length(selectedUsers), outGroupUsers, selectedUsers));
    setSelectedUsers(uniqUsers);
  };

  const handleBulkSave = async () => {
    const ids = map(UserPresenter.id, selectedUsers);
    await bulkCreateAdminGroupUsers(groupId, ids);
    setPage(FIRST_PAGE);
    setSelectedUsers([]);
    handleLoadData();
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item container direction="column" spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              name="findByFullNameOrEmail"
              label={t('adminGroupManage.filters.emails')}
              value={searchEmails}
              onChange={handleChangeSearchValue}
              error={false}
            />
          </Grid>

          <Grid item xs>
            <Button variant="outlined" color="primary" onClick={handleBulkPick}>
              {t('adminGroupManage.actions.pickAll')}
            </Button>
          </Grid>

          {not(isEmpty(selectedUsers)) && (
            <>
              <Grid item xs>
                <Typography>
                  {t('adminGroupManage.filters.selected')}
                  {length(selectedUsers)}
                </Typography>
                {map(
                  u => (
                    <Box component="span" mr={1} mb={1} display="inline-block" key={UserPresenter.id(u)}>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={`${UserPresenter.fullName(u)} (${UserPresenter.email(u)})`}
                        onDelete={handleDeselect(u)}
                      />
                    </Box>
                  ),
                  selectedUsers,
                )}
              </Grid>
              <Grid item xs>
                <Button variant="outlined" color="primary" onClick={handleBulkSave}>
                  {t('adminGroupManage.actions.save')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <UsersTable
          users={outGroupUsers}
          meta={meta}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePerPageChange}
          currentSort={sort}
          isCourseChosen={false}
          rowsPerPage={perPage}
          loading={loading}
          loadingError={loadingError}
          isSelectMode
          selectedUsers={selectedUsers}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          onFiltersChange={handleSortChange}
          loadUsers={handleLoadData}
          groupContext
        />
      </Grid>
    </Grid>
  );
};

export default GroupUsers;
