import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import CoursePresenter from 'presenters/CoursePresenter';

import useStyles from './styles';

const Course = props => {
  const { course, isLoading } = props;
  const classes = useStyles();

  if (isNil(course) || isLoading) {
    return (
      <Typography variant="subtitle2" className={classes.titleSkeleton}>
        <Skeleton width={300} />
      </Typography>
    );
  }

  return <Typography className={classes.title}>{CoursePresenter.name(course)}</Typography>;
};

Course.propTypes = {
  course: PropTypes.shape(),
  isLoading: PropTypes.bool,
};

Course.defaultProps = {
  course: null,
  isLoading: false,
};

export default Course;
