import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Icon from 'components/Icon';
import CoursePresenter from 'presenters/User/CoursePresenter';
import MarkdownContent from 'components/MarkdownContent';

import useStyles from './styles';

const CourseCard = props => {
  const { course, onJoinCourse } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const buttonText = CoursePresenter.isJoined(course)
    ? t('coursesCard.joinedCourseAction')
    : t('coursesCard.courseAction');

  return (
    <Card key={CoursePresenter.id(course)}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardMain}>
          <div className={classes.header}>
            <Typography variant="h5" classes={{ root: classes.courseName }}>
              {CoursePresenter.name(course)}
            </Typography>
            <div className={classes.icons}>
              {CoursePresenter.icons(course).map(icon => (
                <Icon key={icon} name={icon} />
              ))}
            </div>
          </div>
          {CoursePresenter.isCompleted(course) && (
            <Typography variant="body2" gutterBottom classes={{ root: classes.courseFinished }}>
              {t('coursesCard.courseFinished')}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary" classes={{ root: classes.description }} component="div">
            <MarkdownContent content={CoursePresenter.description(course)} className={classes.markdown} />
          </Typography>
        </div>
        <div className={classes.cardFooter}>
          <div className={classes.info}>
            <div className={clsx(classes.infoBox, classes.practices)}>
              <Icon name="notebook" width="18px" />
              <Typography variant="body2" color="textSecondary" classes={{ root: classes.durationLabel }}>
                {CoursePresenter.practicesCount(course)}
                &nbsp;
                {t('coursesCard.practices')}
              </Typography>
            </div>
            <div className={clsx(classes.infoBox, classes.theories)}>
              <Icon name="book" width="18px" />
              <Typography variant="body2" color="textSecondary" classes={{ root: classes.durationLabel }}>
                {CoursePresenter.theoriesCount(course)}
                &nbsp;
                {t('coursesCard.theories')}
              </Typography>
            </div>
            <div className={clsx(classes.infoBox, classes.hours)}>
              <Icon name="clock" width="18px" />
              <Typography variant="body2" color="textSecondary" classes={{ root: classes.durationLabel }}>
                {CoursePresenter.approxHoursCount(course)}
                &nbsp;
                {t('coursesCard.hours')}
              </Typography>
            </div>
          </div>
          <div className={classes.button}>
            <Button variant="contained" color="primary" onClick={onJoinCourse} classes={{ root: classes.courseButton }}>
              {buttonText}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

CourseCard.propTypes = {
  course: CoursePresenter.shape().isRequired,
  onJoinCourse: PropTypes.func.isRequired,
};

export default CourseCard;
