import React from 'react';
import { range } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

const COUNT_SKELETON_ITEMS = 3;
const CURRENT_LIST_ITEM = 0;

const PracticesListSkeleton = () => {
  const classes = useStyles();

  const skeletonItems = range(0, COUNT_SKELETON_ITEMS).map(item => {
    return (
      <ListItem key={item} className={classes.practiceItem} divider>
        <div className={classes.practiceBox}>
          <div className={classes.mainBox}>
            <Skeleton variant="rect" width={20} height={20} className={classes.practiceCheckbox} />
            <Skeleton variant="text" width="70%" className={classes.practiceName} />
          </div>
          {item === CURRENT_LIST_ITEM && (
            <Skeleton variant="rect" width="95%" height={40} className={classes.prLinkRect} />
          )}
        </div>
      </ListItem>
    );
  });

  return (
    <Grid container>
      <Grid item xs={4}>
        <List disablePadding>{skeletonItems}</List>
      </Grid>
      <Grid item xs={8}>
        <Box px={4} py={3}>
          <Skeleton variant="text" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PracticesListSkeleton;
