import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import CurrentUserRepository from 'repositories/CurrentUserRepository';
import SessionsRepository from 'repositories/SessionsRepository';

const sliceName = 'currentUser';

/* eslint no-param-reassign: 0 */

const slice = createSlice({
  name: sliceName,
  initialState: {
    data: null,
    processing: true,
    processingError: null,
  },
  reducers: {
    startLoading(state) {
      state.processingError = null;
      state.processing = true;
    },
    loadingError(state, { payload }) {
      state.processingError = payload.error;
    },
    loadSuccess(state, { payload }) {
      state.data = payload.data.user;
      state.processingError = null;
    },
    logout(state) {
      state.data = null;
    },
    loadFinish(state) {
      state.processing = false;
    },
  },
});

/* eslint no-param-reassign: 1 */

export const { startLoading, loadingError, loadSuccess, logout, loadFinish } = slice.actions;

export const useActions = () => {
  const dispatch = useDispatch();
  return {
    loadCurrentUser: async () => {
      dispatch(startLoading());

      try {
        const { data } = await CurrentUserRepository.loadCurrentUser();
        return dispatch(loadSuccess({ data }));
      } catch (error) {
        const {
          response: { status, statusText },
        } = error;

        return dispatch(loadingError({ error: { status, statusText } }));
      } finally {
        dispatch(loadFinish());
      }
    },

    logout: async () => {
      try {
        await SessionsRepository.destroy();
        return dispatch(logout());
      } catch (error) {
        const {
          responce: { status, statusText },
        } = error;

        return dispatch(loadingError({ error: { status, statusText } }));
      } finally {
        dispatch(loadFinish());
      }
    },
  };
};

export const updateCurrentUser = user => CurrentUserRepository.update(user);
export const unlinkAccount = () => SessionsRepository.unlinkAccount();

export const getSelectors = () => {
  const currentUser = useSelector(state => state[sliceName]);

  return {
    getCurrentUser: () => currentUser.data,
    getLoadingState: () => currentUser.processing,
    getLoadingError: () => currentUser.processingError,
  };
};

export default slice.reducer;
