import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    fontWeight: 500,
    color: theme.palette.common.brandingBlack,
    textAlign: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
      textAlign: 'left',
      marginBottom: 0,
    },
  },

  titleSkeleton: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    paddingTop: 17,
    paddingLeft: 71,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
    },
  },
}));

export default useStyles;
