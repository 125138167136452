import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cursor: {
    cursor: 'move',
  },

  tableRow: {
    backgroundColor: theme.palette.common.white,
  },

  tableCell: {
    borderTop: `1px solid ${theme.palette.common.gray}`,
  },
}));

export default useStyles;
