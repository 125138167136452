import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';

import UserPresenter from 'presenters/UserPresenter';

import useUsers from 'hooks/admin/useUsers';

import AutocompleteAsync from 'components/AutocompleteAsync';

const Teachers = ({ selected, onChange, name, label, required, ...rest }) => {
  const { processing: loading, loadUsers } = useUsers();

  const getOptionLabel = user => take(22, UserPresenter.fullName(user));

  const handleLoad = newSearchValue =>
    loadUsers({ q: { findByFullNameOrEmail: newSearchValue, roleIn: ['admin', 'mentor', 'teacher'] } });

  return (
    <AutocompleteAsync
      selected={selected}
      onChange={onChange}
      name={name}
      label={label}
      required={required}
      loadData={handleLoad}
      loading={loading}
      getOptionLabel={getOptionLabel}
      multiple
      responsePath={['users', 'users']}
      {...rest}
    />
  );
};

Teachers.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

Teachers.defaultProps = {
  selected: null,
  required: false,
};

export default Teachers;
