import * as yup from 'yup';
import { PASSWORD_REGEX, GITHUB_REGEX } from 'utils/regularExpressions';

const validationFields = {
  firstName: yup.string().min(1, 'validations.firstName.minLength').required('validations.firstName.required'),
  lastName: yup.string().min(1, 'validations.lastName.minLength').required('validations.lastName.required'),
  email: yup
    .string()
    .min(1, 'validations.email.minLength')
    .max(255, 'validations.email.maxLength')
    .email('validations.email.invalid')
    .required('validations.email.empty'),
  password: yup
    .string()
    .required('validations.password.required')
    .matches(PASSWORD_REGEX, { message: 'validations.password.invalid' }),
  githubAccount: yup
    .string()
    .required('validations.githubAccount.required')
    .matches(GITHUB_REGEX, { message: 'validations.githubAccount.invalid' }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  githubAccount: '',
};
