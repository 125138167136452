import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import appRoutes from 'routes/appRoutes';

import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';

import UsersPanel from './components/UsersPanel';
import CoursesPanel from './components/CoursesPanel';
import VacanciesPanel from './components/VacanciesPanel';
import GroupsPanel from './components/GroupsPanel';
import StatsPanel from './components/StatsPanel';
import PagesPanel from './components/PagesPanel';

import useStyles from './styles';

const PANEL_NAME = {
  users: 'users',
  courses: 'courses',
  stats: 'stats',
  vacancies: 'vacancies',
  groups: 'groups',
  pages: 'pages',
};

const DashboardPage = props => {
  const { panelName } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  return (
    <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
      <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
        <Tabs value={panelName} indicatorColor="primary" textColor="primary" aria-label="admin menu tabs">
          <Tab label={t('adminMenu.users')} value={PANEL_NAME.users} component={Link} to={appRoutes.adminUsersPath()} />
          <Tab
            label={t('adminMenu.groups')}
            value={PANEL_NAME.groups}
            component={Link}
            to={appRoutes.adminGroupsPath()}
          />
          {(UserPresenter.hasMentorAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
            <Tab
              label={t('adminMenu.courses')}
              value={PANEL_NAME.courses}
              component={Link}
              to={appRoutes.adminCoursesPath()}
            />
          )}
          <Tab label={t('adminMenu.stats')} value={PANEL_NAME.stats} component={Link} to={appRoutes.adminStatsPath()} />
          {(UserPresenter.hasAdminAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
            <Tab
              label={t('adminMenu.vacancies')}
              value={PANEL_NAME.vacancies}
              component={Link}
              to={appRoutes.adminVacanciesPath()}
            />
          )}
          {(UserPresenter.hasAdminAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
            <Tab
              label={t('adminMenu.pages')}
              value={PANEL_NAME.pages}
              component={Link}
              to={appRoutes.adminPagesPath()}
            />
          )}
        </Tabs>

        {panelName === PANEL_NAME.users && <UsersPanel />}
        {panelName === PANEL_NAME.groups && <GroupsPanel />}
        {panelName === PANEL_NAME.courses && <CoursesPanel />}
        {panelName === PANEL_NAME.stats && <StatsPanel />}
        {panelName === PANEL_NAME.vacancies && <VacanciesPanel />}
        {panelName === PANEL_NAME.pages && <PagesPanel />}
      </Paper>
    </Container>
  );
};

DashboardPage.propTypes = {
  panelName: PropTypes.string,
};

DashboardPage.defaultProps = {
  panelName: PANEL_NAME.users,
};

export default DashboardPage;
