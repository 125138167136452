import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import VacancyFeedbackCard from 'components/VacancyFeedbackCard';
import VacancyMoneyCard from 'components/VacancyMoneyCard';

import useStyles from './styles';

const Aside = props => {
  const { vacancyTitle } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <VacancyFeedbackCard
        title={t('vacancy.cards.feedback.title')}
        content={t('vacancy.cards.feedback.content')}
        buttonText={t('vacancy.cards.feedback.writeToEmail')}
        emoji="🧰"
        vacancyTitle={vacancyTitle}
      />

      <VacancyMoneyCard title={t('vacancy.cards.money.title')} content={t('vacancy.cards.money.content')} />
    </Box>
  );
};
Aside.propTypes = {
  vacancyTitle: PropTypes.string,
};
Aside.defaultProps = {
  vacancyTitle: 'Vacancy',
};

export default Aside;
