import React, { useEffect } from 'react';
import { isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/lab/Alert';
import { Backdrop, Container, Box, useMediaQuery, useTheme, Typography } from '@material-ui/core';

import appRoutes from 'routes/appRoutes';
import useCourses from 'hooks/useCourses';

import CoursePresenter from 'presenters/CoursePresenter';

import CourseCard from 'components/CourseCard';
import CoursesCardsSkeleton from 'components/CoursesCardsSkeleton';

import useStyles from './styles';

const Courses = () => {
  const classes = useStyles();
  const { courses, loadCourses, isLoading, loadingError, clearLoadingError, resetCourses } = useCourses();
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const history = useHistory();
  const handleJoinCourse = () => {
    history.push(appRoutes.signInPath());
  };

  useEffect(() => {
    loadCourses();
    return () => {
      resetCourses();
    };
  }, []);

  if (isNil(courses) || isLoading) {
    return (
      <Container component="main" maxWidth="lg">
        <Box display="flex" justifyContent="center">
          <div className={classes.paper}>
            <CoursesCardsSkeleton />
          </div>
        </Box>
      </Container>
    );
  }

  if (isNil(courses) && !isNil(loadingError)) {
    return (
      <Backdrop open>
        <Alert severity="error" onClose={clearLoadingError}>
          {loadingError}
        </Alert>
      </Backdrop>
    );
  }

  return (
    <Container component="main" maxWidth="sm" className={classes.root}>
      <Box>
        {matchesMobile && (
          <Typography variant="h1" className={classes.title}>
            {t('header.courses')}
          </Typography>
        )}
        <div className={classes.paper}>
          {courses.map(course => (
            <CourseCard key={CoursePresenter.id(course)} course={course} onJoinCourse={handleJoinCourse} />
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default Courses;
