/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminCourseStatsRepository from 'repositories/AdminCourseStatsRepository';

import { createRestSlice } from 'utils/RestSlice';

const courseStatsRestSlice = createRestSlice({
  resource: 'courseStat',
  repository: AdminCourseStatsRepository,
  slices: ['loadCourseStats'],
});

const initialState = {
  ...courseStatsRestSlice.initialState,
};

const courseStatsSlice = createSlice({
  name: 'adminCourseStats',
  initialState,
  reducers: {
    ...courseStatsRestSlice.reducers,
  },
});

export default courseStatsSlice.reducer;

export const courseStatsHooks = courseStatsRestSlice.hooks(courseStatsSlice, 'adminCourseStats');
