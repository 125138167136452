import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';

import GroupPresenter from 'presenters/GroupPresenter';
import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { groupsHooks } from 'store/admin/groupsSlice';
import { groupHooks } from 'store/admin/groupSlice';

import ModalConfirmation from 'components/ModalConfirmation';
import GroupModal from './components/GroupModal';
import Group from './components/Group';

const GroupsPanel = () => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);

  const { currentUser } = useCurrentUser();

  const { updateGroup, createGroup, deleteGroup } = groupHooks.use();

  const {
    firstLoadGroups: loadGroups,
    groups: { items: groups, loadingStatus },
  } = groupsHooks.use();

  const { isIdle } = useFetchStatus(loadingStatus);

  useEffect(() => {
    loadGroups();
  }, []);

  if (isIdle) {
    return (
      <Box p={3}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const handleGroupModalOpen =
    (groupData = null) =>
    () => {
      setModalOpen(true);
      if (groupData) {
        setCurrentGroup(groupData);
      }
    };

  const handleGroupModalClose = () => {
    setModalOpen(false);
    setCurrentGroup(null);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentGroup(null);
  };

  const loadGroupsAndCloseModal = () => {
    loadGroups();
    handleGroupModalClose();
  };

  const handleModalOpenDestroy =
    (groupData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (groupData) {
        setCurrentGroup(groupData);
      }
    };

  const handleGroupCreate = values => createGroup(values).then(() => loadGroupsAndCloseModal());

  const handleGroupUpdate = values => updateGroup(currentGroup.id, values).then(() => loadGroupsAndCloseModal());

  const handleCourseDestroy = async () => {
    await deleteGroup(currentGroup.id);
    handleModalDeleteClose();
    loadGroups();
  };

  return (
    <Box p={3}>
      {UserPresenter.hasAdminAccess(currentUser) && (
        <Button color="primary" variant="outlined" onClick={handleGroupModalOpen()}>
          {t('adminCreateGroupButton.createGroup')}
        </Button>
      )}
      <Table aria-label="groups table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell>{t('adminGroupsTable.name')}</TableCell>
            <TableCell>{t('adminGroupsTable.startAt')}</TableCell>
            <TableCell>{t('adminGroupsTable.endAt')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map(group => (
            <Group
              key={GroupPresenter.id(group)}
              group={group}
              onGroupModalOpen={handleGroupModalOpen}
              onModalOpenDestroy={handleModalOpenDestroy}
            />
          ))}
        </TableBody>
      </Table>
      <GroupModal
        group={currentGroup}
        isModalOpen={isModalOpen}
        onGroupModalClose={handleGroupModalClose}
        onGroupCreate={handleGroupCreate}
        onGroupUpdate={handleGroupUpdate}
      />

      <ModalConfirmation
        isOpen={isModalDeleteOpen}
        onClose={handleModalDeleteClose}
        onConfirm={handleCourseDestroy}
        title={t('adminGroupsTable.deleteGroupModalTitle')}
        content={t('adminGroupsTable.deleteGroupModalContent')}
      />
    </Box>
  );
};

export default GroupsPanel;
