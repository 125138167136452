import pluralize from 'pluralize';
import { pipe, pluck, reject, map, isNil, reduce, mergeDeepRight } from 'ramda';

export function validateConfig(config, availableSlices) {
  if (!config.resource) {
    throw new Error('REST Slice: Please define the resource name');
  }
  if (!config.repository) {
    throw new Error('REST Slice: Please define the resource repository object');
  }

  if (!config.slices || config.slices.length === 0) {
    const availableText = Object.keys(availableSlices)
      .map(k => k.replace('Resource', '[Resource]'))
      .join(', ');
    throw new Error(`REST Slice: Please define slices for use. Available are: ${availableText}`);
  }
}

export function populateOptions(config) {
  const { resource, repository } = config;

  const pluralResource = pluralize(resource);

  return {
    resource,
    repository,
    pluralResource,
  };
}

export function combineSlicesBy(key, f) {
  return pipe(pluck(key), reject(isNil), f ? map(f) : r => r, reduce(mergeDeepRight, {}));
}
