import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: theme.typography.pxToRem(40),
  },
}));

export default useStyles;
