import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { isEmpty } from 'ramda';

import { Box, Divider, ListItem, TextField, Typography } from '@material-ui/core';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { userPracticesHooks } from 'store/user/practicesSlice';

import { lessonsHooks } from 'store/user/lessonsSlice';
import appRoutes from 'routes/appRoutes';
import PracticePresenter from 'presenters/User/PracticePresenter';
import { stateEvents } from 'utils/userPracticeStates';
import CheckboxOutlineBlank from 'components/CheckboxOutlineBlank';

import useStyles from './styles';

const PracticeItem = props => {
  const { courseId, lessonId, userPractice, practiceId, isListLoadingPending, isAdminView, children } = props;
  const classes = useStyles();

  const { loadLessons: loadUserLessons } = lessonsHooks.use();

  const {
    loadPractices,
    createPractice,
    updatePractice,
    practice: { updatingStatus },
  } = userPracticesHooks.use();

  const { isPending: isLoadingStatusPending } = useFetchStatus(updatingStatus);

  const isCurrent = PracticePresenter.isCurrent(userPractice, practiceId);
  const isPending = PracticePresenter.isPending(userPractice);
  const isAccepted = PracticePresenter.isAccepted(userPractice);
  const isRejected = PracticePresenter.isRejected(userPractice);
  const isStarted = PracticePresenter.isStarted(userPractice);
  const isCompleted = PracticePresenter.isCompleted(userPractice);

  const isSentPractice = isPending || isAccepted || isCompleted;
  const isOnlyOnce = isSentPractice && PracticePresenter.isOnlyOnce(userPractice);
  const isResultRequired = PracticePresenter.isResultRequired(userPractice);

  const [prLinkValue, changePrLinkValue] = useState(PracticePresenter.getPrLink(userPractice));
  const shouldShowPrLinkField = (isCurrent && isResultRequired) || (isAdminView && !isEmpty(prLinkValue));
  const isBlockedPractice = isAccepted || !isCurrent || (isResultRequired && isEmpty(prLinkValue));

  const loadUserPracticesAndLessons = () => {
    loadPractices(lessonId);
    loadUserLessons(courseId);
  };

  const createUserPractice = (id, prLink) => {
    createPractice(id, { prLink }).then(() => {
      loadUserPracticesAndLessons();
    });
  };

  const updateUserPractice = (id, stateEvent, prLink) => {
    updatePractice(id, { stateEvent, prLink })
      .then(() => {
        loadUserPracticesAndLessons();
      })
      .catch(() => {
        loadUserPracticesAndLessons();
      });
  };

  const handleCurrentCheckboxChange =
    id =>
    ({ target: { checked } }) => {
      const shouldCreatePracticeWithPrLink = isResultRequired && prLinkValue;
      const shouldCreatePractice = shouldCreatePracticeWithPrLink || !isResultRequired;
      if (!isStarted && shouldCreatePractice) {
        return createUserPractice(id, prLinkValue);
      }
      if (!checked) {
        return updateUserPractice(id, stateEvents.rollback, prLinkValue);
      }
      const stateEvent = isResultRequired ? stateEvents.toReview : stateEvents.complete;
      return updateUserPractice(id, stateEvent, prLinkValue);
    };

  const handleCurrentPrLinkChange =
    id =>
    ({ target: { value } }) => {
      changePrLinkValue(value);
      if (!isStarted && isSentPractice) {
        createUserPractice(id, value);
      } else if (isSentPractice) {
        const stateEvent = isSentPractice ? stateEvents.toReview : stateEvents.rollback;
        updateUserPractice(id, stateEvent, value);
      }
    };

  const practiceItemClasses = clsx(classes.practiceItem, { [classes.practiceItemCurrent]: isCurrent });
  const practiceLinkClasses = clsx(classes.link, {
    [classes.highZIndexLink]: !isCurrent,
  });

  return (
    <>
      <ListItem className={practiceItemClasses}>
        <div className={classes.listBox}>
          {!isAdminView && (
            <Link
              to={appRoutes.courseLessonPracticePath(courseId, lessonId, PracticePresenter.id(userPractice))}
              className={practiceLinkClasses}
            />
          )}
          <div className={classes.practice}>
            <div className={classes.checkbox}>
              <CheckboxOutlineBlank
                checked={isSentPractice}
                onChange={handleCurrentCheckboxChange(PracticePresenter.id(userPractice))}
                disabled={
                  isBlockedPractice || isLoadingStatusPending || isListLoadingPending || isAdminView || isOnlyOnce
                }
                dangerUnchecked={isRejected}
                aria-label="Mark completed"
              />
            </div>
            <Typography className={classes.practiceName}>{PracticePresenter.name(userPractice)}</Typography>

            {children}

            {isAccepted && <ThumbUpAltOutlinedIcon className={classes.icon} />}
          </div>
          {shouldShowPrLinkField && !isSentPractice && !isAdminView && (
            <form noValidate>
              <TextField
                classes={{ root: classes.practiceInput }}
                fullWidth
                variant="outlined"
                size="small"
                color="primary"
                value={prLinkValue}
                placeholder={PracticePresenter.result(userPractice)}
                onChange={handleCurrentPrLinkChange(PracticePresenter.id(userPractice))}
              />
            </form>
          )}
          {shouldShowPrLinkField && isSentPractice && (
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="body2">{PracticePresenter.result(userPractice)}</Typography>
              <Typography variant="body2">
                <a target="_blank" rel="noreferrer" href={prLinkValue} className={classes.prlink}>
                  {prLinkValue}
                </a>
              </Typography>
            </Box>
          )}
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

PracticeItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  userPractice: PropTypes.shape().isRequired,
  practiceId: PropTypes.number,
  isListLoadingPending: PropTypes.bool,
  isAdminView: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PracticeItem.defaultProps = {
  practiceId: null,
  isListLoadingPending: false,
  isAdminView: false,
  children: null,
};
export default PracticeItem;
