import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import useLesson from 'sharedContainers/Lesson';
import LessonPresenter from 'presenters/LessonPresenter';
import useStyles from './styles';

const LessonTitle = props => {
  const { courseId, lessonId } = props;
  const classes = useStyles();

  const { loadLesson, lesson, processing: lessonProcessing, resetLesson } = useLesson();

  useEffect(() => {
    loadLesson(courseId, lessonId);
    return () => {
      resetLesson();
    };
  }, [lessonId, courseId]);

  if (isEmpty(lesson) || lessonProcessing) {
    return <Skeleton height={30} variant="rect" component="h5" />;
  }

  return (
    <Typography variant="h5" gutterBottom className={classes.title}>
      {LessonPresenter.name(lesson)}
    </Typography>
  );
};

LessonTitle.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
};

export default LessonTitle;
