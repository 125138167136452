/* eslint-disable no-param-reassign */
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { createSelector } from 'utils/store';

export default ({ slicePath, repositoryAction, responseKey }) => {
  const slice = createSlice({
    name: slicePath.join('/'),
    initialState: {
      data: null,
      meta: {},
      isLoading: false,
      loadingError: null,
    },
    reducers: {
      start(state) {
        state.isLoading = true;
        state.loadingError = null;
      },
      stop(state) {
        state.isLoading = false;
      },
      loadSuccess(state, { payload }) {
        state.data = payload.data;
        state.meta = payload.meta;
      },
      appendSuccess(state, { payload }) {
        const newData = state.data ? [...state.data, ...payload.data] : payload.data;
        state.data = newData;
        state.meta = payload.meta;
      },
      fail(state, { payload }) {
        state.data = null;
        state.loadingError = payload.error;
      },
      reset(state) {
        state.data = null;
        state.meta = {};
        state.isLoading = false;
      },
      clearLoadingError(state) {
        state.loadingError = null;
      },
    },
  });

  const {
    actions: { start, stop, fail, loadSuccess, appendSuccess, reset, clearLoadingError },
  } = slice;

  const selectors = {
    getData: createSelector(slicePath, 'data'),
    getMeta: createSelector(slicePath, 'meta'),
    getIsLoading: createSelector(slicePath, 'isLoading'),
    getLoadingError: createSelector(slicePath, 'loadingError'),
  };

  const useActions = () => {
    const dispatch = useDispatch();

    return {
      load: (...params) => {
        dispatch(start());

        return repositoryAction(...params)
          .then(({ data }) => dispatch(loadSuccess({ data: data[responseKey], meta: data.meta })))
          .catch(error => {
            dispatch(fail());

            return Promise.reject(error);
          })
          .finally(() => {
            dispatch(stop());
          });
      },
      append: (...params) => {
        dispatch(start());

        return repositoryAction(...params)
          .then(({ data }) => dispatch(appendSuccess({ data: data[responseKey], meta: data.meta })))
          .catch(error => {
            dispatch(fail());

            return Promise.reject(error);
          })
          .finally(() => {
            dispatch(stop());
          });
      },
      reset: () => dispatch(reset()),
      clearLoadingError: () => {
        dispatch(clearLoadingError());
      },
    };
  };

  return { slice, selectors, useActions };
};
