import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import VacancyFeedbackCard from 'components/VacancyFeedbackCard';
import VacancyMoneyCard from 'components/VacancyMoneyCard';

import useStyles from './styles';

const Aside = props => {
  const { accordionShown } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <VacancyFeedbackCard
        title={t('vacancies.cards.feedback.title')}
        content={t('vacancies.cards.feedback.content')}
        buttonText={t('vacancies.cards.feedback.writeToEmail')}
        emoji="🚀"
        accordionShown={accordionShown}
      />

      <VacancyMoneyCard
        title={t('vacancies.cards.money.title')}
        content={t('vacancies.cards.money.content')}
        accordionShown={accordionShown}
      />
    </Box>
  );
};
Aside.propTypes = {
  accordionShown: PropTypes.bool,
};
Aside.defaultProps = {
  accordionShown: false,
};

export default Aside;
