import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, equals, or, isEmpty, append, without, length, not, map, values } from 'ramda';

import {
  CircularProgress,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Breadcrumbs from 'components/Breadcrumbs';

import UserPresenter from 'presenters/UserPresenter';
import ExercisePresenter from 'presenters/ExercisePresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminPractices from 'hooks/admin/usePractices';
import useAdminLessons from 'hooks/admin/useLessons';
import useAdminCourses from 'hooks/admin/useCourses';
import useNumberParams from 'hooks/useNumberParams';
import { calculateNextOrder } from 'utils/calculateNextOrder';
import useRedirects from 'hooks/redirects';

import findById from 'utils/findById';
import { isBlank } from 'utils/helpers';
import exerciseStates from 'utils/exerciseStates';
import { camelize } from 'utils/strings';

import ModalConfirmation from 'components/ModalConfirmation';
import Practices from './components/Practices';
import PracticeModal from './components/PracticeModal';

import useStyles from './styles';

const PracticesEditPage = ({ mode }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId, lessonId, practiceId } = useNumberParams(['courseId', 'lessonId', 'practiceId']);
  const { redirectToAdminPracticesPath, redirectToAdminPracticeNewPath } = useRedirects();

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentPractice, setCurrentPractice] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulkState, setBulkState] = useState('');

  const { currentUser } = useCurrentUser();
  const {
    practices,
    isLoading,
    loadAdminPractices,
    resetAdminPractices,
    createPractice,
    updatePractice,
    destroyPractice,
    bulkUpdate,
  } = useAdminPractices();
  const { loadAdminLesson, resetAdminLesson } = useAdminLessons();
  const { loadAdminCourse, resetAdminCourse } = useAdminCourses();

  useEffect(() => {
    loadAdminPractices(courseId, lessonId);
    loadAdminLesson(courseId, lessonId);
    loadAdminCourse(courseId);
    return () => {
      resetAdminPractices();
      resetAdminLesson();
      resetAdminCourse();
    };
  }, []);

  const initialOrder = 0;
  const nextOrder = isBlank(practices) ? initialOrder : calculateNextOrder(practices);
  const practice = isBlank(practices) ? null : findById(practices, practiceId);
  const isModalOpen = or(equals(mode, 'edit'), equals(mode, 'new'));

  const handleCreate = () => redirectToAdminPracticeNewPath(courseId, lessonId);

  const loadPracticesAndCloseModal = () => {
    loadAdminPractices(courseId, lessonId);
    redirectToAdminPracticesPath(courseId, lessonId);
  };

  const handlePracticeCreate = params =>
    createPractice(courseId, lessonId, params).then(() => loadPracticesAndCloseModal());

  const handlePracticeUpdate = params =>
    updatePractice(courseId, lessonId, practice.id, params).then(() => loadPracticesAndCloseModal());

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentPractice(null);
  };

  const handleModalOpenDestroy =
    (practiceData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (practiceData) {
        setCurrentPractice(practiceData);
      }
    };

  const handleTheoryDestroy = async () => {
    await destroyPractice(courseId, lessonId, currentPractice.id);
    handleModalDeleteClose();
    loadAdminPractices(courseId, lessonId);
  };

  const handleSelect = item => e => {
    if (e.target.checked) {
      setSelected(append(item, selected));
    } else {
      setSelected(without([item], selected));
    }
  };

  const handleSelectAll = () => {
    if (length(practices) === length(selected)) {
      setSelected([]);
    } else {
      setSelected(practices);
    }
  };

  const handleBulkUpdate = async () => {
    const list = map(u => ({ id: ExercisePresenter.id(u), state: bulkState }), selected);
    await bulkUpdate(courseId, lessonId, { practices: list });
    setSelected([]);
    loadAdminPractices(courseId, lessonId);
  };

  return (
    <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
      <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
        <Box p={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Breadcrumbs />
            </Grid>
            {UserPresenter.hasMentorAccess(currentUser) && (
              <Grid item>
                <Button color="primary" variant="outlined" onClick={handleCreate}>
                  {t('adminExercisesEditPage.createPractice')}
                </Button>
              </Grid>
            )}
          </Grid>

          {isNil(practices) || isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <Practices
              courseId={courseId}
              lessonId={lessonId}
              practices={practices}
              onModalOpenDestroy={handleModalOpenDestroy}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              selected={selected}
            />
          )}
          <PracticeModal
            practice={practice}
            nextOrder={nextOrder}
            isModalOpen={isModalOpen}
            isLoading={isNil(practices) || isLoading}
            onPracticeCreate={handlePracticeCreate}
            onPracticeUpdate={handlePracticeUpdate}
          />

          <ModalConfirmation
            isOpen={isModalDeleteOpen}
            onClose={handleModalDeleteClose}
            onConfirm={handleTheoryDestroy}
            title={t('adminExercisesEditPage.deletePracticeModalTitle')}
            content={t('adminExercisesEditPage.deletePracticeModalContent')}
          />
        </Box>
      </Paper>

      {not(isEmpty(selected)) && (
        <Paper elevation={12} style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Container component="div" maxWidth="lg">
            <Box py={2} px={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item container xs={6} spacing={4} alignItems="center">
                  <Grid item>
                    <Typography>
                      {t('common.selected')}
                      {length(selected)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel id="state-label">{t('adminEditLessonPracticeModal.state')}</InputLabel>
                    <Select
                      disabled={isLoading}
                      labelId="state-label"
                      id="state-select"
                      value={bulkState}
                      onChange={e => setBulkState(e.target.value)}
                      fullWidth
                    >
                      {values(exerciseStates).map(state => (
                        <MenuItem key={state} value={state}>
                          {t(`adminEditLessonPracticeModal.states.${camelize(state)}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={handleBulkUpdate}>
                    {t('common.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      )}
    </Container>
  );
};

PracticesEditPage.propTypes = {
  mode: PropTypes.string,
};

PracticesEditPage.defaultProps = {
  mode: null,
};

export default PracticesEditPage;
