import {
  apiV1StudentLessonPracticesPath,
  apiV1StudentLessonPracticePath,
  apiV1StudentPracticeUserPracticesPath,
} from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(lessonId) {
    const path = apiV1StudentLessonPracticesPath(lessonId);
    return FetchHelpers.get(path);
  },

  show(lessonId, practiceId) {
    const path = apiV1StudentLessonPracticePath(lessonId, practiceId);
    return FetchHelpers.get(path);
  },

  create(id, userPractice) {
    const path = apiV1StudentPracticeUserPracticesPath(id);
    return FetchHelpers.post(path, { userPractice });
  },

  update(id, userPractice) {
    const path = apiV1StudentPracticeUserPracticesPath(id);
    return FetchHelpers.put(path, { userPractice });
  },
};
