import { apiV1PagesPath, apiV1PagePath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1PagesPath();
    return FetchHelpers.get(path);
  },

  show(id) {
    const path = apiV1PagePath(id);
    return FetchHelpers.get(path);
  },
};
