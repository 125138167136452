import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    courseName: PropTypes.string,
    lessonName: PropTypes.string,
    lessonId: PropTypes.number,
    name: PropTypes.string,
    content: PropTypes.string,
    teacherContent: PropTypes.string,
    link: PropTypes.string,
    order: PropTypes.number,
    state: PropTypes.string,
    createdAt: PropTypes.string,
    availableStates: PropTypes.arrayOf(PropTypes.string),
    result: PropTypes.string,
    isDoc: PropTypes.bool,
    isLink: PropTypes.bool,
    isResultRequired: PropTypes.bool,
    isOnlyOnce: PropTypes.bool,
  },
  {},
);
