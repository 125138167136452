import React from 'react';
import useNumberParams from 'hooks/useNumberParams';
import LastActivities from 'sharedContainers/Admin/LastActivities';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import appRoutes from 'routes/appRoutes';
import Link from 'components/RouterLink';

import { Container } from '@material-ui/core';
import useStyles from './styles';

const UserLastActivitiesPage = () => {
  const classes = useStyles();
  const { courseId, userId } = useNumberParams(['courseId', 'userId']);
  const { t } = useTranslation();

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
          <Link to={appRoutes.courseLastActivitiesPath(courseId)}>{t('adminUserLastActivitiesPage.allEvents')}</Link>
          <Typography color="textPrimary">{userId}</Typography>
        </Breadcrumbs>
      </Container>

      <LastActivities courseId={courseId} userId={userId} />
    </>
  );
};

export default UserLastActivitiesPage;
