import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    width: theme.sizes.sidebarContentWidth,
    marginRight: '12px',
    height: 35,
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  divider: {
    backgroundColor: theme.palette.common.gray7,
    height: 45,
    width: 1,
    margin: 0,
    display: 'block',
  },
}));

export default useStyles;
