import React, { useEffect } from 'react';
import { Router, useLocation } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme, useMediaQuery } from '@material-ui/core';

import UserPresenter from 'presenters/UserPresenter';
import history from 'utils/history';
import { isNull, isBlank } from 'utils/helpers';
import rollbar from 'utils/errorTracking';

import NotAuthenticatedUserSwitch from 'routeSwitches/NotAuthenticatedUserSwitch';
import StudentSwitch from 'routeSwitches/StudentSwitch';
import AdminSwitch from 'routeSwitches/AdminSwitch';

import useCurrentUser from 'hooks/useCurrentUser';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const { currentUser, loadCurrentUser, isCurrentUserLoading } = useCurrentUser();

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  useEffect(() => {
    if (!isBlank(currentUser)) {
      rollbar.configure({
        payload: {
          person: {
            id: currentUser.id,
          },
        },
      });
    }

    if (isBlank(currentUser)) {
      loadCurrentUser().catch(() => {});
    }
  }, [currentUser]);

  const renderSwitch = () => {
    if (isCurrentUserLoading) {
      return (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }

    if (isNull(currentUser)) {
      return <NotAuthenticatedUserSwitch />;
    }

    const currentUserId = UserPresenter.id(currentUser);

    return (
      <>
        {UserPresenter.isStudent(currentUser) && <StudentSwitch currentUserId={currentUserId} />}
        {!UserPresenter.isStudent(currentUser) && <AdminSwitch />}
      </>
    );
  };

  return (
    <Router history={history}>
      {matchesMobile && <ScrollToTop />}
      {renderSwitch()}
    </Router>
  );
};

export default App;
