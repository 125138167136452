import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CoursePresenter from 'presenters/CoursePresenter';

const FilterFieldCourse = props => {
  const { onFieldChange, courses, courseId } = props;
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select defaultValue={courseId || ''} displayEmpty onChange={onFieldChange}>
        <MenuItem value="">{t('lastActivity.labelFieldSelectCourse')}</MenuItem>
        {courses.map(course => (
          <MenuItem key={course.id} value={course.id}>
            <Typography noWrap>{course.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterFieldCourse.propTypes = {
  courseId: PropTypes.number,
  courses: PropTypes.arrayOf(CoursePresenter.shape()),
  onFieldChange: PropTypes.func.isRequired,
};

FilterFieldCourse.defaultProps = {
  courseId: null,
  courses: [],
};

export default FilterFieldCourse;
