import appRoutes from 'routes/appRoutes';

import useAdminCourses from 'hooks/admin/useCourses';
import useAdminLessons from 'hooks/admin/useLessons';

import CoursePresenter from 'presenters/CoursePresenter';
import LessonPresenter from 'presenters/LessonPresenter';

import { truncate } from 'utils/strings';

export default t => {
  const { course } = useAdminCourses();
  const { lesson } = useAdminLessons();

  return {
    [appRoutes.adminPath()]: null,
    [appRoutes.adminCoursesPath()]: t('breadcrumbs.courses'),
    [appRoutes.adminCoursePath(':courseId')]: course ? truncate(CoursePresenter.name(course)) : null,
    [appRoutes.adminLessonsPath(':courseId')]: t('breadcrumbs.lessons'),
    [appRoutes.adminLessonPath(':courseId', ':lessonId')]: lesson ? truncate(LessonPresenter.name(lesson)) : null,
    [appRoutes.adminTheoriesPath(':courseId', ':lessonId')]: t('breadcrumbs.theories'),
    [appRoutes.adminTheoryNewPath(':courseId', ':lessonId')]: null,
    [appRoutes.adminTheoryPath(':courseId', ':lessonId', ':theoryId')]: null,
    [appRoutes.adminTheoryEditPath(':courseId', ':lessonId', ':theoryId')]: null,
    [appRoutes.adminPracticesPath(':courseId', ':lessonId')]: t('breadcrumbs.practices'),
    [appRoutes.adminPracticeNewPath(':courseId', ':lessonId')]: null,
    [appRoutes.adminPracticePath(':courseId', ':lessonId', ':practiceId')]: null,
    [appRoutes.adminPracticeEditPath(':courseId', ':lessonId', ':practiceId')]: null,
  };
};
