import React, { useState, useEffect } from 'react';
import { map } from 'ramda';

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Toolbar, Typography, Menu, IconButton, useMediaQuery, Box, useTheme, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { pagesHooks } from 'store/pagesSlice';

import appRoutes from 'routes/appRoutes';
import externalRoutes from 'routes/externalRoutes';

import PagePresenter from 'presenters/PagePresenter';

import Icon from 'components/Icon';
import Logo from './components/Logo';
import LanguageMenu from './components/LanguageMenu';

import useStyles from './styles';

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const {
    loadPages,
    pages: { items: pages, loadingStatus },
  } = pagesHooks.use();

  useEffect(() => {
    loadPages();
  }, []);

  const { isFulfilled } = useFetchStatus(loadingStatus);

  const desktopMenu = (
    <Box component="nav" display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Box display="flex">
        <NavLink to={appRoutes.coursesPath()} className={classes.link} activeClassName={classes.linkActive}>
          <Typography className={classes.name}>{t('header.courses')}</Typography>
        </NavLink>
        <NavLink to={appRoutes.vacanciesPath()} className={classes.link} activeClassName={classes.linkActive}>
          <Typography className={classes.name}>{t('header.vacancies')}</Typography>
        </NavLink>
        {isFulfilled &&
          map(
            page => (
              <NavLink
                key={PagePresenter.id(page)}
                to={appRoutes.pagePath(PagePresenter.slug(page))}
                className={classes.link}
                activeClassName={classes.linkActive}
              >
                <Typography className={classes.name}>{PagePresenter.name(page)}</Typography>
              </NavLink>
            ),
            pages,
          )}
      </Box>

      <Box className={classes.iconButtons}>
        <LanguageMenu />
        <NavLink to={appRoutes.signInPath()} className={`${classes.link} ${classes.signIn}`}>
          <Typography className={classes.name}>{t('header.signIn')}</Typography>
        </NavLink>
      </Box>
    </Box>
  );

  const mobileMenuId = 'guest-header-nav-mobile';
  const mobileMenu = (
    <Box>
      <IconButton
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="primary"
        size="medium"
      >
        <Icon name="mobileMenu" />
      </IconButton>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={0}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        PaperProps={{
          className: classes.menuPaperMobile,
        }}
      >
        <IconButton onClick={handleMobileMenuClose} color="primary" className={classes.closeIcon}>
          <CloseRoundedIcon width="14" />
        </IconButton>

        <Box component="nav" className={classes.navItems}>
          <NavLink to={appRoutes.coursesPath()} className={classes.link} activeClassName={classes.linkActive}>
            <MenuItem onClick={handleMobileMenuClose}>
              <Typography className={classes.name}>{t('header.courses')}</Typography>
            </MenuItem>
          </NavLink>
          <NavLink to={appRoutes.vacanciesPath()} className={classes.link} activeClassName={classes.linkActive}>
            <MenuItem onClick={handleMobileMenuClose}>
              <Typography className={classes.name}>{t('header.vacancies')}</Typography>
            </MenuItem>
          </NavLink>

          {isFulfilled &&
            map(
              page => (
                <NavLink
                  key={PagePresenter.id(page)}
                  to={appRoutes.pagePath(PagePresenter.slug(page))}
                  className={classes.link}
                  activeClassName={classes.linkActive}
                >
                  <MenuItem onClick={handleMobileMenuClose}>
                    <Typography className={classes.name}>{PagePresenter.name(page)}</Typography>
                  </MenuItem>
                </NavLink>
              ),
              pages,
            )}

          <a href={externalRoutes.dualbootpartnersPath()} target="_blank" rel="noreferrer" className={classes.link}>
            <Typography className={classes.name}>{t('mainPage.about')}</Typography>
          </a>
          <Divider classes={{ root: classes.divider }} orientation="horizontal" variant="middle" />

          <Box className={classes.iconButtons}>
            <LanguageMenu />
            <NavLink to={appRoutes.signInPath()} className={classes.signInButton}>
              <Button
                className={classes.button}
                size="medium"
                variant="contained"
                color="primary"
                onClick={handleMobileMenuClose}
              >
                <Typography className={classes.textButton}>{t('header.signIn')}</Typography>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Menu>
    </Box>
  );

  return (
    <Toolbar className={classes.grow} disableGutters>
      <Logo isDividerShown={matchesDesktop} />
      {matchesMobile && mobileMenu}
      {matchesDesktop && desktopMenu}
    </Toolbar>
  );
};

export default Header;
