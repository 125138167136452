import storeModuleCreators from 'utils/store';
import CourseRepository from 'repositories/CourseRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['course'],
  repositoryAction: CourseRepository.show,
  responseKey: 'course',
});

export default slice.reducer;
