import { useDispatch, useSelector } from 'react-redux';
import { pick, keys } from 'ramda';
import { validateConfig, populateOptions, combineSlicesBy } from './utils';
import { toRealName, keysToRealName, toSliceName } from './conventions';

import * as avaliableSlices from './slices';

const restDispatch =
  (dispatch, reduxActions, config) =>
  (actionName, params = {}) => {
    const action = reduxActions.actions[toRealName(actionName, config.resource)];
    dispatch(action(params));
  };

export function createRestSlice(config) {
  validateConfig(config, avaliableSlices);
  const options = populateOptions(config);

  const enablesSlices = config.slices.map(s => {
    const sliceCreator = avaliableSlices[toSliceName(s, config.resource)];

    if (!sliceCreator) {
      throw new Error(`${s} is not valid REST slice.`);
    }

    return sliceCreator(options);
  });

  const initialState = combineSlicesBy('initialState')(enablesSlices);

  const reducers = combineSlicesBy('reducers', keysToRealName(config.resource))(enablesSlices);

  const useHook = (reduxActions, storeName) => () => {
    const dispatch = useDispatch();

    const state = useSelector(s => pick(keys(initialState), s[storeName]));

    const actions = combineSlicesBy('actionCreators', creator =>
      keysToRealName(config.resource)(creator(restDispatch(dispatch, reduxActions, config))),
    )(enablesSlices);

    return {
      ...state,
      ...actions,
    };
  };

  const useAbstractHook = (reduxActions, storeName) => () => {
    const dispatch = useDispatch();

    const state = useSelector(s =>
      combineSlicesBy('abstractSelector', selector => selector(s[storeName]))(enablesSlices),
    );

    const actions = combineSlicesBy('actionCreators', creator => creator(restDispatch(dispatch, reduxActions, config)))(
      enablesSlices,
    );

    return {
      ...state,
      ...actions,
    };
  };

  return {
    initialState,
    reducers,
    hooks: (reducerActions, storeName) => {
      return {
        useAbstract: useAbstractHook(reducerActions, storeName),
        use: useHook(reducerActions, storeName),
      };
    },
  };
}
