import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  copyButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  codeWrap: {
    position: 'relative',
  },
  blockquote: {
    margin: [16, 0],
    padding: [5, 8, 5, 30],
    background: 'none repeat scroll 0 0 rgba(102,128,153,.05)',
    color: '#333',
    border: 'none',
    borderLeft: '10px solid #d6dbdf',
  },
  code: {
    padding: [3, 5.5],
    margin: 0,
    wordBreak: 'normal',
    backgroundColor: theme.palette.common.gray4,
    borderRadius: 0,
    overflowX: 'auto',
  },
  videoContainer: {
    position: 'relative',
    height: 'auto',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      height: 0,
      paddingBottom: '56.25%' /* 16:9 */,
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
  },

  table: {
    fontSize: '14px',
    lineHeight: '1.7',
    maxWidth: '100%',
    overflow: 'auto',
    border: '1px solid #f6f6f6',
    borderCollapse: 'collapse',
    borderSpacing: '0',
    boxSizing: 'border-box',
    '& tr': {
      border: '1px solid #efefef',
    },
    '& th': {
      textAlign: 'center',
      fontWeight: '700',
      border: '1px solid #efefef',
      padding: '10px 6px',
      backgroundColor: '#f5f7fa',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
    '& td': {
      border: '1px solid #efefef',
      textAlign: 'left',
      padding: '10px 15px',
      wordBreak: 'break-word',
      minWidth: '60px',
      whiteSpace: 'normal',
    },
  },
  markdown: {
    color: theme.palette.common.brandingGray2,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),

    '& > :last-child': {
      marginBottom: 0,
    },
    '& > :first-child': {
      marginTop: 0,
    },

    '& img': {
      maxWidth: '100%',
    },

    '& p > a': {
      color: theme.palette.common.brandingBlue,
      wordBreak: 'break-word',
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      color: theme.palette.common.brandingBlack,
      '& p > a': {
        wordBreak: 'unset',
      },
      '& > :last-child': {
        marginBottom: 'inherit',
      },
      '& > :first-child': {
        marginTop: 'inherit',
      },
    },

    '& h1, h2, h3': {
      lineHeight: 'initial',
    },
  },

  longMarkdown: {
    paddingBottom: theme.spacing(2),
  },
  seeMoreButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginTop: 16,
  },
  rollUpIcon: {
    transform: 'rotate(180deg)',
  },

  accordion: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',

    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },

  accordionSummaryRoot: {
    justifyContent: 'flex-start',
    gap: 12,
    padding: 0,
    minHeight: 'fit-content',

    '&.Mui-expanded': {
      minHeight: 'fit-content',
    },
  },

  accordionSummaryContent: {
    flexGrow: 'unset',
    margin: 0,
    fontSize: theme.typography.pxToRem(14),

    '&.Mui-expanded': {
      margin: 0,
    },
  },

  accordionIcon: {
    padding: 0,
  },

  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
    padding: 0,
    marginBottom: 16,
  },
}));

export default useStyles;
