import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';

import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { groupsHooks } from 'store/admin/groupsSlice';

import GroupPresenter from 'presenters/GroupPresenter';

import useStyles from './styles';

const findGroupById = (groups, groupId) => {
  return groups.find(group => group.id === groupId);
};

const GroupSelect = props => {
  const { value: currentValue, disabled, onChange, allowEmpty } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    firstLoadGroups: loadGroups,
    groups: { items: groups, loadingStatus: groupsLoadingStatus },
  } = groupsHooks.use();

  const { isIdle: isGroupsIdle, isFulfilled: isGroupsFulfilled } = useFetchStatus(groupsLoadingStatus);

  useEffect(() => {
    loadGroups();
  }, []);

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <InputLabel id="filter-group-label">{t('adminUsersFilters.group')}</InputLabel>
      <Select
        disabled={disabled || isGroupsIdle}
        multiple
        name="groupId"
        labelId="filter-group-label"
        id="filter-group-select"
        value={currentValue}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={selected =>
          isGroupsFulfilled && (
            <div className={classes.chips}>
              {selected.map(groupId =>
                groupId ? (
                  <Chip
                    key={groupId}
                    label={GroupPresenter.fullname(findGroupById(groups, groupId))}
                    className={classes.chip}
                  />
                ) : (
                  <MenuItem key={groupId} value={groupId} />
                ),
              )}
            </div>
          )
        }
      >
        {allowEmpty && (
          <MenuItem key="none" value={null}>
            &nbsp;
          </MenuItem>
        )}
        {isGroupsFulfilled &&
          groups.map(group => (
            <MenuItem key={group.id} value={group.id}>
              <Typography noWrap>{GroupPresenter.name(group)}</Typography>
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

GroupSelect.defaultProps = {
  disabled: false,
  allowEmpty: false,
};

GroupSelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(GroupPresenter.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
};

export default GroupSelect;
