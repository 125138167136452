import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { PARSE_RADIX } from 'utils/integers';
import { FIRST_PAGE } from 'utils/pagination';

import { Backdrop, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import UserPresenter from 'presenters/UserPresenter';
import ModalConfirmation from 'components/ModalConfirmation';
import { USERS_SORT_VARIANTS } from 'utils/constants';
import BaseView from './components/BaseView';
import ExtendedView from './components/ExtendedView';

const UsersTable = props => {
  const {
    users,
    meta: { totalCount, currentPage },
    onPageChange,
    onRowsPerPageChange,
    rowsPerPage,
    loading,
    loadingError,
    onImpersonate,
    destroyUser,
    isSelectMode,
    showProgress,
    loadUsers,
    selectedUsers,
    onSelect,
    onSelectAll,
    groupContext,
    isCourseChosen,
    currentSort,
    onFiltersChange,
  } = props;

  const { t } = useTranslation();

  const [hasRemoveFailed, setHasRemoveFailed] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  if (isNil(users) || loading) {
    return <CircularProgress color="primary" />;
  }

  if (isEmpty(users) && !isNil(loadingError)) {
    return (
      <Backdrop open>
        <Alert severity="error">{loadingError}</Alert>
      </Backdrop>
    );
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    onRowsPerPageChange(parseInt(value, PARSE_RADIX));
    onPageChange(FIRST_PAGE);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentUserId(null);
  };
  const handleModalDeleteFailedClose = () => {
    setHasRemoveFailed(false);
    setModalDeleteOpen(false);
    setCurrentUserId(null);
  };
  const handleModalOpenDestroy = id => () => {
    setModalDeleteOpen(true);
    setCurrentUserId(id);
  };

  const handleUserDestroy = async () => {
    try {
      await destroyUser(currentUserId);
      handleModalDeleteClose();
      loadUsers();
    } catch {
      setHasRemoveFailed(true);
    }
  };

  const viewProps = {
    users,
    meta: { totalCount, currentPage },
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    rowsPerPage,
  };

  const contentTable = showProgress ? (
    <ExtendedView {...viewProps} />
  ) : (
    <BaseView
      {...viewProps}
      onFiltersChange={onFiltersChange}
      currentSort={currentSort}
      isSelectMode={isSelectMode}
      isCourseChosen={isCourseChosen}
      onImpersonate={onImpersonate}
      onModalOpenDestroy={handleModalOpenDestroy}
      selectedUsers={selectedUsers}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
    />
  );

  return (
    <>
      {contentTable}
      {groupContext ? (
        <ModalConfirmation
          isOpen={isModalDeleteOpen}
          onClose={handleModalDeleteClose}
          onConfirm={handleUserDestroy}
          title={t('adminGroupManage.actions.removeModal.title')}
          content={t('adminGroupManage.actions.removeModal.deleteUserModalContent')}
        />
      ) : (
        <ModalConfirmation
          isOpen={isModalDeleteOpen}
          isNoChoiceProvided={hasRemoveFailed}
          onClose={hasRemoveFailed ? handleModalDeleteFailedClose : handleModalDeleteClose}
          onConfirm={hasRemoveFailed ? handleModalDeleteFailedClose : handleUserDestroy}
          title={t('adminUsersFilters.deleteUserModal.title')}
          content={
            hasRemoveFailed
              ? t('adminUsersFilters.deleteUserModal.failedContent')
              : t('adminUsersFilters.deleteUserModal.content')
          }
        />
      )}
    </>
  );
};

UsersTable.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  currentSort: PropTypes.string,
  isCourseChosen: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(UserPresenter.shape()),
  meta: PropTypes.shape().isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  destroyUser: PropTypes.func,
  loadUsers: PropTypes.func.isRequired,
  loadingError: PropTypes.string,
  onImpersonate: PropTypes.func,
  showProgress: PropTypes.bool,
  groupContext: PropTypes.bool,
  isSelectMode: PropTypes.bool,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

UsersTable.defaultProps = {
  currentSort: USERS_SORT_VARIANTS.default,
  groupContext: false,
  onImpersonate: null,
  destroyUser: () => {},
  users: [],
  loadingError: null,
  showProgress: false,
  isSelectMode: false,
  selectedUsers: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

export default UsersTable;
