/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import StudentLessonTheoriesRepository from 'repositories/StudentLessonTheoriesRepository';

import { createRestSlice } from 'utils/RestSlice';

const userTheoryRestSlice = createRestSlice({
  resource: 'theory',
  repository: StudentLessonTheoriesRepository,
  slices: ['createTheory', 'updateTheory'],
});

const initialState = {
  ...userTheoryRestSlice.initialState,
};

const userTheorySlice = createSlice({
  name: 'userTheory',
  initialState,
  reducers: {
    ...userTheoryRestSlice.reducers,
  },
});

export default userTheorySlice.reducer;

export const userTheoryHooks = userTheoryRestSlice.hooks(userTheorySlice, 'userTheory');
