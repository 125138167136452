import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    padding: [35, 40],
  },
  header: {
    marginBottom: 10,
  },
  icons: {
    marginLeft: 24,
    display: 'grid',
    gap: '8px',
    gridAutoFlow: 'column',
  },
  content: {
    padding: '0 !important',
  },
  vacancyName: {
    fontSize: theme.typography.pxToRem(20),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 0,
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 0,
    color: theme.palette.common.black,
  },
}));

export default useStyles;
