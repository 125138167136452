import React from 'react';
import PropTypes from 'prop-types';

import VacancyAsideCard from 'components/VacancyAsideCard';

import useStyles from './styles';

const VacancyMoneyCard = props => {
  const { title, content, accordionShown } = props;
  const classes = useStyles();

  return (
    <VacancyAsideCard
      styles={classes.cardMoney}
      emoji="😀"
      title={title}
      content={content}
      accordionShown={accordionShown}
    />
  );
};

VacancyMoneyCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  accordionShown: PropTypes.bool,
};
VacancyMoneyCard.defaultProps = {
  accordionShown: false,
};

export default VacancyMoneyCard;
