const FETCH_STATUSES = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  failed: 'failed',
  init: 'init',
};

Object.freeze(FETCH_STATUSES);

export { FETCH_STATUSES };
