/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import StudentLessonPracticesRepository from 'repositories/StudentLessonPracticesRepository';

import { createRestSlice } from 'utils/RestSlice';

const userPracticesRestSlice = createRestSlice({
  resource: 'practice',
  repository: StudentLessonPracticesRepository,
  slices: ['loadPractices', 'createPractice', 'updatePractice'],
});

const initialState = {
  ...userPracticesRestSlice.initialState,
};

const userPracticesSlice = createSlice({
  name: 'userPractices',
  initialState,
  reducers: {
    ...userPracticesRestSlice.reducers,
  },
});

export default userPracticesSlice.reducer;

export const userPracticesHooks = userPracticesRestSlice.hooks(userPracticesSlice, 'userPractices');
