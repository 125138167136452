import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Tooltip, Typography } from '@material-ui/core';

import { BADGE_SIZES } from 'presenters/User/LessonPresenter';

import useStyles from './styles';

const Item = props => {
  const { item, size, flat, practice, theory } = props;

  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const itemClasses = clsx(classes.item, {
    [classes.smallItem]: size === BADGE_SIZES.small,
    [classes.flat]: flat,
    [classes.itemAcceptedPractice]: practice,
    [classes.itemCompletedTheory]: theory,
  });

  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const handleToggle = () => setTooltipOpened(!isTooltipOpened);

  const tooltipContent = <Typography className={classes.title}>{item.name}</Typography>;
  return (
    <Tooltip title={tooltipContent} placement="top">
      <span
        className={itemClasses}
        style={{ opacity: item.opacity }}
        onMouseEnter={handleToggle}
        onMouseLeave={handleToggle}
        ref={anchorRef}
      />
    </Tooltip>
  );
};

Item.propTypes = {
  item: PropTypes.shape({ name: PropTypes.string, opacity: PropTypes.number }),
  size: PropTypes.string.isRequired,
  flat: PropTypes.bool,
  practice: PropTypes.bool,
  theory: PropTypes.bool,
};

Item.defaultProps = {
  item: { name: null, opacity: 1 },
  flat: false,
  practice: false,
  theory: false,
};

export default Item;
