import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createCourse,
  importCourse,
  updateCourse,
  updateCourses,
  destroyCourse,
} from 'store/admin/coursesSlice';
import { useActions as useShowActions, selectors as showSelectors } from 'store/admin/courseSlice';

const useCourses = () => {
  const { load: loadAdminCourses, reset: resetAdminCourses, clearLoadingError } = useLoadActions();
  const { load: loadAdminCourse, reset: resetAdminCourse, clearLoadingError: clearShowLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const { getData: getShowData, getIsLoading: getShowIsLoading, getLoadingError: getShowLoadingError } = showSelectors;
  const courses = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();
  const course = getShowData();
  const isShowLoading = getShowIsLoading();
  const loadingShowError = getShowLoadingError();

  return {
    courses,
    loadAdminCourses,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminCourses,
    course,
    loadAdminCourse,
    isShowLoading,
    loadingShowError,
    clearShowLoadingError,
    resetAdminCourse,
    createCourse,
    importCourse,
    updateCourse,
    updateCourses,
    destroyCourse,
  };
};

export default useCourses;
