import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import userPracticeStates from 'utils/userPracticeStates';
import userTheoryStates from 'utils/userTheoryStates';
import counterExerciseProgress from 'utils/counterExerciseProgress';
import counterLessonProgress from 'utils/counterLessonProgress';

import PracticePresenter from './PracticePresenter';
import TheoryPresenter from './TheoryPresenter';

export const THEORY = 'theory';
export const PRACTICE = 'practice';
export const BADGE_SIZES = {
  small: 'small',
  large: 'large',
  flat: 'flat',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    order: PropTypes.number,
    userTheories: PropTypes.arrayOf(TheoryPresenter.shape()),
    userPractices: PropTypes.arrayOf(PracticePresenter.shape()),
  },
  {
    isCurrentLesson(currentLesson, lesson) {
      return this.id(currentLesson) === this.id(lesson);
    },
    theoryExercisesCount(lesson) {
      return this.userTheories(lesson).length;
    },
    practiceExercisesCount(lesson) {
      return this.userPractices(lesson).length;
    },
    practiceProgress(lesson) {
      return counterLessonProgress(this.userPractices(lesson), userPracticeStates.accepted, this.order(lesson));
    },
    theoryProgress(lesson) {
      return counterLessonProgress(this.userTheories(lesson), userTheoryStates.completed, this.order(lesson));
    },
    pacticeProgressCount(lesson) {
      return counterExerciseProgress(this.userPractices(lesson), [
        userPracticeStates.accepted,
        userPracticeStates.completed,
      ]);
    },
    theoryProgressCount(lesson) {
      return counterExerciseProgress(this.userTheories(lesson), [userTheoryStates.completed]);
    },
  },
);
