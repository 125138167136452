import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  scrollButton: {
    position: 'fixed',
  },
  startIcon: {
    margin: 0,
    lineHeight: 1,
  },
}));
export default useStyles;
