import { makeStyles } from '@material-ui/core/styles';

const getActiveLinkStyles = theme => ({
  borderBottom: `4px solid ${theme.palette.common.brandingBlue}`,
});

const useStyles = makeStyles(theme => ({
  link: {
    display: 'block',
    borderBottom: `3px solid ${theme.palette.common.brandingGray3}`,
    textAlign: 'center',
    textDecoration: 'none',
    width: '100%',
    padding: [16, 0, 23],
    '&:hover': getActiveLinkStyles(theme),
  },

  linkActive: getActiveLinkStyles(theme),

  text: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    color: theme.palette.common.brandingGray1,
    '&:hover': {
      color: `${theme.palette.common.black}`,
    },
  },

  textActive: {
    color: `${theme.palette.common.brandingBlack}`,
  },
}));

export default useStyles;
