import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  practiceItem: {
    padding: [16, 0, 16],
    alignItems: 'flex-start',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      borderRight: `0.5px solid ${theme.palette.common.brandingGray3}`,
      borderLeft: `0.5px solid ${theme.palette.common.brandingGray3}`,
      padding: 16,
      '&:hover': {
        boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
        background: theme.palette.common.white2,
      },
    },
  },

  practiceItemCurrent: {
    zIndex: 2,
    '& $link': {
      zIndex: 5,
    },
    '& .MuiCheckbox-root': {
      zIndex: 6,
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      background: theme.palette.common.lightBlue3,
      '&:hover': {
        backgroundColor: theme.lighten(theme.palette.common.lightBlue3, 0.05),
      },
    },
  },

  practice: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },

  link: {
    textDecoration: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  highZIndexLink: {
    zIndex: 7,
  },

  listBox: {
    width: 'fill-available',
  },

  checkbox: {
    zIndex: 6,
  },

  practiceName: {
    flex: 'auto',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    color: theme.palette.common.brandingBlack,
  },

  icon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.brandingBlue,
  },

  practiceInput: {
    marginTop: theme.spacing(2),
    background: theme.palette.common.white,
    zIndex: 6,
    borderColor: theme.palette.common.brandingGray3,
    borderRadius: 4,
  },

  prlink: {
    position: 'relative',
    zIndex: 6,
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
}));

export default useStyles;
