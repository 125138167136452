/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import VacanciesRepository from 'repositories/VacanciesRepository';

import { createRestSlice } from 'utils/RestSlice';

const VacanciesRestSlice = createRestSlice({
  resource: 'vacancy',
  repository: VacanciesRepository,
  slices: ['loadVacancy'],
});

const initialState = {
  ...VacanciesRestSlice.initialState,
};

const VacancySlice = createSlice({
  name: 'vacancy',
  initialState,
  reducers: {
    ...VacanciesRestSlice.reducers,
  },
});

export default VacancySlice.reducer;

export const vacancyHooks = VacanciesRestSlice.hooks(VacancySlice, 'vacancy');
