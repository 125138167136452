import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import {
  Box,
  Button,
  Dialog,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { validationSchema, initialValues } from 'forms/admin/createPage';
import { isNullOrUndefined } from 'utils/helpers';
import { mdParser, MdEditor } from 'utils/markdown';

import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = props => {
  const { page, nextOrder, isModalOpen, onModalClose, onCreate, onUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasChanges, setHasChanges] = useState(false);

  const initValues = initialValues(page, nextOrder);
  const isCreated = !isNil(page);

  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: isCreated ? onUpdate : onCreate,
    enableReinitialize: true,
  });

  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onbeforeunloadFn = event => {
      if (hasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign, prettier/prettier, no-param-reassign
        return event.returnValue = "";
      }
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [hasChanges]);

  const trackChanges = (fieldName, value) => {
    if (initValues[fieldName] !== value) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  const handleModalClose = () => {
    if (hasChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.closeConfirm'))) {
        onModalClose();
        resetForm();
        setHasChanges(false);
      }
    } else {
      onModalClose();
      resetForm();
    }
  };

  const handleContentChange = ({ text }) => {
    trackChanges('content', text);
    handleChange({ target: { name: 'content', value: text } });
  };

  const renderSelectItems = () => {
    return values.availableStates.map(availableState => (
      <MenuItem value={availableState} classes={{ root: classes.state }} key={availableState}>
        {availableState}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      TransitionComponent={Transition}
    >
      <Prompt when={hasChanges} message={t('common.promptMessage')} />
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleModalClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('adminEditPageModal.page')}
            {values.name}
          </Typography>
          <Button autoFocus color="primary" variant="contained" onClick={handleSubmit} disabled={!isAbleToSubmit}>
            {t('adminEditPageModal.submitButton')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.paper}>
        <form noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
            <TextField
              required
              name="name"
              label={t('adminEditPageModal.name')}
              value={values.name}
              variant="outlined"
              onChange={handleChange}
              error={!isNullOrUndefined(errors.name)}
              helperText={t(errors.name)}
            />
            <TextField
              name="title"
              label={t('adminEditPageModal.title')}
              value={values.title}
              variant="outlined"
              onChange={handleChange}
              error={!isNullOrUndefined(errors.title)}
              helperText={t(errors.title)}
            />
            <FormControl
              variant="outlined"
              error={!isNullOrUndefined(errors.content)}
              label={t('adminEditPageModal.content')}
              classes={{
                root: clsx(classes.contentBox, { [classes.errorsContentBox]: !isNullOrUndefined(errors.content) }),
              }}
            >
              <MdEditor
                style={{ height: '400px' }}
                name="content"
                value={values.content}
                renderHTML={text => mdParser.render(text)}
                onChange={handleContentChange}
              />
              <FormHelperText error>{t(errors.content)}</FormHelperText>
            </FormControl>
            <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
              <InputLabel id="state">{t('adminEditPageModal.state')}</InputLabel>
              <Select
                labelId="state"
                name="state"
                value={values.state}
                onChange={handleChange}
                label={t('adminEditPageModal.state')}
              >
                {renderSelectItems()}
              </Select>
              <FormHelperText error>{t(errors.state)}</FormHelperText>
            </FormControl>
          </Box>
        </form>
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  page: PropTypes.shape(),
  nextOrder: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  page: null,
};

export default Modal;
