import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import qs from 'qs';
import { map } from 'ramda';

import { Grid, TextField, FormControl, Button } from '@material-ui/core';

import RoleSelect from 'components/RoleSelect';
import HiringStateSelect from 'components/HiringStateSelect';

import UsersTable from 'containers/Admin/containers/DashboardPage/components/UsersPanel/components/UsersTable';

import useGroupUsers from 'hooks/admin/useGroupUsers';

import { DEFAULT_ROWS_PER_PAGE, FIRST_PAGE } from 'utils/pagination';

const GroupUsers = () => {
  const { t } = useTranslation();
  const { groupId } = map(Number, useParams());
  const [searchParams, setSearchParams] = useState({});
  const [page, setPage] = useState(FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const calculateLoadEffects = () => qs.stringify({ ...searchParams, page, rowsPerPage });
  const [loadEffects, setLoadEffects] = useState(calculateLoadEffects());

  const handleRowsPerPageChange = newValue => setRowsPerPage(newValue);
  const handlePageChange = newValue => setPage(newValue);

  const handleFiltersChange = ({ target: { name, value } }) =>
    setSearchParams({
      ...searchParams,
      [name]: value,
    });

  const handleFiltersReset = () => {
    setSearchParams({});
  };

  const {
    loadGroupUsersIn,
    resetGroupUsersIn,
    inGroupUsers,
    isInGroupLoading: loading,
    inGroupLoadingError: loadingError,
    inGroupMeta: meta,
    destroyAdminGroupUser,
  } = useGroupUsers();

  const handleDestroy = userId => destroyAdminGroupUser(groupId, userId);

  const handleLoadUsers = useCallback(() => {
    loadGroupUsersIn(groupId, {
      q: {
        s: 'created_at asc',
        ...searchParams,
      },
      page,
      perPage: rowsPerPage,
    });
  }, [searchParams, page, rowsPerPage]);

  useDebounce(
    () => {
      const newLoadEffects = calculateLoadEffects();
      setLoadEffects(newLoadEffects);
    },
    100,
    [searchParams, page, rowsPerPage],
  );

  useEffect(() => {
    if (page !== FIRST_PAGE) {
      setPage(FIRST_PAGE);
    }
  }, [searchParams]);

  useEffect(() => {
    handleLoadUsers();
    return () => {
      resetGroupUsersIn();
    };
  }, [loadEffects]);

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name="findByFullNameOrEmail"
            label={t('adminGroupManage.filters.findByFullNameOrEmail')}
            value={searchParams.findByFullNameOrEmail || ''}
            onChange={handleFiltersChange}
            error={false}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <RoleSelect
              allowEmpty
              value={searchParams.roleEq}
              onChange={value => handleFiltersChange({ target: { name: 'roleEq', value } })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <HiringStateSelect
              allowEmpty
              value={searchParams.hiringStateEq}
              onChange={value => handleFiltersChange({ target: { name: 'hiringStateEq', value } })}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Button variant="outlined" color="primary" onClick={handleFiltersReset}>
          {t('adminGroupManage.filters.resetButton')}
        </Button>
      </Grid>
      <Grid item container>
        <UsersTable
          users={inGroupUsers}
          meta={meta}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          currentSort={searchParams.s}
          isCourseChosen={false}
          rowsPerPage={rowsPerPage}
          loading={loading}
          loadingError={loadingError}
          destroyUser={handleDestroy}
          onFiltersChange={handleFiltersChange}
          loadUsers={handleLoadUsers}
          groupContext
        />
      </Grid>
    </Grid>
  );
};

export default GroupUsers;
