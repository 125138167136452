import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import GroupPresenter from 'presenters/GroupPresenter';

const FilterFieldGroup = props => {
  const { onFieldChange, groups, groupId, disabled } = props;
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select defaultValue={groupId || ''} displayEmpty onChange={onFieldChange} disabled={disabled}>
        <MenuItem value="">{t('lastActivity.labelFieldSelectGroup')}</MenuItem>
        {groups.map(group => (
          <MenuItem key={group.id} value={group.id}>
            <Typography noWrap>{group.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterFieldGroup.propTypes = {
  groupId: PropTypes.number,
  groups: PropTypes.arrayOf(GroupPresenter.shape()),
  onFieldChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FilterFieldGroup.defaultProps = {
  groupId: null,
  groups: [],
};

export default FilterFieldGroup;
