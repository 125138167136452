import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createPractice,
  updatePractice,
  updatePractices,
  destroyPractice,
  bulkUpdate,
} from 'store/admin/practicesSlice';

const usePractices = () => {
  const { load: loadAdminPractices, reset: resetAdminPractices, clearLoadingError } = useLoadActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const practices = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    practices,
    loadAdminPractices,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminPractices,
    createPractice,
    updatePractice,
    updatePractices,
    destroyPractice,
    bulkUpdate,
  };
};

export default usePractices;
