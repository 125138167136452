import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: [theme.spacing(4), theme.spacing(2)],
    boxShadow: 'none',
    backgroundColor: 'transparent',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.blue7}`,
      borderRadius: 4,
      margin: [42, 0, 42],
      padding: [56, 48],
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginBottom: theme.spacing(1),
    },
  },
  profileInfo: {
    gap: theme.spacing(3),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      gap: 64,
    },
  },
  userDataBlock: {
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gap: theme.spacing(2),
      marginTop: 0,
    },
  },

  avatar: {
    height: 112,
    width: 112,
    fontSize: theme.typography.pxToRem(30),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      height: 148,
      width: 148,
    },
  },

  fullName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
    color: theme.palette.common.brandingBlack,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(32),
    },
  },

  divider: {
    color: theme.palette.common.brandingGray3,
    height: 1,
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      width: 1,
      height: 'auto',
    },
  },

  buttonsBlock: {
    alignItems: 'stretch',
    gap: 16,
    marginTop: 48,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      justifyContent: 'center',
      marginTop: 8,
    },
  },
  button: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
    padding: [theme.spacing(2), theme.spacing(9)],
  },

  dataLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.palette.common.brandingGray2,
  },
  dataContent: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: 400,
    color: theme.palette.common.brandingBlack,
  },
  logoNameBlock: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    maxWidth: '35%',
    flexBasis: '35%',
  },

  link: {
    textDecoration: 'none',
  },
}));

export default useStyles;
