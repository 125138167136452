import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },

  alertIcon: {
    padding: 0,
  },
  alertMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 0,
  },

  button: {
    width: '100%',
    padding: [14, 32],
    fontSize: theme.typography.pxToRem(16),
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.common.brandingGray2,
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0em',
    textDecoration: 'none',

    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },
    '& svg': {
      marginRight: 11,
      width: 16,
      height: 18,
    },
  },

  textLink: {
    fontSize: theme.typography.pxToRem(16),
  },

  text: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.typography.pxToRem(16),
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    width: '100%',
  },

  textButton: {
    display: 'flex',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
}));

export default useStyles;
