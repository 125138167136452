import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import VacancyPresenter from 'presenters/VacancyPresenter';

const Vacancy = props => {
  const { vacancy, onVacancyModalOpen, onModalOpenDestroy } = props;

  const vacancyId = VacancyPresenter.id(vacancy);

  return (
    <TableRow>
      <TableCell>{vacancyId}</TableCell>
      <TableCell>{VacancyPresenter.title(vacancy)}</TableCell>
      <TableCell>{VacancyPresenter.state(vacancy)}</TableCell>
      <TableCell>{VacancyPresenter.createdAt(vacancy)}</TableCell>
      <TableCell align="right">
        <IconButton onClick={onVacancyModalOpen(vacancy)}>
          <EditIcon color="primary" fontSize="small" />
        </IconButton>
        <IconButton onClick={onModalOpenDestroy(vacancy)}>
          <DeleteIcon color="primary" fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

Vacancy.propTypes = {
  vacancy: PropTypes.shape().isRequired,
  onVacancyModalOpen: PropTypes.func.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
};

export default Vacancy;
