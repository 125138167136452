import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
    height: 'max-content',
  },
}));

export default useStyles;
