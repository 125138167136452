import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TableRow, TableCell, Button, Chip } from '@material-ui/core';

import CoursePresenter from 'presenters/CoursePresenter';
import UserCoursePresenter from 'presenters/UserCoursePresenter';
import appRoutes from 'routes/appRoutes';

const Course = props => {
  const { course, userCourse, onJoinCourse, onRevokeCourse, onStartCourse, userId } = props;
  const { t } = useTranslation();

  const courseId = CoursePresenter.id(course);

  return (
    <TableRow>
      <TableCell>{courseId}</TableCell>
      <TableCell>
        {userCourse ? (
          <Link to={appRoutes.adminUserCoursePath(userId, courseId)}>{CoursePresenter.name(course)}</Link>
        ) : (
          CoursePresenter.name(course)
        )}
      </TableCell>
      <TableCell>
        {userCourse && <Chip label={t(`adminUserCourse.state.${UserCoursePresenter.state(userCourse)}`)} />}
      </TableCell>
      <TableCell>
        {!userCourse && (
          <Button variant="outlined" color="primary" onClick={() => onStartCourse(courseId)}>
            {t('adminUserCourse.join')}
          </Button>
        )}

        {userCourse && UserCoursePresenter.isRevoked(userCourse) && (
          <Button variant="outlined" color="primary" onClick={() => onJoinCourse(userCourse.id)}>
            {t('adminUserCourse.join')}
          </Button>
        )}

        {userCourse && !UserCoursePresenter.isRevoked(userCourse) && (
          <Button variant="outlined" onClick={() => onRevokeCourse(userCourse.id)}>
            {t('adminUserCourse.revoke')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

Course.propTypes = {
  userId: PropTypes.number.isRequired,
  course: PropTypes.shape().isRequired,
  userCourse: PropTypes.shape(),
  onStartCourse: PropTypes.func.isRequired,
  onJoinCourse: PropTypes.func.isRequired,
  onRevokeCourse: PropTypes.func.isRequired,
};

Course.defaultProps = {
  userCourse: null,
};

export default Course;
