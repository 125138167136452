import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.brandingBlue,
    '& > a:visited': {
      color: theme.palette.common.brandingBlue,
    },
  },
  separator: {
    height: 19,
    width: 30,
    color: theme.palette.common.brandingGray1,
  },
}));
