import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map } from 'ramda';

import { List, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import appRoutes from 'routes/appRoutes';
import { useFetchStatus } from 'utils/fetchStatusUtils';

import useCourse from 'sharedContainers/Course';
import { lessonsHooks } from 'store/user/lessonsSlice';

import LessonPresenter from 'presenters/User/LessonPresenter';

import LessonItem from 'components/LessonItem';
import CourseTitle from 'components/CourseTitle';

import useStyle from './styles';

export default () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { loadCourse, resetCourse } = useCourse();

  const {
    firstLoadLessons,
    lessons: { items: lessons, loadingStatus },
  } = lessonsHooks.use();

  useEffect(() => {
    loadCourse(courseId);
    firstLoadLessons({ course_id: courseId });
    return () => {
      resetCourse();
    };
  }, [courseId]);

  const { isIdle } = useFetchStatus(loadingStatus);

  if (isIdle) {
    return (
      <List disablePadding className={classes.container}>
        <Box mb={1}>
          <Link to={appRoutes.coursesPath()} className={classes.backLink}>
            <ArrowBackIcon fontSize="inherit" />
            {t('header.courses')}
          </Link>
        </Box>
      </List>
    );
  }

  return (
    <List disablePadding className={classes.container}>
      <Box mb={1}>
        <Link to={appRoutes.coursesPath()} className={classes.backLink}>
          <ArrowBackIcon fontSize="inherit" />
          {t('header.courses')}
        </Link>
      </Box>

      <CourseTitle courseId={courseId} />

      {map(
        lesson => (
          <Link
            className={classes.link}
            key={LessonPresenter.id(lesson)}
            to={appRoutes.courseLessonTheoriesPath(courseId, LessonPresenter.id(lesson))}
          >
            <LessonItem courseLesson={lesson} lessonClasses={classes.listItem} />
          </Link>
        ),
        lessons,
      )}
    </List>
  );
};
