import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { slice, concat, length } from 'ramda';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

import CopyButton from 'components/CopyButton';
import LinkRenderer from './components/LinkRenderer';

import useStyles from './styles';

const components = {
  link: LinkRenderer,
  code({ inline, className, children, ...props }) {
    const classes = useStyles();
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';

    return !inline ? (
      <div className={classes.codeWrap}>
        <div className={classes.copyButton}>
          <CopyButton valueToCopy={String(children)} className={classes.codeWrap} />
        </div>
        <SyntaxHighlighter style={prism} language={language} wrapLongLines {...props}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      </div>
    ) : (
      <code className={clsx(classes.code, className)} {...props}>
        {children}
      </code>
    );
  },
  blockquote({ children }) {
    const classes = useStyles();

    return <div className={classes.blockquote}>{children}</div>;
  },
  table({ children }) {
    const classes = useStyles();

    return <table className={classes.table}>{children}</table>;
  },
  iframe(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
    const matchesMobile = !matchesDesktop;

    const { t } = useTranslation();
    const seeMore = t('common.seeMore');
    const rollUp = t('common.rollUp');
    const [accordionSummary, setAccordionSummary] = useState(seeMore);
    const handleAccordionExpand = (e, expanded) => {
      if (expanded) {
        setAccordionSummary(rollUp);
      } else {
        setAccordionSummary(seeMore);
      }
    };

    return (
      <>
        {matchesMobile && (
          <Accordion
            elevation={0}
            className={clsx(classes.videoContainer, classes.accordion)}
            onChange={handleAccordionExpand}
          >
            <AccordionSummary
              aria-controls={accordionSummary}
              id={accordionSummary}
              classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
              expandIcon={<ExpandMoreRounded color="primary" />}
              IconButtonProps={{ classes: { root: classes.accordionIcon } }}
            >
              <Typography color="primary">{accordionSummary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe {...props} />
            </AccordionDetails>
          </Accordion>
        )}

        {matchesDesktop && (
          <div className={classes.videoContainer}>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe {...props} />
          </div>
        )}
      </>
    );
  },
};

const NUMBER_OF_CHARACTERS = 500;

const MarkdownContent = props => {
  const { content, className, canBeTruncated } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const seeMore = t('common.seeMore');
  const rollUp = t('common.rollUp');

  const [expanded, setExpanded] = useState(false);

  const isMarkdownLong = markdown => length(markdown) > NUMBER_OF_CHARACTERS;

  const isOverflowActive = canBeTruncated && isMarkdownLong(content);
  const showExpandButton = (isOverflowActive && !expanded) || expanded;

  const trimMarkdown = markdown => {
    if (isMarkdownLong(markdown)) {
      return concat(slice(0, NUMBER_OF_CHARACTERS, markdown), '...');
    }
    return slice(0, NUMBER_OF_CHARACTERS, markdown);
  };

  const handleChange = () => setExpanded(!expanded);

  return (
    <>
      {!canBeTruncated && (
        <Markdown
          className={clsx(classes.markdown, className)}
          components={components}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
        >
          {content}
        </Markdown>
      )}

      {canBeTruncated && (
        <div>
          <Markdown
            className={clsx(classes.markdown, className)}
            components={components}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          >
            {!expanded ? trimMarkdown(content) : content}
          </Markdown>

          {showExpandButton && (
            <Button onClick={handleChange} className={classes.seeMoreButton} color="primary">
              {expanded ? rollUp : seeMore}
              <ExpandMoreRounded color="primary" className={expanded ? classes.rollUpIcon : ''} />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

MarkdownContent.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  canBeTruncated: PropTypes.bool,
};

MarkdownContent.defaultProps = {
  className: null,
  canBeTruncated: false,
};

export default MarkdownContent;
