import React from 'react';
import PropTypes from 'prop-types';
import { max, pluck, isEmpty } from 'ramda';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import BadgeGroup from 'components/BadgeGroup';
import LessonPresenter, { THEORY, BADGE_SIZES } from 'presenters/User/LessonPresenter';
import { getPracticesBadgesVariants, getTheoriesBadgesVariants } from 'utils/badgeVariantsSelector';

import CourseProgressCardSkeleton from './components/CourseProgressCardSkeleton';

import useStyles from './styles';

const CourseProgressCard = props => {
  const { courseLessons, type, showTooltips, listItemAdditionalStyles, flexibleBadges, isLoading } = props;
  const classes = useStyles();

  if (isEmpty(courseLessons) || isLoading) {
    return <CourseProgressCardSkeleton />;
  }

  const maxLessonsExercises = max(
    ...courseLessons.map(lesson => {
      const maximaArray =
        type === THEORY ? LessonPresenter.theoryExercisesCount(lesson) : LessonPresenter.practiceExercisesCount(lesson);
      return maximaArray;
    }),
  );

  const badgeWidthPercent = 100 / maxLessonsExercises;

  const flexibleBadgeStyle = flexibleBadges ? { width: `${badgeWidthPercent}%` } : null;

  const getUserExercises = lesson => {
    return type === THEORY ? LessonPresenter.userTheories(lesson) : LessonPresenter.userPractices(lesson);
  };

  const getBadgesVariants = userExercises => {
    return type === THEORY ? getTheoriesBadgesVariants(userExercises) : getPracticesBadgesVariants(userExercises);
  };

  const getTitles = userExercises => {
    return pluck('name', userExercises);
  };

  return (
    <List className={classes.list}>
      {courseLessons.map(lesson => {
        const userExercises = getUserExercises(lesson);
        const lessonId = LessonPresenter.id(lesson);

        return (
          <ListItem key={lessonId} className={classes.lessontItem} disableGutters>
            <Box className={listItemAdditionalStyles} width="100%" height="auto">
              <Typography className={classes.text}>{LessonPresenter.name(lesson)}</Typography>
              <BadgeGroup
                variants={getBadgesVariants(userExercises)}
                titles={getTitles(userExercises)}
                size={BADGE_SIZES.small}
                showTooltips={showTooltips}
                tooltipsPlacement="top"
                flexibleBadgeStyle={flexibleBadgeStyle}
              />
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

CourseProgressCard.propTypes = {
  courseLessons: PropTypes.arrayOf(LessonPresenter.shape()),
  type: PropTypes.oneOf(['theory', 'practice', '']),
  showTooltips: PropTypes.bool,
  listItemAdditionalStyles: PropTypes.string,
  flexibleBadges: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CourseProgressCard.defaultProps = {
  courseLessons: [],
  type: '',
  showTooltips: false,
  listItemAdditionalStyles: null,
  flexibleBadges: false,
  isLoading: false,
};

export default CourseProgressCard;
