import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import findById from 'utils/findById';
import { MenuItem, Typography } from '@material-ui/core';
import appRoutes from 'routes/appRoutes';
import CoursePresenter from 'presenters/CoursePresenter';
import UserPresenter from 'presenters/UserPresenter';

import useStyles from '../../styles';

const CourseMenu = props => {
  const { courses, currentCourseId, currentUser, onMobileMenuClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const currentCourse = findById(courses || [], currentCourseId);

  return (
    <>
      <NavLink
        to={appRoutes.courseLessonTheoriesPath(currentCourseId, CoursePresenter.firstLessonId(currentCourse))}
        className={classes.link}
        activeClassName={classes.linkActive}
      >
        <MenuItem disableGutters onClick={onMobileMenuClose} className={classes.menuItem}>
          <Typography className={classes.name}>{t('header.course')}</Typography>
        </MenuItem>
      </NavLink>
      <NavLink
        to={appRoutes.coursesStudentDashboardPath(currentCourseId, UserPresenter.id(currentUser))}
        className={classes.link}
        activeClassName={classes.linkActive}
      >
        <MenuItem disableGutters onClick={onMobileMenuClose} className={classes.menuItem}>
          <Typography className={classes.name}>{t('header.progress')}</Typography>
        </MenuItem>
      </NavLink>
    </>
  );
};

CourseMenu.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  courses: PropTypes.arrayOf(CoursePresenter.shape()),
  currentCourseId: PropTypes.number.isRequired,
  onMobileMenuClose: PropTypes.func,
};

CourseMenu.defaultProps = {
  courses: [],
  onMobileMenuClose: () => {},
};
export default CourseMenu;
