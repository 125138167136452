import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: [theme.spacing(4), theme.spacing(2)],
  },

  listItem: {
    minHeight: theme.typography.pxToRem(58),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    background: theme.palette.common.white,
  },

  link: {
    textDecoration: 'none',
  },

  backLink: {
    color: theme.palette.common.brandingGray2,
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0em',
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    marginBottom: 0,

    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },
    '& svg': {
      marginRight: 11,
      width: 16,
      height: 18,
      transform: 'translateY(4px)',
      color: theme.palette.common.black1,
    },
  },
}));

export default useStyles;
