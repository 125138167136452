import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Box, Backdrop, Container, Grid, Paper, CircularProgress, Breadcrumbs, Typography } from '@material-ui/core';

import Link from 'components/RouterLink';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { groupHooks } from 'store/admin/groupSlice';
import appRoutes from 'routes/appRoutes';

import GroupUsers from './containers/GroupUsers';

import useStyles from './styles';

const GroupPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loadGroup,
    group: { item: group, loadingStatus },
  } = groupHooks.use();
  const { groupId } = map(Number, useParams());

  useEffect(() => {
    loadGroup(groupId);
  }, []);

  const { isIdle, isPending } = useFetchStatus(loadingStatus);

  if (isIdle || isPending) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const { name, startAt, endAt } = group;

  return (
    <Container>
      <Box my={3}>
        <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
          <Box p={3}>
            <Breadcrumbs separator="›">
              <Link to={appRoutes.adminGroupsPath()}>
                <Typography>{t('adminMenu.groups')}</Typography>
              </Link>
            </Breadcrumbs>
            <Grid container direction="column">
              <div>
                {t('adminGroupManage.pageTitle')}
                {': '}
                {name}
              </div>
              {startAt && (
                <div>
                  {t('adminGroupManage.startAt')}
                  {': '}
                  {startAt}
                </div>
              )}
              {endAt && (
                <div>
                  {t('adminGroupManage.endAt')}
                  {': '}
                  {endAt}
                </div>
              )}
            </Grid>
          </Box>
        </Paper>

        <Box my={3}>
          <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
            <GroupUsers />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default GroupPage;
