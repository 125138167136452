import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import UserPresenter from './UserPresenter';

export default new Presenter({
  kinds: PropTypes.arrayOf(PropTypes.shape()),
  count: PropTypes.number,
  user: UserPresenter.shape(),
});
