/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminVacanciesRepository from 'repositories/AdminVacanciesRepository';

import { createRestSlice } from 'utils/RestSlice';

const VacancyRestSlice = createRestSlice({
  resource: 'vacancy',
  repository: AdminVacanciesRepository,
  slices: ['loadVacancy', 'createVacancy', 'updateVacancy', 'deleteVacancy'],
});

const initialState = {
  ...VacancyRestSlice.initialState,
};

const VacancySlice = createSlice({
  name: 'adminVacancy',
  initialState,
  reducers: {
    ...VacancyRestSlice.reducers,
  },
});

export default VacancySlice.reducer;

export const vacancyHooks = VacancyRestSlice.hooks(VacancySlice, 'adminVacancy');
