import { apiV1AdminUserUserCoursesPath, apiV1AdminUserUserCoursePath } from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  load(params) {
    const path = apiV1AdminUserUserCoursesPath(params);
    return FetchHelpers.get(path);
  },

  create(userId, userCourse) {
    const path = apiV1AdminUserUserCoursesPath({ user_id: userId });
    return FetchHelpers.post(path, { userCourse });
  },

  update(userId, id, userCourse) {
    const path = apiV1AdminUserUserCoursePath(id, { user_id: userId });
    return FetchHelpers.put(path, { userCourse });
  },
};
