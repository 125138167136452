import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

import {
  Grid,
  Typography,
  Card,
  Box,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  List,
  ListItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Skeleton from '@material-ui/lab/Skeleton';

import Icon from 'components/Icon';
import { stateEvents } from 'utils/userPracticeStates';
import { isBlank } from 'utils/helpers';
import ActivityPresenter from 'presenters/ActivityPresenter';
import UserPresenter from 'presenters/UserPresenter';
import ExercisePresenter from 'presenters/ExercisePresenter';
import UserExerciseFeedback from 'presenters/Activity/UserExerciseFeedback';
import TeacherPresenter from 'presenters/Activity/TeacherPresenter';
import DefaultAvatar from 'components/DefaultAvatar/DefaultAvatar';
import MarkdownContent from 'components/MarkdownContent';

import appRoutes from 'routes/appRoutes';
import useStyles from './styles';

export const LastActivitySkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.activityCard} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        avatar={<Skeleton variant="circle" className={classes.avatar} width={56} height={52} />}
        title={<Skeleton variant="text" width={250} />}
        subheader={<Skeleton variant="text" width={250} />}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <Box display="flex" alignItems="center">
          <Skeleton width={30} height={30} className={classes.icon} />
          <Skeleton variant="text" width={150} />
        </Box>
        <Skeleton height={103} />
      </CardContent>
    </Card>
  );
};

const LastActivities = props => {
  const { activity, onChangeUserPracticeState, onRejectPracticeWithFeedback } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const user = ActivityPresenter.user(activity);
  const exercise = ActivityPresenter.exercise(activity);
  const exerciseId = ExercisePresenter.id(exercise);

  const [visiblePracticeContent, setVisiblePracticeContent] = useState(null);
  const isVisiblePracticeContent = visiblePracticeContent === exerciseId;

  const handlePracticeContentOpen = practiceId => () => {
    setVisiblePracticeContent(practiceId);
  };

  const handlePracticeContentClose = () => {
    setVisiblePracticeContent(null);
  };

  const path = appRoutes.lastUserActivitiesPath(ActivityPresenter.courseId(activity), UserPresenter.id(user));

  const cardTitle = (
    <Box display="flex">
      <Typography variant="body1">
        <Link to={path}>{UserPresenter.fullName(user)}</Link>
      </Typography>
      <Typography variant="body1" component="div">
        <div>{ActivityPresenter.studentCourseRating(activity)}</div>
      </Typography>
    </Box>
  );

  const cardSubheader = (
    <Typography variant="subtitle2">
      {ActivityPresenter.updatedDate(activity)}
      &nbsp;|&nbsp;
      <Link to={appRoutes.adminUserPath(UserPresenter.id(user))}>{t('activityCard.userProfile')}</Link>
    </Typography>
  );

  const cardAction = (
    <Typography variant="body1">
      <Link to={appRoutes.adminUserCoursePath(UserPresenter.id(user), ActivityPresenter.courseId(activity))}>
        {ExercisePresenter.courseName(exercise)}
      </Link>
    </Typography>
  );

  return (
    <Card className={classes.activityCard} key={ActivityPresenter.id(activity)} variant="outlined">
      <div className={classes.teachers}>
        {ActivityPresenter.teachers(activity).map(teacher => (
          <DefaultAvatar key={teacher.id} user={teacher} className={classes.smallAvatar} />
        ))}
      </div>

      <CardHeader
        className={classes.cardHeader}
        avatar={<DefaultAvatar user={user} className={classes.avatar} />}
        title={cardTitle}
        subheader={cardSubheader}
        action={cardAction}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <Typography className={classes.lesson}>
          <Link
            to={appRoutes.courseLessonPracticePath(
              ActivityPresenter.courseId(activity),
              ExercisePresenter.lessonId(exercise),
              ExercisePresenter.id(exercise),
            )}
          >
            {ExercisePresenter.lessonName(exercise)}
          </Link>
        </Typography>
        <Box display="flex" alignItems="center">
          <Icon name="edit" className={classes.icon} />
          <Typography>{ExercisePresenter.name(exercise)}</Typography>
          <IconButton
            onClick={isVisiblePracticeContent ? handlePracticeContentClose : handlePracticeContentOpen(exerciseId)}
          >
            {isVisiblePracticeContent ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
          </IconButton>
          <Button
            className={classes.buttonRejectWithMessage}
            variant="outlined"
            color="primary"
            onClick={onRejectPracticeWithFeedback(activity)}
          >
            {t('activityCard.rejectWithMessage')}
          </Button>
          <Button
            className={classes.buttonReject}
            variant="outlined"
            color="primary"
            onClick={onChangeUserPracticeState(activity, stateEvents.reject)}
          >
            {t('activityCard.reject')}
          </Button>
          <Button
            className={classes.buttonAccept}
            variant="contained"
            color="primary"
            onClick={onChangeUserPracticeState(activity, stateEvents.accept)}
          >
            {t('activityCard.accept')}
          </Button>
        </Box>
        {ExercisePresenter.teacherContent(exercise) && (
          <Box py={1} px={4} classes={{ root: clsx({ [classes.content]: !isVisiblePracticeContent }) }}>
            <Typography variant="h5" noWrap>
              {t('activityCard.teacherContent')}
            </Typography>
            <MarkdownContent content={ExercisePresenter.teacherContent(exercise)} />
          </Box>
        )}
        <Box py={1} px={4} classes={{ root: clsx({ [classes.content]: !isVisiblePracticeContent }) }}>
          <Typography variant="h5" noWrap>
            {t('activityCard.content')}
          </Typography>
          <MarkdownContent content={ExercisePresenter.content(exercise)} />
        </Box>
        <Button
          className={classes.prLinkField}
          component="a"
          variant="outlined"
          href={ActivityPresenter.prLink(activity)}
          target="_blank"
        >
          <Typography noWrap className={classes.prLinkBox}>
            {ActivityPresenter.prLink(activity)}
          </Typography>
        </Button>
        <Typography className={classes.answerFor} gutterBottom>
          {`${t('adminUserLastActivitiesPage.answerFor')}: "${ExercisePresenter.result(exercise)}"`}
        </Typography>
        {!isEmpty(ActivityPresenter.userExerciseFeedbacks(activity)) && (
          <>
            <Typography color="textSecondary" gutterBottom>
              {t('adminUserLastActivitiesPage.previousFeedback')}
            </Typography>

            <List>
              {ActivityPresenter.userExerciseFeedbacks(activity).map(feedback => {
                const teacher = UserExerciseFeedback.teacher(feedback);

                return (
                  <ListItem key={UserExerciseFeedback.id(feedback)}>
                    <Grid container direction="column">
                      <Typography variant="body1" gutterBottom>
                        {UserExerciseFeedback.message(feedback)}
                      </Typography>

                      {!isBlank(teacher) && (
                        <Typography component="div" variant="body2" color="textSecondary">
                          <Grid container alignItems="center">
                            <DefaultAvatar
                              key={TeacherPresenter.id(teacher)}
                              user={teacher}
                              className={classes.smallAvatar}
                            />
                            {TeacherPresenter.fullName(teacher)}
                          </Grid>
                        </Typography>
                      )}
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
};

LastActivities.propTypes = {
  activity: PropTypes.shape().isRequired,
  onChangeUserPracticeState: PropTypes.func.isRequired,
  onRejectPracticeWithFeedback: PropTypes.func.isRequired,
};

export default LastActivities;
