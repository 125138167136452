import { apiV1AdminPagesPath, apiV1AdminPagePath, updateAllApiV1AdminPagesPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1AdminPagesPath();
    return FetchHelpers.get(path);
  },

  show(id) {
    const path = apiV1AdminPagePath(id);
    return FetchHelpers.get(path);
  },

  create(page) {
    const path = apiV1AdminPagesPath();
    return FetchHelpers.post(path, { page });
  },

  update(id, page) {
    const path = apiV1AdminPagePath(id);
    return FetchHelpers.put(path, { page });
  },

  updateAll(pages) {
    const path = updateAllApiV1AdminPagesPath();
    return FetchHelpers.put(path, { pagesAttributes: pages });
  },

  delete(id) {
    const path = apiV1AdminPagePath(id);
    return FetchHelpers.delete(path);
  },
};
