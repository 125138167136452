import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import externalRoutes from 'routes/externalRoutes';

import VacancyAsideCard from 'components/VacancyAsideCard';

import useStyles from './styles';

const VacancyFeedbackCard = props => {
  const { title, content, buttonText, emoji, vacancyTitle, accordionShown } = props;
  const classes = useStyles();
  const jobEmailLink = `${externalRoutes.jobEmailPath()}?subject=${vacancyTitle}`;

  const renderExtraContent = () => {
    return (
      <Button className={classes.emailButton} variant="contained" color="primary" size="large" href={jobEmailLink}>
        {buttonText}
      </Button>
    );
  };

  return (
    <VacancyAsideCard
      emoji={emoji}
      title={title}
      content={content}
      extraContent={renderExtraContent()}
      accordionShown={accordionShown}
    />
  );
};

VacancyFeedbackCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  vacancyTitle: PropTypes.string,
  accordionShown: PropTypes.bool,
};
VacancyFeedbackCard.defaultProps = {
  emoji: '',
  vacancyTitle: 'Vacancy',
  accordionShown: false,
};

export default VacancyFeedbackCard;
