import React from 'react';

import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AuthTypeSwitch from 'containers/Authenticator/components/AuthTypeSwitch';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import RegistrationRepository from 'repositories/RegistrationRepository';
import { validationSchema, initialValues } from 'forms/SignUp';
import parseFormErrors from 'utils/parserFormErrors';
import authModes from 'utils/authModes';
import { isNullOrUndefined } from 'utils/helpers';

import useCurrentUser from 'hooks/useCurrentUser';

import useStyles from './styles';

const SignUp = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loadCurrentUser } = useCurrentUser();

  const handleSubmitSignUp = (values, { setErrors }) => {
    return RegistrationRepository.create({
      user: { ...values },
    })
      .then(() => {
        return loadCurrentUser();
      })
      .catch(error => {
        const errors = parseFormErrors(error, ['firstName', 'lastName', 'email', 'githubAccount', 'password']);
        setErrors(errors);
      });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitSignUp,
  });
  const { handleSubmit, errors, handleChange, values, isSubmitting } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  return (
    <>
      <AuthTypeSwitch mode={authModes.SIGN_UP} />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} sm={5}>
            <TextField
              className={clsx(classes.grid, classes.textField)}
              error={!isNullOrUndefined(errors.firstName)}
              helperText={t(errors.firstName)}
              name="firstName"
              required
              margin="normal"
              fullWidth
              variant="standard"
              id="firstName"
              label={t('signUp.firstName')}
              autoFocus
              onChange={handleChange}
              value={values.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={Boolean(errors.lastName)}
              helperText={t(errors.lastName)}
              required
              margin="normal"
              fullWidth
              variant="standard"
              id="lastName"
              label={t('signUp.lastName')}
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="space-between" alignItems="stretch">
          <Grid item xs={12}>
            <TextField
              error={!isNullOrUndefined(errors.githubAccount)}
              helperText={t(errors.githubAccount)}
              variant="standard"
              required
              fullWidth
              placeholder={t('signUp.githubAccount')}
              id="githubAccount"
              label="Github account"
              name="githubAccount"
              autoComplete="githubAccount"
              onChange={handleChange}
              value={values.githubAccount}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!isNullOrUndefined(errors.email)}
              helperText={t(errors.email)}
              variant="standard"
              required
              fullWidth
              id="email"
              label={t('signUp.emailAddress')}
              name="email"
              autoComplete="email"
              onChange={handleChange}
              value={values.email}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!isNullOrUndefined(errors.password)}
              helperText={t(errors.password)}
              variant="standard"
              required
              type="password"
              fullWidth
              id="password"
              label={t('signUp.password')}
              name="password"
              autoComplete="password"
              onChange={handleChange}
              value={values.password}
              className={classes.textField}
            />
          </Grid>
          {errors.serverSignUpError && <Alert severity="error">{errors.serverSignUpError}</Alert>}
          <Grid item xs={12}>
            <Button
              type="submit"
              className={classes.button}
              disabled={!isAbleToSubmit}
              variant="contained"
              color="primary"
            >
              <Typography className={classes.text}>{t('signUp.registration')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SignUp;
