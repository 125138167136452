import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, Card, Container, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useNumberParams from 'hooks/useNumberParams';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { vacancyHooks } from 'store/vacancySlice';
import MarkdownContent from 'components/MarkdownContent';
import appRoutes from 'routes/appRoutes';

import VacancyPresenter from 'presenters/VacancyPresenter';

import Aside from './components/Aside';
import Summary from './components/Summary';

import useStyles from './styles';

const Vacancy = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { vacancyId } = useNumberParams(['vacancyId']);

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const {
    loadVacancy,
    vacancy: { item: vacancy, loadingStatus },
  } = vacancyHooks.use();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadVacancy(vacancyId);
  }, []);

  const { isIdle, isFailed } = useFetchStatus(loadingStatus);

  if (isIdle) {
    return (
      <Container className={classes.root} component="main" maxWidth="lg">
        <Box display="flex" justifyContent="center">
          Loading
        </Box>
      </Container>
    );
  }

  if (isFailed) {
    return <Backdrop open>Error</Backdrop>;
  }

  return (
    <Container className={classes.root} component="main" maxWidth="lg">
      <Link to={appRoutes.vacanciesPath()} className={classes.backLink}>
        <ArrowBackIcon fontSize="inherit" />
        {t('vacancy.backToList')}
      </Link>

      <h1 className={classes.title}>{VacancyPresenter.title(vacancy)}</h1>
      <h2 className={classes.subtitle}>{t('vacancy.subtitle')}</h2>

      <Box className={classes.vacancyBox}>
        <Summary />

        {matchesMobile && <Divider style={{ backgroundColor: theme.palette.common.brandingGray3 }} />}

        <Box className={classes.article}>
          <Card>
            <MarkdownContent className={classes.articleContent} content={VacancyPresenter.description(vacancy) || ''} />
          </Card>
          <Box>
            <Aside vacancyTitle={VacancyPresenter.title(vacancy)} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Vacancy;
