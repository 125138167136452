import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  theoryItem: {
    borderRight: `0.5px solid ${theme.palette.common.brandingGray3}`,
    borderLeft: `0.5px solid ${theme.palette.common.brandingGray3}`,
    padding: 16,
  },

  checkbox: {
    margin: 9,
  },

  theoryName: {
    marginLeft: 20,
    marginRight: 20,
    flex: 'auto',
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useStyles;
