import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    padding: [28, 40, 28, 42],
  },

  courseName: {
    fontSize: theme.typography.pxToRem(22),
    marginBottom: 10,
  },

  description: {
    fontSize: theme.typography.pxToRem(14),
  },

  descriptionBox: {
    marginBottom: 15,
  },

  info: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: 22,
  },

  infoBox: {
    textAlign: 'center',
    marginRight: theme.spacing(3),
  },

  durationHours: {
    fontSize: theme.typography.pxToRem(27),
    marginLeft: 22,
  },

  durationLabel: {
    fontSize: theme.typography.pxToRem(14),
  },

  courseButton: {
    marginRight: 25,
    borderRadius: 4,
  },
}));

export default useStyles;
