import React, { useEffect } from 'react';

import { AppBar, Box } from '@material-ui/core';

import useNumberParams from 'hooks/useNumberParams';

import useUserCourses from 'sharedContainers/User/Courses';

import { HeaderMenu } from './components/HeaderMenu';

import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  const { courseId: currentCourseId } = useNumberParams(['courseId']);
  const { courses, loadCourses } = useUserCourses();

  useEffect(() => {
    loadCourses();
  }, []);

  return (
    <Box pl={0} flexGrow={1}>
      <AppBar position="static" classes={{ root: classes.header }} elevation={0}>
        <HeaderMenu courses={courses} currentCourseId={currentCourseId} />
      </AppBar>
    </Box>
  );
};

export default Header;
