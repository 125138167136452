import React from 'react';
import { range } from 'ramda';
import clsx from 'clsx';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

const COUNT_SKELETON_ITEMS = 5;

const LessonsListSkeleton = () => {
  const classes = useStyles();

  const skeletonItems = range(0, COUNT_SKELETON_ITEMS).map(item => {
    return (
      <ListItem key={item} className={clsx(classes.listItem, classes.divider)} divider>
        <div className={classes.listTitle}>
          <Skeleton />
        </div>
        <Skeleton variant="rect" width="100%" height={2} />
        <Skeleton variant="rect" width="60%" height={2} />
      </ListItem>
    );
  });

  return <List className={classes.list}>{skeletonItems}</List>;
};

export default LessonsListSkeleton;
