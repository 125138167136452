import * as yup from 'yup';

import pageStates from 'utils/pageStates';
import { AVAILABLE_PAGE_STATES_REGEX } from 'utils/regularExpressions';
import PagePresenter from 'presenters/PagePresenter';

const validationFields = {
  name: yup.string().required('validations.page.name.required'),
  title: yup.string(),
  content: yup.string().required('validations.page.content.required'),
  state: yup.string().matches(AVAILABLE_PAGE_STATES_REGEX, { message: 'validations.page.state.invalid' }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (page, nextOrder) => ({
  name: PagePresenter.name(page) || '',
  title: PagePresenter.title(page) || '',
  content: PagePresenter.content(page) || '',
  state: page ? PagePresenter.state(page) : pageStates.draft,
  availableStates: [pageStates.published, pageStates.archived, pageStates.draft],
  order: PagePresenter.order(page) || nextOrder,
});
