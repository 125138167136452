import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

const JOINED = 'joined';
const COMPLETED = 'completed';
const REVOKED = 'revoked';
const FULLTIME = 'full_time';
const INACTIVE = 'inactive';
const DISTANCE = 'distance';

export default new Presenter(
  {
    id: PropTypes.number,
    userId: PropTypes.number,
    courseId: PropTypes.number,
    name: PropTypes.string,
    state: PropTypes.string,
    courseType: PropTypes.string,
  },
  {
    isJoined(course) {
      return this.state(course) === JOINED;
    },
    isCompleted(course) {
      return this.state(course) === COMPLETED;
    },
    isRevoked(course) {
      return this.state(course) === REVOKED;
    },
    isFullTime(course) {
      return this.courseType(course) === FULLTIME;
    },
    isInactive(course) {
      return this.courseType(course) === INACTIVE;
    },
    isDistance(course) {
      return this.courseType(course) === DISTANCE;
    },
  },
);
