import { getSelectors, useActions, updateCurrentUser, unlinkAccount } from 'store/currentUserSlice';

export default () => {
  const { getCurrentUser, getLoadingState } = getSelectors();
  const { loadCurrentUser, logout } = useActions();
  const currentUser = getCurrentUser();
  const isCurrentUserLoading = getLoadingState();

  return { currentUser, loadCurrentUser, isCurrentUserLoading, logout, updateCurrentUser, unlinkAccount };
};
