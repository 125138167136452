/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminGroupsRepository from 'repositories/AdminGroupsRepository';

import { createRestSlice } from 'utils/RestSlice';

const GroupsRestSlice = createRestSlice({
  resource: 'groups',
  repository: AdminGroupsRepository,
  slices: ['loadGroups'],
});

const initialState = {
  ...GroupsRestSlice.initialState,
};

const GroupsSlice = createSlice({
  name: 'adminGroups',
  initialState,
  reducers: {
    ...GroupsRestSlice.reducers,
  },
});

export default GroupsSlice.reducer;

export const groupsHooks = GroupsRestSlice.hooks(GroupsSlice, 'adminGroups');
