import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(36),
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    margin: [24, 0, 24],

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 600,
      padding: [24, 40],
      margin: 0,
      borderBottom: `1px solid ${theme.palette.common.gray7}`,
    },
  },

  skeletonBox: {
    padding: 0,
    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      padding: [0, 40, 0],
    },
  },

  skeletonTitle: {
    fontSize: theme.typography.pxToRem(18),
  },

  listItem: {
    textDecoration: 'none',
    width: '100%',
    backgroundColor: theme.palette.common.white,

    '& .MuiListItem-root': {
      minHeight: theme.typography.pxToRem(58),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      color: theme.palette.common.brandingBlack,
      background: theme.palette.common.white,
      padding: 24,
      gap: 16,
      justifyContent: 'space-between',
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      backgroundColor: 'transparent',

      '& .MuiListItem-root': {
        color: theme.palette.common.brandingGray2,
        padding: [24, 40],
        '&:hover': {
          boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
          background: theme.palette.common.white2,
        },
      },
    },
  },

  listItemCurrent: {
    '& .MuiListItem-root': {
      width: '100%',
      background: theme.palette.common.lightBlue3,
      color: theme.palette.common.brandingBlack,
      fontWeight: 500,

      [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
        '&:hover': {
          backgroundColor: theme.lighten(theme.palette.common.lightBlue3, 0.05),
        },
      },
    },
  },
}));

export default useStyles;
