import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import Icon from 'components/Icon';
import externalRoutes from 'routes/externalRoutes';
import { GuestHeader } from 'layouts/containers/Header';
import useStyles from './styles';

const Auth = props => {
  const { children } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <GuestHeader />
      <main className={classes.summary}>
        <Box className={classes.annotation}>
          <h1 className={classes.title}>
            {t('mainPage.titleWelcome')}
            <i>{t('mainPage.titleOur')}</i>
            <br />
            {t('mainPage.titleSpace')}
          </h1>

          <p className={classes.description}>{t('mainPage.description')}</p>
          <Box className={classes.socialNetworks}>
            <a
              href={externalRoutes.dualbootpartnersPath()}
              target="_blank"
              rel="noreferrer"
              className={classes.aboutUs}
            >
              <span>{t('mainPage.about')}</span>
              <Icon name="arrowSquareOut" />
            </a>
            <a href={externalRoutes.linkedinPath()} target="_blank" rel="noreferrer">
              <Icon name="linkedin" />
            </a>
          </Box>
        </Box>
        <Box className={classes.content}>{children}</Box>
      </main>
    </div>
  );
};

Auth.propTypes = {
  children: PropTypes.node,
};

Auth.defaultProps = {
  children: null,
};

export default Auth;
