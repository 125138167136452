import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const CopyButton = props => {
  const { valueToCopy } = props;
  const [isTooltipOpened, setTooltipOpened] = useState(false);
  let toggleTimeout = null;

  const handleClick = () => {
    setTooltipOpened(true);

    if (toggleTimeout) {
      clearTimeout(toggleTimeout);
    }
    toggleTimeout = setTimeout(() => {
      setTooltipOpened(false);
    }, 3000);
  };

  return (
    <Tooltip title="Скопировано!" open={isTooltipOpened}>
      <CopyToClipboard text={valueToCopy}>
        <Button onClick={handleClick} disableElevation size="small" variant="outlined">
          <FileCopyIcon>Copy to clipboard</FileCopyIcon>
        </Button>
      </CopyToClipboard>
    </Tooltip>
  );
};

CopyButton.propTypes = {
  valueToCopy: PropTypes.string,
};

CopyButton.defaultProps = {
  valueToCopy: '',
};

export default CopyButton;
