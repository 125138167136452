import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formLayout: {
    justifyContent: 'center',
    gap: theme.spacing(3),
  },

  textField: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  countryCode: {
    color: theme.palette.common.brandingBlack,
  },
  input: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.gray5}`,
    fontSize: theme.typography.pxToRem(16),
    padding: [10, 12],
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  error: {
    borderColor: theme.palette.common.red,
    '&:focus': {
      borderColor: theme.palette.common.red,
    },
  },
  success: {
    color: theme.palette.common.green3,
  },
  successAlert: {
    width: 285,
    position: 'absolute',
    top: '-26px',
    left: 0,
    right: 0,
    margin: [0, 'auto'],
  },

  inputLabel: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    color: theme.palette.common.brandingBlack,
    transform: 'translate(0, -7px) scale(1)',
  },

  buttons: {
    alignItems: 'stretch',
    gap: 16,
    marginTop: 24,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      marginTop: 32,
      flexDirection: 'row-reverse',
      flexWrap: 'nowrap',
    },
  },
  button: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
    padding: [theme.spacing(2), theme.spacing(4)],
  },
}));

export default useStyles;
