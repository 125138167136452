import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    minHeight: theme.typography.pxToRem(58),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    '&:hover': {
      boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
      background: theme.palette.common.white2,
    },
  },

  listItemCurrent: {
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      background: theme.palette.common.lightBlue3,
      color: theme.palette.common.brandingBlack,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: theme.lighten(theme.palette.common.lightBlue3, 0.05),
      },
    },
  },

  link: {
    textDecoration: 'none',
  },
}));

export default useStyles;
