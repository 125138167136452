import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import uniqueid from 'lodash.uniqueid';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import queryString from 'query-string';
import clsx from 'clsx';

import {
  IconButton,
  ClickAwayListener,
  Paper,
  MenuItem,
  MenuList,
  Popper,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';

import useStyles from './styles';

const LanguageMenu = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isMenuOpened, setMenuOpened] = useState(false);
  const anchorRef = React.useRef(null);
  const { pathname, search } = useLocation();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const searchParams = queryString.parse(search);
  const ruLink = `${pathname}?${queryString.stringify({ ...searchParams, lng: 'ru' })}`;
  const enLink = `${pathname}?${queryString.stringify({ ...searchParams, lng: 'en' })}`;

  const isLanguageEnglish = i18n.language === 'en';

  const handleToggle = () => {
    setMenuOpened(!isMenuOpened);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpened(false);
  };

  return (
    <>
      {matchesMobile && (
        <Link href={isLanguageEnglish ? ruLink : enLink} className={clsx(classes.link, classes.languageButton)}>
          <TranslateIcon className={classes.languageIcon} fontSize="small" />
          <Typography>{isLanguageEnglish ? t('header.ru') : t('header.en')}</Typography>
        </Link>
      )}

      {matchesDesktop && (
        <>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            onClick={handleToggle}
            ref={anchorRef}
            className={classes.menuItem}
          >
            <TranslateIcon />
          </IconButton>
          <Popper
            placement="bottom-end"
            open={isMenuOpened}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            className={classes.popper}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isMenuOpened} id="menu-list-grow">
                  <MenuItem key={uniqueid(t('header.ru'))}>
                    <Link className={classes.link} href={ruLink}>
                      <Typography className={classes.name}>{t('header.ru')}</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem key={uniqueid(t('header.en'))}>
                    <Link className={classes.link} href={enLink}>
                      <Typography className={classes.name}>{t('header.en')}</Typography>
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      )}
    </>
  );
};

LanguageMenu.propTypes = {};

LanguageMenu.defaultProps = {};
export default LanguageMenu;
