import React from 'react';
import { Provider } from 'react-redux';
import jss from 'jss';
import preset from 'jss-preset-default';
import 'react-markdown-editor-lite/lib/index.css';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider } from '@material-ui/styles';

import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/libre-baskerville';
import '@fontsource/libre-baskerville/400-italic.css';

import store from 'store';

import App from 'containers/App';
import GlobalStyles from 'layouts/GlobalStyles';
import theme from './theme';

import './i18n';

jss.setup(preset());

const DbpApp = () => {
  return (
    <>
      <CssBaseline />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <GlobalStyles />
            <App />
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default DbpApp;
