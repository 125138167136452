import storeModuleCreators from 'utils/store';
import LastActivitiesRepository from 'repositories/LastActivitiesRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['lastActivities'],
  repositoryAction: LastActivitiesRepository.load,
  responseKey: 'lastActivities',
});

export default slice.reducer;
