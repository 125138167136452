import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'ramda';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CourseStatsPresenter from 'presenters/CourseStatsPresenter';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { isBlank } from 'utils/helpers';
import { courseStatsHooks } from 'store/admin/courseStatsSlice';

const CourseStatsPanel = () => {
  const { t } = useTranslation();

  const {
    firstLoadCourseStats,
    courseStats: { items: courseStats, loadingStatus },
  } = courseStatsHooks.use();

  const { isIdle } = useFetchStatus(loadingStatus);

  useEffect(() => {
    firstLoadCourseStats();
  }, []);

  return (
    <>
      {isIdle && <CircularProgress color="primary" />}
      {!isIdle && !isBlank(courseStats) && (
        <Table aria-label="course stats table">
          <TableHead>
            <TableRow>
              <TableCell>{t('adminCourseStats.courseName')}</TableCell>
              <TableCell align="right">{t('adminCourseStats.totalStudents')}</TableCell>
              <TableCell align="right">{t('adminCourseStats.completedStudents')}</TableCell>
              <TableCell align="right">{t('adminCourseStats.monthActiveStudents')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              row => (
                <TableRow key={CourseStatsPresenter.id(row)}>
                  <TableCell component="th" scope="row">
                    {CourseStatsPresenter.name(row)}
                  </TableCell>
                  <TableCell align="right">{CourseStatsPresenter.totalStudentsCount(row)}</TableCell>
                  <TableCell align="right">{CourseStatsPresenter.completedStudentsCount(row)}</TableCell>
                  <TableCell align="right">{CourseStatsPresenter.monthStudentsCount(row)}</TableCell>
                </TableRow>
              ),
              courseStats,
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default CourseStatsPanel;
