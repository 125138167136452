/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminGroupsRepository from 'repositories/AdminGroupsRepository';

import { createRestSlice } from 'utils/RestSlice';

const GroupRestSlice = createRestSlice({
  resource: 'group',
  repository: AdminGroupsRepository,
  slices: ['loadGroup', 'createGroup', 'updateGroup', 'deleteGroup'],
});

const initialState = {
  ...GroupRestSlice.initialState,
};

const GroupSlice = createSlice({
  name: 'adminGroup',
  initialState,
  reducers: {
    ...GroupRestSlice.reducers,
  },
});

export default GroupSlice.reducer;

export const groupHooks = GroupRestSlice.hooks(GroupSlice, 'adminGroup');
