import PropTypes from 'prop-types';
import { join, map, pipe, head, toUpper, isNil } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

export const USER_ROLES = {
  teacher: 'teacher',
  student: 'student',
  admin: 'admin',
};

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    smallAvatarUrl: PropTypes.string,
    bigAvatarUrl: PropTypes.string,
  },
  {
    fullName(user) {
      return `${this.firstName(user)} ${this.lastName(user)}`;
    },
    initials(user) {
      const fullName = [user.firstName, user.lastName];
      return join('', map(pipe(head, toUpper), fullName));
    },
    hasAvatar(user) {
      return !isNil(this.smallAvatarUrl(user));
    },
  },
);
