import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardContent: {
    width: '100%',
    height: '100%',
    padding: [24, 24, 32],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      minHeight: '340px',
      padding: 32,
    },
  },
  cardMain: {
    marginBottom: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginBottom: 32,
    },
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gap: 40,
    },
  },

  courseName: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    color: theme.palette.common.brandingBlack,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
    },
  },

  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginBottom: 8,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginBottom: 16,
    },
  },

  icons: {
    display: 'grid',
    gap: 8,
    gridAutoFlow: 'column',
    alignSelf: 'center',

    '& img': {
      width: 20,
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginLeft: 24,
      '& img': {
        width: 24,
      },
    },
  },

  courseFinished: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.green,
    marginBottom: 10,
  },

  markdown: {
    color: theme.palette.common.brandingGray2,
    '& p > a': {
      fontSize: theme.typography.pxToRem(14),
    },

    '& > :first-child': {
      marginTop: 0,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.brandingGray2,
    marginBottom: 0,
    letterSpacing: '-0.15px',
  },

  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 8,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      rowGap: 8,
    },
  },

  infoBox: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: [8, 0],
    borderRadius: '10px',
    minWidth: '48%',
    flexGrow: 1,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      minWidth: '30%',
    },
  },
  practices: {
    backgroundColor: theme.palette.common.lightGreen,
    color: theme.palette.common.green2,
  },
  theories: {
    backgroundColor: theme.palette.common.lightBlue2,
    color: theme.palette.primary.main,
  },
  hours: {
    backgroundColor: theme.palette.common.lightPurple,
    color: theme.palette.common.purple,
  },

  durationHours: {
    fontSize: theme.typography.pxToRem(27),
    fontWeight: 400,
    marginBottom: 0,
  },

  durationLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    color: 'inherit',
  },

  button: {
    textAlign: 'center',
    display: 'flex',
  },

  courseButton: {
    padding: [14, 32],
    width: '100%',

    '& .MuiButton-label': {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      fontWeight: 600,
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      width: 'auto',
    },
  },
}));

export default useStyles;
