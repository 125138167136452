import React from 'react';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

const CourseProgressCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <List className={classes.list}>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={200} classes={{ root: classes.text }} />
            <Skeleton width={240} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={250} classes={{ root: classes.text }} />
            <Skeleton width={280} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={150} classes={{ root: classes.text }} />
            <Skeleton width={200} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={250} classes={{ root: classes.text }} />
            <Skeleton width={280} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={240} classes={{ root: classes.text }} />
            <Skeleton width={80} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
        <ListItem className={classes.lessonItem}>
          <div>
            <Skeleton width={220} classes={{ root: classes.text }} />
            <Skeleton width={250} height={15} classes={{ root: classes.badgeGroup }} />
          </div>
        </ListItem>
      </List>
    </Grid>
  );
};

export default CourseProgressCardSkeleton;
