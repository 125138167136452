/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminPagesRepository from 'repositories/AdminPagesRepository';

import { createRestSlice } from 'utils/RestSlice';

const PageRestSlice = createRestSlice({
  resource: 'page',
  repository: AdminPagesRepository,
  slices: ['loadPage', 'createPage', 'updatePage', 'deletePage'],
});

const initialState = {
  ...PageRestSlice.initialState,
};

const PageSlice = createSlice({
  name: 'adminPage',
  initialState,
  reducers: {
    ...PageRestSlice.reducers,
  },
});

export default PageSlice.reducer;

export const pageHooks = PageRestSlice.hooks(PageSlice, 'adminPage');
