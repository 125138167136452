import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  teachersSelect: {
    '& .MuiTextField-root': {
      minWidth: 300,
    },
  },
}));

export default useStyles;
