import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { equals, not } from 'ramda';

import { Group } from '@material-ui/icons';
import { Grid, Typography, IconButton, FormControl } from '@material-ui/core';

import { redirectWithReloadPage } from 'utils/locationHelper';
import appRoutes from 'routes/appRoutes';

import UserPresenter, { USER_ROLES } from 'presenters/UserPresenter';
import useUser from 'hooks/admin/useUser';
import useUsers from 'hooks/admin/useUsers';

import GroupSelect from 'sharedContainers/Admin/GroupSelect';
import RoleSelect from 'components/RoleSelect';
import HiringStateSelect from 'components/HiringStateSelect';

import useCurrentUser from 'hooks/useCurrentUser';

const UserSettings = props => {
  const { user, userId } = props;
  const { loadUser, updateUser, isLoading } = useUser();
  const { impersonate } = useUsers();
  const { currentUser } = useCurrentUser();

  const handleChange = field => value => {
    updateUser(userId, { [field]: value }).then(() => loadUser(userId));
  };

  const handleImpersonate = () => impersonate(userId).then(() => redirectWithReloadPage(appRoutes.coursesPath()));

  return (
    <>
      <Typography variant="h5" style={{ wordBreak: 'break-word' }}>
        {UserPresenter.fullName(user)}
        {UserPresenter.hasAdminAccess(currentUser) && (
          <IconButton onClick={handleImpersonate} aria-label="Impersonate">
            <Group />
          </IconButton>
        )}
      </Typography>

      <Typography variant="body1" gutterBottom>
        {UserPresenter.email(user)}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {UserPresenter.phone(user)}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {UserPresenter.contact(user)}
      </Typography>
      <Link href={UserPresenter.githubAccountLink(user)}>
        <Typography variant="body1" gutterBottom>
          {UserPresenter.githubAccountLink(user)}
        </Typography>
      </Link>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <HiringStateSelect
              disabled={isLoading}
              value={UserPresenter.hiringState(user)}
              onChange={handleChange('hiringState')}
            />
          </FormControl>
        </Grid>

        {UserPresenter.hasAdminAccess(currentUser) && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <RoleSelect disabled={isLoading} value={UserPresenter.role(user)} onChange={handleChange('role')} />
            </FormControl>
          </Grid>
        )}
        {UserPresenter.hasHrAccess(currentUser) && not(equals(UserPresenter.role(user), USER_ROLES.admin)) && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <RoleSelect
                disabled={isLoading}
                value={UserPresenter.role(user)}
                onChange={handleChange('role')}
                except={[USER_ROLES.admin]}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <GroupSelect
              disabled={isLoading}
              value={UserPresenter.groupIds(user)}
              onChange={handleChange('groupIds')}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

UserSettings.propTypes = {
  user: UserPresenter.shape().isRequired,
  userId: PropTypes.number.isRequired,
};

export default UserSettings;
