import { useActions as useShowActions, selectors as showSelectors } from 'store/courseSlice';

export default () => {
  const { load: loadCourse, reset: resetCourse } = useShowActions();
  const { getData, getIsLoading } = showSelectors;
  const course = getData();
  const isLoading = getIsLoading();

  return {
    course,
    loadCourse,
    isLoading,
    resetCourse,
  };
};
