import storeModuleCreators from 'utils/store';
import AdminLessonsRepository from 'repositories/AdminLessonsRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['adminLesson'],
  repositoryAction: AdminLessonsRepository.show,
  responseKey: 'lesson',
});

export default slice.reducer;
