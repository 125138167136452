import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    padding: [theme.spacing(4), theme.spacing(3), 0],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      padding: [theme.spacing(4), theme.spacing(3), theme.spacing(4)],
    },
  },

  chartSkeletonBox: {
    height: 285,
    color: theme.palette.common.blue5,
  },

  highchartsTooltip: {
    display: 'none',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      display: 'block',
    },
  },
  outsideTooltip: {
    minHeight: 50,
  },
  tooltipData: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.brandingBlack,
    '& > b': {
      fontWeight: 600,
    },
  },
}));

export default useStyles;
