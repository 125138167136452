import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';

import UserPresenter from 'presenters/UserPresenter';
import useCurrentUser from 'hooks/useCurrentUser';
import DefaultAvatar from 'components/DefaultAvatar';

import Rating from './components/Rating';

import useStyles from './styles';

const StudentProfileInfo = props => {
  const { courseId } = props;
  const { currentUser } = useCurrentUser();
  const classes = useStyles();

  const fullName = UserPresenter.fullName(currentUser);
  const email = UserPresenter.email(currentUser);

  return (
    <Grid container direction="column" classes={{ root: classes.grid }}>
      <Grid item>
        <DefaultAvatar user={currentUser} className={classes.avatar} />
      </Grid>
      <Grid item>
        <Typography className={classes.fullName} gutterBottom>
          {fullName}
        </Typography>
        <Typography className={classes.email} gutterBottom>
          <EmailIcon fontSize="inherit" />
          {email}
        </Typography>
      </Grid>
      <Grid item>
        <Rating userId={UserPresenter.id(currentUser)} courseId={courseId} />
      </Grid>
    </Grid>
  );
};

StudentProfileInfo.propTypes = {
  courseId: PropTypes.number.isRequired,
};

export default StudentProfileInfo;
