import { useActions as useLoadActions, selectors as loadSelectors } from 'store/user/coursesSlice';

const CoursesContainer = () => {
  const { load: loadCourses, reset: resetCourses, clearLoadingError } = useLoadActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const courses = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    courses,
    loadCourses,
    isLoading,
    loadingError,
    clearLoadingError,
    resetCourses,
  };
};

export default CoursesContainer;
