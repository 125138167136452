import React from 'react';
import DynamicBreadcrumbs from 'react-router-dynamic-breadcrumbs';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import getRoutes from './routes';

import useStyles from './styles';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const routes = getRoutes(t);

  return (
    <DynamicBreadcrumbs
      mappedRoutes={routes}
      WrapperComponent={({ children }) => <ol className={classes.root}>{children}</ol>}
      LinkComponent={({ children }) => (
        <li className={classes.link}>
          {children}
          <ChevronRightIcon classes={{ root: classes.separator }} />
        </li>
      )}
      ActiveLinkComponent={({ children }) => <Typography>{children}</Typography>}
    />
  );
};

export default Breadcrumbs;
