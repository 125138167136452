/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import PagesRepository from 'repositories/PagesRepository';

import { createRestSlice } from 'utils/RestSlice';

const PagesRestSlice = createRestSlice({
  resource: 'pages',
  repository: PagesRepository,
  slices: ['loadPages'],
});

const initialState = {
  ...PagesRestSlice.initialState,
};

const PagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    ...PagesRestSlice.reducers,
  },
});

export default PagesSlice.reducer;

export const pagesHooks = PagesRestSlice.hooks(PagesSlice, 'pages');
