import dayjs from 'dayjs';
import localeRu from 'dayjs/locale/ru';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export default {
  translation: {
    icons: 'Иконки',
    cancel: 'Отмена',
    ok: 'Ok',
    common: {
      ok: 'Ок',
      yes: 'Да',
      no: 'Нет',
      seeMore: 'Подробнее',
      rollUp: 'Свернуть',
      apply: 'Применить',
      selected: 'Выбрано: ',
      closeConfirm: 'У вас есть не сохраненные изменения, вы уверены что хотите уйти?',
      promptMessage: 'У вас есть не сохраненные изменения, вы уверены что хотите уйти?',
      contactPromptMessage: 'Мы бы хотели знать ваш контакт для связи, вы уверены что хотите уйти?',
      theories: 'Теории',
      practices: 'Практики',
      completed: 'Завершенно',
      halfCompleted: 'Полу Завершено',
      uncompleted: 'Не Завершено',
      back: 'Назад',
    },
    header: {
      course: 'Курс',
      vacancies: 'Вакансии',
      progress: 'Прогресс',
      events: 'События',
      lessons: 'Уроки',
      adminPanel: 'Админка',
      logout: 'Выход',
      courses: 'Все курсы',
      profile: 'Профиль',
      ru: 'Русский',
      en: 'English',
      signIn: 'Войти на сайт',
    },
    breadcrumbs: {
      adminPanel: 'Админка',
      courses: 'Курсы',
      lessons: 'Уроки',
      theories: 'Теории',
      practices: 'Практики',
    },
    user: {
      hiringStates: {
        none: 'Отсутствует',
        joined_course: 'Проходит курс(ы)',
        can_be_hired: 'Можно нанимать',
        interview_scheduled: 'Назначено собеседование',
        hired: 'Нанят',
        fired: 'Уволен',
        refused_by_us: 'Отклонили мы',
        refused_by_candidate: 'Отказался сам(а)',
      },
      roles: {
        student: 'Студент',
        teacher: 'Преподаватель',
        admin: 'Админ',
        mentor: 'Ментор',
        hr: 'HR',
      },
    },
    chart: {
      academicAchievement: 'Успеваемость',
      date: 'Дата',
      loading: 'Загрузка...',
    },
    studentAge: {
      key_0: '{{count}} год',
      key_1: '{{count}} года',
      key_2: '{{count}} лет',
    },
    mainPage: {
      titleWelcome: 'Добро пожаловать ',
      titleOur: 'в наше ',
      titleSpace: ' Учебное пространство!',
      description:
        'На курсах программирования от Dualboot Partners ты освоишь самые современные и передовые технологии разработки веб и мобильных приложений.',
      about: 'Подробнее о нас',
    },
    signUp: {
      signUp: 'Регистрация',
      emailAddress: 'Email',
      password: 'Пароль',
      alreadyHaveAccount: 'Уже есть аккаунт? Войти',
      firstName: 'Имя',
      lastName: 'Фамилия',
      passwordConfirmation: 'Повторите пароль',
      githubAccount: 'Аккаунт в github, например ruby-ninja',
      registration: 'Зарегистрироваться',
    },
    signIn: {
      authorization: 'Авторизация',
      rememberMe: 'Запомнить меня',
      signIn: 'Войти',
      forgotPassword: 'Забыли пароль?',
      noAccount: 'Нет аккаунта? Зарегистрироваться',
      emailAddress: 'Почта',
      password: 'Пароль',
      google: 'Войти через Google',
      or: 'или',
    },
    forgotPassword: {
      forgotPassword: 'Забыли пароль?',
      sendRequestResetLink: 'Получить ссылку',
      enterYourEmail: 'Введите email на который мы отправим вам ссылку на восстановление',
      emailAddress: 'Почта',
      noAccount: 'Нет аккаунта? Зарегистрироваться',
      emailSent: 'Письмо отпралено на ваш адрес электронной почты',
      sentEmail: 'Отправить',
    },
    resetPassword: {
      enterNewPassword: 'Введите новый пароль',
      savePassword: 'Сохранить пароль',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите новый пароль',
      returnToSignIn: 'Вернуться к авторизации',
      passwordRules:
        'Пароль должен быть не короче 8 символов. Содержать буквы верхнего и нижнего регистра, числа и символы (кроме * ^ % #)',
      passwordUpdated: 'Ваш пароль был изменен',
    },
    studentCard: {
      theory: 'Теория',
      practice: 'Практика',
      rating: {
        youScore: 'Вы набрали ',
        points: ' баллов из ',
      },
    },
    courses: {
      filters: {
        name: 'Поиск по названию',
      },
    },
    coursesCard: {
      practices: 'Практики',
      theories: 'Теории',
      hours: 'Часов',
      courseAction: 'Начать курс',
      joinedCourseAction: 'Продолжить обучение',
      courseFinished: 'Курс закончен',
    },
    validations: {
      firstName: {
        minLength: 'Имя должно содержать больше двух символов',
        invalid: 'Имя должно состоять из букв русского или латинского алфавита без символов и чисел',
        required: 'Поле не заполнено',
      },
      lastName: {
        minLength: 'Фамилия должна содержать больше двух символов',
        invalid: 'Фамилия должна состоять из букв русского или латинского алфавита без символов и чисел',
        required: 'Поле не заполнено',
      },
      password: {
        invalid:
          'Пароль должен состоять из букв латинского алфавита, не короче 8 символов. Содержать числа, буквы верхнего и нижнего регистра',
        required: 'Пароль не должен быть пустым',
      },
      passwordConfirmation: {
        passwordMatch: 'Пароли должны совпадать',
        required: 'Пароль не должен быть пустым',
      },
      email: {
        empty: 'Введите адрес электронной почты',
        minLength: 'Email должен быть не короче 2 символов',
        maxLength: 'Email не должен быть длиннее 255 символов',
        invalid: 'Вы ввели неверный адрес электронной почты',
        required: 'Поле email не должно быть пустым',
      },
      phone: {
        invalid: 'Номер телефона должен состоять от 7 до 15 чисел',
      },
      githubAccount: {
        required: 'Поле не заполнено',
        invalid: 'Неправильный формат',
      },
      contact: {
        required: 'Поле контактов не заполнено',
      },
      group: {
        name: {
          required: 'Поле не заполнено',
        },
      },
      vacancy: {
        title: {
          required: 'Поле не заполнено',
        },
        shortDescription: {
          required: 'Поле не заполнено',
        },
        description: {
          required: 'Поле не заполнено',
        },
        state: {
          invalid: 'Выберите значение из списка',
        },
      },
      page: {
        name: {
          required: 'Поле не заполнено',
        },
        content: {
          required: 'Поле не заполнено',
        },
        state: {
          invalid: 'Выберите значение из списка',
        },
      },
      course: {
        name: {
          required: 'Поле не заполнено',
        },
        description: {
          required: 'Поле не заполнено',
          maxLength: 'Описание должно быть не длиннее 500 символов',
        },
        state: {
          invalid: 'Выберите значение из списка',
        },
        accessDomains: {
          invalids: 'не валидные домены',
          invalid: 'не валидный домен',
        },
      },
      lesson: {
        name: {
          required: 'Поле не заполнено',
        },
        requiredTime: {
          type: 'Введите целое число',
          positive: 'Число должно быть положительным',
          integer: 'Число должно быть целым',
          required: 'Поле не заполнено',
        },
        state: {
          invalid: 'Выберите значение из списка',
        },
      },
      exercise: {
        name: {
          required: 'Поле не заполнено',
        },
        link: {
          invalid: 'Ссылка должна быть действительным URL',
          required: 'Поле не заполнено',
        },
        content: {
          required: 'Поле не заполнено',
        },
        state: {
          invalid: 'Выберите значение из списка',
        },
      },
      note: {
        message: {
          required: 'Поле не заполнено',
        },
      },
    },
    commonErrors: {
      formServerError: 'Произошла непредвиденная ошибка',
    },
    practiceList: {
      feedback: 'Причина возврата',
    },
    studentLesson: {
      theory: 'Теория',
      practice: 'Практика',
      read: 'Читать',
      links:
        'ссылки, помеченные этим лейблом, читать целиком не нужно, это документация, ее нужно просмотреть, читать по необходимости и знать, где она находится. Галочка напротив означает "Я изучил и добавил в закладки"',
      practicePlaceholder: 'Ссылка на пул-реквест',
      noTheory: 'В данном уроке отсутствует Теоретический материал',
      noPractice: 'В данном уроке отсутствует Практический материал',
      introduction: 'Введение',
    },
    adminMenu: {
      users: 'Пользователи',
      groups: 'Группы',
      courses: 'Курсы',
      stats: 'Статистика',
      vacancies: 'Вакансии',
      pages: 'Страницы',
    },
    adminUsersMenu: {
      all: 'Все пользователи',
      fullTimeStudents: 'Ходят на лекции',
      distantStudents: 'Заочное обучение',
      graduates: 'Закончили обучение',
      teachers: 'Преподаватели',
    },
    adminUsersFilters: {
      role: 'Роль',
      group: 'Группа',
      course: 'Курс',
      findByFullNameOrEmail: 'Имя или email',
      showProgress: 'Показывать прогресс',
      deleteUserModalTitle: 'Удалить пользователя',
      deleteUserModalContent: 'Вы уверены, что хотите удалить пользователя?',
      resetButton: 'Сбросить',
    },
    adminUsersTable: {
      hiringState: 'Статус Найма',
      courseRating: 'Курс рейтинг',
      coursePercentage: 'Курс прогресс',
      name: 'Имя',
      phone: 'Телефон',
      email: 'Почта',
      github: 'Github',
      impersonate: 'Залогиниться',
      role: 'Роль',
      registrationDate: 'Создан',
      theory: 'Теория',
      practice: 'Практика',
      progress: 'Прогресс',
    },
    adminUserLastActivitiesPage: {
      allEvents: 'Все события',
      answerFor: 'Ответ на',
      previousFeedback: 'Предыдущие фидбеки',
    },
    adminUserPage: {
      hiringState: 'Статус найма',
      allCourses: 'К списку курсов',
      role: 'Роль',
      student: 'Студент',
      codeReview: 'Необходимо ревью',
      fullTimeEducation: 'Очное обучение',
      finishedEducation: 'Закончил обучение',
      teacher: 'Преподаватель',
      admin: 'Администратор',
      ganttChart: 'Диаграмма Ганта',
      noGanttChart:
        'Пользователь ещё не начал проходить курс. Диаграмма появится после того, как он выполнит хотя бы одно задание',
      allLessons: 'Все уроки',
      rating: 'Рейтинг',
      chart: {
        loading: 'Загрузка...',
        expectedTime: 'Ожидаемое время выполнения',
        theoryTime: 'Время выполнения теории',
        practiceTime: 'Время выполнения практики',
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
        months: dayjs().locale(localeRu).localeData().months(),
        shortMonths: dayjs().locale(localeRu).localeData().monthsShort(),
        weekdays: dayjs().locale(localeRu).localeData().weekdays(),
        shortWeekdays: dayjs().locale(localeRu).localeData().weekdaysShort(),
        week: 'неделя',
        shortWeek: 'н',
        lessons: 'Уроки',
        days: 'Дни',
        rangeSelectorFrom: 'От',
        rangeSelectorTo: 'До',
        rangeSelectorZoom: 'Зум',
        zoomOneMonth: '1мес',
        zoomTwoMonths: '2мес',
        zoomThreeMonths: '3мес',
        zoomAll: 'Всё',
      },
    },
    adminCreateVacancyButton: {
      createVacancy: 'Создать вакансию',
    },
    adminCreatePageButton: {
      createPage: 'Создать страницу',
    },
    adminCreateCourseButton: {
      createCourse: 'Создать новый курс',
      importCourse: 'Импортировать курс',
    },
    adminCreateGroupButton: {
      createGroup: 'Создать группу',
    },
    adminEditGroupModal: {
      name: 'Название',
      startAt: 'Дата старта',
      endAt: 'Дата завершения',
      submitButton: 'Сохранить',
    },
    adminGroupManage: {
      pageTitle: 'Группа',
      startAt: 'Дата старта',
      endAt: 'Дата завершения',
      tabs: {
        internal: 'Студенты',
        external: 'Добавление студентов',
      },
      actions: {
        save: 'Добавить в группу',
        pickAll: 'Выбрать всех',
        removeModal: {
          title: 'Удалить пользователя из группы',
          deleteUserModalContent: 'Вы уверены что хотите удалить пользователя из группы?',
        },
      },
      filters: {
        findByFullNameOrEmail: 'Имя или email',
        resetButton: 'Сбросить',
        emails: 'Шаблон балк поиска: email1@domain.com;another@test.com',
        selected: 'Выбраны: ',
      },
    },
    adminGroupsTable: {
      groups: 'Группы',
      name: 'Название',
      startAt: 'Дата старта',
      endAt: 'Дата завершения',
      deleteGroupModalTitle: 'Удалить группу',
      deleteGroupModalContent: 'Вы уверены, что хотите удалить группу?',
    },
    adminEditCourseModal: {
      name: 'Название',
      description: 'Описание',
      introduction: 'Введение',
      state: 'Состояние',
      submitButton: 'Сохранить',
      accessDomains: 'Домены для доступа',
      teachers: 'Учителя кто будет получать нотификации о ожидающих проверках',
      states: {
        draft: 'Черновик',
        published: 'Опубликованный',
        archived: 'Архивный',
        internalPublished: 'Внутренний доступ',
        sunset: 'Закат',
        internalPublishedMeaning:
          'Внутренний доступ означает, что курс доступен только для студентов с доменом электронной почты из списка доменов доступа (ex. dualbootpartners.com)',
        sunsetMeaning:
          'Закат значит, что для новых студентов курс не доступен, но те кто уже начал могут его закончить',
      },
    },
    adminCoursesTable: {
      courses: 'Курсы',
      name: 'Название',
      description: 'Описание',
      state: 'Состояние',
      createdAt: 'Дата создания',
      progress: 'Прогресс',
      deleteCourseModal: {
        title: 'Удалить курс',
        content: 'Вы уверены, что хотите удалить курс?',
        failedContent: 'Извините, мы не смогли удалить данный курс',
      },
      filters: {
        name: 'Поиск по названию',
        state: 'Состояние',
        reset: 'Сбросить',
        states: {
          draft: 'Черновик',
          published: 'Опубликованный',
          archived: 'Архивный',
          internalPublished: 'Внутренний доступ',
          sunset: 'Закат',
        },
      },
    },
    adminEditVacancyModal: {
      title: 'Вакансия: ',
      label: 'Заголовок',
      shortDescription: 'Краткое описание',
      description: 'Описание',
      state: 'Состояние',
      submitButton: 'Сохранить',
    },
    adminVacanciesTable: {
      vacancies: 'Вакансии',
      title: 'Заголовок',
      description: 'Описание',
      state: 'Состояние',
      createdAt: 'Дата создания',
      deleteVacancyModalTitle: 'Удалить вакансию',
      deleteVacancyModalContent: 'Вы уверены, что хотите удалить вакансию?',
    },
    adminPagesTable: {
      pages: 'Страницы',
      name: 'Имя',
      slug: 'Slug',
      state: 'Состояние',
      createdAt: 'Дата создания',
      deleteModalTitle: 'Удалить страницу',
      deleteModalContent: 'Вы уверены, что хотите удалить страницу?',
    },
    adminEditPageModal: {
      page: 'Страница: ',
      name: 'Имя',
      title: 'Заголовок',
      content: 'Контент',
      state: 'Состояние',
      submitButton: 'Сохранить',
    },
    adminUserCourse: {
      state: {
        joined: 'Проходит',
        completed: 'Завершил',
        revoked: 'Доступ отозван',
      },
      join: 'Добавить курс',
      revoke: 'Запретить доступ',
    },
    adminLessonsEditPage: {
      toCoursesList: 'К списку курсов',
      courseLessons: 'Уроки курса',
      createLesson: 'Создать новый урок',
      deleteLessonModalTitle: 'Удалить урок',
      deleteLessonModalContent: 'Вы уверены, что хотите удалить урок?',
    },
    adminLessonsTable: {
      name: 'Название',
      state: 'Состояние',
      requiredTime: 'Дни',
      createdAt: 'Дата создания',
      theories: 'Теории',
      practices: 'Практики',
    },
    adminEditLessonModal: {
      name: 'Название',
      state: 'Состояние',
      requiredTime: 'Время выполнения (дни)',
      submitButton: 'Сохранить',
      interviewTrigger: 'Возможно интервью после прохождения',
      states: {
        draft: 'Черновик',
        published: 'Опубликованный',
        archived: 'Архивный',
        internalPublished: 'Внутренний доступ',
      },
    },
    adminExercisesEditPage: {
      toLessonsList: 'К списку уроков',
      lessonPractices: 'Практики урока',
      createPractice: 'Создать новую практику',
      lessonTheories: 'Теории урока',
      createTheory: 'Создать новую теорию',
      deleteTheoryModalTitle: 'Удалить теорию',
      deleteTheoryModalContent: 'Вы уверены, что хотите удалить теорию?',
      deletePracticeModalTitle: 'Удалить практику',
      deletePracticeModalContent: 'Вы уверены, что хотите удалить практику?',
    },
    adminLessonPracticesTable: {
      name: 'Название',
      content: 'Содержимое',
      state: 'Состояние',
      createdAt: 'Дата создания',
    },
    adminEditLessonPracticeModal: {
      title: 'Практика: ',
      name: 'Название',
      content: 'Содержимое',
      teacherContent: 'Методические материалы',
      state: 'Состояние',
      submitButton: 'Сохранить',
      result: 'Небольшой плейсхолдер для студента что отправить. Например, ссылка на пулл реквест',
      isResultRequired: 'Практика с результатом?',
      isResultRequiredDescription:
        'Ссылки, помеченные этим чекбоксом означают, что в этой практике ожидается какой то результат от студента. Это нужно для корректного отображения практики',
      isOnlyOnce:
        'Одноразовая практика (если студент отметил практику как сделанную, он не сможет снять галочку обратно)',
      states: {
        draft: 'Черновик',
        published: 'Опубликованный',
        archived: 'Архивный',
        internalPublished: 'Внутренний доступ',
      },
    },
    adminLessonTheoriesTable: {
      name: 'Название',
      link: 'Ссылка',
      state: 'Состояние',
      createdAt: 'Дата создания',
      yes: 'Да',
      not: 'Нет',
    },
    adminEditLessonTheoryModal: {
      title: 'Теория: ',
      name: 'Название',
      link: 'Ссылка',
      state: 'Состояние',
      submitButton: 'Сохранить',
      isDoc: 'Это документация?',
      isDocDescription:
        'Ссылки, помеченные этим чекбоксом, читать целиком не нужно, это документация, студенту ее нужно просмотреть, читать по необходимости и знать, где она находится',
      isLink: 'Ссылка на внешний ресурс?',
      isLinkDescription:
        'Ссылки, помеченные этим чекбоксом означают, что это просто ссылка (статья, документация и т.п.) без текста на платформе. Это нужно для корректного отображения теории',
      isOnlyOnce:
        'Одноразовая теория (если студент отметил теорию как прочитанную, он не сможет снять галочку обратно)',
      states: {
        draft: 'Черновик',
        published: 'Опубликованный',
        archived: 'Архивный',
        internalPublished: 'Внутренний доступ',
      },
    },
    adminStats: {
      teachers: 'Учителя',
      courses: 'Курсы',
    },
    adminTeacherStats: {
      createdAtGteq: 'От',
      createdAtLteq: 'До',
      teachers: 'Учителя/Менторы/Админы',
      apply: 'Применить',
      reset: 'Сбросить',
    },
    adminCourseStats: {
      courseName: 'Имя курса',
      totalStudents: 'Всего студентов',
      completedStudents: 'Студентов с 80% завершенного',
      monthActiveStudents: 'Студенты активные последний месяц',
    },
    activityCard: {
      teacherContent: 'Методические материалы',
      content: 'Задание',
      reject: 'Вернуть',
      accept: 'Принять',
      delay: 'Отложить',
      userProfile: 'Профиль студента',
      rejectWithMessage: 'Вернуть и ответить',
    },
    studentOptions: {
      teacher: 'Преподаватель',
      progressVisibility: 'Отображать прогресс для всех',
    },
    studentsList: {
      title: 'Студенты',
    },
    lastActivity: {
      totalCount: 'Осталось: ',
      sort: 'Сортировка: ',
      noEvents: 'Нет новых событий',
      feedbackConfirmationTitle: 'Вернуть и ответить',
      feedbackMessage: 'Напишите ваш комментарий здесь',
      labelFieldSelectCourse: 'Выберите курс',
      labelFieldSelectGroup: 'Выберите группу',
      labelFieldFindByNameOrEmail: 'Имя или email',
    },
    profile: {
      phone: 'Телефон',
      email: 'Адрес электронной почты',
      password: 'Пароль',
      firstName: 'Имя',
      lastName: 'Фамилия',
      githubAccount: 'Github-аккаунт',
      contact: 'Контакт для связи',
      change: 'Изменить',
      profile: 'Профиль',
      editProfile: 'Редактировать профиль',
      save: 'Сохранить',
      emailMessage:
        'У вас есть связанная учетная запись. Пожалуйста, отключите учетную запись, если вы хотите изменить свой адрес электронной почты',
      passwordMessage: 'Вы отвязали Google аккаунт. Пожалуйста смените пароль',
      githubMessage: 'Пожалуйста заполните Github-аккаунт',
      unlinkedGoogle: 'Отвязать Google',
      profileUpdatedMessage: 'Ваши данные обновлены',
      contactMessage:
        'Пожалуйста, заполните свой контакт (linkedin, telegram и т.д.). Вы можете написать несколько. Это просто текстовое поле',
    },
    error: {
      error404: 'Ошибка 404',
      notFound: 'Страница не найдена',
      error403: 'Ошибка 403',
      forbidden: 'Запрещено',
      notAccess: 'У вас нет разрешения на доступ к этому серверу',
      textHelper: 'Воспользуйтесь панелью навигации',
    },
    vacancy: {
      backToList: 'Вакансии',
      subtitle: 'Всем привет! Мы в Dualboot Partners активно ищем талантливых инженеров',
      summary: {
        about: {
          title: 'Что мы делаем?',
          content:
            'Dualboot — компания по развитию бизнеса и разработке программного обеспечения;\nмы создаем отличное программное обеспечение, но наш успех зависит от того, помогает ли оно бизнесу развиваться.',
        },
        partners: {
          title: 'С кем работаем?',
          content:
            'Среди наших клиентов есть как основатели технологических и не технологических компаний, так и компании из списка Fortune 500, поэтому мы можем начать с малого или быстро масштабироваться в зависимости от ваших потребностей.',
        },
        tech: {
          title: 'Технологическое партнёрство',
          content:
            'Стабильный рост клиента играет ключевую роль в нашем успешном существовании как технологической компании. Мы не формируем бестолковые команды из множества людей, просто потому что есть бюджет, мы решаем задачи бизнеса максимально эффективно, так, как будто это наш собственный продукт.\n\nКогда мы помогаем развиваться клиенту эффективно, растет его прибыль, он может инвестировать больше в разработку, так и растёт и наша доля участия в этом процессе.',
        },
      },
      cards: {
        feedback: {
          title: 'Откликнуться на вакансию',
          content:
            'Пишите нам, даже если пока не хватает каких-то навыков. Нам интересны профи с разным бэкграундом, и мы ценим коллег за то, что они умеют классно делать.',
          writeToEmail: 'Написать на Email',
        },
        money: {
          title: 'Знаешь кандидата на эту роль?',
          content: 'Поделись с ним страничкой.\n Мы будем благодарны!',
        },
      },
    },
    vacancies: {
      title: 'Вакансии',
      subtitle: 'Мы регулярно ищем к себе в команду крутых специалистов',
      cards: {
        feedback: {
          title: 'Мало навыков? Не проблема!',
          content:
            'Пишите нам, даже если пока не хватает каких-то навыков. Нам интересны профи с разным бэкграундом, и мы ценим коллег за то, что они умеют классно делать.',
          writeToEmail: 'Написать на Email',
        },
        money: {
          title: 'Знаешь подходящего кандидата?',
          content: 'Есть на примете подходящие специалисты для нашей команды? Свяжись с нами, мы будем благодарны!',
        },
        noVacancies: {
          title: 'Привет! 👋',
          text: 'Прямо сейчас у нас нет открытых вакансий, но не стесняйтесь и приходите снова, и мы обязательно предложим вам что-нибудь новое.',
        },
      },
    },
    notes: {
      title: 'Заметки',
      create: 'Добавить заметку',
      empty: 'Нет заметок',
      modal: {
        create: {
          submit: 'Сохранить заметку',
        },
        destroy: {
          title: 'Удаление заметки',
          content: 'Вы уверены что хотите удалить заметку?',
        },
      },
    },
  },
};
