import * as yup from 'yup';

import exerciseStates from 'utils/exerciseStates';
import { AVAILABLE_EXERCISE_STATES_REGEX } from 'utils/regularExpressions';
import ExercisePresenter from 'presenters/ExercisePresenter';

const validationFields = {
  name: yup.string().required('validations.exercise.name.required'),
  content: yup.string().required('validations.exercise.content.required'),
  state: yup.string().matches(AVAILABLE_EXERCISE_STATES_REGEX, { message: 'validations.exercise.state.invalid' }),
  result: yup.string(),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (practice, nextOrder) => ({
  name: ExercisePresenter.name(practice) || '',
  content: ExercisePresenter.content(practice) || '',
  teacherContent: ExercisePresenter.teacherContent(practice) || '',
  order: ExercisePresenter.order(practice) || nextOrder,
  isOnlyOnce: ExercisePresenter.isOnlyOnce(practice) || false,
  state: practice ? ExercisePresenter.state(practice) : exerciseStates.draft,
  availableStates: [
    exerciseStates.published,
    exerciseStates.archived,
    exerciseStates.draft,
    exerciseStates.internalPublished,
  ],
  result: ExercisePresenter.result(practice) || '',
  isResultRequired: ExercisePresenter.isResultRequired(practice) || false,
});
