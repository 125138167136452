import { makeStyles } from '@material-ui/core/styles';
import background from 'assets/background.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
  },

  summary: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 32,
    padding: [32, 0],
    margin: [0, 16, 0],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      margin: [20, 50, 0],
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      gap: 60,
      margin: [0, 130, 0],
      padding: `5.625vw 0 ${theme.sizes.headerHeight}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.tv)]: {
      justifyContent: 'space-around',
    },
  },
  annotation: {
    paddingTop: 0,

    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      paddingTop: 60,
      width: 630,
    },
  },

  title: {
    color: theme.palette.common.brandingBlack,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(38.4),
    textAlign: 'center',
    letterSpacing: '-1px',
    margin: 0,
    '& i': {
      fontStyle: 'italic',
      display: 'inline-block',
      fontFamily: theme.typography.fontFamilySerif,
      fontWeight: 400,
      letterSpacing: '-1px',
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(67),
      lineHeight: theme.typography.pxToRem(70),
      textAlign: 'left',
      margin: [20, 0, 43],
    },
  },

  description: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(19.6),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(29),
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 43,
    maxWidth: 495,
    display: 'none',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      display: 'block',
    },
  },

  socialNetworks: {
    fontSize: theme.typography.pxToRem(18),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: '0em',
    textAlign: 'left',
    display: 'none',
    gap: 20,
    alignItems: 'center',

    '& img': {
      display: 'block',
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      display: 'flex',
    },
  },

  aboutUs: {
    color: theme.palette.common.brandingBlack,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      width: 'auto',
    },
  },
}));

export default useStyles;
