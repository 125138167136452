import * as yup from 'yup';

export const email = yup.string().email('validations.email.invalid').required('validations.email.empty');

export const password = yup.string().required('validations.password.required');

export const passwordConfirmation = yup
  .string()
  .oneOf([yup.ref('password')], 'validations.passwordConfirmation.passwordMatch')
  .required('validations.passwordConfirmation.required');
