import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    marginTop: '40px',
    padding: [14, 32],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },

  text: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
  },
  grid: {
    marginRight: 0,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      marginRight: 24,
    },
  },
  textField: {
    marginBottom: 0,

    '&:not(.MuiFormControl-marginNormal)': {
      marginTop: '30px',
    },
  },
  form: {
    width: '100%',
    marginTop: '16px',
    '& .MuiFormControl-root': {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.common.brandingBlack,
      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.common.brandingBlack,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, -7px)',
      },
      '&:hover': {
        '& .MuiInput-underline': {
          '&:hover': {
            '&:before': {
              borderBottom: `2px solid ${theme.palette.common.brandingBlue}`,
            },
            '&:after': {
              borderBottom: `2px solid ${theme.palette.common.brandingBlue}`,
            },
          },
        },
      },
    },
    '& .MuiInputBase-input': {
      lineHeight: theme.typography.pxToRem(24),
      padding: [13, 17],
    },
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: 'none',
      },
      '&:before': {
        borderBottom: `1px solid ${theme.palette.common.gray2}`,
        transition: 'none',
      },
      '&:active:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
      '&:hover:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
    '& .MuiInput-underline.Mui-error': {
      borderBottom: '1px solid #EF4444',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))',
      '&:after': {
        borderBottomColor: 'transparent',
      },
      '&:before': {
        border: 'none',
      },
    },
  },
  formInputs: {
    padding: [45, 0, 27],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '30px',
  },
}));

export default useStyles;
