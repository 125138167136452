const STACK_ICONS = {
  js: 'js',
  react: 'react',
  vue: 'vue',
  node: 'node',
  ruby: 'ruby',
  python: 'python',
  pm: 'pm',
  qa: 'qa',
  flutter: 'flutter',
  infrastructure: 'infrastructure',
  elixir: 'elixir',
};

Object.freeze(STACK_ICONS);

export { STACK_ICONS };
