import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: [0, 0, 0, 1, `${theme.palette.common.silverGray}`],
    padding: 20,
  },

  formBox: {
    '& > *': {
      margin: 10,
      minWidth: 500,
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: 14,
    },
  },

  state: {
    fontSize: 14,
  },
}));

export default useStyles;
