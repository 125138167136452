import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menuItem: {
    marginRight: 0,
  },

  popper: {
    zIndex: theme.zIndex.appBar,
  },

  link: {
    '&:link': {
      textDecoration: 'none',
    },
    color: 'inherit',
  },

  languageButton: {
    display: 'flex',
    gap: 10,
  },

  languageIcon: {
    fill: theme.palette.common.blue5,
  },

  name: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useStyles;
