import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { head, isEmpty } from 'ramda';

import Alert from '@material-ui/lab/Alert';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  List,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import PracticePresenter from 'presenters/User/PracticePresenter';
import appRoutes from 'routes/appRoutes';
import findById from 'utils/findById';
import { isNull } from 'utils/helpers';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { userPracticesHooks } from 'store/user/practicesSlice';

import PracticeItem from 'components/PracticeItem';
import PracticeContent from './components/PracticeContent';
import PracticesListSkeleton from './components/PracticesListSkeleton';

import useStyles from './styles';

const PracticesList = props => {
  const { lessonId, courseId, practiceId } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  const {
    firstLoadPractices,
    resetPractices,
    practices: { items: userPractices, loadingStatus },
  } = userPracticesHooks.use();

  const { isPending, isFinished } = useFetchStatus(loadingStatus);

  useEffect(() => {
    firstLoadPractices(lessonId);
    return () => {
      resetPractices();
    };
  }, [lessonId]);

  useEffect(() => {
    if (isNull(practiceId) && isFinished) {
      const firstPracticeId = PracticePresenter.id(head(userPractices));
      history.push(appRoutes.courseLessonPracticePath(courseId, lessonId, firstPracticeId));
    }
  }, [userPractices]);

  if (!isFinished) {
    return (
      <div className={classes.container}>
        <PracticesListSkeleton />
      </div>
    );
  }
  const currentPractice = findById(userPractices, practiceId);

  const message = PracticePresenter.teacherFeedbackMessage(currentPractice);

  const renderLaptopPractices = () =>
    userPractices.map(userPractice => (
      <PracticeItem
        key={PracticePresenter.id(userPractice)}
        courseId={courseId}
        lessonId={lessonId}
        userPractice={userPractice}
        practiceId={practiceId}
        isListLoadingPending={isPending}
      />
    ));

  const renderMobilePractices = () => {
    return userPractices.map((userPractice, id) => {
      const uniqueId = lessonId + id;
      return (
        <Accordion
          expanded={expanded === uniqueId}
          onChange={handleChange(uniqueId)}
          key={PracticePresenter.id(userPractice)}
          elevation={0}
          className={classes.accordion}
        >
          <AccordionSummary
            aria-controls={PracticePresenter.id(userPractice)}
            aria-label={PracticePresenter.id(userPractice)}
            id={PracticePresenter.id(userPractice)}
            classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
          >
            <PracticeItem
              key={PracticePresenter.id(userPractice)}
              courseId={courseId}
              lessonId={lessonId}
              userPractice={userPractice}
              practiceId={practiceId}
              isListLoadingPending={isPending}
            >
              <ExpandMoreRounded
                className={
                  expanded === uniqueId ? classes.iconSummary : clsx(classes.iconSummary, classes.iconSummaryClosed)
                }
              />
            </PracticeItem>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box pb={3} width="100%">
              {message && (
                <>
                  <Typography className={classes.currentPracticeName}>{t('practiceList.feedback')}</Typography>
                  <Alert severity="warning" className={classes.alert}>
                    {message}
                  </Alert>
                </>
              )}
              <Route path={appRoutes.courseLessonPracticePath(courseId, lessonId, practiceId)}>
                <PracticeContent lessonId={lessonId} practiceId={practiceId} />
              </Route>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const laptopPractices = () => {
    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item md={4} sm={6}>
            <List disablePadding>{renderLaptopPractices()}</List>
          </Grid>
          <Grid item md={8} sm={6}>
            <Box px={4} py={3}>
              {message && (
                <>
                  <Typography className={classes.currentPracticeName}>{t('practiceList.feedback')}</Typography>
                  <Alert severity="warning" className={classes.alert}>
                    {message}
                  </Alert>
                </>
              )}
              <Typography className={classes.currentPracticeName}>{PracticePresenter.name(currentPractice)}</Typography>
              <Route path={appRoutes.courseLessonPracticePath(courseId, lessonId, practiceId)}>
                <PracticeContent lessonId={lessonId} practiceId={practiceId} />
              </Route>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  const mobilePractices = () => {
    return (
      <Box px={3} py={1} className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <List disablePadding>{renderMobilePractices()}</List>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return !isEmpty(userPractices) ? (
    <>
      {matchesMobile && mobilePractices()}
      {matchesLaptop && laptopPractices()}
    </>
  ) : (
    <div className={classes.container}>
      <Box px={4} py={3}>
        {t('studentLesson.noPractice')}
      </Box>
    </div>
  );
};

PracticesList.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  practiceId: PropTypes.number,
};

PracticesList.defaultProps = {
  practiceId: null,
};

export default PracticesList;
