import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    minHeight: '100%',
    padding: 0,

    [theme.breakpoints.up(theme.breakpoints.values.tv)]: {
      maxWidth: '75%',
    },
  },

  gridContainer: {
    padding: [theme.spacing(4), theme.spacing(2)],
    gap: 20,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      padding: [theme.spacing(4)],
    },
  },

  backdrop: {
    zIndex: theme.zIndex.backdrop,
  },

  chart: {
    maxHeight: 353,
  },

  outlined: {
    border: `1px solid ${theme.palette.common.blue7}`,
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    textAlign: 'left',
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    margin: [0, 'auto', 24, 0],
    width: '100%',
  },
}));

export default useStyles;
