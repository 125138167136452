import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';

import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { STACK_ICONS } from 'enums';

import Icon from 'components/Icon';

import GroupPresenter from 'presenters/GroupPresenter';

import useStyles from './styles';

const StackIconSelect = props => {
  const { value: currentValue, disabled, onChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <InputLabel id="filter-icon-label">{t('icons')}</InputLabel>
      <Select
        disabled={disabled}
        multiple
        name="iconId"
        labelId="filter-icon-label"
        id="filter-icon-select"
        value={currentValue}
        onChange={handleChange}
        input={<Input id="select-multiple-icon" className={classes.input} />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(icon => (
              <Icon className={classes.chip} key={icon} name={icon} />
            ))}
          </div>
        )}
      >
        {Object.values(STACK_ICONS).map(icon => (
          <MenuItem key={icon} value={icon}>
            <Icon name={icon} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

StackIconSelect.defaultProps = {
  disabled: false,
};

StackIconSelect.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(GroupPresenter.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StackIconSelect;
