import React from 'react';
import { Switch, Redirect } from 'react-router';

import appRoutes from 'routes/appRoutes';
import AppRoute from 'layouts/components/AppRoute';
import CoursesStudentProgressOverview from 'containers/Student/containers/CoursesStudentProgressOverview';

import exerciseTypes from 'utils/exerciseTypes';
import Courses from 'containers/Student/containers/Courses';
import Vacancies from 'containers/Guest/Vacancies';
import Vacancy from 'containers/Guest/Vacancy';
import Page from 'containers/Guest/Page';
import Exercises from 'containers/Student/containers/Exercises';
import Lessons from 'containers/Student/containers/Lessons';
import UserProfile from 'containers/Student/containers/UserProfile';
import UserEditProfile from 'containers/Student/containers/UserEditProfile';
import Error404 from 'components/Error404';
import Error403 from 'components/Error403';

const StudentSwitch = () => {
  return (
    <Switch>
      <AppRoute path={appRoutes.coursesStudentDashboardPath(':courseId', ':studentId')} exact layout="dashboard">
        <CoursesStudentProgressOverview />
      </AppRoute>
      <AppRoute path={appRoutes.coursesPath()} exact layout="dashboard">
        <Courses />
      </AppRoute>
      <AppRoute path={appRoutes.vacanciesPath()} exact layout="dashboard">
        <Vacancies />
      </AppRoute>
      <AppRoute path={appRoutes.vacancyPath(':vacancyId')} exact layout="dashboard">
        <Vacancy />
      </AppRoute>
      <AppRoute path={appRoutes.pagePath(':slug')} exact layout="dashboard">
        <Page />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonsPath(':courseId')} exact>
        <Lessons />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonPracticesPath(':courseId', ':lessonId')} exact>
        <Exercises exercise={exerciseTypes.practice} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonPracticePath(':courseId', ':lessonId', ':practiceId')} exact>
        <Exercises exercise={exerciseTypes.practice} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonTheoriesPath(':courseId', ':lessonId')} exact>
        <Exercises exercise={exerciseTypes.theory} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonTheoryPath(':courseId', ':lessonId', ':theoryId')} exact>
        <Exercises exercise={exerciseTypes.theory} />
      </AppRoute>
      <AppRoute path={appRoutes.courseIntroductionPath(':courseId')} exact>
        <Exercises />
      </AppRoute>
      <AppRoute path={appRoutes.userProfilePath()} exact>
        <UserProfile />
      </AppRoute>
      <AppRoute path={appRoutes.userEditProfilePath()} exact>
        <UserEditProfile />
      </AppRoute>
      <AppRoute path={appRoutes.error404Path()} exact layout="dashboard">
        <Error404 />
      </AppRoute>
      <AppRoute path={appRoutes.error403Path()} exact layout="dashboard">
        <Error403 />
      </AppRoute>
      <Redirect from={appRoutes.rootPath()} exact to={appRoutes.coursesPath()} />
      <Redirect from={appRoutes.googleOauthPath()} exact to={appRoutes.userEditProfilePath()} />
      <Redirect from={appRoutes.signInPath()} to={appRoutes.coursesPath()} />
      <Redirect from={appRoutes.signUpPath()} exact to={appRoutes.userEditProfilePath()} />
      <Redirect from="*" to={appRoutes.error404Path()} />
    </Switch>
  );
};

export default StudentSwitch;
