import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import appRoutes from 'routes/appRoutes';
import GroupPresenter from 'presenters/GroupPresenter';
import UserPresenter from 'presenters/UserPresenter';
import useCurrentUser from 'hooks/useCurrentUser';

import useStyles from './styles';

const Group = props => {
  const { group, onGroupModalOpen, onModalOpenDestroy } = props;
  const classes = useStyles();
  const { currentUser } = useCurrentUser();

  const groupId = GroupPresenter.id(group);

  return (
    <TableRow>
      <TableCell>{groupId}</TableCell>
      <TableCell>
        <Link to={appRoutes.adminGroupPath(groupId)} className={classes.link}>
          {GroupPresenter.name(group)}
        </Link>
      </TableCell>
      <TableCell>{GroupPresenter.startAt(group)}</TableCell>
      <TableCell>{GroupPresenter.endAt(group)}</TableCell>
      <TableCell align="right">
        {(UserPresenter.hasAdminAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
          <IconButton onClick={onGroupModalOpen(group)}>
            <EditIcon color="primary" fontSize="small" />
          </IconButton>
        )}
        {UserPresenter.hasAdminAccess(currentUser) && (
          <IconButton onClick={onModalOpenDestroy(group)}>
            <DeleteIcon color="primary" fontSize="small" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

Group.propTypes = {
  group: PropTypes.shape().isRequired,
  onGroupModalOpen: PropTypes.func.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
};

export default Group;
