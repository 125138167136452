import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  mobileMenuAvatar: {
    width: 49,
    height: 49,
  },

  menuItem: {
    fontSize: 12,
    color: theme.palette.common.blue5,
  },

  popper: {
    zIndex: theme.zIndex.appBar,
  },

  link: {
    '&:link': {
      textDecoration: 'none',
    },
    color: theme.palette.common.blue5,
  },

  name: {
    fontSize: theme.typography.pxToRem(12),
  },
  fullName: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.15px',
    whiteSpace: 'pre-line',
  },
}));

export default useStyles;
