import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isNil } from 'ramda';

import Tab from '@material-ui/core/Tab';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PracticeItem from 'components/PracticeItem';
import TheoryItem from 'components/TheoryItem';
import LessonItem from 'components/LessonItem';

import exerciseTypes from 'utils/exerciseTypes';
import findById from 'utils/findById';

import LessonPresenter from 'presenters/User/LessonPresenter';
import PracticePresenter from 'presenters/User/PracticePresenter';
import TheoryPresenter from 'presenters/User/TheoryPresenter';
import CoursePresenter from 'presenters/CoursePresenter';

import useAdminLessons from 'hooks/admin/useLessons';
import useAdminCourses from 'hooks/admin/useCourses';

import useStyles from './styles';

const UserExercisesList = props => {
  const { courseId, userId } = props;
  const [currentTab, setCurrentTab] = useState(exerciseTypes.practice);
  const [currentLessonId, setCurrentLessonId] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const { lessons, loadAdminLessons, isLoading: isLessonsLoading, resetAdminLessons } = useAdminLessons();
  const { courses, isLoading: isCoursesLoading, loadAdminCourses, resetAdminCourses } = useAdminCourses();

  useEffect(() => {
    loadAdminLessons(courseId, { user_id: userId });
    loadAdminCourses();
    return () => {
      resetAdminLessons();
      resetAdminCourses();
    };
  }, [courseId]);

  const handleChange = (events, newTab) => {
    setCurrentTab(newTab);
  };

  const handleChangeLesson = lesson => () => {
    setCurrentLessonId(LessonPresenter.id(lesson));
  };

  const theoryClasses = clsx({
    [classes.tabItem]: true,
    [classes.tabSelected]: currentTab === exerciseTypes.theory,
  });
  const practiceClasses = clsx({
    [classes.tabItem]: true,
    [classes.tabSelected]: currentTab === exerciseTypes.practice,
  });

  if (isNil(lessons) || isLessonsLoading || isNil(courses) || isCoursesLoading) {
    return 'Loading...';
  }

  const currentCourse = findById(courses, courseId);
  const lessonId = currentLessonId || CoursePresenter.firstLessonId(currentCourse);
  const currentLesson = findById(lessons, lessonId);

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <List disablePadding>
          {lessons.map(lesson => {
            const isCurrent = LessonPresenter.isCurrentLesson(currentLesson, lesson);
            const itemClasses = clsx(classes.listItem, { [classes.listItemCurrent]: isCurrent });

            return (
              <Button className={classes.button} key={LessonPresenter.id(lesson)} onClick={handleChangeLesson(lesson)}>
                <LessonItem courseLesson={lesson} lessonClasses={itemClasses} isCurrent={isCurrent} />
              </Button>
            );
          })}
        </List>
      </div>
      <div className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          {LessonPresenter.name(currentLesson)}
        </Typography>

        <TabContext value={currentTab}>
          <TabList
            className={classes.tabs}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            TabIndicatorProps={{
              className: classes.indicator,
            }}
          >
            <Tab className={practiceClasses} value={exerciseTypes.practice} label={t('studentLesson.practice')} />
            <Tab className={theoryClasses} value={exerciseTypes.theory} label={t('studentLesson.theory')} />
          </TabList>
          <TabPanel value={exerciseTypes.practice} className={classes.tabPanel}>
            <List disablePadding>
              {currentLesson.userPractices.map(userPractice => (
                <PracticeItem
                  key={PracticePresenter.id(userPractice)}
                  courseId={courseId}
                  lessonId={lessonId}
                  userPractice={userPractice}
                  isAdminView
                />
              ))}
            </List>
          </TabPanel>
          <TabPanel value={exerciseTypes.theory} className={classes.tabPanel}>
            <List disablePadding>
              {currentLesson.userTheories.map(theory => (
                <TheoryItem
                  key={TheoryPresenter.id(theory)}
                  userTheory={theory}
                  lessonId={lessonId}
                  courseId={courseId}
                  isAdminView
                />
              ))}
            </List>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

UserExercisesList.propTypes = {
  courseId: PropTypes.number,
  userId: PropTypes.number,
};
UserExercisesList.defaultProps = {
  courseId: null,
  userId: null,
};

export default UserExercisesList;
