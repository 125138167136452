import { useActions as useLoadActions, selectors as loadSelectors } from 'store/admin/LastActivitiesSlice';

const useLastActivities = () => {
  const {
    load: loadLastActivities,
    clearLoadingError,
    reset: resetLastActivities,
    append: appendLastActivities,
  } = useLoadActions();
  const { getData, getIsLoading, getLoadingError, getMeta } = loadSelectors;
  const lastActivities = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();
  const meta = getMeta();

  return {
    lastActivities,
    resetLastActivities,
    appendLastActivities,
    meta,
    loadLastActivities,
    isLoading,
    loadingError,
    clearLoadingError,
  };
};

export default useLastActivities;
