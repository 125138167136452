import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Divider, Typography, useMediaQuery, useTheme, Tabs, Tab, Paper } from '@material-ui/core';

import LessonPresenter, { THEORY, PRACTICE } from 'presenters/User/LessonPresenter';
import CourseProgressCard from 'components/CourseProgressCard';

import StudentProfileInfo from './components/StudentProfileInfo';
import Course from './components/Course';

import useStyles from './styles';

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const a11yProps = index => {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
};

const StudentProgressDetailedCard = props => {
  const { courseId, course, isCourseLoading, userLessons, isUserLessonsLoading } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedTab, setTab] = useState(0);

  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesLaptop;

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const mobileProgress = (
    <Grid item xs={12}>
      <Course course={course} isLoading={isCourseLoading} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit"
        aria-label="content tabs"
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab label={t('studentCard.theory')} {...a11yProps(0)} />
        <Tab label={t('studentCard.practice')} {...a11yProps(1)} />
      </Tabs>
      <Paper elevation={0} className={classes.courseProgressBlock}>
        <TabPanel value={selectedTab} index={0}>
          <Grid item xs={12}>
            <CourseProgressCard
              courseLessons={userLessons}
              type={THEORY}
              showTooltips
              isLoading={isUserLessonsLoading}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Grid item xs={12}>
            <CourseProgressCard
              courseLessons={userLessons}
              type={PRACTICE}
              showTooltips
              isLoading={isUserLessonsLoading}
            />
          </Grid>
        </TabPanel>
      </Paper>
    </Grid>
  );

  const laptopProgress = (
    <Grid item md={7} className={classes.userCourses}>
      <Course course={course} isLoading={isCourseLoading} />
      <Grid container justifyContent="space-between" className={classes.row}>
        <Grid item xs={6} className={classes.columns}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {t('studentCard.theory')}
          </Typography>
          <CourseProgressCard courseLessons={userLessons} type={THEORY} showTooltips isLoading={isUserLessonsLoading} />
        </Grid>
        <Grid item xs={6} className={classes.columns}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {t('studentCard.practice')}
          </Typography>
          <CourseProgressCard
            courseLessons={userLessons}
            type={PRACTICE}
            showTooltips
            isLoading={isUserLessonsLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container classes={{ root: classes.studentProgressDetailedCard }}>
      <Grid item md={4} xs={12}>
        <StudentProfileInfo courseId={courseId} />
      </Grid>
      <Divider orientation={matchesMobile ? 'horizontal' : 'vertical'} flexItem classes={{ root: classes.divider }} />
      {matchesMobile && mobileProgress}
      {matchesLaptop && laptopProgress}
    </Grid>
  );
};

StudentProgressDetailedCard.propTypes = {
  courseId: PropTypes.number.isRequired,
  course: PropTypes.shape(),
  isCourseLoading: PropTypes.bool,
  userLessons: PropTypes.arrayOf(LessonPresenter.shape()),
  isUserLessonsLoading: PropTypes.bool,
};

StudentProgressDetailedCard.defaultProps = {
  course: null,
  isCourseLoading: false,
  userLessons: [],
  isUserLessonsLoading: false,
};

export default StudentProgressDetailedCard;
