import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    gap: 8,
    alignItems: 'flex-start',
    padding: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gap: 24,
      padding: 32,
    },
  },
  cardEmoji: {
    display: 'inline-block',
    marginRight: '8px',
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(31),
  },
  cardTitle: {
    margin: 0,
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_portrait)]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(26),
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  cardContent: {
    color: theme.palette.common.brandingGray2,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(21),
  },

  accordion: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column-reverse',

    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },

  accordionSummaryRoot: {
    justifyContent: 'flex-start',
    gap: 12,
    padding: 0,
    minHeight: 'fit-content',

    '&.Mui-expanded': {
      minHeight: 'fit-content',
    },
  },

  accordionSummaryContent: {
    flexGrow: 'unset',
    margin: 0,
    fontSize: theme.typography.pxToRem(14),

    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionIcon: {
    padding: 0,
  },

  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
    padding: 0,
    marginBottom: 16,
  },
}));

export default useStyles;
