import {
  apiV1AdminCourseLessonTheoriesPath,
  apiV1AdminCourseLessonTheoryPath,
  updateAllApiV1AdminCourseLessonTheoriesPath,
  bulkUpdateApiV1AdminCourseLessonTheoriesPath,
} from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  load(courseId, lessonId) {
    const path = apiV1AdminCourseLessonTheoriesPath(courseId, lessonId);
    return FetchHelpers.get(path);
  },

  create(courseId, lessonId, theory) {
    const path = apiV1AdminCourseLessonTheoriesPath(courseId, lessonId);
    return FetchHelpers.post(path, { theory });
  },

  update(courseId, lessonId, id, theory) {
    const path = apiV1AdminCourseLessonTheoryPath(courseId, lessonId, id);
    return FetchHelpers.put(path, { theory });
  },

  updateAll(courseId, lessonId, theories) {
    const path = updateAllApiV1AdminCourseLessonTheoriesPath(courseId, lessonId);
    return FetchHelpers.put(path, { lesson_theories_attributes: theories });
  },

  destroy(courseId, lessonId, id) {
    const path = apiV1AdminCourseLessonTheoryPath(courseId, lessonId, id);
    return FetchHelpers.delete(path);
  },

  bulkUpdate(courseId, lessonId, params) {
    const path = bulkUpdateApiV1AdminCourseLessonTheoriesPath(courseId, lessonId);
    return FetchHelpers.post(path, params);
  },
};
