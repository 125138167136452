import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    background: theme.palette.common.white,
    display: 'grid',
    gridTemplateColumns: `${theme.sizes.sidebarContentWidth}px 1fr`,
  },

  sidebar: {
    borderRight: `1px solid ${theme.palette.common.gray7}`,
  },

  paper: {
    padding: '3vw',
    backgroundColor: theme.palette.common.white2,
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `4px solid ${theme.palette.common.gray7}`,
    },
  },

  tabItem: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    padding: [16, 72],
    textTransform: 'none',
    color: theme.palette.common.brandingGray1,
    opacity: 1,
    '&:hover': {
      background: theme.palette.common.white,
      boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
      borderRadius: '3px',
      color: theme.palette.common.brandingBlue,
    },
  },

  tabSelected: {
    color: theme.palette.common.brandingBlack,
  },

  indicator: {
    borderBottom: `4px solid ${theme.palette.common.brandingBlue}`,
  },

  tabPanel: {
    padding: 0,
    background: theme.palette.common.white,
  },

  button: {
    padding: 0,
    width: '100%',
    '&:hover': {
      boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
    },
  },

  listItem: {
    minHeight: theme.typography.pxToRem(58),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    '&:hover': {
      boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
      background: theme.palette.common.white2,
    },
  },

  listItemCurrent: {
    width: '100%',
    background: theme.palette.common.lightBlue3,
    color: theme.palette.common.brandingBlack,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.lighten(theme.palette.common.lightBlue3, 0.05),
    },
  },
}));

export default useStyles;
