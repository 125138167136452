import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    padding: [30, 30, 0],
    boxSizing: 'border-box',
  },

  copyright: {
    padding: 20,
    textAlign: 'center',
    fontSize: 12,
    boxSizing: 'border-box',
    width: '100%',
    marginTop: 40,
  },
}));

export default useStyles;
