import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Dialog,
  TextField,
  InputLabel,
  MenuItem,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  FormHelperText,
  Typography,
  Slide,
  Tabs,
  Tab,
  Paper,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { validationSchema, initialValues } from 'forms/admin/createTheory';

import useNumberParams from 'hooks/useNumberParams';
import useRedirects from 'hooks/redirects';

import { isNullOrUndefined } from 'utils/helpers';
import { camelize } from 'utils/strings';
import { mdParser, MdEditor } from 'utils/markdown';

import useStyles from './styles';

const TabPanel = props => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const a11yProps = index => {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
};

const TheoryModal = props => {
  const { theory, nextOrder, isModalOpen, isLoading, onTheoryCreate, onTheoryUpdate } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId, lessonId } = useNumberParams(['courseId', 'lessonId']);
  const { redirectToAdminTheoriesPath } = useRedirects();
  const [hasChanges, setHasChanges] = useState(false);

  const initValues = initialValues(theory, nextOrder);
  const isTheoryCreated = !isNil(theory);

  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: isTheoryCreated ? onTheoryUpdate : onTheoryCreate,
    enableReinitialize: true,
  });

  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const [selectedTab, setTab] = useState(0);
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);
  const isFieldLinkAvailable = values.isLink;

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onbeforeunloadFn = event => {
      if (hasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign, prettier/prettier, no-param-reassign
        return event.returnValue = "";
      }
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [hasChanges]);

  const trackChanges = (fieldName, value) => {
    if (initValues[fieldName] !== value) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleContentChange = ({ text }) => {
    trackChanges('content', text);
    handleChange({ target: { name: 'content', value: text } });
  };

  const handleTeacherContentChange = ({ text }) => {
    trackChanges('teacherContent', text);
    handleChange({ target: { name: 'teacherContent', value: text } });
  };

  const handleModalClose = () => {
    redirectToAdminTheoriesPath(courseId, lessonId);
  };

  const handleSave = () => {
    setHasChanges(false);
    handleSubmit();
  };

  const promptFunc = () => {
    if (hasChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.closeConfirm'))) {
        resetForm();
        setHasChanges(false);
        return true;
      }
      return false;
    }
    return true;
  };

  const renderSelectItems = () => {
    return values.availableStates.map(availableState => (
      <MenuItem value={availableState} classes={{ root: classes.state }} key={availableState}>
        {t(`adminEditLessonTheoryModal.states.${camelize(availableState)}`)}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      TransitionComponent={Transition}
    >
      <Prompt when={hasChanges} message={promptFunc} />
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleModalClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('adminEditLessonTheoryModal.title')}
            {values.name}
          </Typography>
          <Button autoFocus onClick={handleSave} disabled={!isAbleToSubmit} variant="contained" color="primary">
            {t('adminEditLessonTheoryModal.submitButton')}
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <form noValidate onSubmit={handleSave}>
            <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
              <TextField
                required
                name="name"
                label={t('adminEditLessonTheoryModal.name')}
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.name)}
                helperText={t(errors.name)}
              />
              <div className={isFieldLinkAvailable ? classes.disabled : ''}>
                <Paper square>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="content tabs"
                  >
                    <Tab label={`${t('adminEditLessonPracticeModal.content')}*`} {...a11yProps(0)} />
                  </Tabs>
                </Paper>
                <TabPanel
                  value={selectedTab}
                  index={0}
                  classes={{
                    root: clsx(classes.contentBox, {
                      [classes.errorsContentBox]: !isNullOrUndefined(errors.content),
                    }),
                  }}
                >
                  <MdEditor
                    style={{ height: '400px' }}
                    name="content"
                    value={values.content}
                    renderHTML={text => mdParser.render(text)}
                    onChange={handleContentChange}
                  />
                  <FormHelperText error>{t(errors.content)}</FormHelperText>
                </TabPanel>
                <TabPanel
                  value={selectedTab}
                  index={1}
                  classes={{
                    root: classes.contentBox,
                  }}
                >
                  <MdEditor
                    style={{ height: '400px' }}
                    name="teacherContent"
                    value={values.teacherContent}
                    renderHTML={text => mdParser.render(text)}
                    onChange={handleTeacherContentChange}
                  />
                  <FormHelperText error>{t(errors.teacherContent)}</FormHelperText>
                </TabPanel>
              </div>
              <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
                <InputLabel id="state">{t('adminEditLessonTheoryModal.state')}</InputLabel>
                <Select
                  labelId="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  label={t('adminEditLessonTheoryModal.state')}
                >
                  {renderSelectItems()}
                </Select>
                <FormHelperText error>{t(errors.state)}</FormHelperText>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.isOnlyOnce} onChange={handleChange} name="isOnlyOnce" />}
                  label={t('adminEditLessonTheoryModal.isOnlyOnce')}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.isLink} onChange={handleChange} name="isLink" />}
                  label={t('adminEditLessonTheoryModal.isLink')}
                />
                <FormHelperText>{t('adminEditLessonTheoryModal.isLinkDescription')}</FormHelperText>
              </FormGroup>
              <TextField
                className={!isFieldLinkAvailable ? classes.hidden : ''}
                name="link"
                required={isFieldLinkAvailable}
                label={t('adminEditLessonTheoryModal.link')}
                value={values.link}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.link)}
                helperText={t(errors.link)}
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.isDoc} onChange={handleChange} name="isDoc" />}
                  label={t('adminEditLessonTheoryModal.isDoc')}
                />
                <FormHelperText>{t('adminEditLessonTheoryModal.isDocDescription')}</FormHelperText>
              </FormGroup>
            </Box>
          </form>
        )}
      </Box>
    </Dialog>
  );
};

TheoryModal.propTypes = {
  theory: PropTypes.shape(),
  nextOrder: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onTheoryCreate: PropTypes.func.isRequired,
  onTheoryUpdate: PropTypes.func.isRequired,
};

TheoryModal.defaultProps = {
  theory: null,
};

export default TheoryModal;
