import React from 'react';
import { range } from 'ramda';

import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

const COUNT_SKELETON_CARDS = 4;

const CoursesCardsSkeleton = () => {
  const classes = useStyles();

  return range(0, COUNT_SKELETON_CARDS).map(el => {
    return (
      <Card key={el} className={classes.card} variant="outlined">
        <Skeleton variant="text" width={400} classes={{ root: classes.courseName }} />

        <div className={classes.descriptionBox}>
          <Skeleton variant="text" width={430} classes={{ root: classes.description }} />
          <Skeleton variant="text" width={350} classes={{ root: classes.description }} />
        </div>

        <div className={classes.info}>
          <div className={classes.infoBox}>
            <Skeleton variant="text" width={30} height={45} classes={{ root: classes.durationHours }} />
            <Skeleton variant="text" width={70} classes={{ root: classes.durationLabel }} />
          </div>
          <div className={classes.infoBox}>
            <Skeleton variant="text" width={30} height={45} classes={{ root: classes.durationHours }} />
            <Skeleton variant="text" width={70} classes={{ root: classes.durationLabel }} />
          </div>
          <div className={classes.infoBox}>
            <Skeleton variant="text" width={30} height={45} classes={{ root: classes.durationHours }} />
            <Skeleton variant="text" width={70} classes={{ root: classes.durationLabel }} />
          </div>
        </div>

        <div className={classes.button}>
          <Skeleton variant="rect" width={150} height={40} classes={{ root: classes.courseButton }} />
        </div>
      </Card>
    );
  });
};

export default CoursesCardsSkeleton;
