export const USERS_SORT_VARIANTS = {
  default: 'created_at asc',
  createdAsc: 'created_at asc',
  createdDesc: 'created_at desc',
  courseRatingAsc: 'user_course_rating asc',
  courseRatingDesc: 'user_course_rating desc',

  sortKeys: {
    createdAt: 'created_at',
    courseRating: 'user_course_rating',
  },
};
