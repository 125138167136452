import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Icon from 'components/Icon';
import { Typography, Grid } from '@material-ui/core';

import appRoutes from 'routes/appRoutes';

import useStyles from './styles';

const SuccessMessage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.form}>
        <Grid container direction="row" justifyContent="space-around" alignItems="center" className={classes.grid}>
          <Grid item xs={12} sm={4}>
            <Icon name="check" className={classes.icon} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.text} variant="body1">
              {t('resetPassword.passwordUpdated')}
            </Typography>
          </Grid>
        </Grid>
        <Button className={classes.button} variant="contained" color="primary" href={appRoutes.signInPath()}>
          <Typography className={classes.textButton}>{t('resetPassword.returnToSignIn')}</Typography>
        </Button>
      </div>
    </>
  );
};

export default SuccessMessage;
