import storeModuleCreators from 'utils/store';
import ProgressDiagramDataRepository from 'repositories/ProgressDiagramDataRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['progressDiagramData'],
  repositoryAction: ProgressDiagramDataRepository.show,
  responseKey: 'progressDiagramData',
});

export default slice.reducer;
