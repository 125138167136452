import React from 'react';
import { Switch, Redirect } from 'react-router';

import AppRoute from 'layouts/components/AppRoute';
import appRoutes from 'routes/appRoutes';

import DashboardPage from 'containers/Admin/containers/DashboardPage';
import LessonsEditPage from 'containers/Admin/containers/LessonsEditPage';
import TheoriesEditPage from 'containers/Admin/containers/TheoriesEditPage';
import PracticesEditPage from 'containers/Admin/containers/PracticesEditPage';
import CoursesStudentProgressOverview from 'containers/Student/containers/CoursesStudentProgressOverview';
import Vacancies from 'containers/Guest/Vacancies';
import Vacancy from 'containers/Guest/Vacancy';
import Page from 'containers/Guest/Page';

import UserPage from 'containers/Admin/containers/UserPage';
import Courses from 'containers/Student/containers/Courses';
import Exercises from 'containers/Student/containers/Exercises';
import Lessons from 'containers/Student/containers/Lessons';
import GroupPage from 'containers/Admin/containers/GroupPage';
import LastActivities from 'sharedContainers/Admin/LastActivities';
import UserLastActivitiesPage from 'containers/Admin/containers/UserLastActivitiesPage';
import UserProfile from 'containers/Student/containers/UserProfile';
import UserEditProfile from 'containers/Student/containers/UserEditProfile';
import Error404 from 'components/Error404';
import Error403 from 'components/Error403';

import exerciseTypes from 'utils/exerciseTypes';

const AdminSwitch = () => {
  return (
    <Switch>
      <AppRoute path={appRoutes.coursesStudentDashboardPath(':courseId', ':studentId')} exact layout="dashboard">
        <CoursesStudentProgressOverview />
      </AppRoute>

      <AppRoute path={appRoutes.adminUsersPath()} exact layout="dashboard">
        <DashboardPage panelName="users" />
      </AppRoute>
      <AppRoute path={appRoutes.adminGroupsPath()} exact layout="dashboard">
        <DashboardPage panelName="groups" />
      </AppRoute>
      <AppRoute path={appRoutes.adminCoursesPath()} exact layout="dashboard">
        <DashboardPage panelName="courses" />
      </AppRoute>
      <AppRoute path={appRoutes.adminVacanciesPath()} exact layout="dashboard">
        <DashboardPage panelName="vacancies" />
      </AppRoute>
      <AppRoute path={appRoutes.adminPagesPath()} exact layout="dashboard">
        <DashboardPage panelName="pages" />
      </AppRoute>
      <AppRoute path={appRoutes.adminStatsPath()} exact layout="dashboard">
        <DashboardPage panelName="stats" />
      </AppRoute>
      <AppRoute path={appRoutes.adminLessonsPath(':courseId')} exact layout="dashboard">
        <LessonsEditPage />
      </AppRoute>
      <AppRoute path={appRoutes.adminTheoriesPath(':courseId', ':lessonId')} exact layout="dashboard">
        <TheoriesEditPage />
      </AppRoute>
      <AppRoute path={appRoutes.adminTheoryEditPath(':courseId', ':lessonId', ':theoryId')} exact layout="dashboard">
        <TheoriesEditPage mode="edit" />
      </AppRoute>
      <AppRoute path={appRoutes.adminTheoryNewPath(':courseId', ':lessonId')} exact layout="dashboard">
        <TheoriesEditPage mode="new" />
      </AppRoute>
      <AppRoute path={appRoutes.adminPracticesPath(':courseId', ':lessonId')} exact layout="dashboard">
        <PracticesEditPage />
      </AppRoute>
      <AppRoute
        path={appRoutes.adminPracticeEditPath(':courseId', ':lessonId', ':practiceId')}
        exact
        layout="dashboard"
      >
        <PracticesEditPage mode="edit" />
      </AppRoute>
      <AppRoute path={appRoutes.adminPracticeNewPath(':courseId', ':lessonId')} exact layout="dashboard">
        <PracticesEditPage mode="new" />
      </AppRoute>
      <AppRoute key={appRoutes.adminUserPath()} path={appRoutes.adminUserPath(':userId')} layout="dashboard">
        <UserPage />
      </AppRoute>
      <AppRoute key={appRoutes.adminGroupPath()} path={appRoutes.adminGroupPath(':groupId')} layout="dashboard">
        <GroupPage />
      </AppRoute>
      <AppRoute path={appRoutes.lastUserActivitiesPath(':courseId', ':userId')} exact layout="dashboard">
        <UserLastActivitiesPage />
      </AppRoute>
      <AppRoute path={appRoutes.lastActivitiesPath()} exact layout="dashboard">
        <LastActivities />
      </AppRoute>
      <AppRoute path={appRoutes.courseLastActivitiesPath(':courseId')} exact layout="dashboard">
        <LastActivities />
      </AppRoute>
      <AppRoute path={appRoutes.coursesPath()} exact>
        <Courses />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonsPath(':courseId')} exact>
        <Lessons />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonTheoriesPath(':courseId', ':lessonId')} exact>
        <Exercises exercise={exerciseTypes.theory} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonTheoryPath(':courseId', ':lessonId', ':theoryId')} exact>
        <Exercises exercise={exerciseTypes.theory} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonPracticesPath(':courseId', ':lessonId')} exact>
        <Exercises exercise={exerciseTypes.practice} />
      </AppRoute>
      <AppRoute path={appRoutes.courseLessonPracticePath(':courseId', ':lessonId', ':practiceId')} exact>
        <Exercises exercise={exerciseTypes.practice} />
      </AppRoute>
      <AppRoute path={appRoutes.courseIntroductionPath(':courseId')} exact>
        <Exercises />
      </AppRoute>
      <AppRoute path={appRoutes.userProfilePath()} exact>
        <UserProfile />
      </AppRoute>
      <AppRoute path={appRoutes.userEditProfilePath()} exact>
        <UserEditProfile />
      </AppRoute>
      <AppRoute path={appRoutes.vacanciesPath()} exact layout="dashboard">
        <Vacancies />
      </AppRoute>
      <AppRoute path={appRoutes.vacancyPath(':vacancyId')} exact layout="dashboard">
        <Vacancy />
      </AppRoute>
      <AppRoute path={appRoutes.pagePath(':slug')} exact layout="dashboard">
        <Page />
      </AppRoute>
      <AppRoute path={appRoutes.error404Path()} exact layout="dashboard">
        <Error404 />
      </AppRoute>
      <AppRoute path={appRoutes.error403Path()} exact layout="dashboard">
        <Error403 />
      </AppRoute>
      <Redirect from={appRoutes.adminCoursePath(':courseId')} to={appRoutes.adminLessonsPath(':courseId')} />
      <Redirect from={appRoutes.rootPath()} exact to={appRoutes.coursesPath()} />
      <Redirect from={appRoutes.signInPath()} to={appRoutes.lastActivitiesPath()} />
      <Redirect from="*" to={appRoutes.error404Path()} />
    </Switch>
  );
};

export default AdminSwitch;
