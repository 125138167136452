import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    grow: {
      flexGrow: 1,
      justifyContent: 'space-between',
      boxShadow: 'none',
      padding: [24, 16, 0],

      [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
        boxShadow: '0px 6px 40px rgba(112, 144, 176, 0.08)',
        padding: [0, 48, 0, 0],
      },
    },

    navItems: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexGrow: 1,
      gap: 24,
      padding: [30, 30, 0, 0],
      width: '100%',
    },

    divider: {
      backgroundColor: theme.palette.common.gray10,
      width: '100%',
      height: 1,
      margin: 0,
    },

    menuPaperMobile: {
      width: 290,
      height: '100%',
      maxHeight: '100vh',
      top: 0,
      left: 'unset',
      right: 0,
      '& .MuiMenu-list': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: [24, 20, 0, 32],
      },

      '& .MuiMenuItem-root': {
        padding: 0,
        minHeight: 'auto',
      },
    },
    closeIcon: {
      alignSelf: 'flex-end',
    },

    iconButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'auto',
      gap: 24,

      '& .MuiIconButton-root': {
        padding: 5,
      },

      [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
        flexDirection: 'row',
        alignItems: 'center',
        height: '32px',
        gap: 0,
      },
      [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
        '& .MuiIconButton-root': {
          padding: 14,
        },
      },
    },

    mobileMenuLink: {
      textDecoration: 'none',
      color: theme.palette.common.brandingBlack,
      marginLeft: 0,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      padding: 0,
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 10,
      },
    },

    headerButton: {
      width: 109,
      padding: 5,
      fontSize: theme.typography.pxToRem(14),
    },

    headerSelect: {
      width: 'fit-content',
      fontSize: theme.typography.pxToRem(12),
    },

    selectSpacer: {
      marginRight: 20,
    },

    link: {
      textDecoration: 'none',
      color: theme.palette.common.brandingBlack,
      marginLeft: 0,
      textAlign: 'left',

      [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
        color: theme.palette.common.brandingGray2,
        height: 70,
        marginLeft: theme.typography.pxToRem(24),
      },
    },

    signInButton: {
      textDecoration: 'none',
    },

    button: {
      width: 176,
      padding: [14, 32],
      fontSize: theme.typography.pxToRem(16),
    },

    textButton: {
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(21),
    },

    linkActive: {
      color: theme.palette.common.brandingBlue,
    },

    name: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      padding: 0,

      '&:hover': {
        color: theme.palette.common.brandingBlue,
      },

      [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
        fontWeight: 500,
        padding: [25, 0, 25, 0],
      },
    },

    courseMenu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 24,

      '&.MuiListItem-button:hover': {
        backgroundColor: 'transparent',
      },

      [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
        flexDirection: 'row',
        gap: 0,
      },
    },
    menuItem: {
      padding: 0,
      '&.MuiMenuItem-root:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { index: 1 },
);

export default useStyles;
