/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import StudentLessonPracticesRepository from 'repositories/StudentLessonPracticesRepository';

const sliceName = 'practice';

const slice = createSlice({
  name: sliceName,
  initialState: {
    data: {},
    meta: {},
    processing: false,
    processingError: null,
  },
  reducers: {
    loadPracticeStart(state) {
      state.processingError = null;
      state.processing = true;
    },
    loadPracticeFail(state, { payload }) {
      state.processingError = payload.error;
    },
    clearPracticeProcessingError(state) {
      state.processingError = null;
    },
    loadPracticeSuccess(state, { payload }) {
      state.data = payload.practice;
      state.meta = payload.meta;
      state.processingError = null;
    },
    loadPracticeFinish(state) {
      state.processing = false;
    },
    resetPracticeData(state) {
      state.data = [];
    },
  },
});

const {
  loadPracticeStart,
  loadPracticeFail,
  clearPracticeProcessingError,
  loadPracticeSuccess,
  loadPracticeFinish,
  resetPracticeData,
} = slice.actions;

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    loadPractice: (lessonId, practiceId) => {
      dispatch(loadPracticeStart());
      return StudentLessonPracticesRepository.show(lessonId, practiceId)
        .then(({ data }) => {
          dispatch(loadPracticeSuccess(data));
        })
        .catch(error => {
          dispatch(loadPracticeFail({ error: error.message }));

          return Promise.reject(error);
        })
        .finally(() => dispatch(loadPracticeFinish()));
    },
    clearProcessingError: () => {
      dispatch(clearPracticeProcessingError());
    },
    resetPractice: () => {
      dispatch(resetPracticeData());
    },
  };
};

export default slice.reducer;
