import * as yup from 'yup';

import exerciseStates from 'utils/exerciseStates';
import { AVAILABLE_EXERCISE_STATES_REGEX } from 'utils/regularExpressions';
import ExercisePresenter from 'presenters/ExercisePresenter';
import i18n from 'i18n';

const validationFields = {
  name: yup.string().required('validations.exercise.name.required'),
  state: yup.string().matches(AVAILABLE_EXERCISE_STATES_REGEX, { message: 'validations.exercise.state.invalid' }),
  isLink: yup.boolean(),
  content: yup.string().when('isLink', {
    is: false,
    then: yup.string().required('validations.exercise.content.required'),
  }),
  link: yup
    .string()
    .url(i18n.t('validations.exercise.link.invalid'))
    .when('isLink', {
      is: true,
      then: yup.string().url().required('validations.exercise.link.required'),
    }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (theory, nextOrder) => ({
  name: ExercisePresenter.name(theory) || '',
  link: ExercisePresenter.link(theory) || '',
  content: ExercisePresenter.content(theory) || '',
  teacherContent: ExercisePresenter.teacherContent(theory) || '',
  order: ExercisePresenter.order(theory) || nextOrder,
  isDoc: ExercisePresenter.isDoc(theory) || false,
  isLink: ExercisePresenter.isLink(theory) || false,
  isOnlyOnce: ExercisePresenter.isOnlyOnce(theory) || false,
  state: theory ? ExercisePresenter.state(theory) : exerciseStates.draft,
  availableStates: [
    exerciseStates.published,
    exerciseStates.archived,
    exerciseStates.draft,
    exerciseStates.internalPublished,
  ],
});
