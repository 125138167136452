import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  practiceItem: {
    borderRight: `0.5px solid ${theme.palette.common.brandingGray3}`,
    borderLeft: `0.5px solid ${theme.palette.common.brandingGray3}`,
    padding: 16,
  },

  practiceBox: {
    width: 'fill-available',
  },

  mainBox: {
    display: 'flex',
    alignItems: 'center',
  },

  practiceCheckbox: {
    margin: 9,
  },

  practiceName: {
    marginLeft: 20,
    marginRight: 20,
    flex: 'auto',
    fontSize: theme.typography.pxToRem(12),
  },

  prLinkRect: {
    margin: 10,
  },
}));

export default useStyles;
