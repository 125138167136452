import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty, isNil, path } from 'ramda';

import { isBlank } from 'utils/helpers';

import Autocomplete from 'components/Autocomplete';

const DATA_LOADING_TIME_DELAY = 500;

const AutocompleteAsync = ({
  selected,
  onChange,
  name,
  label,
  required,
  loadData,
  flatOptions,
  responsePath,
  multiple,
  ...rest
}) => {
  const [opened, setOpened] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const resetOptions = () => setOptions([]);

  const handlePositionsLoadSuccess = response => {
    const newOptions = path(responsePath, response);

    setOptions(newOptions);
  };

  const handleLoadData = newSearchValue => loadData(newSearchValue).then(handlePositionsLoadSuccess);

  const debouncedDataLoading = useDebouncedCallback(
    newSearchValue => handleLoadData(newSearchValue),
    DATA_LOADING_TIME_DELAY,
  );

  const handleChangeSearchValue = ({ target: { value: newSearchValue } }) => {
    const searchDependingDataLoadingAction = isEmpty(newSearchValue) ? handleLoadData : debouncedDataLoading;

    setSearchValue(newSearchValue);
    searchDependingDataLoadingAction(newSearchValue);
  };

  const handleOpen = () => {
    handleLoadData(searchValue);
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
    setSearchValue('');
    resetOptions();
  };

  const getOptions = () => {
    if (multiple) {
      return options || [];
    }
    return !isBlank(options) && !isNil(flatOptions) ? flatOptions(options) : options;
  };

  return (
    <Autocomplete
      onChangeSearchValue={handleChangeSearchValue}
      searchValue={searchValue}
      selected={selected}
      onChange={onChange}
      name={name}
      label={label}
      options={getOptions()}
      required={required}
      onOpen={handleOpen}
      onClose={handleClose}
      open={opened}
      multiple={multiple}
      {...rest}
    />
  );
};

AutocompleteAsync.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  flatOptions: PropTypes.func,
  multiple: PropTypes.bool,
  responsePath: PropTypes.arrayOf(PropTypes.string),
};

AutocompleteAsync.defaultProps = {
  selected: null,
  flatOptions: null,
  required: false,
  multiple: false,
  responsePath: ['payload', 'data'],
};

export default AutocompleteAsync;
