import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export default {
  translation: {
    icons: 'Icons',
    cancel: 'Cancel',
    ok: 'Ok',
    common: {
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      seeMore: 'See more',
      rollUp: 'Roll up',
      apply: 'Apply',
      selected: 'Selected: ',
      closeConfirm: 'You have unsaved changes, are you sure you want to leave?',
      promptMessage: 'You have unsaved changes, are you sure you want to leave?',
      contactPromptMessage: 'We would like to know your contact, are you sure you want to leave?',
      theories: 'Theories',
      practices: 'Practices',
      completed: 'Completed',
      halfCompleted: 'Half Completed',
      uncompleted: 'Uncompleted',
      back: 'Back',
    },
    header: {
      course: 'Course',
      vacancies: 'Vacancies',
      progress: 'Progress',
      events: 'Events',
      lessons: 'Lessons',
      adminPanel: 'Admin Panel',
      logout: 'Logout',
      courses: 'All Courses',
      profile: 'Profile',
      ru: 'Russian',
      en: 'English',
      signIn: 'Sign In',
    },
    breadcrumbs: {
      adminPanel: 'Admin',
      courses: 'Courses',
      lessons: 'Lessons',
      theories: 'Theories',
      practices: 'Practices',
    },
    user: {
      hiringStates: {
        none: 'none',
        joined_course: 'Joined Course',
        can_be_hired: 'Can Be Hired',
        interview_scheduled: 'Interview Scheduled',
        hired: 'Hired',
        fired: 'Fired',
        refused_by_us: 'Refused By Us',
        refused_by_candidate: 'Refused By Candidate',
      },
      roles: {
        student: 'Student',
        teacher: 'Teacher',
        admin: 'Admin',
        mentor: 'Mentor',
        hr: 'HR',
      },
    },
    chart: {
      academicAchievement: 'Academic achievement',
      date: 'Date',
      loading: 'Loading...',
    },
    studentAge: {
      key: '{{count}} year old',
      key_plural: '{{count}} years old',
    },
    mainPage: {
      titleWelcome: 'Welcome ',
      titleOur: 'to our ',
      titleSpace: 'Learning Space!',
      description:
        'Our main goal with this platform is to promote growth and career development. With the help of our fellows we develop a series of courses to help our team evolve.',
      about: 'About us',
    },
    signUp: {
      signUp: 'Sign Up',
      emailAddress: 'Email Address',
      password: 'Password',
      alreadyHaveAccount: 'Already have an account? Sign In',
      firstName: 'First Name',
      lastName: 'Last Name',
      passwordConfirmation: 'Password Confirmation',
      githubAccount: 'Github account e.g. ruby-ninja',
      registration: 'Sign Up',
    },
    signIn: {
      signIn: 'Sign In',
      forgotPassword: 'Forgot Password?',
      rememberMe: 'Remember Me',
      emailAddress: 'Email Address',
      password: 'Password',
      noAccount: "Don't have an account? Sign Up",
      google: 'Sign in with Google',
      or: 'or',
    },
    forgotPassword: {
      forgotPassword: 'Forgot your password?',
      sendRequestResetLink: 'Request reset link',
      enterYourEmail: "Enter your email address below and we'll get you back on track",
      emailAddress: 'Email Address',
      noAccount: "Don't have an account? Sign Up",
      emailSent: 'Email sent to your email address',
      sentEmail: 'Send',
    },
    resetPassword: {
      enterNewPassword: 'Enter new password',
      savePassword: 'Save password',
      newPassword: 'New password',
      repeatPassword: 'Repeat new password',
      returnToSignIn: 'Return to Sign In',
      passwordRules:
        "Password can't be shorter 8 symbols. Must contain characters uppercase and downcase, digits and symbols (expect * ^ % #)",
      passwordUpdated: 'Password updated. You can sign In with new password',
    },
    studentCard: {
      theory: 'Theory',
      practice: 'Practice',
      rating: {
        youScore: 'You scored ',
        points: ' points out of ',
      },
    },
    courses: {
      filters: {
        name: 'Search by name',
      },
    },
    coursesCard: {
      practices: 'Practices',
      theories: 'Theories',
      hours: 'Hours',
      courseAction: 'Go to course',
      joinedCourseAction: 'Continue the course',
      courseFinished: 'Course finished',
    },
    validations: {
      firstName: {
        minLength: 'Must contain more than two characters',
        invalid: 'Must consist of letters of Russian or Latin alphabet without symbols and numbers',
        required: 'First name required',
      },
      lastName: {
        minLength: 'Must contain more than two characters',
        invalid: 'Must consist of letters of Russian or Latin alphabet without symbols and numbers.',
        required: 'Last name required',
      },
      password: {
        invalid:
          'Password must consist of letters of the Latin alphabet, not shorter than 8 characters. Must contain digits, characters uppercase and downcase',
        required: 'Password required',
      },
      passwordConfirmation: {
        passwordMatch: 'Passwords not match',
        required: 'Password required',
      },
      email: {
        empty: 'Enter email',
        minLength: 'Must contain more than 2 characters',
        maxLength: 'Must not contain more than 255 characters',
        invalid: 'Enter correct email',
        required: 'Email is required',
      },
      phone: {
        invalid: 'The phone number must contain from seven to fifteen digits',
      },
      githubAccount: {
        required: 'Github account is required',
        invalid: 'Invalid format',
      },
      contact: {
        required: 'Contact is required',
      },
      group: {
        name: {
          required: 'Name is required',
        },
      },
      vacancy: {
        title: {
          required: 'Name is required',
        },
        shortDescription: {
          required: 'Description is required',
        },
        description: {
          required: 'Description is required',
        },
        state: {
          invalid: 'Select a value from the list',
        },
      },
      page: {
        name: {
          required: 'Name is required',
        },
        content: {
          required: 'Content is required',
        },
        state: {
          invalid: 'Select a value from the list',
        },
      },
      course: {
        name: {
          required: 'Name is required',
        },
        description: {
          required: 'Description is required',
          maxLength: "Description shouldn't be more than 500",
        },
        state: {
          invalid: 'Select a value from the list',
        },
        accessDomains: {
          invalids: 'are not valid domains',
          invalid: 'is not a valid domain',
        },
      },
      lesson: {
        name: {
          required: 'Name is required',
        },
        requiredTime: {
          type: 'Input integer number',
          positive: 'Number should be positive',
          integer: 'Number should be integer',
          required: 'Required time cannot be empty',
        },
        state: {
          invalid: 'Select a value from the list',
        },
      },
      exercise: {
        name: {
          required: 'Name is required',
        },
        link: {
          invalid: 'Link must be a valid url',
          required: 'Link is required',
        },
        content: {
          required: 'Content is required',
        },
        state: {
          invalid: 'Select a value from the list',
        },
      },
      note: {
        message: {
          required: 'Field is required',
        },
      },
    },
    commonErrors: {
      formServerError: 'Unexpected error has occurred',
    },
    practiceList: {
      feedback: 'Teacher Feedback',
    },
    studentLesson: {
      theory: 'Theory',
      practice: 'Practice',
      read: 'Read',
      links:
        'the links marked with this label do not need to be read in whole, this is documentation, you need to look through it and read as necessary and know where it is. A checkmark opposite means "I learned and added to bookmarks"',
      practicePlaceholder: 'Link on Pull Request',
      noTheory: 'There is no Theory in this lesson',
      noPractice: 'There is no Practice in this lesson',
      introduction: 'Introduction',
    },
    adminMenu: {
      users: 'Users',
      groups: 'Groups',
      courses: 'Courses',
      stats: 'Stats',
      vacancies: 'Vacancies',
      pages: 'Pages',
    },
    adminUsersMenu: {
      all: 'All users',
      fullTimeStudents: 'Joined Lections',
      distantStudents: 'Distant students',
      graduates: 'Graduates',
      teachers: 'Teachers',
    },
    adminUserLastActivitiesPage: {
      allEvents: 'All Events',
      answerFor: 'Answer for',
      previousFeedback: 'Previous feedback',
    },
    adminUsersFilters: {
      role: 'Role',
      group: 'Group',
      course: 'Course',
      findByFullNameOrEmail: 'Name or Email',
      showProgress: 'Show progress',
      resetButton: 'Reset',
      deleteUserModal: {
        title: 'Delete the user',
        content: 'Are you sure you want to delete the user?',
        failedContent: 'Sorry, we were unable to delete the user',
      },
    },
    adminUsersTable: {
      hiringState: 'Hiring State',
      courseRating: 'Course Rating',
      coursePercentage: 'Course Progress',
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      github: 'Github',
      impersonate: 'Impersonate',
      registrationDate: 'Created',
      theory: 'Theory',
      role: 'Role',
      practice: 'Practice',
      progress: 'Progress',
    },
    adminUserPage: {
      hiringState: 'Hiring State',

      allCourses: 'Back to Courses',
      role: 'Role',
      student: 'Student',
      codeReview: 'Code review',
      fullTimeEducation: 'Full time education',
      finishedEducation: 'Finished education',
      teacher: 'Teacher',
      admin: 'Admin',
      ganttChart: 'Gantt chart',
      noGanttChart:
        'The user has not started taking the course yet. The diagram will appear after he has completed at least one task',
      allLessons: 'All lessons',
      rating: 'Rating',
      chart: {
        loading: 'Loading...',
        expectedTime: 'Expected time',
        theoryTime: 'Theory time',
        practiceTime: 'Practice time',
        startDate: 'Start date',
        endDate: 'End date',
        months: dayjs().localeData().months(),
        shortMonths: dayjs().localeData().monthsShort(),
        weekdays: dayjs().localeData().weekdays(),
        shortWeekdays: dayjs().localeData().weekdaysShort(),
        week: 'Week',
        shortWeek: 'W',
        lessons: 'Lessons',
        days: 'Days',
        rangeSelectorFrom: 'From',
        rangeSelectorTo: 'To',
        rangeSelectorZoom: 'Zoom',
        zoomOneMonth: '1m',
        zoomTwoMonths: '2m',
        zoomThreeMonths: '3m',
        zoomAll: 'All',
      },
    },
    adminCreateVacancyButton: {
      createVacancy: 'Create new vacancy',
    },
    adminCreatePageButton: {
      createPage: 'Create new page',
    },
    adminCreateCourseButton: {
      createCourse: 'Create new course',
      importCourse: 'Import course',
    },
    adminCreateGroupButton: {
      createGroup: 'Create Group',
    },
    adminEditGroupModal: {
      name: 'name',
      startAt: 'Start At',
      endAt: 'End At',
      submitButton: 'Save',
    },
    adminGroupManage: {
      pageTitle: 'Group',
      startAt: 'Start at',
      endAt: 'End at',
      tabs: {
        internal: 'Members',
        external: 'Add members',
      },
      actions: {
        save: 'Add to group',
        pickAll: 'Pick everyone',
        removeModal: {
          title: 'Remove the user from this group',
          deleteUserModalContent: 'Are you sure you want to remove the user?',
        },
      },
      filters: {
        findByFullNameOrEmail: 'Name or Email',
        resetButton: 'Reset',
        emails: 'Pattern for bulk search: email1@domain.com;another@test.com',
        selected: 'Selected: ',
      },
    },
    adminGroupsTable: {
      groups: 'Groups',
      name: 'name',
      startAt: 'Start At',
      endAt: 'End At',
      deleteGroupModalTitle: 'Delete the group',
      deleteGroupModalContent: 'Are you sure you want to delete the group?',
    },
    adminEditCourseModal: {
      name: 'Title',
      description: 'Description',
      introduction: 'Introduction',
      state: 'State',
      submitButton: 'Save',
      accessDomains: 'Access domains',
      teachers: 'Teachers who can get weekly reminders about waiting students homework',
      states: {
        draft: 'Draft',
        published: 'Published',
        archived: 'Archived',
        internalPublished: 'Internal access',
        sunset: 'Sunset',
        internalPublishedMeaning:
          'Internal access means course only available for students with email domain from access domains list (ex. dualbootpartners.com)',
        sunsetMeaning:
          'Sunset means that course is not available for a new students but students who already joined can finished it',
      },
    },
    adminCoursesTable: {
      courses: 'Courses',
      name: 'Title',
      description: 'Description',
      state: 'State',
      createdAt: 'Created at',
      progress: 'Progress',
      deleteCourseModal: {
        title: 'Delete the course',
        content: 'Are you sure you want to delete the course?',
        failedContent: 'Sorry, we were unable to delete the course',
      },
      filters: {
        name: 'Search by name',
        state: 'State',
        reset: 'Reset',
        states: {
          draft: 'Draft',
          published: 'Published',
          archived: 'Archived',
          internalPublished: 'Internal access',
          sunset: 'Sunset',
        },
      },
    },
    adminEditVacancyModal: {
      title: 'Vacancy: ',
      label: 'Title',
      description: 'Description',
      shortDescription: 'shortDescription',
      state: 'State',
      submitButton: 'Save',
    },
    adminVacanciesTable: {
      vacancies: 'Vacancies',
      title: 'Title',
      description: 'Description',
      state: 'State',
      createdAt: 'Created at',
      deleteVacancyModalTitle: 'Delete the vacancy',
      deleteVacancyModalContent: 'Are you sure you want to delete the vacancy?',
    },
    adminPagesTable: {
      pages: 'Pages',
      name: 'Name',
      slug: 'Slug',
      state: 'State',
      createdAt: 'Created at',
      deleteModalTitle: 'Delete the page',
      deleteModalContent: 'Are you sure you want to delete the page?',
    },
    adminEditPageModal: {
      page: 'Page: ',
      name: 'Name',
      title: 'Title',
      content: 'Content',
      state: 'State',
      submitButton: 'Save',
    },
    adminUserCourse: {
      state: {
        joined: 'Joined',
        completed: 'Completed',
        revoked: 'Revoked',
      },
      join: 'Join to course',
      revoke: 'Revoke access',
    },
    adminLessonsEditPage: {
      toCoursesList: 'To courses list',
      courseLessons: 'Course lessons',
      createLesson: 'Create new lesson',
      deleteLessonModalTitle: 'Delete the lesson',
      deleteLessonModalContent: 'Are you sure you want to delete the lesson?',
    },
    adminLessonsTable: {
      name: 'Title',
      state: 'State',
      requiredTime: 'Days',
      createdAt: 'Created at',
      theories: 'Theories',
      practices: 'Practices',
    },
    adminEditLessonModal: {
      name: 'Title',
      requiredTime: 'Required time (days)',
      state: 'State',
      submitButton: 'Save',
      interviewTrigger: 'Interview is available after acceptance',
      states: {
        draft: 'Draft',
        published: 'Published',
        archived: 'Archived',
        internalPublished: 'Internal access',
      },
    },
    adminExercisesEditPage: {
      toLessonsList: 'To lessons list',
      lessonPractices: 'Lesson practices',
      createPractice: 'Create new practice',
      lessonTheories: 'Lesson theories',
      createTheory: 'Create new theory',
      deleteTheoryModalTitle: 'Delete the theory',
      deleteTheoryModalContent: 'Are you sure you want to delete the theory?',
      deletePracticeModalTitle: 'Delete the practice',
      deletePracticeModalContent: 'Are you sure you want to delete the practice?',
    },
    adminLessonPracticesTable: {
      name: 'Title',
      content: 'Content',
      state: 'State',
      createdAt: 'Created at',
    },
    adminEditLessonPracticeModal: {
      title: 'Practice: ',
      name: 'Title',
      content: 'Content',
      teacherContent: 'Teacher Content',
      state: 'State',
      submitButton: 'Save',
      result: 'A little placeholder for a student. For example, the link to a pull request',
      isResultRequired: 'Is result required?',
      isResultRequiredDescription:
        'The practices marked with this checkbox means that practice expects some result from a student. This is necessary to display the practice correctly',
      isOnlyOnce: "Enable once (if a student mark the practice as done, they can't uncheck it back)",
      states: {
        draft: 'Draft',
        published: 'Published',
        archived: 'Archived',
        internalPublished: 'Internal access',
      },
    },
    adminLessonTheoriesTable: {
      name: 'Title',
      link: 'Link',
      state: 'State',
      createdAt: 'Created at',
      yes: 'Yes',
      not: 'No',
    },
    adminEditLessonTheoryModal: {
      title: 'Theory: ',
      name: 'Title',
      link: 'Link',
      state: 'State',
      submitButton: 'Save',
      isDoc: 'Is it a documentation?',
      isDocDescription:
        'The links marked with this checkbox do not need to be read in whole, this is documentation, student need to look through it and read as necessary and know where it is',
      isLink: 'Is it an external link?',
      isLinkDescription:
        'The links marked with this checkbox means that it is just a link (article, docs, helpful link, etc) without any text on the platform. This is necessary to display the theory correctly',
      isOnlyOnce: "Enable once (if a student mark the theory as read, they can't uncheck it back)",
      states: {
        draft: 'Draft',
        published: 'Published',
        archived: 'Archived',
        internalPublished: 'Internal access',
      },
    },
    adminStats: {
      teachers: 'Teachers',
      courses: 'Courses',
    },
    adminTeacherStats: {
      createdAtGteq: 'From',
      createdAtLteq: 'To',
      teachers: 'Teachers/Mentors/Admins',
      apply: 'Apply',
      reset: 'Reset',
    },
    adminCourseStats: {
      courseName: 'Course Name',
      totalStudents: 'Total Students',
      completedStudents: 'Students with 80% Completed',
      monthActiveStudents: 'Last Month Active Students',
    },
    activityCard: {
      teacherContent: 'Teacher Content',
      content: 'Content',
      reject: 'Reject',
      accept: 'Accept',
      delay: 'Delay',
      userProfile: 'Student Profile',
      rejectWithMessage: 'Reject & Comment',
    },
    studentOptions: {
      teacher: 'Teacher',
      progressVisibility: 'Display progress to all users',
    },
    studentsList: {
      title: 'Students',
    },
    lastActivity: {
      totalCount: 'Left: ',
      sort: 'Sort: ',
      noEvents: 'No new events',
      feedbackConfirmationTitle: 'Reject & Comment',
      feedbackMessage: 'Put your feedback here',
      labelFieldSelectCourse: 'Select course',
      labelFieldSelectGroup: 'Select group',
      labelFieldFindByNameOrEmail: 'Name or Email',
    },
    profile: {
      phone: 'Phone',
      email: 'Email',
      password: 'Password',
      firstName: 'First Name',
      lastName: 'Last Name',
      githubAccount: 'Github-account',
      contact: 'Contact for communication',
      change: 'Change',
      back: 'Back',
      profile: 'Profile',
      editProfile: 'Edit profile',
      save: 'Save',
      emailMessage: 'You have a linked account. Please unlink the account if you want to change your email',
      passwordMessage: 'Google account is unlinked. Please reset your password',
      githubMessage: 'Please complete your Github-account',
      unlinkedGoogle: 'Unlink Google',
      profileUpdatedMessage: 'Your data has been updated',
      contactMessage:
        'Please fill your contact (linkedin, telegram, etc). You can type a few things. It is just a text field',
    },
    error: {
      error404: 'Error 404',
      notFound: 'Page not found',
      error403: 'Error 403',
      forbidden: 'Forbidden',
      notAccess: "You don't have permission to access on this server",
      textHelper: 'Use the navigation bar',
    },
    vacancy: {
      backToList: 'Vacancies',
      subtitle: 'Hi all, we at Dualboot Partners are actively looking for talented engineers',
      summary: {
        about: {
          title: 'What are we doing?',
          content:
            'Dualboot is a business development and software company;\nwe make great software, but our success depends on it helping businesses grow.',
        },
        partners: {
          title: 'Who do we work with?',
          content:
            'Our clients range from founders of tech and non-tech companies to Fortune 500 companies, so we can start small or scale quickly depending on your needs.',
        },
        tech: {
          title: 'Technology partnership',
          content:
            "Sustained customer growth plays a key role in our successful existence as a technology company. We don't form clueless teams of lots of people just because there is a budget, we solve business problems as effectively as possible, as if it were our own product.\n\nWhen we help develop the client effectively, their profits grow, they can invest more in development, and so does our share of the process.",
        },
      },
      cards: {
        feedback: {
          title: 'Apply for the job',
          content:
            "Write to us, even if you don't have any skills yet. We're interested in pros with different backgrounds, and we value colleagues for what they can do well.",
          writeToEmail: 'Write to Email',
        },
        money: {
          title: 'Do you know the candidate for this role?',
          content: "Share the page with him.\n We'd appreciate it!",
        },
      },
    },
    vacancies: {
      title: 'Vacancies',
      subtitle: 'We are regularly looking for cool specialists to join our team',
      cards: {
        feedback: {
          title: 'Not enough skills? No problem!',
          content:
            "Write to us, even if you lack some skills. We're interested in pros with different backgrounds, and we value colleagues for what they can do well.",
          writeToEmail: 'Write to Email',
        },
        money: {
          title: 'Do you know the right candidate?',
          content: "Do you have the right people in mind for our team? Contact us, we'd appreciate it!",
        },
        noVacancies: {
          title: 'Hi there! 👋',
          text: "Right now we don't have any open positions, but don't hesitate to come back and we will surely have something new for you.",
        },
      },
    },
    notes: {
      title: 'Notes',
      create: 'Add note',
      empty: 'No notes',
      modal: {
        create: {
          submit: 'Save note',
        },
        destroy: {
          title: 'Delete note',
          content: 'Are you sure you want to remove the note?',
        },
      },
    },
  },
};
