import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil, isEmpty, trim } from 'ramda';

import { Typography, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import appRoutes from 'routes/appRoutes';

import useCourse from 'sharedContainers/Course';
import CoursePresenter from 'presenters/CoursePresenter';
import Icon from 'components/Icon';

import useStyles from './styles';

const CourseTitle = props => {
  const { courseId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { course, isLoading } = useCourse();
  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  if (isNil(course) || isLoading) {
    return (
      <div className={classes.skeletonBox}>
        <Skeleton variant="text" className={classes.skeletonTitle} height={100} />
      </div>
    );
  }

  return (
    <>
      <Typography className={classes.title}>{CoursePresenter.name(course)}</Typography>

      {!isNil(course.introduction) && !isEmpty(trim(course.introduction)) && (
        <NavLink
          to={appRoutes.courseIntroductionPath(courseId)}
          className={classes.listItem}
          activeClassName={classes.listItemCurrent}
          exact
        >
          <ListItem divider>
            {t('studentLesson.introduction')}
            {matchesMobile && <Icon name="arrowRight" width="6" />}
          </ListItem>
        </NavLink>
      )}
    </>
  );
};

CourseTitle.propTypes = {
  courseId: PropTypes.number.isRequired,
};

export default CourseTitle;
