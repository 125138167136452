import storeModuleCreators from 'utils/store';
import AdminTheoriesRepository from 'repositories/AdminTheoriesRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminTheories'],
  repositoryAction: AdminTheoriesRepository.load,
  responseKey: 'theories',
});

export const createTheory = (courseId, lessonId, theory) => AdminTheoriesRepository.create(courseId, lessonId, theory);

export const updateTheory = (courseId, lessonId, id, theory) =>
  AdminTheoriesRepository.update(courseId, lessonId, id, theory);

export const destroyTheory = (courseId, lessonId, id) => AdminTheoriesRepository.destroy(courseId, lessonId, id);

export const updateTheories = (courseId, lessonId, theories) =>
  AdminTheoriesRepository.updateAll(courseId, lessonId, theories);

export const bulkUpdate = (courseId, lessonId, theories) =>
  AdminTheoriesRepository.bulkUpdate(courseId, lessonId, theories);

export default slice.reducer;
