import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  emailButton: {
    display: 'block',
    textAlign: 'center',
    boxShadow: 'none',
    padding: [14, 32],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
  },
}));

export default useStyles;
