import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';

const PracticeContentSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.practiceContent}>
      <Skeleton variant="text" width="98%" className={classes.contentLine} />
      <Skeleton variant="text" width="100%" className={classes.contentLine} />
      <Skeleton variant="text" width="65%" className={classes.contentLine} />
      <Skeleton variant="text" width="80%" className={classes.contentLine} />
      <Skeleton variant="text" width="95%" className={classes.contentLine} />
      <Skeleton variant="text" width="73%" className={classes.contentLine} />
      <Skeleton variant="text" width="90%" className={classes.contentLine} />
      <Skeleton variant="text" width="98%" className={classes.contentLine} />
      <Skeleton variant="text" width="70%" className={classes.contentLine} />
      <Skeleton variant="text" width="50%" className={classes.contentLine} />
    </div>
  );
};

export default PracticeContentSkeleton;
