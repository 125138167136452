import React from 'react';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmpty, isNil, equals, not, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Button,
  Grid,
  InputBase,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
  Fade,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import UserPresenter from 'presenters/UserPresenter';
import { validationSchema, initialValues } from 'forms/users/profile';
import { isNullOrUndefined, isBlank } from 'utils/helpers';

import useStyles from './styles';

const COUNTRY_CODE = '+';

const UserForm = props => {
  const {
    onSaveProfile,
    currentUser,
    changedPasswordMessage,
    isUnlinkedAccount,
    provider,
    githubAccount,
    isUpdateMessageShown,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const initValues = initialValues(currentUser);
  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: onSaveProfile,
  });

  const { handleSubmit, errors, handleChange, values, isSubmitting } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  const isFormChanged = not(equals(values, initValues));
  const isContactEmpty = isEmpty(prop('contact', initValues));

  const handleBack = () => history.goBack();

  const promptFunc = () => {
    if (isContactEmpty) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.contactPromptMessage'))) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Prompt when={isContactEmpty} message={promptFunc} />
      <Grid container spacing={6}>
        <Grid container item md={6} className={classes.formLayout}>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.firstName)} fullWidth margin="dense">
              <InputLabel classes={{ root: classes.inputLabel }} htmlFor="firstName">
                {t('profile.firstName')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.firstName) }),
                }}
                id="firstName"
                required
                placeholder={t('profile.firstName')}
                name="firstName"
                autoComplete="firstName"
                onChange={handleChange}
                value={values.firstName}
              />
              {!isNullOrUndefined(errors.firstName) && (
                <FormHelperText error id="error-firstName">
                  {t(errors.firstName)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.lastName)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="lastName">
                {t('profile.lastName')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.lastName) }),
                }}
                id="lastName"
                required
                placeholder={t('profile.lastName')}
                name="lastName"
                autoComplete="lastName"
                onChange={handleChange}
                value={values.lastName}
              />
              {!isNullOrUndefined(errors.lastName) && (
                <FormHelperText error id="error-lastName">
                  {t(errors.lastName)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.email)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="email">
                {t('profile.email')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.email) }),
                }}
                id="email"
                required
                placeholder={t('profile.email')}
                name="email"
                autoComplete="email"
                disabled={!isNil(provider)}
                onChange={handleChange}
                value={values.email}
              />
              {!isNullOrUndefined(errors.email) && (
                <FormHelperText error id="error-email">
                  {t(errors.email)}
                </FormHelperText>
              )}
              {!isNil(provider) && (
                <FormHelperText className={classes.success} id="success-password">
                  {t('profile.emailMessage')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.password)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="password">
                {t('profile.password')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, {
                    [classes.error]: !isNullOrUndefined(errors.password),
                  }),
                }}
                id="password"
                type="password"
                required
                placeholder={t('profile.password')}
                name="password"
                onChange={handleChange}
                value={values.password}
              />
              {!isNullOrUndefined(errors.password) && (
                <FormHelperText error id="error-password">
                  {t(errors.password)}
                </FormHelperText>
              )}
              {isEmpty(errors) && (
                <FormHelperText className={classes.success} id="success-password">
                  {changedPasswordMessage}
                </FormHelperText>
              )}
              {isUnlinkedAccount && (
                <FormHelperText className={classes.success} id="success-password">
                  {t('profile.passwordMessage')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.githubAccount)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="githubAccount">
                {t('profile.githubAccount')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.githubAccount) }),
                }}
                id="githubAccount"
                required
                placeholder={t('profile.githubAccount')}
                name="githubAccount"
                autoComplete="githubAccount"
                onChange={handleChange}
                value={values.githubAccount}
              />
              {!isNullOrUndefined(errors.githubAccount) && (
                <FormHelperText error id="error-githubAccount">
                  {t(errors.githubAccount)}
                </FormHelperText>
              )}
              {isNil(githubAccount) && (
                <FormHelperText className={classes.success} id="success-password">
                  {t('profile.githubMessage')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.contact)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="contact">
                {t('profile.contact')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  input: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.contact) }),
                }}
                id="contact"
                placeholder={t('profile.contact')}
                name="contact"
                onChange={handleChange}
                value={values.contact}
              />
              {!isNullOrUndefined(errors.contact) && (
                <FormHelperText error id="error-contact">
                  {t(errors.contact)}
                </FormHelperText>
              )}
              {isBlank(values.contact) && (
                <FormHelperText className={classes.success} id="success-contact">
                  {t('profile.contactMessage')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!isNullOrUndefined(errors.phone)} fullWidth margin="dense">
              <InputLabel className={classes.inputLabel} htmlFor="phone">
                {t('profile.phone')}
              </InputLabel>
              <InputBase
                classes={{
                  root: classes.textField,
                  inputAdornedStart: clsx(classes.input, { [classes.error]: !isNullOrUndefined(errors.phone) }),
                }}
                id="phone"
                placeholder={t('profile.phone')}
                name="phone"
                autoComplete="phone"
                onChange={handleChange}
                value={values.phone}
                startAdornment={
                  <InputAdornment position="start">
                    <Typography className={classes.countryCode}>{COUNTRY_CODE}</Typography>
                  </InputAdornment>
                }
              />
              {!isNullOrUndefined(errors.phone) && (
                <FormHelperText error id="error-phone">
                  {t(errors.phone)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
          {isUpdateMessageShown && (
            <Fade in={isUpdateMessageShown}>
              <Alert severity="success" className={classes.successAlert}>
                {t('profile.profileUpdatedMessage')}
              </Alert>
            </Fade>
          )}
          <Grid container className={classes.buttons}>
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isAbleToSubmit || isUpdateMessageShown || !isFormChanged}
                className={classes.button}
              >
                {t('profile.save')}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={handleBack}
                type="button"
                variant="outlined"
                color="primary"
                fullWidth
                disabled={isSubmitting || isUpdateMessageShown}
                className={classes.button}
              >
                {t('common.back')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

UserForm.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  changedPasswordMessage: PropTypes.string,
  isUnlinkedAccount: PropTypes.bool.isRequired,
  provider: PropTypes.string,
  githubAccount: PropTypes.string,
  isUpdateMessageShown: PropTypes.bool,
};

UserForm.defaultProps = {
  changedPasswordMessage: '',
  provider: null,
  githubAccount: null,
  isUpdateMessageShown: false,
};

export default UserForm;
