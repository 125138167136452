import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createLesson,
  updateLesson,
  updateLessons,
  destroyLesson,
  bulkUpdate,
} from 'store/admin/lessonsSlice';
import { useActions as useShowActions, selectors as showSelectors } from 'store/admin/lessonSlice';

const useLessons = () => {
  const { load: loadAdminLessons, reset: resetAdminLessons, clearLoadingError } = useLoadActions();
  const { load: loadAdminLesson, reset: resetAdminLesson, clearLoadingError: clearShowLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const { getData: getShowData, getIsLoading: getShowIsLoading, getLoadingError: getShowLoadingError } = showSelectors;
  const lessons = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();
  const lesson = getShowData();
  const isShowLoading = getShowIsLoading();
  const loadingShowError = getShowLoadingError();

  return {
    lessons,
    loadAdminLessons,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminLessons,
    lesson,
    loadAdminLesson,
    isShowLoading,
    loadingShowError,
    clearShowLoadingError,
    resetAdminLesson,
    createLesson,
    updateLesson,
    updateLessons,
    destroyLesson,
    bulkUpdate,
  };
};

export default useLessons;
