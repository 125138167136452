export default {
  completed: 'completed',
  rejected: 'rejected',
  uncompleted: 'uncompleted',
};

export const stateEvents = {
  complete: 'complete',
  revoke: 'revoke',
  cancel: 'cancel',
};
