import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    zIndex: 10,
    borderRadius: 4,
    marginBottom: 3,
    '&[x-placement*="bottom"]': {
      boxShadow: '0px -3px 14px 2px rgba(0,0,0,0.2);',
    },
    '&[x-placement*="top"]': {
      boxShadow: '0px 3px 14px 2px rgba(0,0,0,0.2);',
    },
    '&[x-placement*="right"]': {
      boxShadow: '-3px 0px 14px 2px rgba(0,0,0,0.2);',
    },
    '&[x-placement*="left"]': {
      boxShadow: '3px 0px 14px 2px rgba(0,0,0,0.2);',
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  tooltip: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useStyles;
