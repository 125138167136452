import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import clsx from 'clsx';

import {
  Box,
  Button,
  Dialog,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Paper,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { validationSchema, initialValues } from 'forms/admin/createPractice';

import useNumberParams from 'hooks/useNumberParams';
import useRedirects from 'hooks/redirects';

import { isNullOrUndefined } from 'utils/helpers';
import { camelize } from 'utils/strings';
import { mdParser, MdEditor } from 'utils/markdown';

import useStyles from './styles';

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const a11yProps = index => {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
};

const PracticeModal = props => {
  const { practice, nextOrder, isModalOpen, isLoading, onPracticeCreate, onPracticeUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId, lessonId } = useNumberParams(['courseId', 'lessonId']);
  const { redirectToAdminPracticesPath } = useRedirects();
  const [hasChanges, setHasChanges] = useState(false);

  const initValues = initialValues(practice, nextOrder);
  const isPracticeCreated = !isNil(practice);

  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: isPracticeCreated ? onPracticeUpdate : onPracticeCreate,
    enableReinitialize: true,
  });

  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const [selectedTab, setTab] = useState(0);
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);
  const isFieldResultAvailable = values.isResultRequired;

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onbeforeunloadFn = event => {
      if (hasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign, prettier/prettier, no-param-reassign
        return event.returnValue = "";
      }
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [hasChanges]);

  const trackChanges = (fieldName, value) => {
    if (initValues[fieldName] !== value) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleModalClose = () => {
    redirectToAdminPracticesPath(courseId, lessonId);
  };

  const handleSave = () => {
    setHasChanges(false);
    handleSubmit();
  };

  const handleContentChange = ({ text }) => {
    trackChanges('content', text);
    handleChange({ target: { name: 'content', value: text } });
  };

  const handleTeacherContentChange = ({ text }) => {
    trackChanges('teacherContent', text);
    handleChange({ target: { name: 'teacherContent', value: text } });
  };

  const promptFunc = () => {
    if (hasChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.closeConfirm'))) {
        resetForm();
        setHasChanges(false);
        return true;
      }
      return false;
    }
    return true;
  };

  const renderSelectItems = () => {
    return values.availableStates.map(availableState => (
      <MenuItem value={availableState} classes={{ root: classes.state }} key={availableState}>
        {t(`adminEditLessonPracticeModal.states.${camelize(availableState)}`)}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      TransitionComponent={Transition}
    >
      <Prompt when={hasChanges} message={promptFunc} />
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleModalClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('adminEditLessonPracticeModal.title')}
            {values.name}
          </Typography>
          <Button autoFocus color="primary" variant="contained" onClick={handleSave} disabled={!isAbleToSubmit}>
            {t('adminEditLessonPracticeModal.submitButton')}
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <form noValidate onSubmit={handleSave}>
            <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
              <TextField
                required
                name="name"
                label={t('adminEditLessonPracticeModal.name')}
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.name)}
                helperText={t(errors.name)}
              />
              <Paper square>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="content tabs"
                >
                  <Tab label={`${t('adminEditLessonPracticeModal.content')}*`} {...a11yProps(0)} />
                  <Tab label={t('adminEditLessonPracticeModal.teacherContent')} {...a11yProps(1)} />
                </Tabs>
              </Paper>
              <TabPanel
                value={selectedTab}
                index={0}
                classes={{
                  root: clsx(classes.contentBox, { [classes.errorsContentBox]: !isNullOrUndefined(errors.content) }),
                }}
              >
                <MdEditor
                  style={{ height: '400px' }}
                  name="content"
                  value={values.content}
                  renderHTML={text => mdParser.render(text)}
                  onChange={handleContentChange}
                />
                <FormHelperText error>{t(errors.content)}</FormHelperText>
              </TabPanel>
              <TabPanel
                value={selectedTab}
                index={1}
                classes={{
                  root: classes.contentBox,
                }}
              >
                <MdEditor
                  style={{ height: '400px' }}
                  name="teacherContent"
                  value={values.teacherContent}
                  renderHTML={text => mdParser.render(text)}
                  onChange={handleTeacherContentChange}
                />
                <FormHelperText error>{t(errors.teacherContent)}</FormHelperText>
              </TabPanel>
              <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
                <InputLabel id="state">{t('adminEditLessonPracticeModal.state')}</InputLabel>
                <Select
                  labelId="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  label={t('adminEditLessonPracticeModal.state')}
                >
                  {renderSelectItems()}
                </Select>
                <FormHelperText error>{t(errors.state)}</FormHelperText>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.isOnlyOnce} onChange={handleChange} name="isOnlyOnce" />}
                  label={t('adminEditLessonPracticeModal.isOnlyOnce')}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={values.isResultRequired} onChange={handleChange} name="isResultRequired" />
                  }
                  label={t('adminEditLessonPracticeModal.isResultRequired')}
                />
                <FormHelperText>{t('adminEditLessonPracticeModal.isResultRequiredDescription')}</FormHelperText>
              </FormGroup>
              <TextField
                className={!isFieldResultAvailable ? classes.hidden : ''}
                name="result"
                label={t('adminEditLessonPracticeModal.result')}
                value={values.result}
                variant="outlined"
                onChange={handleChange}
              />
            </Box>
          </form>
        )}
      </Box>
    </Dialog>
  );
};

PracticeModal.propTypes = {
  practice: PropTypes.shape(),
  nextOrder: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPracticeCreate: PropTypes.func.isRequired,
  onPracticeUpdate: PropTypes.func.isRequired,
};

PracticeModal.defaultProps = {
  practice: null,
};

export default PracticeModal;
