import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Link from 'components/RouterLink';
import { Typography, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from 'react-i18next';
import appRoutes from 'routes/appRoutes';
import { isEmpty } from 'ramda';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import ResetPasswordRepository from 'repositories/ResetPasswordRepository';
import parseFormErrors from 'utils/parserFormErrors';
import { isUndefined } from 'utils/helpers';
import { validationSchema, initialValues } from 'forms/users/resetPassword';

import SuccessMessage from './components/SuccessMessage';

import useStyles from './styles';

const MODES = {
  SUCCESS: 'success',
  NONE: 'none',
};

const UpdatePassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mode, setMode] = useState(MODES.NONE);

  const { search } = useLocation();
  const { password_reset_token: passwordResetToken } = queryString.parse(search);

  const handleSubmitUpdatePassword = (values, { setErrors }) => {
    return ResetPasswordRepository.updatePassword({
      user: { ...values, passwordResetToken },
    })
      .then(() => {
        setMode(MODES.SUCCESS);
      })
      .catch(error => {
        const errors = parseFormErrors(error, ['password', 'passwordResetToken']);
        const formError = errors.default || errors.passwordResetToken;
        setErrors({ form: formError, password: errors.password });
      });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitUpdatePassword,
  });

  const { handleSubmit, handleChange, errors, values, isSubmitting } = formik;

  const renderUpdateForm = () => (
    <>
      <div className={classes.box}>
        <Link className={classes.link} to={appRoutes.signInPath()}>
          <ArrowBackIcon fontSize="small" />
          <Typography className={classes.textLink}>{t('resetPassword.returnToSignIn')}</Typography>
        </Link>
      </div>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <TextField
          error={!isUndefined(errors.password)}
          helperText={t(errors.password)}
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="password"
          label={t('resetPassword.newPassword')}
          name="password"
          type="password"
          autoComplete="password"
          autoFocus
          onChange={handleChange}
          value={values.password}
        />
        <TextField
          error={!isUndefined(errors.passwordConfirmation)}
          helperText={t(errors.passwordConfirmation)}
          variant="standard"
          margin="normal"
          fullWidth
          required
          id="passwordConfirmation"
          label={t('resetPassword.repeatPassword')}
          name="passwordConfirmation"
          type="password"
          autoComplete="passwordConfirmation"
          onChange={handleChange}
          value={values.passwordConfirmation}
        />
        {errors.form && <Alert severity="error">{errors.form}</Alert>}
        <Button
          type="submit"
          disabled={isSubmitting || !isEmpty(errors)}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          <Typography className={classes.textButton}>{t('resetPassword.savePassword')}</Typography>
        </Button>
      </form>
    </>
  );

  const renderSuccessMessage = () => <SuccessMessage />;

  return mode === MODES.SUCCESS ? renderSuccessMessage() : renderUpdateForm();
};

export default UpdatePassword;
