import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
  },

  listTitle: {
    width: '100%',
    padding: [22, 24, 12, 24],
    fontSize: theme.typography.pxToRem(12),
  },

  listItem: {
    padding: 0,
    flexWrap: 'wrap',
    height: 58,
  },

  divider: {
    borderBottom: `none`,
  },
}));

export default useStyles;
