import { apiV1AdminVacanciesPath, apiV1AdminVacancyPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1AdminVacanciesPath();
    return FetchHelpers.get(path);
  },

  show(id) {
    const path = apiV1AdminVacancyPath(id);
    return FetchHelpers.get(path);
  },

  create(vacancy) {
    const path = apiV1AdminVacanciesPath();
    return FetchHelpers.post(path, { vacancy });
  },

  update(id, vacancy) {
    const path = apiV1AdminVacancyPath(id);
    return FetchHelpers.put(path, { vacancy });
  },

  delete(id) {
    const path = apiV1AdminVacancyPath(id);
    return FetchHelpers.delete(path);
  },
};
