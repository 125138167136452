import {
  apiV1AdminCourseLessonPracticesPath,
  apiV1AdminCourseLessonPracticePath,
  updateAllApiV1AdminCourseLessonPracticesPath,
  bulkUpdateApiV1AdminCourseLessonPracticesPath,
} from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  load(courseId, lessonId) {
    const path = apiV1AdminCourseLessonPracticesPath(courseId, lessonId);
    return FetchHelpers.get(path);
  },

  create(courseId, lessonId, practice) {
    const path = apiV1AdminCourseLessonPracticesPath(courseId, lessonId);
    return FetchHelpers.post(path, { practice });
  },

  update(courseId, lessonId, id, practice) {
    const path = apiV1AdminCourseLessonPracticePath(courseId, lessonId, id);
    return FetchHelpers.put(path, { practice });
  },

  updateAll(courseId, lessonId, practices) {
    const path = updateAllApiV1AdminCourseLessonPracticesPath(courseId, lessonId);
    return FetchHelpers.put(path, { lesson_practices_attributes: practices });
  },

  destroy(courseId, lessonId, id) {
    const path = apiV1AdminCourseLessonPracticePath(courseId, lessonId, id);
    return FetchHelpers.delete(path);
  },

  bulkUpdate(courseId, lessonId, params) {
    const path = bulkUpdateApiV1AdminCourseLessonPracticesPath(courseId, lessonId);
    return FetchHelpers.post(path, params);
  },
};
