import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  largeBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 15,
    width: 30,
    marginBottom: 5,
    borderRight: `1px solid ${theme.palette.common.white}`,
    '&:last-child': {
      borderTopRightRadius: 7,
      borderBottomRightRadius: 7,
    },
    '&:first-child': {
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
    },
  },

  smallBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 8,
    flexShrink: 0,
    flexGrow: 1,
    marginBottom: 5,
    borderRight: `1px solid ${theme.palette.common.white}`,
    '&:last-child': {
      borderTopRightRadius: 3.5,
      borderBottomRightRadius: 3.5,
    },
    '&:first-child': {
      borderTopLeftRadius: 3.5,
      borderBottomLeftRadius: 3.5,
    },
  },

  flatBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 2,
    marginBottom: 0,
    flexShrink: 0,
    flexGrow: 1,
  },

  badgeAcceptedPractice: {
    backgroundColor: theme.palette.primary.main,
  },

  badgeCompletedTheory: {
    backgroundColor: theme.palette.primary.main,
  },

  badgePendingPractice: {
    backgroundColor: theme.lighten(theme.palette.common.brandingBlue, -0.15),
  },

  badgeWarning: {
    backgroundColor: theme.palette.common.red,
  },

  badgeLight: {
    backgroundColor: theme.palette.common.lightBlue,
  },

  list: {
    padding: 0,
  },

  title: {
    fontSize: 12,
    fontWeight: 600,
    padding: 10,
  },
}));

export default useStyles;
