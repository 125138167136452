import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import useRating from 'hooks/useRating';

import useStyles from './styles';

const Rating = props => {
  const { userId, courseId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { rating, isLoading, loadRating, resetRating } = useRating();
  const params = { ratingAttributes: { courseId } };

  useEffect(() => {
    loadRating(userId, params);
    return () => {
      resetRating();
    };
  }, [courseId]);

  if (isNil(rating) || isLoading) {
    return (
      <Typography classes={{ root: classes.ratingBox }}>
        <Skeleton classes={{ root: classes.rating }} width={260} />
      </Typography>
    );
  }

  return (
    <Typography classes={{ root: classes.ratingBox }}>
      {t('studentCard.rating.youScore')}
      <span className={classes.number}>{rating.userCourseRating || 0}</span>
      {t('studentCard.rating.points')}
      <span className={classes.number}>{rating.courseRating}</span>
    </Typography>
  );
};

Rating.propTypes = {
  userId: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
};

export default Rating;
