import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: [32, 16],
    boxShadow: 'none',
    backgroundColor: 'transparent',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.blue7}`,
      borderRadius: 4,
      margin: [42, 0, 42],
      padding: [56, 48],
    },
  },

  logoNameBlock: {
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      flexDirection: 'row',
      gap: 32,
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    marginBottom: 24,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      marginBottom: 40,
    },
  },

  profileInfo: {
    gap: 24,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      gap: 40,
    },
  },

  avatar: {
    height: 112,
    width: 112,
    fontSize: theme.typography.pxToRem(30),
  },

  fullName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
    color: theme.palette.common.brandingBlack,
  },

  divider: {
    color: theme.palette.common.brandingGray3,
    height: 1,
    width: '100%',
  },

  button: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
    padding: [10, 24],
    marginTop: 16,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      padding: [16, 72],
    },
  },
  fullNameBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'auto',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      alignItems: 'flex-start',
    },
  },
}));

export default useStyles;
