import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import TheoryPresenter from 'presenters/User/TheoryPresenter';
import PracticePresenter from 'presenters/User/PracticePresenter';

const JOINED = 'joined';
const COMPLETED = 'completed';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    theoriesCount: PropTypes.number,
    practicesCount: PropTypes.number,
    hoursCount: PropTypes.number,
    userTheories: PropTypes.arrayOf(TheoryPresenter.shape()),
    userPractices: PropTypes.arrayOf(PracticePresenter.shape()),
    firstLessonId: PropTypes.number,
    userState: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.string),
    introduction: PropTypes.string,
  },
  {
    isJoined(course) {
      return this.userState(course) === JOINED;
    },
    isCompleted(course) {
      return this.userState(course) === COMPLETED;
    },
    isStarted(course) {
      return this.userState(course) === (JOINED || COMPLETED);
    },
    approxHoursCount(course) {
      return this.hoursCount(course);
    },
  },
);
