export default {
  pending: 'pending',
  rejected: 'rejected',
  completed: 'completed',
  accepted: 'accepted',
  rollbacked: 'rollbacked',
};

export const stateEvents = {
  reject: 'reject',
  toReview: 'to_review',
  accept: 'accept',
  complete: 'complete',
  rollback: 'rollback',
  draft: 'draft',
};
