import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  practiceContent: {
    marginRight: 52,
  },

  contentLine: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useStyles;
