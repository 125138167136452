import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.common.white2,
    display: 'flex',
    flexDirection: 'column',
    padding: [theme.spacing(4), theme.spacing(2)],

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      flexDirection: 'row',
      padding: 0,
    },
  },

  sidebar: {
    minWidth: theme.sizes.sidebarContentWidth,
    width: '100%',
    background: theme.palette.common.white,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      width: theme.sizes.sidebarContentWidth,
      boxShadow: '0px 6px 40px rgba(112, 144, 176, 0.08)',
      borderRight: `1px solid ${theme.palette.common.gray7}`,
    },
  },

  wrapper: {
    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      padding: '3vw',
    },
    [theme.breakpoints.up(theme.breakpoints.values.tv)]: {
      padding: '3vw 0',
    },
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `4px solid ${theme.palette.common.gray7}`,
    },
  },

  tabItem: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    padding: [16, 72],
    textTransform: 'none',
    color: theme.palette.common.brandingGray1,
    opacity: 1,

    '@media(hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: theme.palette.common.white,
        boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
        borderRadius: '3px',
        color: theme.palette.common.brandingBlue,
      },
    },
  },

  tabSelected: {
    color: theme.palette.common.black1,
    fontWeight: 600,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      color: theme.palette.common.brandingBlack,
      fontWeight: 500,
    },
  },

  indicator: {
    borderBottom: `3px solid ${theme.palette.common.brandingBlue}`,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      borderBottom: `4px solid ${theme.palette.common.brandingBlue}`,
    },
  },

  scrollButton: {
    bottom: '1vw',
    right: '2vw',
    padding: 5,
    minWidth: 'max-content',

    '& .MuiButton-label': {
      width: 24,
    },
  },

  backLink: {
    color: theme.palette.common.brandingGray2,
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0em',
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    marginBottom: 0,

    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },
    '& svg': {
      marginRight: 11,
      width: 16,
      height: 18,
      transform: 'translateY(4px)',
      color: theme.palette.common.black1,
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginBottom: 15,
    },
  },
}));

export default useStyles;
