import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  ratingBox: {
    color: theme.palette.common.brandingBlue,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(23.4),
    letterSpacing: '0.15px',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  number: {
    fontWeight: 600,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontWeight: 700,
    },
  },
  rating: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useStyles;
