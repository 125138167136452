import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';

import VacancyPresenter from 'presenters/VacancyPresenter';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { vacanciesHooks } from 'store/admin/vacanciesSlice';
import { vacancyHooks } from 'store/admin/vacancySlice';

import ModalConfirmation from 'components/ModalConfirmation';
import VacancyModal from './components/VacancyModal';
import Vacancy from './components/Vacancy';

const VacanciesPanel = () => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentVacancy, setCurrentVacancy] = useState(null);

  const { updateVacancy, createVacancy, deleteVacancy } = vacancyHooks.use();

  const {
    firstLoadVacancies: loadVacancies,
    vacancies: { items: vacancies, loadingStatus },
  } = vacanciesHooks.use();

  const { isIdle } = useFetchStatus(loadingStatus);

  useEffect(() => {
    loadVacancies();
  }, []);

  if (isIdle) {
    return (
      <Box p={3}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const handleVacancyModalOpen =
    (vacancyData = null) =>
    () => {
      setModalOpen(true);
      if (vacancyData) {
        setCurrentVacancy(vacancyData);
      }
    };

  const handleVacancyModalClose = () => {
    setModalOpen(false);
    setCurrentVacancy(null);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentVacancy(null);
  };

  const loadVacanciesAndCloseModal = () => {
    loadVacancies();
    handleVacancyModalClose();
  };

  const handleModalOpenDestroy =
    (vacancyData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (vacancyData) {
        setCurrentVacancy(vacancyData);
      }
    };

  const handleVacancyCreate = values => createVacancy(values).then(() => loadVacanciesAndCloseModal());

  const handleVacancyUpdate = values =>
    updateVacancy(currentVacancy.id, values).then(() => loadVacanciesAndCloseModal());

  const handleVacancyDestroy = async () => {
    await deleteVacancy(currentVacancy.id);
    handleModalDeleteClose();
    loadVacancies();
  };

  return (
    <Box p={3}>
      <Button color="primary" variant="outlined" onClick={handleVacancyModalOpen()}>
        {t('adminCreateVacancyButton.createVacancy')}
      </Button>
      <Table aria-label="vacancies table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell>{t('adminVacanciesTable.title')}</TableCell>
            <TableCell>{t('adminVacanciesTable.state')}</TableCell>
            <TableCell>{t('adminVacanciesTable.createdAt')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {vacancies.map(vacancy => (
            <Vacancy
              key={VacancyPresenter.id(vacancy)}
              vacancy={vacancy}
              onVacancyModalOpen={handleVacancyModalOpen}
              onModalOpenDestroy={handleModalOpenDestroy}
            />
          ))}
        </TableBody>
      </Table>
      <VacancyModal
        vacancy={currentVacancy}
        isModalOpen={isModalOpen}
        onVacancyModalClose={handleVacancyModalClose}
        onVacancyCreate={handleVacancyCreate}
        onVacancyUpdate={handleVacancyUpdate}
      />

      <ModalConfirmation
        isOpen={isModalDeleteOpen}
        onClose={handleModalDeleteClose}
        onConfirm={handleVacancyDestroy}
        title={t('adminVacanciesTable.deleteVacancyModalTitle')}
        content={t('adminVacanciesTable.deleteVacancyModalContent')}
      />
    </Box>
  );
};

export default VacanciesPanel;
