import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { pagesHooks } from 'store/admin/pagesSlice';
import { pageHooks } from 'store/admin/pageSlice';
import { calculateNextOrder } from 'utils/calculateNextOrder';

import ModalConfirmation from 'components/ModalConfirmation';
import Modal from './components/Modal';
import Pages from './components/Pages';

const PagesPanel = () => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  const { updatePage, createPage, deletePage } = pageHooks.use();

  const {
    loadPages,
    resetPages,
    pages: { items: pages, loadingStatus },
  } = pagesHooks.use();

  const { isIdle, isPending } = useFetchStatus(loadingStatus);

  useEffect(() => {
    loadPages();

    return () => {
      resetPages();
    };
  }, []);

  if (isIdle || isPending) {
    return (
      <Box p={3}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const initialOrder = 0;
  const nextOrder = isEmpty(pages) ? initialOrder : calculateNextOrder(pages);

  const handleModalOpen =
    (pageData = null) =>
    () => {
      setModalOpen(true);
      if (pageData) {
        setCurrentPage(pageData);
      }
    };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentPage(null);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentPage(null);
  };

  const loadAndCloseModal = () => {
    loadPages();
    handleModalClose();
  };

  const handleModalOpenDestroy =
    (pageData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (pageData) {
        setCurrentPage(pageData);
      }
    };

  const handleCreate = values => createPage(values).then(() => loadAndCloseModal());

  const handleUpdate = values => updatePage(currentPage.id, values).then(() => loadAndCloseModal());

  const handleDestroy = () =>
    deletePage(currentPage.id).then(() => {
      handleModalDeleteClose();
      loadPages();
    });

  return (
    <Box p={3}>
      <Button color="primary" variant="outlined" onClick={handleModalOpen()}>
        {t('adminCreatePageButton.createPage')}
      </Button>

      <Pages pages={pages} onModalOpen={handleModalOpen} onModalOpenDestroy={handleModalOpenDestroy} />

      <Modal
        page={currentPage}
        nextOrder={nextOrder}
        isModalOpen={isModalOpen}
        onModalClose={handleModalClose}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />

      <ModalConfirmation
        isOpen={isModalDeleteOpen}
        onClose={handleModalDeleteClose}
        onConfirm={handleDestroy}
        title={t('adminPagesTable.deleteModalTitle')}
        content={t('adminPagesTable.deleteModalContent')}
      />
    </Box>
  );
};

export default PagesPanel;
