import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    slug: PropTypes.string,
    state: PropTypes.string,
    createdAt: PropTypes.string,
    availableStates: PropTypes.arrayOf(PropTypes.string),
    order: PropTypes.number,
  },
  {},
);
