/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function loadResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        loadingStatus: FETCH_STATUSES.idle,
        items: null,
        meta: {},
        error: null,
      },
    },
    reducers: {
      loadResourcesInit(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.idle;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
      },
      loadResourcesStart(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.pending;
        state[pluralResource].error = null;
      },
      loadResourcesSuccess(state, { payload: { items, meta } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[pluralResource].items = items;
        state[pluralResource].meta = meta;
      },
      loadResourcesFail(state, { payload: { error } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
      loadResourcesReset(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.idle;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
      },
    },
    actionCreators(restDispatch) {
      return {
        firstLoadResources: params => {
          restDispatch('loadResourcesInit');

          return repository
            .index(params)
            .then(({ data }) => {
              restDispatch('loadResourcesSuccess', {
                items: data[pluralResource],
                meta: data.meta,
              });
              return data;
            })
            .catch(error => {
              restDispatch('loadResourcesFail', { error });
              throw error;
            });
        },
        loadResources: params => {
          restDispatch('loadResourcesStart');

          return repository
            .index(params)
            .then(({ data }) => {
              restDispatch('loadResourcesSuccess', {
                items: data[pluralResource],
                meta: data.meta,
              });
              return data;
            })
            .catch(error => {
              restDispatch('loadResourcesFail', { error });
              throw error;
            });
        },
        resetResources: () => restDispatch('loadResourcesReset'),
      };
    },
    abstractSelector: state => {
      return {
        resources: state[pluralResource],
      };
    },
  };
}
