import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isEmpty, head, map, split } from 'ramda';

import { Typography, TextField, Box, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import ResetPasswordRepository from 'repositories/ResetPasswordRepository';
import parseFormErrors from 'utils/parserFormErrors';
import { validationSchema, initialValues } from 'forms/users/forgotPassword';
import Link from 'components/RouterLink';

import appRoutes from 'routes/appRoutes';

import useStyles from './styles';

const ForgotPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEmailSent, setEmailSent] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email }, { setErrors }) => {
      return ResetPasswordRepository.resetPassword({ user: { email } })
        .then(() => {
          setEmailSent(true);
        })
        .catch(error => {
          setEmailSent(false);
          const errors = parseFormErrors(error, 'email');
          const formError = errors.default || errors.email;
          setErrors({ form: formError });
        });
    },
  });
  const { handleSubmit, handleChange, errors, values, isSubmitting } = formik;

  const renderErrorMessage = () => {
    const splittedErrorMessage = split('\n', head(errors.form));
    return map(paragraph => {
      return (
        <Box m={0} key={paragraph}>
          {paragraph}
        </Box>
      );
    }, splittedErrorMessage);
  };

  return (
    <Box className={classes.box}>
      <Link className={classes.link} to={appRoutes.signInPath()}>
        <ArrowBackIcon fontSize="small" />
        <Typography className={classes.textLink}>{t('resetPassword.returnToSignIn')}</Typography>
      </Link>
      <Typography className={classes.text} variant="body1">
        {t('forgotPassword.enterYourEmail')}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          value={values.email}
          autoFocus
        />
        {isEmailSent && <Alert severity="success">{t('forgotPassword.emailSent')}</Alert>}
        {errors.form && (
          <Alert severity="error" classes={{ message: classes.alertMessage, icon: classes.alertIcon }}>
            {renderErrorMessage()}
          </Alert>
        )}
        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isSubmitting || !isEmpty(errors) || isEmailSent || isEmpty(values.email)}
        >
          <Typography className={classes.textButton}>{t('forgotPassword.sentEmail')}</Typography>
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
