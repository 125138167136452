/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import LessonsRepository from 'repositories/LessonsRepository';

const sliceName = 'lesson';

const slice = createSlice({
  name: sliceName,
  initialState: {
    data: {},
    meta: {},
    processing: false,
    processingError: null,
  },
  reducers: {
    loadLessonStart(state) {
      state.processingError = null;
      state.processing = true;
    },
    loadLessonFail(state, { payload }) {
      state.processingError = payload.error;
    },
    clearLessonProcessingError(state) {
      state.processingError = null;
    },
    loadLessonSuccess(state, { payload }) {
      state.data = payload.lesson;
      state.meta = payload.meta;
      state.processingError = null;
    },
    loadLessonFinish(state) {
      state.processing = false;
    },
    resetLessonData(state) {
      state.data = [];
    },
  },
});

const {
  loadLessonStart,
  loadLessonFail,
  clearLessonProcessingError,
  loadLessonSuccess,
  loadLessonFinish,
  resetLessonData,
} = slice.actions;

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    loadLesson: (courseId, lessonId) => {
      dispatch(loadLessonStart());
      return LessonsRepository.show(courseId, lessonId)
        .then(({ data }) => {
          dispatch(loadLessonSuccess(data));
        })
        .catch(error => {
          dispatch(loadLessonFail({ error: error.message }));

          return Promise.reject(error);
        })
        .finally(() => dispatch(loadLessonFinish()));
    },
    clearProcessingError: () => {
      dispatch(clearLessonProcessingError());
    },
    resetLesson: () => {
      dispatch(resetLessonData());
    },
  };
};

export default slice.reducer;
