/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import PagesRepository from 'repositories/PagesRepository';

import { createRestSlice } from 'utils/RestSlice';

const PagesRestSlice = createRestSlice({
  resource: 'page',
  repository: PagesRepository,
  slices: ['loadPage'],
});

const initialState = {
  ...PagesRestSlice.initialState,
};

const PageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    ...PagesRestSlice.reducers,
  },
});

export default PageSlice.reducer;

export const pageHooks = PagesRestSlice.hooks(PageSlice, 'page');
