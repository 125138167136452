import { useSelector } from 'react-redux';

import { useActions } from 'store/practiceSlice';

const sliceName = 'practice';

export default () => {
  const practiceActions = useActions();
  const loadPractice = (lessonId, practiceId) => practiceActions.loadPractice(lessonId, practiceId);
  const clearProcessingError = () => practiceActions.clearProcessingError();
  const resetPractice = () => practiceActions.resetPractice();

  const practice = useSelector(state => state[sliceName].data);
  const meta = useSelector(state => state[sliceName].meta);
  const processing = useSelector(state => state[sliceName].processing);
  const processingError = useSelector(state => state[sliceName].processingError);

  return {
    loadPractice,
    clearProcessingError,
    resetPractice,
    practice,
    meta,
    processing,
    processingError,
  };
};
