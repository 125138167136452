import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    textDecoration: 'none',
  },

  textLink: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.brandingBlue,
  },

  textButton: {
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(21),
  },

  button: {
    width: '100%',
    padding: [14, 32],
    fontSize: theme.typography.pxToRem(16),
  },

  icon: {
    height: 21,
    width: 22,
  },
  form: {
    width: '100%',
    '& .MuiFormControl-root': {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.common.brandingBlack,
      '& .MuiInputLabel-formControl': {
        lineHeight: theme.typography.pxToRem(21),
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.common.brandingBlack,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, -5px)',
      },
      '&:hover': {
        '& .MuiInput-underline': {
          '&:hover': {
            '&:before': {
              borderBottom: `2px solid ${theme.palette.common.brandingBlue}`,
            },
            '&:after': {
              borderBottom: `2px solid ${theme.palette.common.brandingBlue}`,
            },
          },
        },
      },
    },
    '& .MuiInputBase-input': {
      lineHeight: theme.typography.pxToRem(24),
      padding: [13, 17],
    },
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: 'none',
      },
      '&:before': {
        borderBottom: `1px solid ${theme.palette.common.gray2}`,
        transition: 'none',
      },
      '&:active:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
      '&:hover:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
    '& .MuiInput-underline.Mui-error': {
      borderBottom: '1px solid #EF4444',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))',
      '&:after': {
        borderBottomColor: 'transparent',
      },
      '&:before': {
        border: 'none',
      },
    },
  },

  formInputs: {
    padding: [45, 0, 40],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '30px',
  },

  separator: {
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: `${theme.palette.common.brandingGray2}`,
    width: '100%',
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.common.blue5}`,
    },
    '&::before': {
      marginRight: '.65em',
    },
    '&::after': {
      marginLeft: '.65em',
    },
  },

  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    width: '100%',
    marginTop: 24,
  },
}));

export default useStyles;
