import React from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import useCourse from 'sharedContainers/Course';

import CoursePresenter from 'presenters/CoursePresenter';

import MarkdownContent from 'components/MarkdownContent';
import useStyles from './styles';

const CourseIntroduction = () => {
  const { course, isLoading } = useCourse();
  const { t } = useTranslation();
  const classes = useStyles();

  if (isNil(course) || isLoading) {
    return (
      <>
        <Skeleton variant="rect" height={30} component="h5" />
        <Skeleton variant="rect" height={100} component="div" />
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom className={classes.title}>
        {t('studentLesson.introduction')}
      </Typography>
      <div className={classes.container}>
        <Box p={3}>
          <MarkdownContent content={CoursePresenter.introduction(course)} className={classes.markdown} />
        </Box>
      </div>
    </>
  );
};

export default CourseIntroduction;
