import React, { useState, useRef } from 'react';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useTheme, useMediaQuery } from '@material-ui/core';

import UserPresenter from 'presenters/UserPresenter';

import useStyles from './styles';

const CourseProgressChart = props => {
  const { chartData, isLoading, user } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const [tooltipDate, setTooltipDate] = useState(null);
  const [tooltipAchievement, setTooltipAchievement] = useState(null);
  const chartBlockRef = useRef(null);

  if (isNil(chartData) || isLoading) {
    return (
      <Paper elevation={0} classes={{ root: classes.chartContainer, outlined: classes.outlined }}>
        <Box display="flex" justifyContent="center" alignItems="center" classes={{ root: classes.chartSkeletonBox }}>
          {t('chart.loading')}
        </Box>
      </Paper>
    );
  }

  const { openedExercisesCountByDate, guideline, courseStartDate, courseExercisesCount } = chartData;

  const userSerie = {
    color: theme.palette.common.burgundy,
    name: UserPresenter.fullName(user),
    data: openedExercisesCountByDate,
  };
  const guidelineSerie = {
    color: theme.palette.common.brandingGray3,
    dashStyle: 'LongDash',
    name: 'Guideline',
    data: guideline,
  };
  const chartSeries = [userSerie, guidelineSerie];

  const getDate = date => {
    const currentDate = new Date(date);
    return format(currentDate, 'yyyy.MM.dd');
  };

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'Inter',
      },
      height: 285,
      type: 'line',
    },
  });

  const DEFAULT_X_POSITION = 0;
  const HALF = 1 / 2;

  const chartBlockWidth = !isNil(chartBlockRef.current) && chartBlockRef.current.offsetWidth;
  const halfChartBlockWidth = chartBlockWidth * HALF;
  const legendXPosition = matchesMobile ? `-${halfChartBlockWidth}px` : DEFAULT_X_POSITION;

  const options = {
    title: {
      text: '',
    },

    legend: {
      layout: 'vertical',
      align: matchesMobile ? 'center' : 'right',
      verticalAlign: 'bottom',
      x: legendXPosition,
      itemMarginBottom: 12,

      itemStyle: {
        fontWeight: 'regular',
        color: theme.palette.common.brandingBlack,
        fontSize: theme.typography.pxToRem(14),
      },
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
    },

    tooltip: {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      borderRadius: 10,
      borderWidth: 5,
      xDateFormat: `<b>${t('chart.date')}: %Y.%m.%d </b>`,
      pointFormat: `<b>${t('chart.academicAchievement')}: {point.y}/${courseExercisesCount} </b>`,
      style: {
        fontSize: 12,
      },
      className: classes.highchartsTooltip,
      formatter: matchesMobile
        ? function formatter(tooltip) {
            const chosenOptionDate = tooltip.chart.hoverPoint.options.x;
            const chosenOptionAchievement = tooltip.chart.hoverPoint.options.y;
            setTooltipDate(getDate(chosenOptionDate));
            setTooltipAchievement(`${chosenOptionAchievement}/${courseExercisesCount}`);
          }
        : undefined,
    },

    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: 'white',
          lineWidth: 2,
          lineColor: theme.palette.common.brandingBlue,
          radius: 4,
        },
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0,
            },
          },
        },
        pointStart: courseStartDate,
      },
    },

    credits: {
      enabled: false,
    },

    yAxis: {
      labels: {
        style: {
          color: theme.palette.common.gray3,
          fontSize: 13,
        },
      },
      gridLineWidth: 0,
      endOnTick: false,
      title: {
        text: '',
      },
    },

    xAxis: {
      labels: {
        style: {
          color: theme.palette.common.gray3,
          fontSize: 13,
        },
      },
      tickPosition: 'inside',
      gridLineWidth: 1,
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b',
      },
    },

    series: chartSeries,
  };

  return (
    <Paper ref={chartBlockRef} elevation={0} classes={{ root: classes.chartContainer }}>
      {matchesMobile && (
        <div className={classes.outsideTooltip}>
          {tooltipDate && tooltipAchievement && (
            <>
              <p className={classes.tooltipData}>
                {t('chart.date')}:<b>{tooltipDate}</b>
              </p>
              <p className={classes.tooltipData}>
                {t('chart.academicAchievement')}:<b>{tooltipAchievement}</b>
              </p>
            </>
          )}
        </div>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Paper>
  );
};

CourseProgressChart.propTypes = {
  chartData: PropTypes.shape(),
  isLoading: PropTypes.bool,
  user: PropTypes.shape().isRequired,
};

CourseProgressChart.defaultProps = {
  chartData: null,
  isLoading: false,
};

export default CourseProgressChart;
