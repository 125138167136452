import storeModuleCreators from 'utils/store';
import AdminPracticesRepository from 'repositories/AdminPracticesRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminPractices'],
  repositoryAction: AdminPracticesRepository.load,
  responseKey: 'practices',
});

export const createPractice = (courseId, lessonId, practice) =>
  AdminPracticesRepository.create(courseId, lessonId, practice);
export const updatePractice = (courseId, lessonId, id, practice) =>
  AdminPracticesRepository.update(courseId, lessonId, id, practice);
export const destroyPractice = (courseId, lessonId, id) => AdminPracticesRepository.destroy(courseId, lessonId, id);
export const updatePractices = (courseId, lessonId, practices) =>
  AdminPracticesRepository.updateAll(courseId, lessonId, practices);
export const bulkUpdate = (courseId, lessonId, practices) =>
  AdminPracticesRepository.bulkUpdate(courseId, lessonId, practices);

export default slice.reducer;
