import storeModuleCreators from 'utils/store';
import AdminCoursesRepository from 'repositories/AdminCoursesRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminCourses'],
  repositoryAction: AdminCoursesRepository.load,
  responseKey: 'courses',
});

export const createCourse = course => AdminCoursesRepository.create(course);
export const importCourse = file => AdminCoursesRepository.import(file);
export const updateCourse = (id, course) => AdminCoursesRepository.update(id, course);
export const destroyCourse = id => AdminCoursesRepository.destroy(id);
export const updateCourses = courses => AdminCoursesRepository.updateAll(courses);

export default slice.reducer;
