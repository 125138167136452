/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function updateResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        updatingStatus: FETCH_STATUSES.idle,
        errors: null,
      },
    },
    reducers: {
      updateResourceStart(state) {
        state[resource].updatingStatus = FETCH_STATUSES.pending;
        state[resource].errors = null;
      },
      updateResourceSuccess(state) {
        state[resource].updatingStatus = FETCH_STATUSES.fulfilled;
      },
      updateResourceFail(state, { payload: { errors } }) {
        state[resource].updatingStatus = FETCH_STATUSES.failed;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        updateResource: (...params) => {
          restDispatch('updateResourceStart');

          return repository
            .update(...params)
            .then(({ data }) => restDispatch('updateResourceSuccess', data))
            .catch(errors => {
              restDispatch('updateResourceFail', { errors });
              throw errors;
            });
        },
      };
    },
    abstractSelector: state => {
      return {
        resource: state[resource],
      };
    },
  };
}
