import { useSelector } from 'react-redux';

import { useActions } from 'store/lessonSlice';

const sliceName = 'lesson';

export default () => {
  const lessonActions = useActions();
  const loadLesson = (courseId, lessonId) => {
    lessonActions.loadLesson(courseId, lessonId);
  };
  const clearProcessingError = () => lessonActions.clearProcessingError();
  const resetLesson = () => lessonActions.resetLesson();

  const lesson = useSelector(state => state[sliceName].data);
  const meta = useSelector(state => state[sliceName].meta);
  const processing = useSelector(state => state[sliceName].processing);
  const processingError = useSelector(state => state[sliceName].processingError);

  return {
    loadLesson,
    clearProcessingError,
    resetLesson,
    lesson,
    meta,
    processing,
    processingError,
  };
};
