const object = {
  draft: 'draft',
  published: 'published',
  archived: 'archived',
  internalPublished: 'internal_published',
  sunset: 'sunset',
};

export const courseStatesList = [
  object.published,
  object.archived,
  object.draft,
  object.internalPublished,
  object.sunset,
];

export default object;
