import React, { useEffect } from 'react';

import { Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useNumberParams from 'hooks/useNumberParams';
import useCourse from 'sharedContainers/Course';
import useProgressDiagramData from 'hooks/useProgressDiagramData';
import useCurrentUser from 'hooks/useCurrentUser';
import UserPresenter from 'presenters/UserPresenter';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { lessonsHooks } from 'store/user/lessonsSlice';
import CourseProgressChart from './components/CourseProgressChart';
import StudentProgressDetailedCard from './components/StudentProgressDetailedCard';

import useStyles from './styles';

const CoursesStudentProgressOverview = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesLaptop;

  const { currentUser } = useCurrentUser();
  const { courseId } = useNumberParams(['courseId']);
  const {
    loadLessons: loadUserLessons,
    lessons: { items: userLessons, loadingStatus },
  } = lessonsHooks.use();

  const { isFulfilled } = useFetchStatus(loadingStatus);

  const { loadCourse, course, isLoading: isCourseLoading, resetCourse } = useCourse();
  const {
    progressDiagramData,
    isLoading: isProgressDiagramDataLoading,
    loadProgressDiagramData,
    resetProgressDiagramData,
  } = useProgressDiagramData();
  const params = { progressAttributes: { courseId } };

  useEffect(() => {
    loadProgressDiagramData(UserPresenter.id(currentUser), params);
    return () => {
      resetProgressDiagramData();
    };
  }, [courseId]);

  useEffect(() => {
    loadUserLessons(courseId);
  }, [courseId]);

  useEffect(() => {
    loadCourse(courseId);
    return () => {
      resetCourse();
    };
  }, [courseId]);

  return (
    <Container component="main" classes={{ root: classes.rootContainer }}>
      <Grid container classes={{ root: classes.gridContainer }}>
        <Grid item xs={12}>
          {matchesMobile ? (
            <>
              <Typography variant="h1" className={classes.title}>
                {t('header.progress')}
              </Typography>
              <StudentProgressDetailedCard
                courseId={courseId}
                course={course}
                isCourseLoading={isCourseLoading}
                userLessons={userLessons}
                isUserLessonsLoading={!isFulfilled}
              />
            </>
          ) : (
            <Paper elevation={0} classes={{ outlined: classes.outlined }}>
              <StudentProgressDetailedCard
                courseId={courseId}
                course={course}
                isCourseLoading={isCourseLoading}
                userLessons={userLessons}
                isUserLessonsLoading={!isFulfilled}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12}>
          <CourseProgressChart
            chartData={progressDiagramData}
            isLoading={isProgressDiagramDataLoading}
            user={currentUser}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoursesStudentProgressOverview;
