import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { ListItem, Typography, Divider } from '@material-ui/core';

import CheckboxOutlineBlank from 'components/CheckboxOutlineBlank';

import { userTheoryHooks } from 'store/user/theorySlice';
import { userTheoriesHooks } from 'store/user/theoriesSlice';
import { lessonsHooks } from 'store/user/lessonsSlice';

import { stateEvents } from 'utils/userTheoryStates';
import { useFetchStatus } from 'utils/fetchStatusUtils';

import TheoryPresenter from 'presenters/User/TheoryPresenter';

import appRoutes from 'routes/appRoutes';

import useStyles from './styles';

const TheoryItem = props => {
  const { courseId, lessonId, userTheory, theoryId, isAdminView, children } = props;
  const {
    createTheory,
    updateTheory,
    theory: { updatingStatus },
  } = userTheoryHooks.use();
  const {
    loadTheories,
    theories: { loadingStatus },
  } = userTheoriesHooks.use();
  const { loadLessons: loadUserLessons } = lessonsHooks.use();
  const classes = useStyles();

  const { isPending: isLoadingStatusPending } = useFetchStatus(updatingStatus);
  const { isPending } = useFetchStatus(loadingStatus);

  const isCompleted = TheoryPresenter.isCompleted(userTheory);
  const isRejected = TheoryPresenter.isRejected(userTheory);

  const updateUserTheoriesAndUserLessons = () => {
    loadTheories(lessonId);
    loadUserLessons(courseId);
  };

  const handleTheoryChange = (id, userState) => async e => {
    const stateEvent = e.target.checked ? stateEvents.complete : stateEvents.cancel;
    if (userState) {
      await updateTheory(id, { stateEvent });
    } else {
      await createTheory(id);
    }
    updateUserTheoriesAndUserLessons();
  };

  const isCurrent = TheoryPresenter.isCurrent(userTheory, theoryId);
  const theoryItemClasses = clsx(classes.theoryItem, { [classes.theoryItemCurrent]: isCurrent });
  const isOnlyOnce = isCompleted && TheoryPresenter.isOnlyOnce(userTheory);

  return (
    <>
      <ListItem className={theoryItemClasses}>
        {!isAdminView && (
          <Link
            to={appRoutes.courseLessonTheoryPath(courseId, lessonId, TheoryPresenter.id(userTheory))}
            className={classes.link}
          />
        )}
        <CheckboxOutlineBlank
          checked={isCompleted}
          onChange={handleTheoryChange(TheoryPresenter.id(userTheory), TheoryPresenter.userState(userTheory))}
          dangerUnchecked={isRejected}
          disabled={isPending || isLoadingStatusPending || isAdminView || isOnlyOnce}
          aria-label="Mark completed"
        />
        <Typography className={classes.theoryName}>{TheoryPresenter.nameWithDoc(userTheory)}</Typography>
        {children}
      </ListItem>
      <Divider />
    </>
  );
};

TheoryItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  theoryId: PropTypes.number,
  userTheory: PropTypes.shape().isRequired,
  isAdminView: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

TheoryItem.defaultProps = {
  theoryId: null,
  isAdminView: false,
  children: null,
};

export default TheoryItem;
