import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    lineHeight: theme.typography.pxToRem(36),
    fontWeight: 700,

    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      margin: [24, 0, 24],
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: 600,
    },
  },
}));

export default useStyles;
