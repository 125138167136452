import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [theme.spacing(4), theme.spacing(2)],
    maxWidth: '100%',
  },
  backdrop: {
    zIndex: 999,
  },

  form: {
    display: 'grid',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      width: 'auto',
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      width: '1100px',
      margin: [0, 'auto'],
    },
  },

  paper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(2),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      width: 'auto',
      columnGap: 20,
      rowGap: 26,
      gridTemplateColumns: 'repeat(2, 1fr)',
      padding: `24px 0 ${theme.sizes.headerHeight}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      width: '1100px',
      margin: [0, 'auto'],
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    textAlign: 'left',
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    margin: [0, 'auto', 24, 0],
    width: '100%',
  },
}));

export default useStyles;
