import storeModuleCreators from 'utils/store';
import AdminUserCourseRepository from 'repositories/User/AdminUserCourseRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminUserCourses'],
  repositoryAction: AdminUserCourseRepository.load,
  responseKey: 'userCourses',
});

export const createAdminUserCourse = (userId, userCourse) => AdminUserCourseRepository.create(userId, userCourse);
export const updateAdminUserCourse = (userId, id, userCourse) =>
  AdminUserCourseRepository.update(userId, id, userCourse);

export default slice.reducer;
