import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';

import appRoutes from 'routes/appRoutes';

import logo from 'assets/dualboot-logo.svg';

import useStyles from './styles';

const Logo = props => {
  const { isDividerShown } = props;
  const classes = useStyles();
  const theme = useTheme();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  return (
    <div className={classes.imageContainer}>
      <NavLink to={appRoutes.rootPath()}>
        {matchesMobile && <img src={logo} alt="logo dualboot partners" height="34" width="134" />}
        {matchesDesktop && <img src={logo} alt="logo dualboot partners" height="35" width="206" />}
      </NavLink>
      {isDividerShown && <Divider classes={{ root: classes.divider }} orientation="vertical" variant="middle" />}
    </div>
  );
};

Logo.propTypes = {
  isDividerShown: PropTypes.bool.isRequired,
};

export default Logo;
