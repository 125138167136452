import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import PagePresenter from 'presenters/PagePresenter';

import { pagesHooks } from 'store/admin/pagesSlice';

import useStyles from './styles';

const Pages = props => {
  const { pages, onModalOpen, onModalOpenDestroy } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { updateAllPages } = pagesHooks.use();

  const [sortedList, setSortedList] = useState(pages);
  const [widths, setWidths] = useState([]);

  const handleChange = ({ oldIndex, newIndex }) => {
    const newList = arrayMove(sortedList, oldIndex, newIndex);
    const newOrder = newList.map((course, index) => ({ id: course.id, order: index }));
    setSortedList(newList);
    updateAllPages(newOrder);
  };

  return (
    <List
      lockVertically
      beforeDrag={({ elements, index }) => {
        const cells = Array.from(elements[index].children);
        const cellsWidths = cells.map(cell => window.getComputedStyle(cell).width);
        setWidths(cellsWidths);
      }}
      values={sortedList}
      onChange={handleChange}
      renderList={({ children, props: renderListProps }) => (
        <Table aria-label="courses table">
          <TableHead>
            <TableRow>
              <TableCell>{t('adminPagesTable.name')}</TableCell>
              <TableCell>{t('adminPagesTable.slug')}</TableCell>
              <TableCell>{t('adminPagesTable.state')}</TableCell>
              <TableCell>{t('adminPagesTable.createdAt')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...renderListProps}>{children}</TableBody>
        </Table>
      )}
      renderItem={({ value, props: renderItemProps, isDragged }) => {
        const tdWidths = isDragged ? widths : [];
        const rootClasses = clsx(classes.tableRow, classes.cursor);

        const row = (
          <TableRow
            {...renderItemProps}
            style={{
              ...renderItemProps.style,
            }}
            classes={{ root: rootClasses }}
          >
            <TableCell style={{ width: tdWidths[1] }} classes={{ root: classes.tableCell }}>
              {PagePresenter.name(value)}
            </TableCell>
            <TableCell style={{ width: tdWidths[2] }} classes={{ root: classes.tableCell }}>
              {PagePresenter.slug(value)}
            </TableCell>
            <TableCell style={{ width: tdWidths[3] }} classes={{ root: classes.tableCell }}>
              {PagePresenter.state(value)}
            </TableCell>
            <TableCell style={{ width: tdWidths[4] }} classes={{ root: classes.tableCell }}>
              {PagePresenter.createdAt(value)}
            </TableCell>
            <TableCell align="right" style={{ width: tdWidths[5] }} classes={{ root: classes.tableCell }}>
              <IconButton onClick={onModalOpen(value)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <IconButton onClick={onModalOpenDestroy(value)}>
                <DeleteIcon color="primary" fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        );
        return isDragged ? (
          <Table>
            <TableBody>{row}</TableBody>
          </Table>
        ) : (
          row
        );
      }}
    />
  );
};

Pages.propTypes = {
  pages: PropTypes.arrayOf(PagePresenter.shape()).isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
};

export default Pages;
