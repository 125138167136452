import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@material-ui/core';

import useStyles from './styles';

const Summary = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent className={classes.layout}>
        <section className={classes.about}>
          <h3 className={classes.heading}>
            <span role="img" aria-label="ok">
              👌
            </span>
            {t('vacancy.summary.about.title')}
          </h3>
          <p className={classes.text}>{t('vacancy.summary.about.content')}</p>
        </section>

        <section className={classes.partners}>
          <h3 className={classes.heading}>
            <span role="img" aria-label="office">
              🏢
            </span>
            {t('vacancy.summary.partners.title')}
          </h3>
          <p className={classes.text}>{t('vacancy.summary.partners.content')}</p>
        </section>

        <section className={classes.tech}>
          <h3 className={classes.heading}>
            <span role="img" aria-label="laptop">
              💻
            </span>
            {t('vacancy.summary.tech.title')}
          </h3>
          <p className={classes.text}>{t('vacancy.summary.tech.content')}</p>
        </section>
      </CardContent>
    </Card>
  );
};

export default Summary;
