import * as yup from 'yup';

import NotePresenter from 'presenters/NotePresenter';

const validationFields = {
  message: yup.string().required('validations.note.message.required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = note => ({
  message: NotePresenter.message(note) || '',
});
