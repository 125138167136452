import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import TeacherPresenter from './TeacherPresenter';

export default new Presenter({
  id: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  message: PropTypes.string,
  teacher: TeacherPresenter.shape(),
});
