import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import authModes, { isSignIn, isSignUp, isForgotPassword, isUpdatePassword } from 'utils/authModes';

import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import ForgotPassword from './containers/ForgotPassword';
import UpdatePassword from './containers/UpdatePassword';

import useStyles from './styles';

const Authenticator = props => {
  const { mode } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.cardContent}>
        {isSignIn(mode) && <SignIn />}
        {isSignUp(mode) && <SignUp />}
        {isForgotPassword(mode) && <ForgotPassword />}
        {isUpdatePassword(mode) && <UpdatePassword />}
      </CardContent>
    </Card>
  );
};

Authenticator.propTypes = {
  mode: PropTypes.oneOf(Object.values(authModes)).isRequired,
};

export default Authenticator;
