import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { split, map, includes, length, isEmpty } from 'ramda';

import {
  Grid,
  TableHead,
  TableRow,
  Link as MaterialLink,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  Typography,
  TableSortLabel,
} from '@material-ui/core';
import { Group, Delete } from '@material-ui/icons';

import TablePaginationActions from 'components/TablePaginationActions';
import CheckboxOutlineBlank from 'components/CheckboxOutlineBlank';
import { USERS_SORT_VARIANTS } from 'utils/constants';
import { truncate } from 'utils/strings';
import appRoutes from 'routes/appRoutes';
import UserPresenter from 'presenters/UserPresenter';
import useCurrentUser from 'hooks/useCurrentUser';

const BaseView = props => {
  const {
    users,
    meta: { totalCount, currentPage },
    onPageChange,
    onRowsPerPageChange,
    rowsPerPage,
    onImpersonate,
    onModalOpenDestroy,
    isSelectMode,
    isCourseChosen,
    selectedUsers,
    onSelect,
    onSelectAll,
    currentSort,
    onFiltersChange,
  } = props;
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  const [currentSortProperty, currentSortOrder] = split(' ', currentSort);

  const handleRequestSort = (event, property) => {
    const newOrder = currentSortProperty === property && currentSortOrder === 'asc' ? 'desc' : 'asc';
    onFiltersChange({ target: { name: 's', value: `${property} ${newOrder}` } });
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {isSelectMode && (UserPresenter.hasAdminAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
            <TableCell padding="none">
              <CheckboxOutlineBlank
                indeterminate={length(selectedUsers) > 0 && length(selectedUsers) < length(users)}
                checked={length(users) > 0 && length(users) === length(selectedUsers)}
                onChange={onSelectAll}
                disabled={isEmpty(users)}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </TableCell>
          )}
          <TableCell>{t('adminUsersTable.name')}</TableCell>
          <TableCell>{t('adminUsersTable.email')}</TableCell>
          <TableCell>{t('adminUsersTable.github')}</TableCell>
          <TableCell>{t('adminUsersTable.hiringState')}</TableCell>
          <TableCell>{t('adminUsersTable.role')}</TableCell>
          {isCourseChosen && (
            <>
              <TableCell align="center">
                <TableSortLabel
                  active={
                    currentSort === USERS_SORT_VARIANTS.courseRatingAsc ||
                    currentSort === USERS_SORT_VARIANTS.courseRatingDesc
                  }
                  direction={currentSortOrder}
                  onClick={createSortHandler(USERS_SORT_VARIANTS.sortKeys.courseRating)}
                >
                  {t('adminUsersTable.courseRating')}
                </TableSortLabel>
              </TableCell>
              <TableCell>{t('adminUsersTable.coursePercentage')}</TableCell>
            </>
          )}
          <TableCell>
            <TableSortLabel
              active={currentSort === USERS_SORT_VARIANTS.createdAsc || currentSort === USERS_SORT_VARIANTS.createdDesc}
              direction={currentSortOrder}
              onClick={createSortHandler(USERS_SORT_VARIANTS.sortKeys.createdAt)}
            >
              {t('adminUsersTable.registrationDate')}
            </TableSortLabel>
          </TableCell>
          {UserPresenter.hasAdminAccess(currentUser) && <TableCell padding="none" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => {
          const userId = UserPresenter.id(user);

          return (
            <TableRow key={userId}>
              {isSelectMode &&
                (UserPresenter.hasAdminAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
                  <TableCell padding="none">
                    <CheckboxOutlineBlank
                      checked={includes(userId, map(UserPresenter.id, selectedUsers))}
                      onChange={onSelect(user)}
                    />
                  </TableCell>
                )}
              <TableCell>
                <Link to={appRoutes.adminUserPath(userId)}>{truncate(UserPresenter.fullName(user))}</Link>
              </TableCell>
              <TableCell>
                <Typography title={UserPresenter.email(user)} noWrap>
                  {truncate(UserPresenter.email(user))}
                </Typography>
              </TableCell>
              <TableCell>
                {UserPresenter.githubAccountLink(user) && (
                  <MaterialLink href={UserPresenter.githubAccountLink(user)}>
                    {UserPresenter.githubAccount(user)}
                  </MaterialLink>
                )}
              </TableCell>
              <TableCell>{t(`user.hiringStates.${UserPresenter.hiringState(user)}`)}</TableCell>
              <TableCell>{t(`user.roles.${UserPresenter.role(user)}`)}</TableCell>
              {isCourseChosen && (
                <>
                  <TableCell align="center">{UserPresenter.userCourseRating(user)}</TableCell>
                  <TableCell>{UserPresenter.progressPercentage(user)}</TableCell>
                </>
              )}
              <TableCell>{UserPresenter.registrationDate(user)}</TableCell>
              {UserPresenter.hasAdminAccess(currentUser) && (
                <TableCell padding="none">
                  <Grid container wrap="nowrap">
                    {onImpersonate && (
                      <IconButton onClick={onImpersonate(UserPresenter.id(user))} aria-label="Impersonate">
                        <Group />
                      </IconButton>
                    )}
                    {!isSelectMode && (
                      <IconButton onClick={onModalOpenDestroy(UserPresenter.id(user))}>
                        <Delete color="primary" fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            colSpan={isCourseChosen ? 10 : 9}
            count={totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

BaseView.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  currentSort: PropTypes.string.isRequired,
  isCourseChosen: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(UserPresenter.shape()),
  meta: PropTypes.shape().isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onImpersonate: PropTypes.func,
  rowsPerPage: PropTypes.number.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
  isSelectMode: PropTypes.bool,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

BaseView.defaultProps = {
  users: [],
  onImpersonate: () => {},
  isSelectMode: false,
  selectedUsers: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

export default BaseView;
