import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  name: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 500,
    color: theme.palette.common.black,
    paddingTop: 20,
  },
  gridContainer: {
    height: 56,
    margin: [0, 24],
    '& .MuiDataGrid-root ': {
      border: 'none',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 0,
    },
    '& .MuiDataGrid-overlay': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'row',
  },
  sort: {
    display: 'flex',
    flexGrow: '1',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.blue7}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  totalCount: {
    backgroundColor: theme.palette.common.blue7,
    borderRadius: 4,
    padding: [16, 24],
    marginRight: 24,
  },
  totalCountNumber: {
    display: 'inline-block',
    width: 40,
  },
  textFieldFullName: {
    margin: [0, 16],
    width: 200,
  },
}));

export default useStyles;
