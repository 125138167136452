import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import UsersRepository from 'repositories/UsersRepository';

const sliceName = 'adminUsers';

/* eslint no-param-reassign: 0 */

const slice = createSlice({
  name: sliceName,
  initialState: {
    data: [],
    meta: {},
    processing: false,
    processingError: null,
  },
  reducers: {
    start(state) {
      state.processingError = null;
      state.processing = true;
    },
    fail(state, { payload }) {
      state.processingError = payload.error;
    },
    clearProcessingError(state) {
      state.processingError = null;
    },
    loadSuccess(state, { payload }) {
      state.data = payload.users;
      state.meta = payload.meta;
    },
    loadFinish(state) {
      state.processing = false;
    },
    resetData(state) {
      state.data = [];
    },
  },
});

const { start, fail, clearProcessingError, loadSuccess, loadFinish, resetData } = slice.actions;

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    loadUsers: params => {
      dispatch(start());

      return UsersRepository.index(params)
        .then(({ data }) => {
          dispatch(loadSuccess(data));
          return data;
        })
        .catch(error => {
          dispatch(fail({ error: error.message }));

          return Promise.reject(error);
        })
        .finally(() => dispatch(loadFinish()));
    },
    clearProcessingError: () => {
      dispatch(clearProcessingError());
    },
    resetData: () => {
      dispatch(resetData());
    },
    impersonate: id => UsersRepository.impersonate(id),
    stopImpersonate: id => UsersRepository.stopImpersonate(id),
    bulkUpdate: params => UsersRepository.bulkUpdate(params),
  };
};

export default slice.reducer;
