import * as yup from 'yup';
import { map } from 'ramda';

import { IS_VALID_DOMAIN_LIST } from 'forms/customRules';

import courseStates from 'utils/courseStates';
import { AVAILABLE_COURSE_STATES_REGEX } from 'utils/regularExpressions';
import CoursePresenter from 'presenters/CoursePresenter';
import UserPresenter from 'presenters/UserPresenter';

const validationFields = {
  name: yup.string().required('validations.course.name.required'),
  description: yup
    .string()
    .required('validations.course.description.required')
    .max(500, 'validations.course.description.maxLength'),
  state: yup.string().matches(AVAILABLE_COURSE_STATES_REGEX, { message: 'validations.course.state.invalid' }),
  accessDomains: yup.array().of(yup.string()).test(IS_VALID_DOMAIN_LIST),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = (course, nextOrder) => ({
  name: CoursePresenter.name(course) || '',
  icons: CoursePresenter.icons(course) || [],
  description: CoursePresenter.description(course) || '',
  introduction: CoursePresenter.introduction(course) || '',
  state: course ? CoursePresenter.state(course) : courseStates.draft,
  availableStates: [
    courseStates.published,
    courseStates.archived,
    courseStates.draft,
    courseStates.internalPublished,
    courseStates.sunset,
  ],
  order: CoursePresenter.order(course) || nextOrder,
  accessDomains: CoursePresenter.accessDomains(course) || [],
  teachers: CoursePresenter.teachers(course) || [],
});

export const serialize = values => {
  const { teachers, ...rest } = values;

  const params = {
    ...rest,
    teacherIds: map(UserPresenter.id, teachers),
  };

  return params;
};
