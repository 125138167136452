import TheoryPresenter from 'presenters/User/TheoryPresenter';
import PracticePresenter from 'presenters/User/PracticePresenter';

export const getPracticesBadgesVariants = userPractices => {
  return userPractices.map(userPractice => PracticePresenter.badgeVariant(userPractice));
};

export const getTheoriesBadgesVariants = userTheories => {
  return userTheories.map(userTheory => TheoryPresenter.badgeVariant(userTheory));
};
