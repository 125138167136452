import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalConfirmation = props => {
  const { isOpen, onClose, onConfirm, content, title, isNoChoiceProvided } = props;

  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {!isEmpty(title) && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {isNoChoiceProvided ? (
          <Button onClick={onConfirm} color="primary">
            {t('common.ok')}
          </Button>
        ) : (
          <>
            <Button onClick={onConfirm} color="primary">
              {t('common.yes')}
            </Button>
            <Button onClick={onClose} color="primary" autoFocus>
              {t('common.no')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

ModalConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  isNoChoiceProvided: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

ModalConfirmation.defaultProps = {
  isOpen: false,
  isNoChoiceProvided: false,
};

export default ModalConfirmation;
