import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import userPracticeStates from 'utils/userPracticeStates';
import { NOT_STARTED } from 'utils/helpers';
import formatDateWithTime from 'utils/dateTime';

export const PRACTICE_BADGE_VARIANTS = {
  pending: 'pendingPractice',
  accepted: 'accepted',
  completed: 'accepted',
  rejected: 'warning',
  rollbacked: 'light',
  notStarted: 'light',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    order: PropTypes.number,
    content: PropTypes.string,
    prLink: PropTypes.string,
    userState: PropTypes.string,
    updatedAt: PropTypes.string,
    teacherFeedbackMessage: PropTypes.string,
    result: PropTypes.string,
    isResultRequired: PropTypes.bool,
    isOnlyOnce: PropTypes.bool,
  },
  {
    getPrLink(practice) {
      return this.prLink(practice) || '';
    },
    isPending(practice) {
      return this.userState(practice) === userPracticeStates.pending;
    },
    isRejected(practice) {
      return this.userState(practice) === userPracticeStates.rejected;
    },
    isAccepted(practice) {
      return this.userState(practice) === userPracticeStates.accepted;
    },
    isCompleted(practice) {
      return this.userState(practice) === userPracticeStates.completed;
    },
    isRollbacked(practice) {
      return this.userState(practice) === userPracticeStates.rollbacked;
    },
    isStarted(practice) {
      return !isNil(this.userState(practice));
    },
    isCurrent(userPractice, currentPracticeId) {
      return this.id(userPractice) === currentPracticeId;
    },
    updatedDate(practice) {
      return formatDateWithTime(this.updatedAt(practice));
    },
    badgeVariant(practice) {
      const userState = isNil(this.userState(practice)) ? NOT_STARTED : this.userState(practice);
      return PRACTICE_BADGE_VARIANTS[userState];
    },
  },
);
