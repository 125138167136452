import storeModuleCreators from 'utils/store';
import CourseRepository from 'repositories/User/CourseRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['studentCourses'],
  repositoryAction: CourseRepository.load,
  responseKey: 'courses',
});

export default slice.reducer;
