import storeModuleCreators from 'utils/store';
import RatingRepository from 'repositories/RatingRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['rating'],
  repositoryAction: RatingRepository.show,
  responseKey: 'rating',
});

export default slice.reducer;
