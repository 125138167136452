import * as yup from 'yup';

import vacancyStates from 'utils/vacancyStates';
import { AVAILABLE_COURSE_STATES_REGEX } from 'utils/regularExpressions';
import VacancyPresenter from 'presenters/VacancyPresenter';

const validationFields = {
  title: yup.string().required('validations.vacancy.title.required'),
  description: yup.string().required('validations.vacancy.description.required'),
  shortDescription: yup.string().required('validations.vacancy.description.required'),
  state: yup.string().matches(AVAILABLE_COURSE_STATES_REGEX, { message: 'validations.vacancy.state.invalid' }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = vacancy => ({
  title: VacancyPresenter.title(vacancy) || '',
  description: VacancyPresenter.description(vacancy) || '',
  icons: VacancyPresenter.icons(vacancy) || [],
  shortDescription: VacancyPresenter.shortDescription(vacancy) || '',
  state: vacancy ? VacancyPresenter.state(vacancy) : vacancyStates.draft,
  availableStates: [vacancyStates.published, vacancyStates.archived, vacancyStates.draft],
});
