import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr',
  },
  card: {
    padding: 30,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
  },
  cardFeedback: {
    borderColor: theme.palette.common.spindle,
    backgroundColor: theme.palette.common.linkWater,
  },
  cardMoney: {
    borderColor: theme.palette.common.whiteRock,
    backgroundColor: theme.palette.common.quarterSpanishWhite,
  },
  cardLearn: {
    borderColor: theme.palette.common.gray6,
    backgroundColor: theme.palette.common.white,
  },
  cardEmoji: {
    display: 'block',
    marginBottom: '9px',
    fontSize: '30px',
    lineHeight: '36px',
  },
  cardTitle: {
    marginTop: 0,
    marginBottom: '5px',
    fontSize: '18px',
    lineHeight: '21.6px',
    fontWeight: 600,
  },
  cardContent: {
    fontSize: '15px',
    lineHeight: '22.5px',
    fontWeight: 400,
  },
  emailButton: {
    display: 'block',
    marginTop: '15px',
    textAlign: 'center',
    boxShadow: 'none',
  },
  learnButton: {
    display: 'block',
    marginTop: '15px',
    textAlign: 'center',
  },
}));

export default useStyles;
