import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  formBox: {
    '& > *': {
      margin: 10,
      minWidth: 400,
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: 14,
    },
    '& .rc-md-editor.full': {
      padding: [75, 10, 10],
      height: '100%',
      width: '100%',
    },
  },

  state: {
    fontSize: 14,
  },

  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  hidden: {
    visibility: 'hidden',
    pointerEvents: 'none',
    display: 'none',
  },
}));

export default useStyles;
