import React from 'react';
import PropTypes from 'prop-types';

const LinkRenderer = props => {
  const { href, children } = props;
  return (
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
};

LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LinkRenderer;
