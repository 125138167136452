import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.common.white,
  },

  currentPracticeName: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: theme.palette.common.brandingBlack,
    letterSpacing: '-0.15px',
    margin: [0, 0, 24],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(26),
    },
  },

  practiceNameSkeleton: {
    margin: 15,
    marginTop: 19,
  },

  alert: {
    marginBottom: 24,
  },

  accordion: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.common.gray10}`,

    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  accordionSummaryRoot: {
    gap: 12,
    padding: 0,
    minHeight: 'fit-content',
    border: 'none',

    '&.Mui-expanded': {
      minHeight: 'fit-content',
    },
  },
  accordionSummaryContent: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),

    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
    padding: 0,
    width: '100%',
  },

  iconSummary: {
    width: 18,
    fill: theme.palette.common.darkBlue,
    marginLeft: theme.spacing(2),
  },

  iconSummaryClosed: {
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
