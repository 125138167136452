import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import DefaultAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const Autocomplete = ({
  onChange,
  onChangeSearchValue,
  searchValue,
  options,
  isLoading,
  label,
  name,
  getOptionLabel,
  getOptionSelected,
  required,
  selected,
  error,
  helperText,
  onClose,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleChange = (event, value) => {
    onChange(name, value);
  };

  return (
    <DefaultAutocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={isLoading}
      onChange={handleChange}
      value={selected}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={onChangeSearchValue}
          required={required}
          error={error}
          value={searchValue}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  onChangeSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  onClose: PropTypes.func,
};

Autocomplete.defaultProps = {
  error: false,
  helperText: null,
  required: false,
  selected: null,
  isLoading: false,
  options: [],
  getOptionLabel: option => option.name,
  getOptionSelected: (option, value) => option.id === value.id,
  onClose: Function.prototype,
};

export default Autocomplete;
