export default {
  rootPath: () => '/',
  signInPath: () => '/sign-in',
  signUpPath: () => '/sign-up',
  forgotPasswordPath: () => '/forgot-password',
  updatePasswordPath: () => '/update-password',
  googleOauthPath: () => '/auth/google_oauth2',
  progressPath: () => '/progress',
  lessonsPath: () => '/lessons',
  adminPath: () => '/admin',
  teacherPath: () => '/teacher',
  coursesPath: () => '/courses',
  vacanciesPath: () => '/vacancies',
  vacancyPath: id => `/vacancies/${id}`,
  pagePath: slug => `/pages/${slug}`,
  coursesStudentDashboardPath: (courseId, studentId) => `/courses/${courseId}/students/${studentId}`,
  courseStudentsPath: id => `/courses/${id}/students`,
  coursesStudentProgressPath: (courseId, studentId) => `/students/${studentId}/courses/${courseId}/progress`,
  lastUserActivitiesPath: (courseId, userId) => `/courses/${courseId}/last-activities/${userId}`,
  courseLastActivitiesPath: courseId => `/courses/${courseId}/last-activities`,
  lastActivitiesPath: () => `/last-activities`,
  studentPath: () => '/student',
  coursePath: () => `/course`,
  courseLessonsPath: courseId => `/courses/${courseId}/lessons`,
  courseLessonPath: (courseId, lessonId) => `/courses/${courseId}/lessons/${lessonId}`,
  courseLessonTheoriesPath: (courseId, lessonId) => `/courses/${courseId}/lessons/${lessonId}/theories`,
  courseLessonTheoryPath: (courseId, lessonId, theoryId) =>
    `/courses/${courseId}/lessons/${lessonId}/theories/${theoryId}`,
  courseLessonPracticesPath: (courseId, lessonId) => `/courses/${courseId}/lessons/${lessonId}/practices`,
  courseLessonPracticePath: (courseId, lessonId, practiceId) =>
    `/courses/${courseId}/lessons/${lessonId}/practices/${practiceId}`,
  courseIntroductionPath: courseId => `/courses/${courseId}/introduction`,
  userProfilePath: () => `/users/account`,
  userEditProfilePath: () => `/users/account/edit`,
  adminUsersPath: () => `/admin/users`,
  adminCoursesPath: () => `/admin/courses`,
  adminGroupsPath: () => `/admin/groups`,
  adminGroupPath: id => `/admin/groups/${id}`,
  adminVacanciesPath: () => `/admin/vacancies`,
  adminPagesPath: () => `/admin/pages`,
  adminStatsPath: () => `/admin/stats`,
  adminCoursePath: courseId => `/admin/courses/${courseId}`,
  adminLessonsPath: courseId => `/admin/courses/${courseId}/lessons`,
  adminLessonPath: (courseId, lessonId) => `/admin/courses/${courseId}/lessons/${lessonId}`,
  adminTheoriesPath: (courseId, lessonId) => `/admin/courses/${courseId}/lessons/${lessonId}/theories`,
  adminTheoryNewPath: (courseId, lessonId) => `/admin/courses/${courseId}/lessons/${lessonId}/theories/new`,
  adminTheoryPath: (courseId, lessonId, theoryId) =>
    `/admin/courses/${courseId}/lessons/${lessonId}/theories/${theoryId}`,
  adminTheoryEditPath: (courseId, lessonId, theoryId) =>
    `/admin/courses/${courseId}/lessons/${lessonId}/theories/${theoryId}/edit`,
  adminPracticesPath: (courseId, lessonId) => `/admin/courses/${courseId}/lessons/${lessonId}/practices`,
  adminPracticeNewPath: (courseId, lessonId) => `/admin/courses/${courseId}/lessons/${lessonId}/practices/new`,
  adminPracticePath: (courseId, lessonId, practiceId) =>
    `/admin/courses/${courseId}/lessons/${lessonId}/practices/${practiceId}`,
  adminPracticeEditPath: (courseId, lessonId, practiceId) =>
    `/admin/courses/${courseId}/lessons/${lessonId}/practices/${practiceId}/edit`,
  adminUserPath: id => `/admin/users/${id}`,
  adminUserCoursesPath: userId => `/admin/users/${userId}/courses`,
  adminUserCoursePath: (userId, id) => `/admin/users/${userId}/courses/${id}`,
  error404Path: () => '/404',
  error403Path: () => '/403',
};
