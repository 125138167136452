import { makeStyles } from '@material-ui/core/styles';
import background from 'assets/background.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',

    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      minWidth: theme.breakpoints.values.desktop,
    },
  },
}));

export default useStyles;
