import { filter, length, join, head } from 'ramda';

import { isBlank } from 'utils/helpers';
import i18n from 'i18n';

const isValidDomain = string => {
  if (isBlank(string)) {
    return false;
  }

  return /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi.test(string);
};

export const IS_VALID_DOMAIN_LIST = {
  name: 'is-valid-domain-list',
  test(domains) {
    const invalidDomains = filter(domain => !isValidDomain(domain), domains);
    const invalidDomainsCount = length(invalidDomains);
    const isMultipleDomainsError = invalidDomainsCount > 1;
    if (isMultipleDomainsError) {
      const message = `${join(', ', invalidDomains)} ${i18n.t('validations.course.accessDomains.invalids')}`;
      return this.createError({ message });
    }

    if (invalidDomainsCount > 0) {
      const message = `${head(invalidDomains)} ${i18n.t('validations.course.accessDomains.invalid')}`;
      return this.createError({ message });
    }

    return true;
  },
};
