import {
  inGroupApiV1AdminGroupUsersPath,
  outGroupApiV1AdminGroupUsersPath,
  bulkCreateApiV1AdminGroupUsersPath,
  apiV1AdminGroupUserPath,
} from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  outGroup(groupId, params) {
    const path = outGroupApiV1AdminGroupUsersPath(groupId);
    return FetchHelpers.get(path, params);
  },
  inGroup(groupId, params) {
    const path = inGroupApiV1AdminGroupUsersPath(groupId);
    return FetchHelpers.get(path, params);
  },
  bulkCreate(groupId, ids) {
    const path = bulkCreateApiV1AdminGroupUsersPath(groupId);
    return FetchHelpers.post(path, { group: { ids } });
  },
  destroy(groupId, id) {
    const path = apiV1AdminGroupUserPath(groupId, id);
    return FetchHelpers.delete(path);
  },
};
