import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    state: PropTypes.string,
    createdAt: PropTypes.string,
    availableStates: PropTypes.arrayOf(PropTypes.string),
    icons: PropTypes.arrayOf(PropTypes.string),
  },
  {},
);
