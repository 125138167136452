import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isEmpty, isNil } from 'ramda';

import { Modal, Grid, Button, Backdrop, Fade, FormHelperText } from '@material-ui/core';

import { validationSchema, initialValues } from 'forms/admin/createNote';
import { mdParser, MdEditor } from 'utils/markdown';

import useStyles from './styles';

const NoteModal = props => {
  const { note, isModalOpen, onModalClose, onCreate, onUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasChanges, setHasChanges] = useState(false);

  const initValues = initialValues(note);
  const isCreated = !isNil(note);
  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: isCreated ? onUpdate : onCreate,
    enableReinitialize: true,
  });
  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onbeforeunloadFn = event => {
      if (hasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign, prettier/prettier, no-param-reassign
        return event.returnValue = "";
      }
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [hasChanges]);

  const trackChanges = (fieldName, value) => {
    if (initValues[fieldName] !== value) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  const handleModalClose = () => {
    if (hasChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.closeConfirm'))) {
        onModalClose();
        resetForm();
        setHasChanges(false);
      }
    } else {
      onModalClose();
      resetForm();
    }
  };

  const handleMessageChange = ({ text }) => {
    trackChanges('message', text);
    handleChange({ target: { name: 'message', value: text } });
  };

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="column">
              <Prompt when={hasChanges} message={t('common.promptMessage')} />
              <MdEditor
                style={{ height: '200px' }}
                name="message"
                value={values.message}
                renderHTML={text => mdParser.render(text)}
                onChange={handleMessageChange}
              />
              <FormHelperText error>{t(errors.message)}</FormHelperText>

              <Button type="submit" disabled={!isAbleToSubmit} color="primary" variant="contained">
                {t('notes.modal.create.submit')}
              </Button>
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

NoteModal.propTypes = {
  note: PropTypes.shape(),
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

NoteModal.defaultProps = {
  note: null,
};

export default NoteModal;
