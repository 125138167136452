/* eslint-disable react/no-this-in-sfc */
import React, { useRef } from 'react';
import { isNil, isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsGantt from 'highcharts/modules/gantt';
import hcPatternFill from 'highcharts-pattern-fill';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

hcPatternFill(Highcharts);
highchartsGantt(Highcharts);

const GanttChart = props => {
  const { chartData, isLoading } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const chartRef = useRef(null);

  if (isNil(chartData) || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        {t('adminUserPage.chart.loading')}
      </Box>
    );
  }

  const { expectedTimeData, theoryTimeData, practiceTimeData } = chartData;
  const isStudentProgressEmpty = isEmpty(expectedTimeData) && isEmpty(theoryTimeData) && isEmpty(practiceTimeData);

  const expectedTimeSerie = {
    data: expectedTimeData.map((point, index) => ({
      ...point,
      y: index,
      name: t('adminUserPage.chart.expectedTime'),
      color: 'url(#custom-pattern)',
    })),
  };

  const theoryTimeSerie = {
    data: theoryTimeData.map((point, index) => ({
      ...point,
      y: index,
      name: t('adminUserPage.chart.theoryTime'),
      color: theme.palette.common.blue2,
      opacity: 0.8,
    })),
  };

  const practiceTimeSerie = {
    data: practiceTimeData.map((point, index) => ({
      ...point,
      y: index,
      name: t('adminUserPage.chart.practiceTime'),
      color: theme.palette.common.blue1,
      opacity: 0.8,
    })),
  };

  const chartSeries = [expectedTimeSerie, theoryTimeSerie, practiceTimeSerie];

  Highcharts.setOptions({
    lang: {
      months: t('adminUserPage.chart.months', { returnObjects: true }),
      shortMonths: t('adminUserPage.chart.shortMonths', { returnObjects: true }),
      weekdays: t('adminUserPage.chart.weekdays', { returnObjects: true }),
      shortWeekdays: t('adminUserPage.chart.shortWeekdays', { returnObjects: true }),
      rangeSelectorFrom: t('adminUserPage.chart.rangeSelectorFrom'),
      rangeSelectorTo: t('adminUserPage.chart.rangeSelectorTo'),
      rangeSelectorZoom: t('adminUserPage.chart.rangeSelectorZoom'),
    },
  });

  const chartOptions = {
    tooltip: {
      formatter() {
        return `<b>${this.point.name}</b><br />
          ${t('adminUserPage.chart.startDate')}: ${Highcharts.dateFormat('%A, %e %b %Y', this.point.start)} <br /> 
          ${t('adminUserPage.chart.endDate')}: ${Highcharts.dateFormat('%A, %e %b %Y', this.point.end)}`;
      },
    },

    chart: {
      events: {
        load() {
          const chart = this;
          const { series } = chart;
          const min = series[series.length - 1].processedXData[0];
          const max = min + 35 * 24 * 60 * 60 * 1000;

          chart.xAxis[0].setExtremes(min, max);
        },
      },
    },

    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
      },
      yAxis: {
        reversed: true,
        categories: [],
      },
    },

    scrollbar: {
      enabled: true,
    },

    rangeSelector: {
      enabled: true,
      buttons: [
        {
          type: 'month',
          count: 1,
          text: t('adminUserPage.chart.zoomOneMonth'),
        },
        {
          type: 'month',
          count: 2,
          text: t('adminUserPage.chart.zoomTwoMonths'),
        },
        {
          type: 'month',
          count: 3,
          text: t('adminUserPage.chart.zoomThreeMonths'),
        },
        {
          type: 'all',
          text: t('adminUserPage.chart.zoomAll'),
        },
      ],
    },

    xAxis: [
      {
        dateTimeLabelFormats: {
          week: {
            list: [`${t('adminUserPage.chart.week')} %W`, `${t('adminUserPage.chart.shortWeek')}%W`],
          },
        },
      },
      {
        dateTimeLabelFormats: {
          week: {
            list: [`${t('adminUserPage.chart.week')} %W`, `${t('adminUserPage.chart.shortWeek')}%W`],
          },
        },
      },
    ],

    yAxis: {
      type: 'category',
      grid: {
        columns: [
          {
            title: {
              text: t('adminUserPage.chart.lessons'),
            },
            labels: {
              format: '{point.lesson}',
            },
          },
          {
            title: {
              text: t('adminUserPage.chart.days'),
            },
            labels: {
              format: '{point.requiredTime}',
            },
          },
        ],
      },
      staticScale: 50,
    },

    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: theme.palette.common.blue5,
            strokeWidth: 4.5,
          },
        },
      ],
    },

    series: chartSeries,
  };

  return (
    <>
      {isStudentProgressEmpty ? (
        <Typography variant="body1" align="center">
          {t('adminUserPage.noGanttChart')}
        </Typography>
      ) : (
        <HighchartsReact constructorType="ganttChart" highcharts={Highcharts} options={chartOptions} ref={chartRef} />
      )}
    </>
  );
};

GanttChart.propTypes = {
  chartData: PropTypes.shape(),
  isLoading: PropTypes.bool,
};

GanttChart.defaultProps = {
  chartData: null,
  isLoading: false,
};

export default GanttChart;
