import { useActions as useShowActions, selectors as showSelectors } from 'store/admin/ganttProgressDataSlice';

const useGanttProgressData = () => {
  const { load: loadGanttProgressData, reset: resetGanttProgressData, clearLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = showSelectors;
  const ganttProgressData = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    ganttProgressData,
    loadGanttProgressData,
    isLoading,
    loadingError,
    clearLoadingError,
    resetGanttProgressData,
  };
};

export default useGanttProgressData;
