import React from 'react';
import { Link } from 'react-router-dom';
import uniqueid from 'lodash.uniqueid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map, addIndex, nth, head, length } from 'ramda';

import {
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  Typography,
  Grid,
} from '@material-ui/core';

import TablePaginationActions from 'components/TablePaginationActions';
import ProgressItem from 'components/ProgressItem';

import appRoutes from 'routes/appRoutes';
import UserPresenter from 'presenters/UserPresenter';

import { truncate } from 'utils/strings';

const mapIndexed = addIndex(map);

const PREVENT_ZERO_DIVIDER = 1;
const prepareGroup = (progress, key) =>
  map(
    ({ name, [key]: { count, done } }) => ({
      name,
      opacity: done / (count || PREVENT_ZERO_DIVIDER) + 0.05,
    }),
    progress,
  );

const ExtendedView = props => {
  const {
    users,
    meta: { totalCount, currentPage },
    onPageChange,
    onRowsPerPageChange,
    rowsPerPage,
  } = props;
  const { t } = useTranslation();

  const colSpan = length(UserPresenter.progress(head(users))) || 0;

  return (
    <div>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('adminUsersTable.name')}</TableCell>
            <TableCell>{t('adminUsersTable.hiringState')}</TableCell>
            <TableCell colSpan={colSpan + 1}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item>{t('adminUsersTable.progress')}</Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item>
                    <ProgressItem item={{ opacity: 1 }} flat practice size="flat" />
                    <ProgressItem item={{ opacity: 1 }} flat theory size="flat" />
                  </Grid>
                  <Grid item>{t('common.completed')}</Grid>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item>
                    <ProgressItem item={{ opacity: 0.5 }} flat practice size="flat" />
                    <ProgressItem item={{ opacity: 0.5 }} flat theory size="flat" />
                  </Grid>
                  <Grid item>{t('common.halfCompleted')}</Grid>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item>
                    <ProgressItem item={{ opacity: 0.05 }} flat practice size="flat" />
                    <ProgressItem item={{ opacity: 0.05 }} flat theory size="flat" />
                  </Grid>
                  <Grid item>{t('common.uncompleted')}</Grid>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(user => {
            const userId = UserPresenter.id(user);
            const progress = UserPresenter.progress(user);
            const practices = progress && prepareGroup(progress, 'practices');
            const theories = progress && prepareGroup(progress, 'theories');

            return (
              <TableRow key={userId}>
                <TableCell>
                  <Link to={appRoutes.adminUserPath(userId)}>{truncate(UserPresenter.fullName(user))}</Link>
                </TableCell>
                <TableCell>{t(`user.hiringStates.${UserPresenter.hiringState(user)}`)}</TableCell>
                {practices && (
                  <TableCell padding="none" align="right">
                    <Typography variant="caption" display="block" align="center">
                      {t('common.practices')}
                    </Typography>
                    <Typography variant="caption" display="block" align="center">
                      {t('common.theories')}
                    </Typography>
                  </TableCell>
                )}
                {practices &&
                  mapIndexed(
                    (practice, i) => (
                      <TableCell padding="none" key={i}>
                        <ProgressItem item={practice} key={uniqueid(practice)} flat practice size="flat" />
                        <ProgressItem
                          item={nth(i, theories)}
                          key={uniqueid(nth(i, theories))}
                          flat
                          theory
                          size="flat"
                        />
                      </TableCell>
                    ),
                    practices,
                  )}
              </TableRow>
            );
          }, users)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={colSpan + 3}
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

ExtendedView.propTypes = {
  users: PropTypes.arrayOf(UserPresenter.shape()),
  meta: PropTypes.shape().isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

ExtendedView.defaultProps = {
  users: [],
};

export default ExtendedView;
