import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import clsx from 'clsx';
import { map, length, isEmpty, includes } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import CheckboxOutlineBlank from 'components/CheckboxOutlineBlank';

import ExercisePresenter from 'presenters/ExercisePresenter';
import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminPractices from 'hooks/admin/usePractices';
import { camelize } from 'utils/strings';

import appRoutes from 'routes/appRoutes';

import useStyles from './styles';

const Practices = props => {
  const { courseId, lessonId, practices, onModalOpenDestroy, selected, onSelect, onSelectAll } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [widths, setWidths] = useState([]);
  const [sortedPracticesList, setSortedPracticesList] = useState(practices);

  const { currentUser } = useCurrentUser();
  const { updatePractices } = useAdminPractices();

  const handleChange = ({ oldIndex, newIndex }) => {
    const newPracticesList = arrayMove(sortedPracticesList, oldIndex, newIndex);
    const practicesOrders = newPracticesList.map((practice, index) => ({
      id: practice.lessonPracticesId,
      order: index,
    }));
    setSortedPracticesList(newPracticesList);
    updatePractices(courseId, lessonId, practicesOrders);
  };

  return (
    <List
      lockVertically
      beforeDrag={({ elements, index }) => {
        const cells = Array.from(elements[index].children);
        const cellsWidths = cells.map(cell => window.getComputedStyle(cell).width);
        setWidths(cellsWidths);
      }}
      values={sortedPracticesList}
      onChange={handleChange}
      renderList={({ children, props: renderListProps }) => (
        <Table aria-label="practices table">
          <TableHead>
            <TableRow id="practice-table-header-row">
              {UserPresenter.hasAdminAccess(currentUser) && (
                <TableCell padding="none">
                  <CheckboxOutlineBlank
                    indeterminate={length(selected) > 0 && length(selected) < length(practices)}
                    checked={length(practices) > 0 && length(practices) === length(selected)}
                    onChange={onSelectAll}
                    disabled={isEmpty(practices)}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}
              <TableCell>{t('adminLessonPracticesTable.name')}</TableCell>
              <TableCell>{t('adminLessonPracticesTable.state')}</TableCell>
              <TableCell>{t('adminLessonPracticesTable.createdAt')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...renderListProps}>{children}</TableBody>
        </Table>
      )}
      renderItem={({ value, props: renderItemProps, isDragged }) => {
        const tdWidths = isDragged ? widths : [];
        const practiceId = ExercisePresenter.id(value);
        const row = (
          <TableRow
            {...renderItemProps}
            style={{
              ...renderItemProps.style,
            }}
            classes={{ root: clsx(classes.cursor) }}
          >
            {UserPresenter.hasAdminAccess(currentUser) && (
              <TableCell style={{ width: tdWidths[0] }} padding="none">
                <CheckboxOutlineBlank
                  checked={includes(practiceId, map(ExercisePresenter.id, selected))}
                  onChange={onSelect(value)}
                />
              </TableCell>
            )}
            <TableCell style={{ width: tdWidths[1] }}>{ExercisePresenter.name(value)}</TableCell>
            <TableCell style={{ width: tdWidths[2] }}>
              {t(`adminEditLessonPracticeModal.states.${camelize(ExercisePresenter.state(value))}`)}
            </TableCell>
            <TableCell style={{ width: tdWidths[3] }}>{ExercisePresenter.createdAt(value)}</TableCell>
            <TableCell style={{ width: tdWidths[4] }} align="right">
              <Grid container alignItems="center" wrap="nowrap">
                <Link to={appRoutes.adminPracticeEditPath(courseId, lessonId, practiceId)}>
                  <IconButton>
                    <EditIcon color="primary" fontSize="small" />
                  </IconButton>
                </Link>
                {UserPresenter.hasMentorAccess(currentUser) && (
                  <IconButton onClick={onModalOpenDestroy(value)}>
                    <DeleteIcon color="primary" fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        );
        return isDragged ? (
          <Table>
            <TableBody>{row}</TableBody>
          </Table>
        ) : (
          row
        );
      }}
    />
  );
};

Practices.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  practices: PropTypes.arrayOf(ExercisePresenter.shape()),
  onModalOpenDestroy: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

Practices.defaultProps = {
  practices: null,
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

export default Practices;
