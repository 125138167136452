import {
  useActions as useShowActions,
  selectors as showSelectors,
  updateUser,
  destroyUser,
} from 'store/admin/userSlice';

const useUser = () => {
  const { load: loadUser, clearLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = showSelectors;
  const user = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    user,
    loadUser,
    isLoading,
    loadingError,
    clearLoadingError,
    updateUser,
    destroyUser,
  };
};

export default useUser;
