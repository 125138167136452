import { useActions as useShowActions, selectors as showSelectors } from 'store/progressDiagramDataSlice';

const useProgressDiagramData = () => {
  const { load: loadProgressDiagramData, reset: resetProgressDiagramData, clearLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = showSelectors;
  const progressDiagramData = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    progressDiagramData,
    loadProgressDiagramData,
    isLoading,
    loadingError,
    clearLoadingError,
    resetProgressDiagramData,
  };
};

export default useProgressDiagramData;
