import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, append, without, length, not, map, values } from 'ramda';

import {
  CircularProgress,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Breadcrumbs from 'components/Breadcrumbs';

import UserPresenter from 'presenters/UserPresenter';
import LessonPresenter from 'presenters/LessonPresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminLessons from 'hooks/admin/useLessons';
import useAdminCourses from 'hooks/admin/useCourses';
import useNumberParams from 'hooks/useNumberParams';
import { calculateNextOrder } from 'utils/calculateNextOrder';
import lessonStates from 'utils/lessonStates';
import { camelize } from 'utils/strings';

import ModalConfirmation from 'components/ModalConfirmation';
import LessonModal from './components/LessonModal';
import Lessons from './components/Lessons';

import useStyles from './styles';

const LessonsEditPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId } = useNumberParams(['courseId']);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulkState, setBulkState] = useState('');

  const { currentUser } = useCurrentUser();
  const {
    lessons,
    isLoading,
    loadAdminLessons,
    resetAdminLessons,
    createLesson,
    updateLesson,
    destroyLesson,
    bulkUpdate,
  } = useAdminLessons();
  const { loadAdminCourse, resetAdminCourse } = useAdminCourses();

  useEffect(() => {
    loadAdminLessons(courseId);
    loadAdminCourse(courseId);
    return () => {
      resetAdminLessons();
      resetAdminCourse();
    };
  }, []);

  if (isNil(lessons) || isLoading) {
    return (
      <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
        <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
          <Box p={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Breadcrumbs />
              </Grid>
            </Grid>
            <CircularProgress color="primary" />
          </Box>
        </Paper>
      </Container>
    );
  }

  const initialOrder = 0;
  const nextOrder = isEmpty(lessons) ? initialOrder : calculateNextOrder(lessons);

  const handleLessonModalOpen =
    (lessonData = null) =>
    () => {
      setModalOpen(true);
      if (lessonData) {
        setCurrentLesson(lessonData);
      }
    };

  const handleLessonModalClose = () => {
    setModalOpen(false);
    setCurrentLesson(null);
  };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentLesson(null);
  };

  const loadLessonsAndCloseModal = () => {
    loadAdminLessons(courseId);
    handleLessonModalClose();
  };

  const handleLessonCreate = params => createLesson(courseId, params).then(() => loadLessonsAndCloseModal());

  const handleLessonUpdate = params =>
    updateLesson(courseId, currentLesson.id, params).then(() => loadLessonsAndCloseModal());

  const handleModalOpenDestroy =
    (lessonData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (lessonData) {
        setCurrentLesson(lessonData);
      }
    };

  const handleLessonDestroy = async () => {
    await destroyLesson(courseId, currentLesson.id);
    handleModalDeleteClose();
    loadAdminLessons(courseId);
  };

  const handleSelect = lesson => e => {
    if (e.target.checked) {
      setSelected(append(lesson, selected));
    } else {
      setSelected(without([lesson], selected));
    }
  };

  const handleSelectAll = () => {
    if (length(lessons) === length(selected)) {
      setSelected([]);
    } else {
      setSelected(lessons);
    }
  };

  const handleBulkUpdate = async () => {
    const list = map(u => ({ id: LessonPresenter.id(u), state: bulkState }), selected);
    await bulkUpdate(courseId, { lessons: list });
    setSelected([]);
    loadAdminLessons(courseId);
  };

  return (
    <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
      <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
        <Box p={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Breadcrumbs />
            </Grid>
            {UserPresenter.hasMentorAccess(currentUser) && (
              <Grid item>
                <Button color="primary" variant="outlined" onClick={handleLessonModalOpen()}>
                  {t('adminLessonsEditPage.createLesson')}
                </Button>
              </Grid>
            )}
          </Grid>

          <Lessons
            courseId={courseId}
            lessons={lessons}
            onLessonModalOpen={handleLessonModalOpen}
            onModalOpenDestroy={handleModalOpenDestroy}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            selected={selected}
          />
          <LessonModal
            lesson={currentLesson}
            nextOrder={nextOrder}
            isModalOpen={isModalOpen}
            onLessonModalClose={handleLessonModalClose}
            onLessonCreate={handleLessonCreate}
            onLessonUpdate={handleLessonUpdate}
          />

          <ModalConfirmation
            isOpen={isModalDeleteOpen}
            onClose={handleModalDeleteClose}
            onConfirm={handleLessonDestroy}
            title={t('adminLessonsEditPage.deleteLessonModalTitle')}
            content={t('adminLessonsEditPage.deleteLessonModalContent')}
          />
        </Box>
      </Paper>

      {not(isEmpty(selected)) && (
        <Paper elevation={12} style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Container component="div" maxWidth="lg">
            <Box py={2} px={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item container xs={6} spacing={4} alignItems="center">
                  <Grid item>
                    <Typography>
                      {t('common.selected')}
                      {length(selected)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel id="state-label">{t('adminEditLessonModal.state')}</InputLabel>
                    <Select
                      disabled={isLoading}
                      labelId="state-label"
                      id="state-select"
                      value={bulkState}
                      onChange={e => setBulkState(e.target.value)}
                      fullWidth
                    >
                      {values(lessonStates).map(state => (
                        <MenuItem key={state} value={state}>
                          {t(`adminEditLessonModal.states.${camelize(state)}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={handleBulkUpdate}>
                    {t('common.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      )}
    </Container>
  );
};

export default LessonsEditPage;
