import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      margin: [24, 0, 24],
    },
  },

  container: {
    backgroundColor: theme.palette.common.white,
  },

  markdown: {
    padding: 0,
    '& > :first-child': {
      margin: 0,
    },
  },
}));

export default useStyles;
