import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
  },
  {
    fullname(group) {
      const startAt = this.startAt(group);
      const endAt = this.endAt(group);
      const name = this.name(group);

      if (startAt && endAt) {
        return `${name}: ${startAt} — ${endAt}`;
      }

      return name;
    },
  },
);
