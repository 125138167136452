import {
  selectorsGroupUsersIn as loadGroupUsersInSelectors,
  useActionsGroupUsersIn as useLoadGroupUsersInActions,
  selectorsGroupUsersOut as loadGroupUsersOutSelectors,
  useActionsGroupUsersOut as useLoadGroupUsersOutActions,
  bulkCreateAdminGroupUsers,
  destroyAdminGroupUser,
} from 'store/admin/groupUsersSlice';

export default () => {
  const {
    load: loadGroupUsersIn,
    reset: resetGroupUsersIn,
    clearLoadingGroupUsersInError,
  } = useLoadGroupUsersInActions();
  const {
    load: loadGroupUsersOut,
    reset: resetGroupUsersOut,
    clearLoadingGroupUsersOutError,
  } = useLoadGroupUsersOutActions();
  const {
    getData: getGroupUsersInData,
    getIsLoading: getGroupUsersInIsLoading,
    getLoadingError: getGroupUsersInLoadingError,
    getMeta: getGroupUsersInMeta,
  } = loadGroupUsersInSelectors;
  const {
    getData: getGroupUsersOutData,
    getIsLoading: getGroupUsersOutIsLoading,
    getLoadingError: getGroupUsersOutLoadingError,
    getMeta: getGroupUsersOutMeta,
  } = loadGroupUsersOutSelectors;
  const inGroupUsers = getGroupUsersInData();
  const isInGroupLoading = getGroupUsersInIsLoading();
  const inGroupLoadingError = getGroupUsersInLoadingError();
  const inGroupMeta = getGroupUsersInMeta();
  const outGroupUsers = getGroupUsersOutData();
  const isOutGroupLoading = getGroupUsersOutIsLoading();
  const outGroupLoadingError = getGroupUsersOutLoadingError();
  const outGroupMeta = getGroupUsersOutMeta();

  return {
    loadGroupUsersIn,
    resetGroupUsersIn,
    clearLoadingGroupUsersInError,
    inGroupUsers,
    isInGroupLoading,
    inGroupLoadingError,
    inGroupMeta,
    loadGroupUsersOut,
    resetGroupUsersOut,
    clearLoadingGroupUsersOutError,
    outGroupUsers,
    isOutGroupLoading,
    outGroupLoadingError,
    outGroupMeta,
    bulkCreateAdminGroupUsers,
    destroyAdminGroupUser,
  };
};
