import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import { isEmpty, isNil, range, head } from 'ramda';
import qs from 'qs';

import { Container, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import useUserCourses from 'sharedContainers/User/Courses';
import useNumberParams from 'hooks/useNumberParams';
import { stateEvents } from 'utils/userPracticeStates';
import ActivityPresenter from 'presenters/ActivityPresenter';
import MetaPresenter from 'presenters/MetaPresenter';
import ModalConfirmationWithMessage from 'components/ModalConfirmationWithMessage';
import { updatePracticeState } from 'hooks/admin/useUserPractice';
import useLastActivities from 'hooks/admin/useLastActivities';
import { groupsHooks } from 'store/admin/groupsSlice';
import { useFetchStatus } from 'utils/fetchStatusUtils';

import InfiniteScroll from 'react-infinite-scroller';
import { useLoading } from 'hooks';
import LastActivity, { LastActivitySkeleton } from './components/LastActivity';
import FilterFieldCourse from './components/FilterFieldCourse';
import FilterFieldGroup from './components/FilterFieldGroup';

import useStyles from './styles';

const initSortColumns = [
  { field: 'user_course_rating', headerName: 'Rating', flex: 1 },
  { field: 'updated_at', headerName: 'Updated At', flex: 1.5 },
];

const DEFAULT_SORT = { field: 'updated_at', sort: 'desc' };
const SORT_OFF = { field: '', sort: null };

const initRejectModalData = {
  modalActivity: null,
  isOpenRejectModal: false,
};

const LastActivities = props => {
  const { courseId: currentCourseId, userId } = props;
  const { courseId: courseIdFromUrl } = useNumberParams(['courseId']);
  const { courses } = useUserCourses();
  const {
    firstLoadGroups: loadGroups,
    groups: { items: groups, loadingStatus: groupsLoadingStatus },
  } = groupsHooks.use();
  const { isIdle: isGroupsIdle } = useFetchStatus(groupsLoadingStatus);

  const [sortColumns, setSortColumns] = useState(initSortColumns);
  const [courseId, setCourseId] = useState(courseIdFromUrl || currentCourseId);
  const [groupId, setGroupId] = useState(null);
  const [nameOrEmail, setNameOrEmail] = useState('');

  const classes = useStyles();
  const { t } = useTranslation();
  const [sortable, setSortable] = useState(DEFAULT_SORT);

  const calculateLoadEffects = () => qs.stringify({ sortable, courseId, groupId, nameOrEmail });
  const [loadEffects, setLoadEffects] = useState(calculateLoadEffects());

  const [{ isOpenRejectModal, modalActivity }, setRejectModalData] = useState(initRejectModalData);
  const { appendLastActivities, lastActivities, resetLastActivities, meta } = useLastActivities();
  const { func: loadLastActivitiesWithLoading, isPending } = useLoading(appendLastActivities);
  const params = {
    q: {
      findByFullNameOrEmail: nameOrEmail,
      forCourse: courseId,
      userIdEq: userId,
      userGroupsIdEq: groupId,
      ...(sortable && { s: `${sortable.field} ${sortable.sort}` }),
    },
  };
  const currentPage = MetaPresenter.page(meta);
  const hasMore = MetaPresenter.hasMore(meta) && !isPending;

  const resetData = () => resetLastActivities();
  const loadData = (page = 1) => loadLastActivitiesWithLoading({ ...params, page });
  const appendData = (page = currentPage + 1) => {
    if (isPending) return;
    loadLastActivitiesWithLoading({ ...params, page });
  };

  const handleFilterCourseChanged = useCallback(
    e => {
      resetData();
      setCourseId(e.target.value);
    },
    [resetData],
  );
  const handleFilterFindByNameOrEmailChanged = useCallback(
    e => {
      resetData();
      setNameOrEmail(e.target.value);
    },
    [resetData],
  );
  const handleFilterGroupChanged = useCallback(
    e => {
      resetData();
      setGroupId(e.target.value);
    },
    [resetData],
  );

  useDebounce(
    () => {
      const newLoadEffects = calculateLoadEffects();
      setLoadEffects(newLoadEffects);
    },
    200,
    [sortable, courseId, groupId, nameOrEmail],
  );

  useEffect(() => {
    loadData();
    return () => {
      resetData();
    };
  }, [loadEffects]);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    if (courses && groups) {
      setSortColumns([
        ...initSortColumns,
        {
          field: 'for_course',
          headerName: 'Course',
          sortable: false,
          flex: 3,
          renderHeader: () => (
            <FilterFieldCourse courses={courses} courseId={courseId} onFieldChange={handleFilterCourseChanged} />
          ),
        },
        {
          field: 'user_groups_id_eq',
          headerName: 'Group',
          sortable: false,
          flex: 2,
          renderHeader: () => (
            <FilterFieldGroup
              groups={groups}
              groupId={groupId}
              onFieldChange={handleFilterGroupChanged}
              disabled={isGroupsIdle}
            />
          ),
        },
      ]);
    }
  }, [courses, groups]);

  const onRejectPracticeWithFeedback = activity => () => {
    setRejectModalData({
      modalActivity: activity,
      isOpenRejectModal: true,
    });
  };

  const handleRejectModalClose = () => {
    setRejectModalData(initRejectModalData);
  };

  const handleRejectModalConfirm = feedbackMessage => {
    const activityId = ActivityPresenter.id(modalActivity);
    updatePracticeState(activityId, { stateEvent: stateEvents.reject, feedbackMessage }).then(() => loadData(1));
    resetData();
    setRejectModalData(initRejectModalData);
  };

  const onChangeUserPracticeState = (activity, stateEvent) => () => {
    const activityId = ActivityPresenter.id(activity);
    resetData();
    updatePracticeState(activityId, { stateEvent }).then(() => loadData(1));
  };

  const handleSortChanged = sortModel => {
    setSortable(head(sortModel) ?? SORT_OFF);
  };

  const renderPlaceholder = () => {
    const COUNT_SKELETON_CARDS = 3;

    const skeletonCards = range(0, COUNT_SKELETON_CARDS).map(index => {
      return <LastActivitySkeleton key={index} />;
    });

    return (
      <Container component="main" maxWidth="lg">
        {skeletonCards}
      </Container>
    );
  };

  const renderEmptyItems = () => {
    return (
      <Typography className={classes.name} align="center">
        {t('lastActivity.noEvents')}
      </Typography>
    );
  };

  const renderActivityItems = () => {
    return lastActivities.map(activity => (
      <LastActivity
        key={ActivityPresenter.id(activity)}
        activity={activity}
        onChangeUserPracticeState={onChangeUserPracticeState}
        onRejectPracticeWithFeedback={onRejectPracticeWithFeedback}
      />
    ));
  };
  return (
    <>
      {isOpenRejectModal && (
        <ModalConfirmationWithMessage
          onClose={handleRejectModalClose}
          onConfirm={handleRejectModalConfirm}
          title={t('lastActivity.feedbackConfirmationTitle')}
          messageLabel={t('lastActivity.feedbackMessage')}
        />
      )}

      <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
        <InfiniteScroll loadMore={() => appendData()} hasMore={hasMore} loader={null} initialLoad={false}>
          <div className={classes.gridContainer}>
            <div className={classes.totalCount}>
              {t('lastActivity.totalCount')}
              <span className={classes.totalCountNumber}>{MetaPresenter.totalCount(meta)}</span>
            </div>

            <div className={classes.sort}>
              <TextField
                className={classes.textFieldFullName}
                fullWidth
                name="findByFullNameOrEmail"
                label={t('lastActivity.labelFieldFindByNameOrEmail')}
                value={nameOrEmail || ''}
                onChange={handleFilterFindByNameOrEmailChanged}
              />
              <DataGrid
                columns={sortColumns}
                rows={[]}
                sortingMode="server"
                sortModel={[sortable]}
                sortingOrder={['desc', 'asc', null]}
                onSortModelChange={handleSortChanged}
                disableColumnMenu
                hideFooter
                hideFooterPagination
              />
            </div>
          </div>
          {isEmpty(lastActivities) && !isPending && renderEmptyItems()}
          {!isEmpty(lastActivities) && !isNil(lastActivities) && renderActivityItems()}
          {isPending && renderPlaceholder()}
        </InfiniteScroll>
      </Container>
    </>
  );
};

LastActivities.propTypes = {
  userId: PropTypes.number,
  courseId: PropTypes.number,
};

LastActivities.defaultProps = {
  userId: null,
  courseId: null,
};

export default LastActivities;
