import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkbox: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  disabled: {
    opacity: '0.5 !important',
  },
  checkboxIcon: {
    width: 20,
    height: 20,
  },
}));

export default useStyles;
