import React from 'react';
import PropTypes from 'prop-types';
import uniqueid from 'lodash.uniqueid';
import clsx from 'clsx';

import { BADGE_SIZES } from 'presenters/User/LessonPresenter';

import Badge from './components/Badge';

import useStyles from './styles';

const BadgeGroup = props => {
  const { variants, size, titles, showTooltips, tooltipsPlacement, flexibleBadgeStyle } = props;
  const classes = useStyles();
  const badgeClasses = clsx(classes.badgeGroup, { [classes.badgeGroupThin]: size === BADGE_SIZES.small });

  return (
    <div className={badgeClasses}>
      {variants.map((variant, index) => (
        <Badge
          variant={variant}
          size={size}
          key={uniqueid(variant)}
          title={titles[index]}
          showTooltips={showTooltips}
          tooltipsPlacement={tooltipsPlacement}
          flexibleBadgeStyle={flexibleBadgeStyle}
        />
      ))}
    </div>
  );
};

BadgeGroup.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  size: PropTypes.oneOf(Object.values(BADGE_SIZES)),
  titles: PropTypes.arrayOf(PropTypes.string),
  showTooltips: PropTypes.bool,
  tooltipsPlacement: PropTypes.string,
  flexibleBadgeStyle: PropTypes.shape(),
};

BadgeGroup.defaultProps = {
  variants: [],
  size: BADGE_SIZES.large,
  titles: [],
  showTooltips: false,
  flexibleBadgeStyle: null,
  tooltipsPlacement: null,
};

export default BadgeGroup;
