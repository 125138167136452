import { useActions as useShowActions, selectors as showSelectors } from 'store/ratingSlice';

const useRating = () => {
  const { load: loadRating, reset: resetRating, clearLoadingError } = useShowActions();
  const { getData, getIsLoading, getLoadingError } = showSelectors;
  const rating = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    rating,
    loadRating,
    isLoading,
    loadingError,
    clearLoadingError,
    resetRating,
  };
};

export default useRating;
