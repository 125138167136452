import * as yup from 'yup';

import GroupPresenter from 'presenters/GroupPresenter';

const validationFields = {
  name: yup.string().required('validations.group.name.required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = vacancy => ({
  name: GroupPresenter.name(vacancy) || '',
  startAt: GroupPresenter.startAt(vacancy) || '',
  endAt: GroupPresenter.endAt(vacancy) || '',
});
