import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import uniqueid from 'lodash.uniqueid';
import { isNil, map } from 'ramda';

import { CloseRounded, StopScreenShare } from '@material-ui/icons';

import {
  Toolbar,
  Typography,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
  Menu,
  Divider,
  Button,
  MenuItem,
} from '@material-ui/core';

import { redirectWithReloadPage } from 'utils/locationHelper';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { pagesHooks } from 'store/pagesSlice';

import useCurrentUser from 'hooks/useCurrentUser';
import useUsers from 'hooks/admin/useUsers';
import appRoutes from 'routes/appRoutes';
import externalRoutes from 'routes/externalRoutes';
import UserPresenter from 'presenters/UserPresenter';
import CoursePresenter from 'presenters/CoursePresenter';
import PagePresenter from 'presenters/PagePresenter';

import Icon from 'components/Icon';
import ContextCourse from './components/ContextCourse';
import CourseMenu from './components/CourseMenu';
import UserMenu from './components/UserMenu';
import Logo from './components/Logo';
import LanguageMenu from './components/LanguageMenu';

import useStyles from './styles';

const HeaderMenu = props => {
  const { courses, currentCourseId } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { currentUser, logout } = useCurrentUser();
  const { stopImpersonate } = useUsers();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleStopImpersonate = () => stopImpersonate().then(() => redirectWithReloadPage(appRoutes.adminUsersPath()));
  const handleLogout = () => {
    logout();
  };

  const {
    loadPages,
    pages: { items: pages, loadingStatus },
  } = pagesHooks.use();

  useEffect(() => {
    loadPages();
  }, []);

  const { isFulfilled } = useFetchStatus(loadingStatus);

  const desktopMenu = (
    <Box component="nav" display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" mr={3}>
        {currentCourseId && (
          <CourseMenu currentCourseId={currentCourseId} courses={courses} currentUser={currentUser} />
        )}

        {!currentCourseId && (
          <>
            <NavLink to={appRoutes.coursesPath()} className={classes.link} activeClassName={classes.linkActive}>
              <Typography className={classes.name}>{t('header.courses')}</Typography>
            </NavLink>
          </>
        )}

        {UserPresenter.hasTeacherAccess(currentUser) && (
          <NavLink
            to={currentCourseId ? appRoutes.courseLastActivitiesPath(currentCourseId) : appRoutes.lastActivitiesPath()}
            className={classes.link}
            activeClassName={classes.linkActive}
          >
            <Typography className={classes.name}>{t('header.events')}</Typography>
          </NavLink>
        )}
        {(UserPresenter.hasTeacherAccess(currentUser) || UserPresenter.hasHrAccess(currentUser)) && (
          <NavLink to={appRoutes.adminUsersPath()} className={classes.link} activeClassName={classes.linkActive}>
            <Typography className={classes.name}>{t('header.adminPanel')}</Typography>
          </NavLink>
        )}

        <NavLink to={appRoutes.vacanciesPath()} className={classes.link} activeClassName={classes.linkActive}>
          <Typography className={classes.name}>{t('header.vacancies')}</Typography>
        </NavLink>

        {isFulfilled &&
          map(
            page => (
              <NavLink
                key={PagePresenter.id(page)}
                to={appRoutes.pagePath(PagePresenter.slug(page))}
                className={classes.link}
                activeClassName={classes.linkActive}
              >
                <Typography className={classes.name}>{PagePresenter.name(page)}</Typography>
              </NavLink>
            ),
            pages,
          )}
      </Box>

      <Box display="flex" alignItems="center">
        <ContextCourse courses={courses} currentCourseId={currentCourseId} />

        <Box className={classes.iconButtons}>
          <LanguageMenu />
          <UserMenu />
          {UserPresenter.isImpersonated(currentUser) && (
            <IconButton onClick={handleStopImpersonate} aria-label="Stop impersonate">
              <StopScreenShare />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );

  const mobileMenuId = 'header-nav-mobile';
  const mobileMenu = (
    <Box>
      <IconButton
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="primary"
        size="medium"
      >
        <Icon name="mobileMenu" />
      </IconButton>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={0}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        PaperProps={{
          className: classes.menuPaperMobile,
        }}
      >
        <IconButton onClick={handleMobileMenuClose} color="primary" className={classes.closeIcon}>
          <CloseRounded width="14" />
        </IconButton>

        <Box component="nav" className={classes.navItems}>
          <Box mb={3}>
            <MenuItem onClick={handleMobileMenuClose} disableGutters>
              <UserMenu />
            </MenuItem>
          </Box>

          {currentCourseId && (
            <MenuItem onClick={handleMobileMenuClose} disableGutters component="ul" className={classes.courseMenu}>
              <CourseMenu currentCourseId={currentCourseId} courses={courses} currentUser={currentUser} />
            </MenuItem>
          )}

          {!currentCourseId && (
            <NavLink to={appRoutes.coursesPath()} className={classes.link} activeClassName={classes.linkActive}>
              <MenuItem onClick={handleMobileMenuClose} disableGutters>
                <Typography className={classes.name}>{t('header.courses')}</Typography>
              </MenuItem>
            </NavLink>
          )}

          <NavLink to={appRoutes.vacanciesPath()} className={classes.link} activeClassName={classes.linkActive}>
            <MenuItem onClick={handleMobileMenuClose} disableGutters>
              <Typography className={classes.name}>{t('header.vacancies')}</Typography>
            </MenuItem>
          </NavLink>

          {isFulfilled &&
            map(
              page => (
                <NavLink
                  key={PagePresenter.id(page)}
                  to={appRoutes.pagePath(PagePresenter.slug(page))}
                  className={classes.link}
                  activeClassName={classes.linkActive}
                >
                  <Typography className={classes.name}>{PagePresenter.name(page)}</Typography>
                </NavLink>
              ),
              pages,
            )}

          <MenuItem onClick={handleMobileMenuClose} disableGutters>
            <a href={externalRoutes.dualbootpartnersPath()} target="_blank" rel="noreferrer" className={classes.link}>
              <Typography className={classes.name}>{t('mainPage.about')}</Typography>
            </a>
          </MenuItem>

          <Divider classes={{ root: classes.divider }} orientation="horizontal" variant="middle" />

          <Box className={classes.iconButtons}>
            <MenuItem onClick={handleMobileMenuClose} disableGutters>
              <LanguageMenu />
            </MenuItem>
            <MenuItem onClick={handleLogout} disableGutters>
              <Button
                className={classes.mobileMenuLink}
                key={uniqueid(t('header.logout'))}
                startIcon={<Icon name="logout" width="19" />}
              >
                {t('header.logout')}
              </Button>
            </MenuItem>
          </Box>
        </Box>
      </Menu>
    </Box>
  );

  return (
    <Toolbar className={classes.grow}>
      <Logo isDividerShown={!isNil(currentUser) && matchesDesktop} />
      {matchesMobile && mobileMenu}
      {matchesDesktop && desktopMenu}
    </Toolbar>
  );
};

HeaderMenu.propTypes = {
  courses: PropTypes.arrayOf(CoursePresenter.shape()),
  currentCourseId: PropTypes.number,
};

HeaderMenu.defaultProps = {
  courses: null,
  currentCourseId: null,
};

export default HeaderMenu;
