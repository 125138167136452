import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, equals, or, isEmpty, append, without, length, not, map, values } from 'ramda';

import {
  CircularProgress,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Breadcrumbs from 'components/Breadcrumbs';

import UserPresenter from 'presenters/UserPresenter';
import ExercisePresenter from 'presenters/ExercisePresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminTheories from 'hooks/admin/useTheories';
import useAdminLessons from 'hooks/admin/useLessons';
import useAdminCourses from 'hooks/admin/useCourses';
import useNumberParams from 'hooks/useNumberParams';
import { calculateNextOrder } from 'utils/calculateNextOrder';
import useRedirects from 'hooks/redirects';

import findById from 'utils/findById';
import { isBlank } from 'utils/helpers';
import exerciseStates from 'utils/exerciseStates';
import { camelize } from 'utils/strings';

import ModalConfirmation from 'components/ModalConfirmation';
import TheoryModal from './components/TheoryModal';
import Theories from './components/Theories';

import useStyles from './styles';

const TheoriesEditPage = ({ mode }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { courseId, lessonId, theoryId } = useNumberParams(['courseId', 'lessonId', 'theoryId']);
  const { redirectToAdminTheoriesPath, redirectToAdminTheoryNewPath } = useRedirects();

  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentTheory, setCurrentTheory] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulkState, setBulkState] = useState('');

  const { currentUser } = useCurrentUser();
  const {
    theories,
    isLoading,
    loadAdminTheories,
    resetAdminTheories,
    createTheory,
    updateTheory,
    destroyTheory,
    bulkUpdate,
  } = useAdminTheories();
  const { loadAdminLesson, resetAdminLesson } = useAdminLessons();
  const { loadAdminCourse, resetAdminCourse } = useAdminCourses();

  useEffect(() => {
    loadAdminTheories(courseId, lessonId);
    loadAdminLesson(courseId, lessonId);
    loadAdminCourse(courseId);
    return () => {
      resetAdminTheories();
      resetAdminLesson();
      resetAdminCourse();
    };
  }, []);

  const initialOrder = 0;
  const nextOrder = isBlank(theories) ? initialOrder : calculateNextOrder(theories);
  const theory = isBlank(theories) ? null : findById(theories, theoryId);
  const isModalOpen = or(equals(mode, 'edit'), equals(mode, 'new'));

  const handleCreate = () => redirectToAdminTheoryNewPath(courseId, lessonId);

  const loadTheoriesAndCloseModal = () => {
    loadAdminTheories(courseId, lessonId);
    redirectToAdminTheoriesPath(courseId, lessonId);
  };

  const handleTheoryCreate = params => createTheory(courseId, lessonId, params).then(() => loadTheoriesAndCloseModal());

  const handleTheoryUpdate = params =>
    updateTheory(courseId, lessonId, theory.id, params).then(() => loadTheoriesAndCloseModal());

  const handleModalOpenDestroy =
    (theoryData = null) =>
    () => {
      setModalDeleteOpen(true);
      if (theoryData) {
        setCurrentTheory(theoryData);
      }
    };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentTheory(null);
  };

  const handleTheoryDestroy = async () => {
    await destroyTheory(courseId, lessonId, currentTheory.id);
    handleModalDeleteClose();
    loadAdminTheories(courseId, lessonId);
  };

  const handleSelect = item => e => {
    if (e.target.checked) {
      setSelected(append(item, selected));
    } else {
      setSelected(without([item], selected));
    }
  };

  const handleSelectAll = () => {
    if (length(theories) === length(selected)) {
      setSelected([]);
    } else {
      setSelected(theories);
    }
  };

  const handleBulkUpdate = async () => {
    const list = map(u => ({ id: ExercisePresenter.id(u), state: bulkState }), selected);
    await bulkUpdate(courseId, lessonId, { theories: list });
    setSelected([]);
    loadAdminTheories(courseId, lessonId);
  };

  return (
    <Container component="main" maxWidth="lg" classes={{ root: classes.rootContainer }}>
      <Paper elevation={0} variant="outlined" classes={{ outlined: classes.outlined }}>
        <Box p={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Breadcrumbs />
            </Grid>
            {UserPresenter.hasMentorAccess(currentUser) && (
              <Grid item>
                <Button color="primary" variant="outlined" onClick={handleCreate}>
                  {t('adminExercisesEditPage.createTheory')}
                </Button>
              </Grid>
            )}
          </Grid>

          {isNil(theories) || isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <Theories
              courseId={courseId}
              lessonId={lessonId}
              theories={theories}
              onModalOpenDestroy={handleModalOpenDestroy}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              selected={selected}
            />
          )}
          <TheoryModal
            theory={theory}
            nextOrder={nextOrder}
            isModalOpen={isModalOpen}
            isLoading={isNil(theories) || isLoading}
            onTheoryCreate={handleTheoryCreate}
            onTheoryUpdate={handleTheoryUpdate}
          />

          <ModalConfirmation
            isOpen={isModalDeleteOpen}
            onClose={handleModalDeleteClose}
            onConfirm={handleTheoryDestroy}
            title={t('adminExercisesEditPage.deleteTheoryModalTitle')}
            content={t('adminExercisesEditPage.deleteTheoryModalContent')}
          />
        </Box>
      </Paper>

      {not(isEmpty(selected)) && (
        <Paper elevation={12} style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Container component="div" maxWidth="lg">
            <Box py={2} px={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item container xs={6} spacing={4} alignItems="center">
                  <Grid item>
                    <Typography>
                      {t('common.selected')}
                      {length(selected)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel id="state-label">{t('adminEditLessonTheoryModal.state')}</InputLabel>
                    <Select
                      disabled={isLoading}
                      labelId="state-label"
                      id="state-select"
                      value={bulkState}
                      onChange={e => setBulkState(e.target.value)}
                      fullWidth
                    >
                      {values(exerciseStates).map(state => (
                        <MenuItem key={state} value={state}>
                          {t(`adminEditLessonTheoryModal.states.${camelize(state)}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={handleBulkUpdate}>
                    {t('common.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      )}
    </Container>
  );
};

TheoriesEditPage.propTypes = {
  mode: PropTypes.string,
};

TheoriesEditPage.defaultProps = {
  mode: null,
};

export default TheoriesEditPage;
