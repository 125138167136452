import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 400,
    marginBottom: 16,
  },
  list: {
    paddingTop: 0,
  },
  lessontItem: {
    paddingLeft: 0,
    paddingBottom: 1,
    marginBottom: theme.spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

export default useStyles;
