import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import dayjs from 'dayjs';

import Presenter from 'utils/PropTypesPresenter';
import userTheoryStates from 'utils/userTheoryStates';
import { NOT_STARTED } from 'utils/helpers';

export const THEORY_BADGE_VARIANTS = {
  completed: 'completed',
  rejected: 'warning',
  uncompleted: 'light',
  notStarted: 'light',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
    isLink: PropTypes.bool,
    isDoc: PropTypes.bool,
    isOnlyOnce: PropTypes.bool,
    userState: PropTypes.string,
    updatedAt: PropTypes.string,
  },
  {
    nameWithDoc(theory) {
      return this.isDoc(theory) ? `${this.name(theory)} *` : this.name(theory);
    },
    isCompleted(theory) {
      return this.userState(theory) === userTheoryStates.completed;
    },
    isRejected(theory) {
      return this.userState(theory) === userTheoryStates.rejected;
    },
    isStarted(theory) {
      return !isNil(this.userState(theory));
    },
    isCurrent(theory, currentTheoryId) {
      return this.id(theory) === currentTheoryId;
    },
    updatedDate(theory) {
      return dayjs(this.updatedAt(theory)).format('D MMMM YYYY, HH:mm');
    },
    badgeVariant(theory) {
      const userState = isNil(this.userState(theory)) ? NOT_STARTED : this.userState(theory);
      return THEORY_BADGE_VARIANTS[userState];
    },
  },
);
