import storeModuleCreators from 'utils/store';
import AdminCoursesRepository from 'repositories/AdminCoursesRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['adminCourse'],
  repositoryAction: AdminCoursesRepository.show,
  responseKey: 'course',
});

export default slice.reducer;
