import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MultiTextInput = ({ onChange, label, items, error, helperText }) => {
  const handleChange = (...args) => {
    const [, values] = args;

    onChange(values);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
      }
      renderInput={params => (
        <TextField {...params} error={error} helperText={helperText} variant="outlined" label={label} />
      )}
      onChange={handleChange}
      value={items}
    />
  );
};

MultiTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

MultiTextInput.defaultProps = {
  error: false,
  helperText: null,
};

export default MultiTextInput;
