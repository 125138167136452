import React from 'react';
import PropTypes from 'prop-types';

import useCurrentUser from 'hooks/useCurrentUser';
import { Header, GuestHeader } from 'layouts/containers/Header';

import { isNil } from 'ramda';
import useStyles from './styles';

const Dashboard = props => {
  const { children } = props;
  const classes = useStyles();
  const { currentUser } = useCurrentUser();

  return (
    <div className={classes.root}>
      {isNil(currentUser) ? <GuestHeader /> : <Header />}
      {children}
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
};

Dashboard.defaultProps = {
  children: null,
};

export default Dashboard;
