import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 999,
  },

  root: {
    padding: [32, 16],
    maxWidth: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      padding: 32,
    },

    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      padding: ['7vw', 0],
      maxWidth: '1100px',
    },
  },

  grid: {
    display: 'grid',
    gap: 24,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gridTemplateColumns: '1fr 1fr',
      gap: 16,
    },
  },
  vacancies: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    order: 3,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      order: -1,
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    letterSpacing: '0em',
    marginTop: 0,
    marginBottom: 16,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(35),
      marginBottom: 24,
    },
  },

  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 400,
    color: theme.palette.common.black1,
    letterSpacing: '-0.15px',
    marginTop: 0,
    marginBottom: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(21),
      lineHeight: theme.typography.pxToRem(26),
      color: theme.palette.common.brandingBlack,
      marginBottom: 38,
    },
  },

  noVacanciesCard: {
    gap: 16,
    '& h5': {
      color: theme.palette.common.black1,
    },
    '& p': {
      color: theme.palette.common.brandingGray2,
      letterSpacing: '-0.15px',
    },
  },
}));

export default useStyles;
