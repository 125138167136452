import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    maxHeight: '100%',
    overflowY: 'scroll',
  },

  paper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: [0, 0, 0, 1, `${theme.palette.common.silverGray}`],
    padding: 20,
    width: '90%',
    overflowY: 'auto',
    position: 'absolute',
    top: '5rem',
  },

  formBox: {
    '& > *': {
      margin: 10,
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: 14,
    },
    '& .rc-md-editor': {
      margin: [10, 0],
    },
  },

  state: {
    fontSize: 14,
  },
}));

export default useStyles;
