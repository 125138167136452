import PropTypes from 'prop-types';
import { differenceInYears, format } from 'date-fns';
import { join, map, pipe, head, toUpper, isNil } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

import PracticePresenter from './User/PracticePresenter';
import TheoryPresenter from './User/TheoryPresenter';
import GroupPresenter from './GroupPresenter';

export const USER_ROLES = {
  teacher: 'teacher',
  student: 'student',
  admin: 'admin',
  mentor: 'mentor',
  hr: 'hr',
};

export const HIRING_STATES = {
  none: 'none',
  joined_course: 'joined_course',
  can_be_hired: 'can_be_hired',
  interview_scheduled: 'interview_scheduled',
  hired: 'hired',
  refused_by_us: 'refused_by_us',
  refused_by_candidate: 'refused_by_candidate',
  fired: 'fired',
};

const JOINED = 'joined';
const COMPLETED = 'completed';
const FULLTIME = 'full_time';
const DISTANCE = 'distance';

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    githubAccountLink: PropTypes.string,
    role: PropTypes.string,
    birthday: PropTypes.string,
    rating: PropTypes.number,
    githubAccount: PropTypes.string,
    phone: PropTypes.string,
    contact: PropTypes.string,
    createdAt: PropTypes.string,
    password: PropTypes.string,
    lastVisit: PropTypes.string,
    userCourseRating: PropTypes.number,
    courseRating: PropTypes.number,
    userTheories: PropTypes.arrayOf(TheoryPresenter.shape()),
    userPractices: PropTypes.arrayOf(PracticePresenter.shape()),
    courseState: PropTypes.string,
    courseType: PropTypes.string,
    provider: PropTypes.string,
    smallAvatarUrl: PropTypes.string,
    bigAvatarUrl: PropTypes.string,
    hiringState: PropTypes.string,
    impersonated: PropTypes.bool,
    progress: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        practices: PropTypes.shape({ count: PropTypes.number, done: PropTypes.number }),
        theories: PropTypes.shape({ count: PropTypes.number, done: PropTypes.number }),
      }),
    ),
    groups: PropTypes.arrayOf(GroupPresenter.shape()),
  },
  {
    theories(user) {
      return this.userTheories(user);
    },
    practices(user) {
      return this.userPractices(user);
    },
    isTeacher(user) {
      return this.role(user) === USER_ROLES.teacher;
    },
    isStudent(user) {
      return this.role(user) === USER_ROLES.student;
    },
    isAdmin(user) {
      return this.role(user) === USER_ROLES.admin;
    },
    isMentor(user) {
      return this.role(user) === USER_ROLES.mentor;
    },
    isHr(user) {
      return this.role(user) === USER_ROLES.hr;
    },
    fullName(user) {
      return `${this.firstName(user)} ${this.lastName(user)}`;
    },
    age(user) {
      return differenceInYears(new Date(), new Date(this.birthday(user)));
    },
    registrationDate(user) {
      return format(new Date(this.createdAt(user)), 'MM/dd/yyyy');
    },
    initials(user) {
      const fullName = [user.firstName, user.lastName];
      return join('', map(pipe(head, toUpper), fullName));
    },
    isJoined(course) {
      return this.courseState(course) === JOINED;
    },
    isCompleted(course) {
      return this.courseState(course) === COMPLETED;
    },
    isFullTime(course) {
      return this.courseType(course) === FULLTIME;
    },
    isDistance(course) {
      return this.courseType(course) === DISTANCE;
    },
    phoneNumberWithoutCountryCode(user) {
      return this.phone(user).replace(/^\+/, '');
    },
    hasAvatar(user) {
      return !isNil(this.smallAvatarUrl(user));
    },
    isImpersonated(u) {
      return this.impersonated(u);
    },
    groupIds(u) {
      return this.groups(u).map(uu => uu.id);
    },
    progressPercentage(u) {
      if (!this.userCourseRating(u) || !this.courseRating(u)) {
        return '0%';
      }

      return `${((this.userCourseRating(u) / this.courseRating(u)) * 100).toFixed(0)}%`;
    },
    hasAdminAccess(u) {
      return this.isAdmin(u);
    },
    hasMentorAccess(u) {
      return this.isAdmin(u) || this.isMentor(u);
    },
    hasTeacherAccess(u) {
      return this.isAdmin(u) || this.isMentor(u) || this.isTeacher(u);
    },
    hasHrAccess(u) {
      return this.isHr(u);
    },
  },
);
