import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import List from '@material-ui/core/List';

import appRoutes from 'routes/appRoutes';
import findById from 'utils/findById';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { lessonsHooks } from 'store/user/lessonsSlice';

import LessonItem from 'components/LessonItem';
import LessonPresenter from 'presenters/User/LessonPresenter';
import LessonsListSkeleton from './components/LessonsListSkeleton';

import useStyle from './styles';

const LessonsList = props => {
  const classes = useStyle();
  const { courseId, lessonId } = props;

  const {
    firstLoadLessons,
    lessons: { items: userLessons, loadingStatus },
  } = lessonsHooks.use();

  const { isIdle } = useFetchStatus(loadingStatus);

  useEffect(() => {
    firstLoadLessons({ course_id: courseId });
  }, [courseId]);

  if (isIdle) {
    return <LessonsListSkeleton />;
  }

  const renderLessons = () => {
    const currentLesson = findById(userLessons, lessonId);

    return userLessons.map(lesson => {
      const isCurrent = LessonPresenter.isCurrentLesson(currentLesson, lesson);
      const itemClasses = clsx(classes.listItem, { [classes.listItemCurrent]: isCurrent });

      return (
        <Link
          className={classes.link}
          key={LessonPresenter.id(lesson)}
          to={appRoutes.courseLessonTheoriesPath(courseId, LessonPresenter.id(lesson))}
        >
          <LessonItem courseLesson={lesson} lessonClasses={itemClasses} isCurrent={isCurrent} />
        </Link>
      );
    });
  };

  return <List disablePadding>{renderLessons()}</List>;
};

LessonsList.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
};

export default LessonsList;
