import { isEmpty, either, isNil } from 'ramda';

export const NOT_STARTED = 'notStarted';

export const isUndefined = value => value === undefined;

export const isNull = value => value === null;

export const isNullOrUndefined = value => isNull(value) || isUndefined(value);

export const isBlank = either(isEmpty, isNil);

export default {
  isUndefined,
  isNull,
  isNullOrUndefined,
  isBlank,
};
