import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputLabel, MenuItem, Select } from '@material-ui/core';

import { HIRING_STATES } from 'presenters/UserPresenter';

const HiringStateSelect = props => {
  const { value: currentValue, onChange, disabled, allowEmpty } = props;
  const { t } = useTranslation();

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <InputLabel id="user-hiring-state-label">{t('adminUserPage.hiringState')}</InputLabel>
      <Select
        disabled={disabled}
        labelId="user-hiring-state-label"
        id="user-hiring-state-select"
        value={currentValue}
        onChange={handleChange}
        fullWidth
      >
        {allowEmpty && (
          <MenuItem key="none" value={undefined}>
            &nbsp;
          </MenuItem>
        )}

        {Object.values(HIRING_STATES).map(hiringState => (
          <MenuItem key={hiringState} value={hiringState}>
            {t(`user.hiringStates.${hiringState}`)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

HiringStateSelect.defaultProps = {
  value: '',
  disabled: false,
  allowEmpty: false,
};

HiringStateSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
};

export default HiringStateSelect;
