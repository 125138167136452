import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import qs from 'qs';
import { isEmpty, isNil, last, filter, without, append, not, length, map } from 'ramda';

import useUserCourses from 'sharedContainers/User/Courses';
import { DEFAULT_ROWS_PER_PAGE, FIRST_PAGE } from 'utils/pagination';
import { redirectWithReloadPage } from 'utils/locationHelper';
import appRoutes from 'routes/appRoutes';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  Container,
} from '@material-ui/core';

import useUsers from 'hooks/admin/useUsers';
import useUser from 'hooks/admin/useUser';
import CoursePresenter from 'presenters/CoursePresenter';
import UserPresenter from 'presenters/UserPresenter';

import RoleSelect from 'components/RoleSelect';
import HiringStateSelect from 'components/HiringStateSelect';
import GroupSelect from 'sharedContainers/Admin/GroupSelect';
import UsersTable from './components/UsersTable';

const UsersPanel = () => {
  const { t } = useTranslation();

  const { courses, isLoading: isCoursesLoading } = useUserCourses();
  const [searchParams, setSearchParams] = useState({ byGroupId: [] });

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [bulkHiringState, setBulkHiringState] = useState('');
  const [page, setPage] = useState(FIRST_PAGE);
  const [showProgress, setShowProgress] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const handleRowsPerPageChange = newValue => setRowsPerPage(newValue);
  const calculateLoadEffects = () => qs.stringify({ ...searchParams, page, rowsPerPage, showProgress });
  const [loadEffects, setLoadEffects] = useState(calculateLoadEffects());
  const handlePageChange = newValue => setPage(newValue);

  const {
    usersData: { users },
    meta,
    processing: loading,
    processingError: loadingError,
    loadUsers,
    resetUsersData,
    impersonate,
    bulkUpdate,
  } = useUsers();

  const { destroyUser } = useUser();

  const handleImpersonate = id => () => impersonate(id).then(() => redirectWithReloadPage(appRoutes.coursesPath()));

  const handleFiltersChange = ({ target: { name, value } }) => setSearchParams({ ...searchParams, [name]: value });
  const handleFiltersChangeGroup = ({ target: { name, value } }) => {
    if (last(value) === null) {
      setSearchParams({ ...searchParams, byGroupId: [null] });
      return;
    }
    setSearchParams({ ...searchParams, [name]: filter(el => el !== null, value) });
  };

  const handleFiltersReset = () => {
    setShowProgress(false);
    setSearchParams({ byGroupId: [] });
  };
  const handleLoadUsers = useCallback(() => {
    loadUsers({
      courseId: searchParams.courseId,
      q: showProgress
        ? { ...searchParams, hiringStateNotIn: ['hired', 'fired'], s: 'user_course_rating desc' }
        : searchParams,
      page,
      perPage: rowsPerPage,
      ...(showProgress && { includes: ['progress'] }),
    });
  }, [searchParams, page, showProgress, rowsPerPage]);

  const handleSelect = user => e => {
    if (e.target.checked) {
      setSelectedUsers(append(user, selectedUsers));
    } else {
      setSelectedUsers(without([user], selectedUsers));
    }
  };

  const handleSelectAll = () => {
    if (length(users) === length(selectedUsers)) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users);
    }
  };

  const handleBulkHiringStateUpdate = async () => {
    const list = map(u => ({ id: UserPresenter.id(u), hiringState: bulkHiringState }), selectedUsers);
    await bulkUpdate({ users: list });
    setSelectedUsers([]);
    handleLoadUsers();
  };

  useDebounce(
    () => {
      const newLoadEffects = calculateLoadEffects();
      setLoadEffects(newLoadEffects);
    },
    100,
    [searchParams, page, rowsPerPage, showProgress],
  );

  useEffect(() => {
    if (page !== FIRST_PAGE) {
      setPage(FIRST_PAGE);
    }
  }, [searchParams]);

  useEffect(() => {
    handleLoadUsers();
    return () => {
      resetUsersData();
    };
  }, [loadEffects]);

  const handleChangeView = () => setShowProgress(!showProgress);

  return (
    <Box px={3} py={1}>
      <Grid container spacing={1}>
        <Grid item container justifyContent="space-between" spacing={1} alignItems="flex-end">
          <Grid item xs={2}>
            <TextField
              fullWidth
              name="findByFullNameOrEmail"
              label={t('adminUsersFilters.findByFullNameOrEmail')}
              value={searchParams.findByFullNameOrEmail || ''}
              onChange={handleFiltersChange}
              error={false}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <RoleSelect
                allowEmpty
                disabled={loading}
                value={searchParams.roleEq}
                onChange={value => handleFiltersChange({ target: { name: 'roleEq', value } })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <HiringStateSelect
                allowEmpty
                disabled={loading}
                value={searchParams.hiringStateEq}
                onChange={value => handleFiltersChange({ target: { name: 'hiringStateEq', value } })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {!(isEmpty(courses) || isNil(courses)) && (
              <FormControl fullWidth>
                <InputLabel id="filter-course-label">{t('adminUsersFilters.course')}</InputLabel>
                <Select
                  disabled={isCoursesLoading}
                  name="courseId"
                  labelId="filter-course-label"
                  id="filter-course-select"
                  value={searchParams.courseId || ''}
                  onChange={handleFiltersChange}
                >
                  <MenuItem key="none" value={undefined}>
                    &nbsp;
                  </MenuItem>
                  {courses.map(course => (
                    <MenuItem key={course.id} value={course.id}>
                      {CoursePresenter.name(course)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <GroupSelect
                allowEmpty
                value={searchParams.byGroupId || ''}
                onChange={value => handleFiltersChangeGroup({ target: { name: 'byGroupId', value } })}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item>
            {searchParams.courseId && (
              <FormControlLabel
                control={
                  <Checkbox checked={showProgress} onChange={handleChangeView} name="showProgress" color="default" />
                }
                label={t('adminUsersFilters.showProgress')}
              />
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleFiltersReset}>
              {t('adminUsersFilters.resetButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <UsersTable
        users={users}
        meta={meta}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        currentSort={searchParams.s}
        isCourseChosen={!isNil(searchParams.courseId)}
        rowsPerPage={rowsPerPage}
        loading={loading}
        loadingError={loadingError}
        onImpersonate={handleImpersonate}
        destroyUser={destroyUser}
        onFiltersChange={handleFiltersChange}
        showProgress={showProgress}
        loadUsers={handleLoadUsers}
        isSelectMode
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        selectedUsers={selectedUsers}
      />

      {not(isEmpty(selectedUsers)) && (
        <Paper elevation={12} style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <Container component="div" maxWidth="lg">
            <Box py={2} px={3}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item container xs={6} spacing={4} alignItems="center">
                  <Grid item>
                    <Typography>
                      {t('common.selected')}
                      {length(selectedUsers)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <HiringStateSelect disabled={loading} value={bulkHiringState} onChange={setBulkHiringState} />
                  </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={handleBulkHiringStateUpdate}>
                    {t('common.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      )}
    </Box>
  );
};

export default UsersPanel;
