import { apiV1VacanciesPath, apiV1VacancyPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1VacanciesPath();
    return FetchHelpers.get(path);
  },

  show(id) {
    const path = apiV1VacancyPath(id);
    return FetchHelpers.get(path);
  },
};
