import { FETCH_STATUSES } from 'enums';

const useFetchStatus = status => {
  const isIdle = status === FETCH_STATUSES.idle;
  const isPending = status === FETCH_STATUSES.pending;
  const isInit = status === FETCH_STATUSES.init;
  const isFulfilled = status === FETCH_STATUSES.fulfilled;
  const isFailed = status === FETCH_STATUSES.failed;
  const isFinished = isFulfilled || isFailed;

  return {
    isIdle,
    isInit,
    isPending,
    isFulfilled,
    isFailed,
    isFinished,
  };
};

export { FETCH_STATUSES, useFetchStatus };
