import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import dictionary from './dictionary';

const resolveIconPath = name => {
  return path([name], dictionary);
};

const Icon = props => {
  const { className, name, width } = props;
  return <img className={className} alt="icon" src={resolveIconPath(name)} width={width} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  width: '24px',
};

export default Icon;
