import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  badgeGroup: {
    display: 'flex',
    height: 'auto',
    borderRadius: 7,
    overflow: 'hidden',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'stretch',
  },

  badgeGroupThin: {
    display: 'flex',
    borderRadius: 3.5,
    overflow: 'hidden',
  },
}));

export default useStyles;
