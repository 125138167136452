import { apiV1StudentUserCoursesPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1StudentUserCoursesPath();
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiV1StudentUserCoursesPath();
    return FetchHelpers.post(path, params);
  },
};
