import { createTheme } from '@material-ui/core/styles';
import Color from 'color';

const white = '#fff';
const white1 = '#F2F5F8';
const white2 = '#F9FBFF';
const black = '#0f0f0f';
const black1 = '#000000';
const brandingBlack = '#212121';

const blue1 = '#286AA6';
const blue2 = '#00A3DA';
const blue3 = '#8C9AAD';
const blue4 = '#EBEFF2';
const blue5 = '#7F8FA4';
const blue6 = '#F2F5F8';
const blue7 = '#E6EAEE';
const blue8 = '#E7EBEF';
const brandingBlue = '#005AF0';
const navyBlue = '#364263';
const lightBlue = '#CCDEFC';
const lightBlue2 = '#E6EFFE';
const lightBlue3 = 'rgba(179, 206, 251, 0.5)';
const lightBlue4 = '#DDEAFF';
const darkBlue = '#202C4A';

const red = '#ED3732';
const red2 = '#EF4444';
const burgundy = '#AC3737';
const gray1 = '#E0E0E0';
const gray2 = '#B8C1CC';
const gray3 = '#9FA9BA';
const gray4 = '#f5f5f5';
const gray5 = '#ced4da';
const gray6 = '#edeff2';
const gray7 = '#e7ecf2';
const brandingGray1 = '#A6AEBB';
const brandingGray2 = '#5A6773';
const brandingGray3 = '#D5DDE5';
const gray8 = '#4B5563';
const gray9 = '#D9D9D9';
const gray10 = 'rgba(117, 117, 117, 0.54)';

const green = '#007a08';
const green2 = '#047857';
const lightGreen = '#ECFDF5';
const green3 = '#478437';

const purple = '#7C3AED';
const lightPurple = '#EDE9FE';

const athensGray = '#F2F4F6';
const jellyBean = '#286AA6';
const silverGray = '#8c9aad';
const midnightBlue = '#1a4976';
const listShadow = '#E1E7ED';
const transparent = 'transparent';
const ripeLemon = '#F8E71C';
const spindle = '#C5D5EC';
const linkWater = '#DBE5F4';
const whiteRock = '#EBE5D2';
const quarterSpanishWhite = '#FAF7ED';

export const fade = (color, alpha) => Color(color).alpha(alpha).string();

export const lighten = (color, amount) => Color(color).lighten(amount).string();

const theme = createTheme({
  fade,
  lighten,
  palette: {
    primary: {
      main: brandingBlue,
    },
    secondary: {
      main: red,
    },
    error: {
      main: red2,
    },
    common: {
      white,
      black,
      red,
      red2,
      burgundy,
      gray1,
      gray2,
      gray3,
      gray4,
      gray5,
      gray6,
      gray7,
      green,
      blue1,
      blue2,
      blue3,
      blue4,
      blue5,
      black1,
      white1,
      blue6,
      blue7,
      blue8,
      brandingBlue,
      brandingBlack,
      brandingGray1,
      brandingGray2,
      brandingGray3,
      white2,
      navyBlue,
      lightBlue,
      lightBlue2,
      lightBlue3,
      lightBlue4,
      darkBlue,
      gray8,
      gray9,
      gray10,
      lightGreen,
      green2,
      green3,
      purple,
      lightPurple,

      athensGray,
      jellyBean,
      midnightBlue,
      silverGray,
      listShadow,
      transparent,
      ripeLemon,
      spindle,
      linkWater,
      whiteRock,
      quarterSpanishWhite,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontFamilySerif: ['Libre Baskerville', 'Times New Roman, serif'].join(','),
    h5: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        color: blue3,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        minWidth: 0,
        borderRadius: 40,
      },
      outlined: {
        border: `1px solid ${brandingBlack}`,
        color: brandingBlack,
      },
      outlinedPrimary: {
        border: `1px solid ${brandingBlue}`,
      },
    },
    MuiCheckbox: {
      root: {
        color: blue1,
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: lighten(blue5, 0.62),
      },
    },
    MuiSwitch: {
      root: {
        width: 43,
        height: 27,
        padding: 0,
      },

      switchBase: {
        padding: 1.6,
        '&$checked': {
          transform: 'translateX(16px)',
          color: `${white} !important`,
          '& + $track': {
            backgroundColor: `${blue1} !important`,
            opacity: 1,
            border: `none`,
          },
        },
      },

      thumb: {
        width: 23.8,
        height: 23.8,
        border: `1.6px solid ${transparent} !important`,
        backgroundColor: white,
      },

      track: {
        borderRadius: 27 / 2,
        border: `none`,
        backgroundColor: blue4,
        opacity: 1,
        transition: ['background-color', 'border'],
      },

      checked: {},
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
        border: `1px solid ${brandingGray3}`,
        borderRadius: '4px',
        backgroundColor: white,
      },
    },
    MuiCardContent: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '&:last-child': {
          paddingBottom: undefined,
        },
      },
    },
    MuiAlert: {
      standardSuccess: {
        color: green3,
      },
    },
  },
  sizes: {
    sidebarContentWidth: 270,
    headerHeight: 73,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      tablet_portrait: 600,
      tablet_landscape: 960,
      laptop: 1024,
      desktop: 1280,
      tv: 1920,
    },
  },
  props: {
    MuiSelect: {
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
  },
});

export default theme;
