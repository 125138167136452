import { apiV1PasswordResetPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  resetPassword(params) {
    const path = apiV1PasswordResetPath();
    return FetchHelpers.post(path, params);
  },
  updatePassword(params) {
    const path = apiV1PasswordResetPath();
    return FetchHelpers.put(path, params);
  },
};
