import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { isEmpty } from 'ramda';

export default new Presenter(
  {
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    totalPages: PropTypes.number,
  },
  {
    page(meta) {
      if (isEmpty(meta)) return 1;
      return this.currentPage(meta);
    },
    hasMore(meta) {
      if (isEmpty(meta)) return true;
      return this.currentPage(meta) < this.totalPages(meta);
    },
  },
);
