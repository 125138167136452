/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminPagesRepository from 'repositories/AdminPagesRepository';

import { createRestSlice } from 'utils/RestSlice';

const PagesRestSlice = createRestSlice({
  resource: 'pages',
  repository: AdminPagesRepository,
  slices: ['loadPages', 'updateAllPages'],
});

const initialState = {
  ...PagesRestSlice.initialState,
};

const PagesSlice = createSlice({
  name: 'adminPages',
  initialState,
  reducers: {
    ...PagesRestSlice.reducers,
  },
});

export default PagesSlice.reducer;

export const pagesHooks = PagesRestSlice.hooks(PagesSlice, 'adminPages');
