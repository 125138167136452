import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Typography } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import useStyles from './styles';

const VacancyAsideCard = props => {
  const { title, content, emoji, extraContent, styles, accordionShown } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const seeMore = t('common.seeMore');
  const rollUp = t('common.rollUp');

  const [accordionSummary, setAccordionSummary] = useState(seeMore);

  const handleAccordionExpand = (e, expanded) => {
    if (expanded) {
      setAccordionSummary(rollUp);
    } else {
      setAccordionSummary(seeMore);
    }
  };

  return (
    <Card className={styles}>
      <CardContent className={classes.card}>
        <h4 className={classes.cardTitle}>
          <span role="img" aria-label="Give feedback" className={classes.cardEmoji}>
            {emoji}
          </span>
          {title}
        </h4>

        {!accordionShown && (
          <>
            <Typography className={classes.cardContent}>{content}</Typography>
            {extraContent}
          </>
        )}

        {accordionShown && (
          <Accordion className={classes.accordion} elevation={0} onChange={handleAccordionExpand}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded color="primary" />}
              aria-controls={`${title}-accordion`}
              id={`${title}-accordion`}
              IconButtonProps={{ classes: { root: classes.accordionIcon } }}
              classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
            >
              <Typography color="primary">{accordionSummary}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <>
                <Typography className={classes.cardContent}>{content}</Typography>
                {extraContent}
              </>
            </AccordionDetails>
          </Accordion>
        )}
      </CardContent>
    </Card>
  );
};

VacancyAsideCard.propTypes = {
  title: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  extraContent: PropTypes.node,
  styles: PropTypes.string,
  accordionShown: PropTypes.bool,
};

VacancyAsideCard.defaultProps = {
  extraContent: null,
  styles: null,
  accordionShown: false,
};

export default VacancyAsideCard;
