import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    height: 88,
    width: 88,
  },

  grid: {
    display: 'flex',
    alignItem: 'center',
    marginTop: 100,
  },

  button: {
    width: '100%',
    marginTop: 40,
  },

  text: {
    fontSize: theme.typography.pxToRem(24),
  },

  textButton: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
}));

export default useStyles;
