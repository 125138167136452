import humps from 'humps';
import { isPlural } from 'pluralize';

export function toResourceName(actionName, resource) {
  const capName = humps.pascalize(resource);
  if (isPlural(capName)) {
    return actionName.replace(capName, 'Resources');
  }

  return actionName.replace(capName, 'Resource');
}

export function toSliceName(actionName, resource) {
  return `${toResourceName(actionName, resource)}Slice`;
}

export function toRealName(actionName, resource) {
  const capName = humps.pascalize(resource);
  if (isPlural(capName)) {
    return actionName.replace('Resources', capName);
  }
  return actionName.replace('Resource', capName);
}

export function keysToRealName(resource) {
  return object => {
    return Object.keys(object).reduce((result, key) => ({ ...result, [toRealName(key, resource)]: object[key] }), {});
  };
}
