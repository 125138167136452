import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { head, isEmpty } from 'ramda';
import clsx from 'clsx';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  List,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

import appRoutes from 'routes/appRoutes';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { userTheoriesHooks } from 'store/user/theoriesSlice';
import TheoryPresenter from 'presenters/User/TheoryPresenter';
import findById from 'utils/findById';
import { isNull } from 'utils/helpers';
import TheoryItem from 'components/TheoryItem';
import TheoryContent from './components/TheoryContent';
import TheoriesListSkeleton from './components/TheoriesListSkeleton';

import useStyles from './styles';

const TheoriesList = props => {
  const { courseId, lessonId, theoryId } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  const {
    firstLoadTheories,
    resetTheories,
    theories: { items: userTheories, loadingStatus },
  } = userTheoriesHooks.use();

  const { isIdle, isFinished } = useFetchStatus(loadingStatus);

  useEffect(() => {
    firstLoadTheories(lessonId);
    return () => {
      resetTheories();
    };
  }, [lessonId]);

  useEffect(() => {
    if (isNull(theoryId) && isFinished) {
      const firstTheoryId = TheoryPresenter.id(head(userTheories));
      history.push(appRoutes.courseLessonTheoryPath(courseId, lessonId, firstTheoryId));
    }
  }, [userTheories]);

  const renderInfoBox = () => (
    <div className={classes.infoBox}>
      <Typography className={classes.infoKey}>* -</Typography>
      <Typography className={classes.infoValue}>{t('studentLesson.links')}</Typography>
    </div>
  );

  if (isIdle) {
    return (
      <div className={classes.container}>
        <TheoriesListSkeleton />
        {renderInfoBox()}
      </div>
    );
  }

  const currentTheory = findById(userTheories, theoryId);

  const renderTheories = () =>
    userTheories.map(theory => (
      <TheoryItem
        key={TheoryPresenter.id(theory)}
        userTheory={theory}
        lessonId={lessonId}
        courseId={courseId}
        theoryId={theoryId}
      />
    ));

  const renderMobileTheories = () => {
    return userTheories.map((theory, id) => {
      const uniqueId = lessonId + id;
      return (
        <Accordion
          expanded={expanded === uniqueId}
          onChange={handleChange(uniqueId)}
          key={TheoryPresenter.id(theory)}
          elevation={0}
          className={classes.accordion}
        >
          <AccordionSummary
            aria-controls={TheoryPresenter.id(theory)}
            aria-label={TheoryPresenter.id(theory)}
            id={TheoryPresenter.id(theory)}
            classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
          >
            <TheoryItem
              key={TheoryPresenter.id(theory)}
              userTheory={theory}
              lessonId={lessonId}
              courseId={courseId}
              theoryId={theoryId}
            >
              <ExpandMoreRounded
                className={
                  expanded === uniqueId ? classes.iconSummary : clsx(classes.iconSummary, classes.iconSummaryClosed)
                }
              />
            </TheoryItem>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box pb={3} width="100%">
              <Route path={appRoutes.courseLessonTheoryPath(courseId, lessonId, theoryId)}>
                <TheoryContent lessonId={lessonId} theoryId={theoryId} />
              </Route>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const laptopTheories = () => {
    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item md={4} sm={6}>
            <List disablePadding>{renderTheories()}</List>
          </Grid>
          <Grid item md={8} sm={6}>
            <Box px={4} py={3}>
              <Typography className={classes.currentTheoryName}>{TheoryPresenter.name(currentTheory)}</Typography>
              <Route path={appRoutes.courseLessonTheoryPath(courseId, lessonId, theoryId)}>
                <TheoryContent lessonId={lessonId} theoryId={theoryId} />
              </Route>
            </Box>
          </Grid>
        </Grid>
        {renderInfoBox()}
      </div>
    );
  };

  const mobileTheories = () => {
    return (
      <Box px={3} py={1} className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <List disablePadding>{renderMobileTheories()}</List>
          </Grid>
        </Grid>
        {renderInfoBox()}
      </Box>
    );
  };

  return !isEmpty(userTheories) ? (
    <>
      {matchesMobile && mobileTheories()}
      {matchesLaptop && laptopTheories()}
    </>
  ) : (
    <div className={classes.container}>
      <Box px={4} py={3}>
        {t('studentLesson.noTheory')}
      </Box>
    </div>
  );
};

TheoriesList.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  theoryId: PropTypes.number,
};

TheoriesList.defaultProps = {
  theoryId: null,
};

export default TheoriesList;
