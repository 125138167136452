import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  theoryItem: {
    padding: [16, 0, 16],
    alignItems: 'flex-start',

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      borderRight: `0.5px solid ${theme.palette.common.brandingGray3}`,
      borderLeft: `0.5px solid ${theme.palette.common.brandingGray3}`,
      padding: 16,

      '&:hover': {
        boxShadow: [1, 1, 9, 1, `${theme.fade(theme.palette.common.midnightBlue, 0.13)}`],
        background: theme.palette.common.white2,
      },
    },
  },

  theoryItemCurrent: {
    zIndex: 2,
    '& $link': {
      zIndex: 5,
    },
    '& .MuiCheckbox-root': {
      zIndex: 6,
    },

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      background: theme.palette.common.lightBlue3,
      '&:hover': {
        backgroundColor: theme.lighten(theme.palette.common.lightBlue3, 0.05),
      },
    },
  },

  theoryName: {
    marginLeft: '16px',
    flex: 'auto',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    color: theme.palette.common.brandingBlack,
  },

  link: {
    textDecoration: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 7,
  },
}));

export default useStyles;
