import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Link from 'components/RouterLink';

import appRoutes from 'routes/appRoutes';
import authModes, { isSignIn, isSignUp } from 'utils/authModes';

import useStyles from './styles';

const AuthTypeSwitch = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { mode } = props;

  const signInLinkClass = clsx({
    [classes.link]: true,
    [classes.linkActive]: isSignIn(mode),
  });

  const signInTextClass = clsx({
    [classes.text]: true,
    [classes.textActive]: isSignIn(mode),
  });

  const signUpLinkClass = clsx({
    [classes.link]: true,
    [classes.linkActive]: isSignUp(mode),
  });

  const signUpTextClass = clsx({
    [classes.text]: true,
    [classes.textActive]: isSignUp(mode),
  });

  return (
    <Grid container direction="row" justifyContent="space-around" alignItems="center">
      <Grid item xs={6}>
        <Link underline="none" to={appRoutes.signInPath()} className={signInLinkClass}>
          <Typography className={signInTextClass}>{t('signIn.signIn')}</Typography>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Link underline="none" to={appRoutes.signUpPath()} className={signUpLinkClass}>
          <Typography className={signUpTextClass}>{t('signUp.signUp')}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

AuthTypeSwitch.propTypes = {
  mode: PropTypes.oneOf(Object.values(authModes)).isRequired,
};

export default AuthTypeSwitch;
