import _ from 'lodash';
import { decamelize } from 'utils/keysConverter';

export function toFormDataQuery(key, value, lastKey = null) {
  if ((!_.isObject(value) && !_.isNil(value)) || value instanceof File) {
    return { key, value };
  }

  if (_.isArray(value)) {
    return _.flatten(
      value.map((arrayValue, i) => {
        const arKey = _.isObject(arrayValue) && lastKey.includes('_attributes') ? `${key}[${i}]` : `${key}[]`;
        return toFormDataQuery(arKey, arrayValue, lastKey);
      }),
    );
  }

  return _.map(value, (nestedValue, nestedKey) => toFormDataQuery(`${key}[${nestedKey}]`, nestedValue, nestedKey));
}

export function toFormData(model, attributes) {
  const formData = new FormData();
  const body = decamelize(attributes);
  const convertedParams = toFormDataQuery(model, body);
  _.flattenDeep(convertedParams).forEach(({ key, value }) => {
    formData.append(key, value);
  });
  return formData;
}
