import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';

import ChangebleTooltip from 'components/ChangebleTooltip';
import { THEORY_BADGE_VARIANTS } from 'presenters/User/TheoryPresenter';
import { PRACTICE_BADGE_VARIANTS } from 'presenters/User/PracticePresenter';

import useStyles from './styles';

const Badge = props => {
  const { variant, size, title, showTooltips, flexibleBadgeStyle, tooltipsPlacement } = props;
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const badgeSize = clsx(classes.badge, { [classes[`${size}Badge`]]: true });

  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const handleToggle = () => {
    setTooltipOpened(!isTooltipOpened);
  };

  const tooltipContent = <Typography className={classes.title}>{title}</Typography>;
  const tooltip = (
    <ChangebleTooltip
      isTooltipOpened={isTooltipOpened}
      onToggle={handleToggle}
      anchorEl={anchorRef.current}
      placement={tooltipsPlacement}
      tooltipContent={tooltipContent}
    />
  );
  switch (variant) {
    case PRACTICE_BADGE_VARIANTS.accepted:
      return (
        <span
          className={clsx(badgeSize, classes.badgeAcceptedPractice)}
          style={flexibleBadgeStyle}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          ref={anchorRef}
        >
          {showTooltips && tooltip}
        </span>
      );
    case PRACTICE_BADGE_VARIANTS.notStarted:
    case THEORY_BADGE_VARIANTS.notStarted:
      return (
        <span
          className={clsx(badgeSize, classes.badgeLight)}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          style={flexibleBadgeStyle}
          ref={anchorRef}
        >
          {showTooltips && tooltip}
        </span>
      );
    case THEORY_BADGE_VARIANTS.completed:
      return (
        <span
          className={clsx(badgeSize, classes.badgeCompletedTheory)}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          style={flexibleBadgeStyle}
          ref={anchorRef}
        >
          {showTooltips && tooltip}
        </span>
      );
    case PRACTICE_BADGE_VARIANTS.pending:
      return (
        <span
          className={clsx(badgeSize, classes.badgePendingPractice)}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          style={flexibleBadgeStyle}
          ref={anchorRef}
        >
          {showTooltips && tooltip}
        </span>
      );
    case PRACTICE_BADGE_VARIANTS.rejected:
      return (
        <span
          className={clsx(badgeSize, classes.badgeWarning)}
          style={flexibleBadgeStyle}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          ref={anchorRef}
        >
          {showTooltips && tooltip}
        </span>
      );
    default:
      return null;
  }
};

Badge.propTypes = {
  variant: PropTypes.oneOf(['completed', 'pendingTheory', 'pendingPractice', 'accepted', 'light', 'warning']),
  size: PropTypes.string.isRequired,
  title: PropTypes.string,
  showTooltips: PropTypes.bool,
  flexibleBadgeStyle: PropTypes.shape(),
  tooltipsPlacement: PropTypes.string,
};
Badge.defaultProps = {
  variant: null,
  showTooltips: false,
  flexibleBadgeStyle: null,
  tooltipsPlacement: null,
  title: null,
};

export default Badge;
