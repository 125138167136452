import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { map, length, isEmpty, includes } from 'ramda';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import CheckboxOutlineBlank from 'components/CheckboxOutlineBlank';

import UserPresenter from 'presenters/UserPresenter';
import LessonPresenter from 'presenters/LessonPresenter';

import appRoutes from 'routes/appRoutes';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminLessons from 'hooks/admin/useLessons';
import { camelize } from 'utils/strings';

import useStyles from './styles';

const Lessons = props => {
  const { courseId, lessons, onLessonModalOpen, onModalOpenDestroy, selected, onSelect, onSelectAll } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [widths, setWidths] = useState([]);
  const [sortedLessonsList, setSortedLessonsList] = useState(lessons);

  const { currentUser } = useCurrentUser();
  const { updateLessons } = useAdminLessons();

  const handleChange = ({ oldIndex, newIndex }) => {
    const newLessonsList = arrayMove(sortedLessonsList, oldIndex, newIndex);
    const lessonsOrders = newLessonsList.map((lesson, index) => ({ id: lesson.courseLessonsId, order: index }));
    setSortedLessonsList(newLessonsList);
    updateLessons(courseId, lessonsOrders);
  };

  return (
    <List
      lockVertically
      beforeDrag={({ elements, index }) => {
        const cells = Array.from(elements[index].children);
        const cellsWidths = cells.map(cell => window.getComputedStyle(cell).width);
        setWidths(cellsWidths);
      }}
      values={sortedLessonsList}
      onChange={handleChange}
      renderList={({ children, props: renderListProps }) => (
        <Table aria-label="lessons table">
          <TableHead>
            <TableRow>
              {UserPresenter.hasAdminAccess(currentUser) && (
                <TableCell padding="none">
                  <CheckboxOutlineBlank
                    indeterminate={length(selected) > 0 && length(selected) < length(lessons)}
                    checked={length(lessons) > 0 && length(lessons) === length(selected)}
                    onChange={onSelectAll}
                    disabled={isEmpty(lessons)}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}
              <TableCell>{t('adminLessonsTable.name')}</TableCell>
              <TableCell>{t('adminLessonsTable.state')}</TableCell>
              <TableCell>{t('adminLessonsTable.requiredTime')}</TableCell>
              <TableCell>{t('adminLessonsTable.createdAt')}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...renderListProps}>{children}</TableBody>
        </Table>
      )}
      renderItem={({ value, props: renderItemProps, isDragged }) => {
        const lessonId = LessonPresenter.id(value);
        const tdWidths = isDragged ? widths : [];
        const row = (
          <TableRow
            {...renderItemProps}
            style={{
              ...renderItemProps.style,
            }}
            classes={{ root: clsx(classes.cursor) }}
          >
            {UserPresenter.hasAdminAccess(currentUser) && (
              <TableCell style={{ width: tdWidths[0] }} padding="none">
                <CheckboxOutlineBlank
                  checked={includes(lessonId, map(LessonPresenter.id, selected))}
                  onChange={onSelect(value)}
                />
              </TableCell>
            )}
            <TableCell style={{ width: tdWidths[1] }}>{LessonPresenter.name(value)}</TableCell>
            <TableCell style={{ width: tdWidths[2] }}>
              {t(`adminEditLessonModal.states.${camelize(LessonPresenter.state(value))}`)}
            </TableCell>
            <TableCell style={{ width: tdWidths[3] }}>{LessonPresenter.requiredTime(value)}</TableCell>
            <TableCell style={{ width: tdWidths[4] }}>{LessonPresenter.createdAt(value)}</TableCell>
            <TableCell style={{ width: tdWidths[5] }} align="right">
              <Link to={appRoutes.adminTheoriesPath(courseId, lessonId)} className={classes.link}>
                {t('adminLessonsTable.theories')}
              </Link>
              <Link to={appRoutes.adminPracticesPath(courseId, lessonId)} className={classes.link}>
                {t('adminLessonsTable.practices')}
              </Link>
            </TableCell>
            <TableCell style={{ width: tdWidths[6] }} align="right">
              <IconButton onClick={onLessonModalOpen(value)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              {UserPresenter.hasMentorAccess(currentUser) && (
                <IconButton onClick={onModalOpenDestroy(value)}>
                  <DeleteIcon color="primary" fontSize="small" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        );
        return isDragged ? (
          <Table>
            <TableBody>{row}</TableBody>
          </Table>
        ) : (
          row
        );
      }}
    />
  );
};

Lessons.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessons: PropTypes.arrayOf(LessonPresenter.shape()).isRequired,
  onLessonModalOpen: PropTypes.func.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape()),
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

Lessons.defaultProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

export default Lessons;
