import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 15,
    width: 30,
    marginBottom: 5,
    borderRight: `1px solid ${theme.palette.common.blue4}`,
    '&:last-child': {
      borderTopRightRadius: 7,
      borderBottomRightRadius: 7,
    },
    '&:first-child': {
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
    },
    backgroundColor: theme.palette.primary.main,
  },

  smallItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 8,
    width: 30,
    marginBottom: 5,
    borderRight: `1px solid ${theme.palette.common.white}`,
    '&:last-child': {
      borderTopRightRadius: 3.5,
      borderBottomRightRadius: 3.5,
    },
    '&:first-child': {
      borderTopLeftRadius: 3.5,
      borderBottomLeftRadius: 3.5,
    },
  },

  flat: {
    border: 'none',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    '&:last-child': {
      borderRadius: 0,
    },
    '&:first-child': {
      borderRadius: 0,
    },
  },

  itemAcceptedPractice: {
    backgroundColor: theme.palette.primary.main,
  },

  itemCompletedTheory: {
    backgroundColor: theme.palette.common.blue2,
  },

  itemPendingPractice: {
    backgroundColor: theme.lighten(theme.palette.common.blue4, -0.15),
  },

  itemWarning: {
    backgroundColor: theme.palette.common.red,
  },

  itemLight: {
    backgroundColor: theme.palette.common.blue4,
  },

  list: {
    padding: 0,
  },

  title: {
    fontSize: 12,
    fontWeight: 600,
    padding: 10,
  },
}));

export default useStyles;
