import { apiV1AdminGroupsPath, apiV1AdminGroupPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = apiV1AdminGroupsPath();
    return FetchHelpers.get(path);
  },

  show(id) {
    const path = apiV1AdminGroupPath(id);
    return FetchHelpers.get(path);
  },

  create(group) {
    const path = apiV1AdminGroupsPath();
    return FetchHelpers.post(path, { group });
  },

  update(id, group) {
    const path = apiV1AdminGroupPath(id);
    return FetchHelpers.put(path, { group });
  },

  delete(id) {
    const path = apiV1AdminGroupPath(id);
    return FetchHelpers.delete(path);
  },
};
