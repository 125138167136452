import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [24, 16, 33],
    maxWidth: '1100px',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      padding: ['3.6vw', 0],
    },
  },
  backLink: {
    color: theme.palette.common.brandingGray2,
    display: 'inline-block',
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: '0em',
    textDecoration: 'none',

    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },
    '& svg': {
      marginRight: 11,
      width: 16,
      height: 18,
      transform: 'translateY(4px)',
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      marginBottom: 15,
    },
  },
  title: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 700,
    margin: [24, 0, 16],

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(35),
      margin: [24, 0],
    },
  },
  subtitle: {
    color: theme.palette.common.black1,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 400,
    letterSpacing: '-0.15px',
    marginBottom: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(21),
      lineHeight: theme.typography.pxToRem(27),
      marginBottom: 51,
    },
  },

  vacancyBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },

  article: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      display: 'grid',
      gap: 20,
      gridTemplateColumns: '1.04fr 1fr',
    },
  },

  articleContent: {
    padding: 32,

    '& h3': {
      color: theme.palette.common.brandingBlack,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(23),
      margin: [0, 0, 8],
    },

    '& p': {
      color: theme.palette.common.navyBlue,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      marginBottom: 32,
    },

    '& ul': {
      color: theme.palette.common.navyBlue,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
      marginBottom: 32,
      '&:last-child': {
        marginBottom: 0,
      },
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      '& h3': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(26),
        margin: [0, 0, 24],
      },
      '& p': {
        marginBottom: 40,
      },
      '& ul': {
        marginBottom: 40,
      },
    },
  },
}));

export default useStyles;
