import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Fade, Grid, Paper, Typography } from '@material-ui/core';
import { isNil } from 'ramda';

import useCurrentUser from 'hooks/useCurrentUser';
import parseFormErrors from 'utils/parserFormErrors';
import UserPresenter from 'presenters/UserPresenter';
import DefaultAvatar from 'components/DefaultAvatar';

import UserForm from './components/UserForm';
import useStyles from './styles';

const UserEditProfile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser, unlinkAccount, updateCurrentUser, loadCurrentUser } = useCurrentUser();

  const [provider, setProvider] = useState(UserPresenter.provider(currentUser));
  const [isUnlinkedAccount, setIsUnlinkedAccount] = useState(false);
  const [passwordMessage, setChangedPasswordMessage] = useState(null);
  const [githubAccount, setGithubAccount] = useState(UserPresenter.githubAccount(currentUser));
  const [isUpdateMessageShown, setIsUpdateMessageShown] = useState(false);

  const timerRef = useRef(null);
  useMemo(() => {
    return timerRef.current;
  }, [timerRef]);

  useEffect(() => {
    return clearTimeout(timerRef.current);
  }, []);

  const handleCurrentUserUpdateProvider = () => {
    return unlinkAccount().then(({ data }) => {
      setProvider(data.changedPasswordMessage);
      setIsUnlinkedAccount(true);
    });
  };

  const handleUpdateMessageShow = () => {
    setIsUpdateMessageShown(true);
    timerRef.current = setTimeout(() => {
      setIsUpdateMessageShown(false);
    }, 2000);
  };
  const handleSubmitSaveProfile = (values, { setErrors }) => {
    return updateCurrentUser({
      user: { ...values },
    })
      .then(({ data }) => {
        setChangedPasswordMessage(data.changedPasswordMessage);
        setGithubAccount(data.user.user.githubAccount);
        handleUpdateMessageShow();
        loadCurrentUser();
      })
      .catch(error => {
        const errors = parseFormErrors(error, ['email', 'githubAccount', 'password', 'phone']);
        setErrors(errors);
      });
  };

  return (
    <Fade in appear exit timeout={{ enter: 500, exit: 500 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <Typography className={classes.title}>{t('profile.editProfile')}</Typography>
            <Grid container direction="column" className={classes.profileInfo}>
              <Grid container spacing={2}>
                <Grid item container spacing={1} className={classes.logoNameBlock}>
                  <Grid item>
                    <DefaultAvatar user={currentUser} className={classes.avatar} />
                  </Grid>
                  <Grid item container className={classes.fullNameBlock}>
                    <Typography className={classes.fullName} color="textPrimary">
                      {UserPresenter.fullName(currentUser)}
                    </Typography>
                    <Button
                      onClick={handleCurrentUserUpdateProvider}
                      disabled={isNil(provider)}
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                    >
                      {t('profile.unlinkedGoogle')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" className={classes.divider} />
              <UserForm
                onSaveProfile={handleSubmitSaveProfile}
                currentUser={currentUser}
                changedPasswordMessage={passwordMessage}
                isUnlinkedAccount={isUnlinkedAccount}
                provider={provider}
                githubAccount={githubAccount}
                isUpdateMessageShown={isUpdateMessageShown}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default UserEditProfile;
