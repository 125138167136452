import React from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';

import { Button, TextField, InputAdornment, Typography, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import appRoutes from 'routes/appRoutes';
import SessionsRepository from 'repositories/SessionsRepository';
import { validationSchema, initialValues } from 'forms/signIn';
import parseFormErrors from 'utils/parserFormErrors';
import useCurrentUser from 'hooks/useCurrentUser';

import AuthTypeSwitch from 'containers/Authenticator/components/AuthTypeSwitch';
import Icon from 'components/Icon';
import Link from 'components/RouterLink';

import authModes from 'utils/authModes';
import { isUndefined } from 'utils/helpers';

import useStyles from './styles';

const SignIn = () => {
  const classes = useStyles();
  const { loadCurrentUser } = useCurrentUser();
  const { t } = useTranslation();

  const handleSubmitSignIn = (values, { setErrors }) => {
    return SessionsRepository.create({
      user: { ...values },
    })
      .then(() => {
        return loadCurrentUser();
      })
      .catch(error => {
        const errors = parseFormErrors(error, ['email']);
        setErrors({ serverSignInError: errors.email });
      });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitSignIn,
  });

  const { errors, values, handleChange, handleSubmit, isSubmitting } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  return (
    <>
      <AuthTypeSwitch mode={authModes.SIGN_IN} />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <div className={classes.formInputs}>
          <TextField
            error={!isUndefined(errors.email)}
            helperText={t(errors.email)}
            variant="standard"
            required
            fullWidth
            id="email"
            label={t('signIn.emailAddress')}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
            value={values.email}
            InputProps={
              !isUndefined(errors.email)
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <ErrorRoundedIcon color="error" />
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
          <TextField
            error={!isUndefined(errors.password)}
            helperText={t(errors.password)}
            variant="standard"
            required
            fullWidth
            name="password"
            label={t('signIn.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            value={values.password}
          />
          {errors.serverSignInError && <Alert severity="error">{errors.serverSignInError}</Alert>}
        </div>
        <Button
          type="submit"
          disabled={!isAbleToSubmit}
          className={classes.button}
          size="medium"
          variant="contained"
          color="primary"
        >
          <Typography className={classes.textButton}>{t('signIn.signIn')}</Typography>
        </Button>
        <Link className={classes.link} to={appRoutes.forgotPasswordPath()}>
          <Typography className={classes.textLink}>{t('signIn.forgotPassword')}</Typography>
        </Link>
      </form>
      <Box className={classes.actionButtons}>
        <div className={classes.separator}>
          <Typography className={classes.text}>{t('signIn.or')}</Typography>
        </div>
        <Button
          className={classes.button}
          startIcon={<Icon name="googleIcon" className={classes.icon} />}
          href={appRoutes.googleOauthPath()}
          variant="outlined"
        >
          <Typography className={classes.textButton}>{t('signIn.google')}</Typography>
        </Button>
      </Box>
    </>
  );
};

export default SignIn;
