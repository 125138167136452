import PropTypes from 'prop-types';
import formatDateWithTime from 'utils/dateTime';

import Presenter from 'utils/PropTypesPresenter';

import UserPresenter from './UserPresenter';
import ExercisePresenter from './ExercisePresenter';
import TeacherPresenter from './Activity/TeacherPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    prLink: PropTypes.string,
    type: PropTypes.string,
    user: UserPresenter.shape(),
    exercise: PropTypes.arrayOf(ExercisePresenter.shape()),
    courseRating: PropTypes.number,
    courseId: PropTypes.number,
    userCourseRating: PropTypes.number,
    teachers: PropTypes.arrayOf(TeacherPresenter.shape()),
    userExerciseFeedbacks: PropTypes.arrayOf(PropTypes.shape()),
  },
  {
    updatedDate(lastActivity) {
      return formatDateWithTime(this.updatedAt(lastActivity));
    },
    studentCourseRating(lastActivity) {
      return `(${this.userCourseRating(lastActivity)}/${this.courseRating(lastActivity)})`;
    },
  },
);
