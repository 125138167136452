import storeModuleCreators from 'utils/store';
import AdminLessonsRepository from 'repositories/AdminLessonsRepository';

const { createLoadActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createLoadActionStoreModule({
  slicePath: ['adminLessons'],
  repositoryAction: AdminLessonsRepository.index,
  responseKey: 'lessons',
});

export const createLesson = (courseId, lesson) => AdminLessonsRepository.create(courseId, lesson);
export const updateLesson = (courseId, id, lesson) => AdminLessonsRepository.update(courseId, id, lesson);
export const destroyLesson = (courseId, id) => AdminLessonsRepository.destroy(courseId, id);
export const updateLessons = (courseId, lessons) => AdminLessonsRepository.updateAll(courseId, lessons);
export const bulkUpdate = (courseId, lessons) => AdminLessonsRepository.bulkUpdate(courseId, lessons);

export default slice.reducer;
