import * as yup from 'yup';
import { PASSWORD_REGEX, GITHUB_REGEX } from 'utils/regularExpressions';
import UserPresenter from 'presenters/UserPresenter';

const validationFields = {
  firstName: yup.string().min(1, 'validations.firstName.minLength').required('validations.firstName.required'),
  lastName: yup.string().min(1, 'validations.lastName.minLength').required('validations.lastName.required'),
  email: yup
    .string()
    .min(1, 'validations.email.minLength')
    .max(255, 'validations.email.maxLength')
    .email('validations.email.invalid')
    .required('validations.email.empty'),
  password: yup.string().nullable().matches(PASSWORD_REGEX, { message: 'validations.password.invalid' }),
  githubAccount: yup
    .string()
    .required('validations.githubAccount.required')
    .matches(GITHUB_REGEX, { message: 'validations.githubAccount.invalid' }),
  contact: yup.string(),
  phone: yup.string().max(15, 'validations.phone.invalid').min(7, 'validations.phone.invalid'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = user => ({
  firstName: UserPresenter.firstName(user),
  lastName: UserPresenter.lastName(user),
  email: UserPresenter.email(user),
  password: '',
  githubAccount: UserPresenter.githubAccount(user) || '',
  phone: UserPresenter.phone(user) ? UserPresenter.phoneNumberWithoutCountryCode(user) : '',
  contact: UserPresenter.contact(user) || '',
});
