import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from 'components/Icon';

import useStyles from './styles';

const CheckboxOutlineBlank = ({ onChange, checked, disabled, dangerUnchecked }) => {
  const classes = useStyles();

  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      checkedIcon={<Icon name="checkbox" className={classes.checkboxIcon} />}
      className={clsx(classes.checkbox, { [classes.disabled]: disabled })}
      icon={
        dangerUnchecked ? (
          <Icon name="uncheckedDangerCheckbox" className={classes.checkboxIcon} />
        ) : (
          <Icon name="unfinishedLessonCheckbox" className={classes.checkboxIcon} />
        )
      }
      disableRipple
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
    />
  );
};

CheckboxOutlineBlank.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dangerUnchecked: PropTypes.bool,
};

CheckboxOutlineBlank.defaultProps = {
  disabled: false,
  dangerUnchecked: false,
};

export default CheckboxOutlineBlank;
