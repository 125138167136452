/* eslint-disable no-param-reassign */
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { createSelector } from 'utils/store';

export default ({ slicePath, repositoryAction, responseKey }) => {
  const slice = createSlice({
    name: slicePath.join('/'),
    initialState: {
      data: null,
      isLoading: false,
      loadingError: null,
    },
    reducers: {
      start(state) {
        state.isLoading = true;
        state.loadingError = null;
      },
      stop(state) {
        state.isLoading = false;
      },
      loadSuccess(state, { payload }) {
        state.data = payload[responseKey];
      },
      fail(state, { payload }) {
        state.data = null;
        state.loadingError = payload.error;
      },
      reset(state) {
        state.data = null;
        state.isLoading = false;
      },
      clearLoadingError(state) {
        state.loadingError = null;
      },
    },
  });

  const {
    actions: { start, stop, fail, loadSuccess, reset, clearLoadingError },
  } = slice;

  const selectors = {
    getData: createSelector(slicePath, 'data'),
    getIsLoading: createSelector(slicePath, 'isLoading'),
    getLoadingError: createSelector(slicePath, 'loadingError'),
  };

  const useActions = () => {
    const dispatch = useDispatch();

    return {
      load: (...params) => {
        dispatch(start());

        return repositoryAction(...params)
          .then(({ data }) => dispatch(loadSuccess(data)))
          .catch(error => {
            dispatch(fail());

            return Promise.reject(error);
          })
          .finally(() => {
            dispatch(stop());
          });
      },
      reset: () => dispatch(reset()),
      clearLoadingError: () => {
        dispatch(clearLoadingError());
      },
    };
  };

  return { slice, selectors, useActions };
};
