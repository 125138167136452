import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabList, TabContext, TabPanel } from '@material-ui/lab';
import { Tab } from '@material-ui/core';

import TeacherStatsPanel from './containers/TeacherStatsPanel';
import CourseStatsPanel from './containers/CourseStatsPanel';

const PANEL_NAME = {
  teachers: 'teachers',
  courses: 'courses',
};

const StatsPanel = () => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(PANEL_NAME.teachers);

  const handleChange = (events, newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <TabContext value={currentTab}>
      <TabList
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="admin stats menu tabs"
        variant="fullWidth"
      >
        <Tab value={PANEL_NAME.teachers} label={t('adminStats.teachers')} />
        <Tab value={PANEL_NAME.courses} label={t('adminStats.courses')} />
      </TabList>
      <TabPanel value={PANEL_NAME.teachers}>
        <TeacherStatsPanel />
      </TabPanel>
      <TabPanel value={PANEL_NAME.courses}>
        <CourseStatsPanel />
      </TabPanel>
    </TabContext>
  );
};

export default StatsPanel;
