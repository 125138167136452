import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { useFormik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Paper, Tab, Tabs } from '@material-ui/core';

import StackIconSelect from 'components/StackIconSelect';
import MultiTextInput from 'components/MultiTextInput';
import SelectTeachers from 'components/Select/Teachers';

import { validationSchema, initialValues, serialize } from 'forms/admin/createCourse';
import { isNullOrUndefined } from 'utils/helpers';
import { camelize } from 'utils/strings';
import { mdParser, MdEditor } from 'utils/markdown';

import useStyles from './styles';

const TabPanel = props => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = index => {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
};

const CourseModal = props => {
  const { course, nextOrder, isModalOpen, onCourseModalClose, onCourseCreate, onCourseUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasChanges, setHasChanges] = useState(false);

  const initValues = initialValues(course, nextOrder);
  const isCourseCreated = !isNil(course);

  const formik = useFormik({
    validationSchema,
    initialValues: initValues,
    onSubmit: values => {
      const action = isCourseCreated ? onCourseUpdate : onCourseCreate;
      action(serialize(values));
    },
    enableReinitialize: true,
  });

  const { values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, resetForm } = formik;
  const [selectedTab, setTab] = useState(0);
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const onbeforeunloadFn = event => {
      if (hasChanges) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign, prettier/prettier, no-param-reassign
        return event.returnValue = "";
      }
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [hasChanges]);

  const trackChanges = (fieldName, value) => {
    if (initValues[fieldName] !== value) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleIconChange = value => {
    trackChanges('icons', value);
    handleChange({ target: { name: 'icons', value } });
  };
  const handleDescriptionChange = ({ text }) => {
    trackChanges('description', text);
    handleChange({ target: { name: 'description', value: text } });
  };
  const handleIntroductionChange = ({ text }) => {
    trackChanges('introduction', text);
    handleChange({ target: { name: 'introduction', value: text } });
  };

  const renderSelectItems = () => {
    return values.availableStates.map(availableState => (
      <MenuItem value={availableState} classes={{ root: classes.state }} key={availableState}>
        {t(`adminEditCourseModal.states.${camelize(availableState)}`)}
      </MenuItem>
    ));
  };

  const handleModalClose = () => {
    if (hasChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('common.closeConfirm'))) {
        onCourseModalClose();
        resetForm();
        setTab(0);
        setHasChanges(false);
      }
    } else {
      onCourseModalClose();
      resetForm();
      setTab(0);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <Prompt when={hasChanges} message={t('common.promptMessage')} />
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
              <TextField
                required
                name="name"
                label={t('adminEditCourseModal.name')}
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.name)}
                helperText={t(errors.name)}
              />
              <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
                <StackIconSelect value={values.icons || []} onChange={handleIconChange} />
              </FormControl>

              <Paper square>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="content tabs"
                >
                  <Tab label={`${t('adminEditCourseModal.description')}`} {...a11yProps(0)} />
                  <Tab label={`${t('adminEditCourseModal.introduction')}`} {...a11yProps(1)} />
                </Tabs>
              </Paper>

              <TabPanel value={selectedTab} index={0}>
                <MdEditor
                  style={{ height: '200px' }}
                  name="description"
                  value={values.description}
                  label={t('adminEditCourseModal.description')}
                  renderHTML={text => mdParser.render(text)}
                  onChange={handleDescriptionChange}
                />
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <MdEditor
                  style={{ height: '200px' }}
                  name="introduction"
                  value={values.introduction}
                  label={t('adminEditCourseModal.introduction')}
                  renderHTML={text => mdParser.render(text)}
                  onChange={handleIntroductionChange}
                />
                <FormHelperText error>{t(errors.introduction)}</FormHelperText>
              </TabPanel>

              <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
                <InputLabel id="state">{t('adminEditCourseModal.state')}</InputLabel>
                <Select
                  labelId="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  label={t('adminEditCourseModal.state')}
                >
                  {renderSelectItems()}
                </Select>
                <FormHelperText error>{t(errors.state)}</FormHelperText>
                <FormHelperText>{t('adminEditCourseModal.states.internalPublishedMeaning')}</FormHelperText>
                <FormHelperText>{t('adminEditCourseModal.states.sunsetMeaning')}</FormHelperText>
              </FormControl>
              <MultiTextInput
                variant="outlined"
                label={t('adminEditCourseModal.accessDomains')}
                name="accessDomains"
                multiline
                onChange={v => setFieldValue('accessDomains', v)}
                disabled={isSubmitting}
                minRows={1}
                items={values.accessDomains}
                error={!isNullOrUndefined(errors.accessDomains)}
                helperText={t(errors.accessDomains)}
              />
              <SelectTeachers
                onChange={setFieldValue}
                name="teachers"
                label={t('adminEditCourseModal.teachers')}
                selected={values.teachers}
              />
              <Button type="submit" disabled={!isAbleToSubmit} color="primary" variant="contained">
                {t('adminEditCourseModal.submitButton')}
              </Button>
            </Box>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

CourseModal.propTypes = {
  course: PropTypes.shape(),
  nextOrder: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onCourseModalClose: PropTypes.func.isRequired,
  onCourseCreate: PropTypes.func.isRequired,
  onCourseUpdate: PropTypes.func.isRequired,
};

CourseModal.defaultProps = {
  course: null,
};

export default CourseModal;
