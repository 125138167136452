import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import { validationSchema, initialValues } from 'forms/admin/createLesson';
import { isNullOrUndefined } from 'utils/helpers';
import { camelize } from 'utils/strings';

import useStyles from './styles';

const LessonModal = props => {
  const { lesson, nextOrder, isModalOpen, onLessonModalClose, onLessonCreate, onLessonUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isLessonCreated = !isNil(lesson);
  const formik = useFormik({
    validationSchema,
    initialValues: initialValues(lesson, nextOrder),
    onSubmit: isLessonCreated ? onLessonUpdate : onLessonCreate,
    enableReinitialize: true,
  });
  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  const handleModalClose = () => {
    onLessonModalClose();
    resetForm();
  };

  const renderSelectItems = () => {
    return values.availableStates.map(availableState => (
      <MenuItem value={availableState} classes={{ root: classes.state }} key={availableState}>
        {t(`adminEditLessonModal.states.${camelize(availableState)}`)}
      </MenuItem>
    ));
  };

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
              <TextField
                required
                name="name"
                label={t('adminEditLessonModal.name')}
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.name)}
                helperText={t(errors.name)}
              />
              <TextField
                required
                name="requiredTime"
                label={t('adminEditLessonModal.requiredTime')}
                value={values.requiredTime}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.requiredTime)}
                helperText={t(errors.requiredTime)}
              />
              <FormControl variant="outlined" error={!isNullOrUndefined(errors.state)}>
                <InputLabel id="state">{t('adminEditLessonModal.state')}</InputLabel>
                <Select
                  labelId="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  label={t('adminEditLessonModal.state')}
                >
                  {renderSelectItems()}
                </Select>
                <FormHelperText error>{t(errors.state)}</FormHelperText>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={values.interviewTrigger} onChange={handleChange} name="interviewTrigger" />
                  }
                  label={t('adminEditLessonModal.interviewTrigger')}
                />
              </FormGroup>
              <Button type="submit" disabled={!isAbleToSubmit} color="primary" variant="contained">
                {t('adminEditLessonModal.submitButton')}
              </Button>
            </Box>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

LessonModal.propTypes = {
  lesson: PropTypes.shape(),
  nextOrder: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onLessonModalClose: PropTypes.func.isRequired,
  onLessonCreate: PropTypes.func.isRequired,
  onLessonUpdate: PropTypes.func.isRequired,
};

LessonModal.defaultProps = {
  lesson: null,
};

export default LessonModal;
