export const openNewTab = url => {
  window.open(url, '_blank');
};

export const reloadPage = () => {
  window.location.reload();
};

export const redirectWithReloadPage = url => {
  window.location.replace(url);
};

export const setLocationHash = hash => {
  window.location.hash = hash;
};
