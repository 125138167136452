import { configureStore, combineReducers } from '@reduxjs/toolkit';

import adminCoursesReducer from './admin/coursesSlice';
import adminCourseReducer from './admin/courseSlice';
import adminTeacherStats from './admin/teacherStatsSlice';
import adminCourseStats from './admin/courseStatsSlice';
import adminGroups from './admin/groupsSlice';
import adminGroup from './admin/groupSlice';
import adminGroupUsers from './admin/groupUsersSlice';
import adminVacancies from './admin/vacanciesSlice';
import adminVacancy from './admin/vacancySlice';
import adminPages from './admin/pagesSlice';
import adminPage from './admin/pageSlice';
import vacancies from './vacanciesSlice';
import vacancy from './vacancySlice';
import pages from './pagesSlice';
import page from './pageSlice';
import adminLessonsReducer from './admin/lessonsSlice';
import adminLessonReducer from './admin/lessonSlice';
import adminTheoriesReducer from './admin/theoriesSlice';
import adminPracticesReducer from './admin/practicesSlice';
import adminUsersReducer from './admin/usersSlice';
import adminUserCoursesReducer from './admin/user/userCoursesSlice';
import adminUserNotesReducer from './admin/user/userNotesSlice';
import courseReducer from './courseSlice';
import coursesReducer from './coursesSlice';
import currentUserReducer from './currentUserSlice';
import lessonReducer from './lessonSlice';
import userReducer from './admin/userSlice';
import userLessons from './user/lessonsSlice';
import userPractices from './user/practicesSlice';
import userTheories from './user/theoriesSlice';
import userTheory from './user/theorySlice';
import lastActivitiesReducer from './admin/LastActivitiesSlice';
import practiceReducer from './practiceSlice';
import studentCoursesReducer from './user/coursesSlice';
import progressDiagramDataReducer from './progressDiagramDataSlice';
import ratingSlice from './ratingSlice';
import ganttProgressDataReducer from './admin/ganttProgressDataSlice';

const store = configureStore({
  reducer: combineReducers({
    adminTeacherStats,
    adminCourseStats,
    adminVacancy,
    adminVacancies,
    adminPage,
    adminPages,
    adminGroup,
    adminGroupUsers,
    adminGroups,
    vacancies,
    vacancy,
    pages,
    page,
    userLessons,
    userPractices,
    userTheories,
    userTheory,
    adminCourses: adminCoursesReducer,
    adminCourse: adminCourseReducer,
    adminLessons: adminLessonsReducer,
    adminLesson: adminLessonReducer,
    adminTheories: adminTheoriesReducer,
    adminPractices: adminPracticesReducer,
    adminUserCourses: adminUserCoursesReducer,
    adminUserNotes: adminUserNotesReducer,
    adminUsers: adminUsersReducer,
    course: courseReducer,
    courses: coursesReducer,
    currentUser: currentUserReducer,
    lesson: lessonReducer,
    practice: practiceReducer,
    studentCourses: studentCoursesReducer,
    user: userReducer,
    lastActivities: lastActivitiesReducer,
    progressDiagramData: progressDiagramDataReducer,
    rating: ratingSlice,
    ganttProgressData: ganttProgressDataReducer,
  }),
});

export default store;
