import storeModuleCreators from 'utils/store';
import UsersRepository from 'repositories/UsersRepository';

const { createShowActionStoreModule } = storeModuleCreators;

export const { slice, selectors, useActions } = createShowActionStoreModule({
  slicePath: ['user'],
  repositoryAction: UsersRepository.show,
  responseKey: 'user',
});

export const updateUser = (id, params) => UsersRepository.update(id, params);
export const destroyUser = id => UsersRepository.destroy(id);

export default slice.reducer;
