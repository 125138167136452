import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    marginRight: 14,
    width: 34,
    height: 34,
    border: `2px solid ${theme.palette.common.blue7}`,
  },
}));

export default useStyles;
