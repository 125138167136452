import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import UserPresenter from './UserPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    message: PropTypes.string,
    author: UserPresenter.shape(),
  },
  {
    isAuthor(note, user) {
      return UserPresenter.id(this.author(note)) === UserPresenter.id(user);
    },
  },
);
