import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  layout: {
    display: 'flex',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    columnGap: 72,
    rowGap: 32,
    padding: 24,

    '& section': {
      whiteSpace: 'pre-wrap',
    },

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      display: 'grid',
      rowGap: 80,
      padding: 40,
    },
  },
  about: {
    gridArea: '1 / 1 / 2 / 2',
  },
  partners: {
    gridArea: '2 / 1 / 3 / 2',
  },
  tech: {
    gridArea: '1 / 2 / 3 / 3',
  },
  heading: {
    color: theme.palette.common.brandingBlack,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 600,
    display: 'flex',
    gap: 8,
    marginTop: 0,
    marginBottom: 8,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(31),
      marginBottom: 16,
    },
  },
  text: {
    color: theme.palette.common.brandingGray2,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 400,
    margin: 0,
  },
}));

export default useStyles;
