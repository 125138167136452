import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
  },
  card: {
    padding: [35, 40],
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    color: theme.palette.common.black1,
  },
  icons: {
    marginLeft: 24,
    display: 'grid',
    gap: '8px',
    gridAutoFlow: 'column',
  },
  content: {
    gap: '16px',
    padding: 24,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      padding: 32,
    },
  },
  vacancyName: {
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.brandingBlack,

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  description: {
    marginBottom: 0,
    color: theme.palette.common.brandingGray2,
    lineHeight: theme.typography.pxToRem(21),
  },
}));

export default useStyles;
