import React, { useEffect, useState } from 'react';
import { isNil, isEmpty, map } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TextField, Backdrop, Box, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import appRoutes from 'routes/appRoutes';
import { redirectWithReloadPage } from 'utils/locationHelper';

import useUserCourses from 'sharedContainers/User/Courses';

import UserCourseRepository from 'repositories/UserCourseRepository';

import CoursePresenter from 'presenters/User/CoursePresenter';

import CourseCard from 'components/CourseCard';
import CoursesCardsSkeleton from 'components/CoursesCardsSkeleton';

import useStyles from './styles';

const Courses = () => {
  const classes = useStyles();
  const { courses, loadCourses, isLoading, loadingError, clearLoadingError } = useUserCourses();
  const { t } = useTranslation();
  const [courseName, setCourseName] = useState('');

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const history = useHistory();
  const handleJoinCourse = course => () => {
    const courseId = CoursePresenter.id(course);
    const lessonId = CoursePresenter.firstLessonId(course);
    const path = matchesMobile
      ? appRoutes.courseLessonsPath(courseId)
      : appRoutes.courseLessonTheoriesPath(courseId, lessonId);

    if (CoursePresenter.isJoined(course) || CoursePresenter.isCompleted(course)) {
      return history.push(path);
    }

    const params = { userCourse: { courseId } };

    return UserCourseRepository.create(params).then(() => {
      redirectWithReloadPage(path);
    });
  };

  const handleLoadCourses = (newSearchValue = courseName) =>
    loadCourses({
      q: {
        nameCont: newSearchValue,
      },
    });

  const debouncedCoursesLoading = useDebouncedCallback(() => handleLoadCourses(courseName), 500);

  const handleChangeSearchValue = ({ target: { value } }) => setCourseName(value);

  useEffect(() => {
    debouncedCoursesLoading();
  }, [courseName]);

  if (isNil(courses) && !isNil(loadingError)) {
    return (
      <Backdrop open>
        <Alert severity="error" onClose={clearLoadingError}>
          {loadingError}
        </Alert>
      </Backdrop>
    );
  }

  return (
    <Container component="main" maxWidth="lg" className={classes.root}>
      <Box>
        {matchesMobile && (
          <Typography variant="h1" className={classes.title}>
            {t('header.courses')}
          </Typography>
        )}
        <div className={classes.form}>
          <TextField
            fullWidth
            name="nameCont"
            label={t('courses.filters.name')}
            value={courseName}
            onChange={handleChangeSearchValue}
            error={false}
          />
        </div>
        <div className={classes.paper}>
          {(isNil(courses) || isLoading) && <CoursesCardsSkeleton />}
          {!isEmpty(courses) &&
            !isNil(courses) &&
            map(
              course => (
                <CourseCard key={CoursePresenter.id(course)} course={course} onJoinCourse={handleJoinCourse(course)} />
              ),
              courses,
            )}
        </div>
      </Box>
    </Container>
  );
};

export default Courses;
