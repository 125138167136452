import { apiV1CoursesPath, apiV1CoursePath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  load() {
    const path = apiV1CoursesPath();
    return FetchHelpers.get(path);
  },

  show(courseId) {
    const path = apiV1CoursePath(courseId);
    return FetchHelpers.get(path);
  },
};
