import courseStates from 'utils/courseStates';
import lessonStates from 'utils/lessonStates';
import exerciseStates from 'utils/exerciseStates';
import pageStates from 'utils/pageStates';

export const NAME_REGEX = /^([а-яА-ЯёЁa-zA-Z]*)$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const GITHUB_REGEX = /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i;
// eslint-disable-next-line no-useless-escape
export const PHONE_REGEX = /^(\+\d)[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2}[-\s\.][0-9]{2}$/;
export const AVAILABLE_COURSE_STATES_REGEX = new RegExp(`(${Object.values(courseStates).join('|')})`);
export const AVAILABLE_LESSON_STATES_REGEX = new RegExp(`(${Object.values(lessonStates).join('|')})`);
export const AVAILABLE_EXERCISE_STATES_REGEX = new RegExp(`(${Object.values(exerciseStates).join('|')})`);
export const AVAILABLE_PAGE_STATES_REGEX = new RegExp(`(${Object.values(pageStates).join('|')})`);

export default {
  NAME_REGEX,
  PASSWORD_REGEX,
  GITHUB_REGEX,
  PHONE_REGEX,
  AVAILABLE_COURSE_STATES_REGEX,
  AVAILABLE_LESSON_STATES_REGEX,
  AVAILABLE_EXERCISE_STATES_REGEX,
  AVAILABLE_PAGE_STATES_REGEX,
};
