import { useSelector } from 'react-redux';

import { useActions } from 'store/admin/usersSlice';

const sliceName = 'adminUsers';

export default () => {
  const adminUsersActions = useActions();
  const loadUsers = params => adminUsersActions.loadUsers(params);
  const resetUsersData = () => adminUsersActions.resetData();
  const { impersonate, stopImpersonate, bulkUpdate } = adminUsersActions;
  const usersData = useSelector(state => state[sliceName].data);
  const meta = useSelector(state => state[sliceName].meta);
  const processing = useSelector(state => state[sliceName].processing);
  const processingError = useSelector(state => state[sliceName].processingError);

  return {
    usersData,
    meta,
    processing,
    processingError,
    loadUsers,
    resetUsersData,
    impersonate,
    stopImpersonate,
    bulkUpdate,
  };
};
