import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { useFormik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';

import { validationSchema, initialValues } from 'forms/admin/createGroup';
import { isNullOrUndefined } from 'utils/helpers';

import useStyles from './styles';

const GroupModal = props => {
  const { group, isModalOpen, onGroupModalClose, onGroupCreate, onGroupUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isGroupCreated = !isNil(group);

  const formik = useFormik({
    validationSchema,
    initialValues: initialValues(group),
    onSubmit: isGroupCreated ? onGroupUpdate : onGroupCreate,
    enableReinitialize: true,
  });

  const { values, errors, handleChange, handleSubmit, isSubmitting, resetForm } = formik;
  const isAbleToSubmit = !isSubmitting && isEmpty(errors);

  const handleModalClose = () => {
    onGroupModalClose();
    resetForm();
  };

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" classes={{ root: classes.formBox }}>
              <TextField
                required
                name="name"
                label={t('adminEditGroupModal.name')}
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.name)}
                helperText={t(errors.name)}
              />
              <TextField
                name="startAt"
                label={t('adminEditGroupModal.startAt')}
                value={values.startAt}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.startAt)}
                helperText={t(errors.startAt)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name="endAt"
                label={t('adminEditGroupModal.endAt')}
                value={values.endAt}
                variant="outlined"
                onChange={handleChange}
                error={!isNullOrUndefined(errors.endAt)}
                helperText={t(errors.endAt)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button type="submit" disabled={!isAbleToSubmit} color="primary" variant="contained">
                {t('adminEditGroupModal.submitButton')}
              </Button>
            </Box>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

GroupModal.propTypes = {
  group: PropTypes.shape(),
  isModalOpen: PropTypes.bool.isRequired,
  onGroupModalClose: PropTypes.func.isRequired,
  onGroupCreate: PropTypes.func.isRequired,
  onGroupUpdate: PropTypes.func.isRequired,
};

GroupModal.defaultProps = {
  group: null,
};

export default GroupModal;
