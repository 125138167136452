import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.common.blue7,
  },

  header: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.common.blue5,
    height: theme.sizes.headerHeight,
    border: 'none',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 4px 14px rgba(148, 148, 148, 0.07)',
    },
  },

  iconButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 13,
    justifyContent: 'space-between',
    width: 95,
  },

  imageContainer: {
    marginLeft: 5,
    width: 238,
    height: 35,
  },

  headerSelect: {
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(21),
    color: theme.palette.common.brandingGray2,
    fontWeight: 400,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
    '&:hover': {
      color: theme.palette.common.brandingBlue,
    },

    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      maxWidth: 300,
    },
  },

  headerButton: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.brandingGray2,
    fontWeight: 400,
    maxWidth: '300px',
    '&[data-value="all"]': {
      fontWeight: 600,
    },
  },

  selectSpacer: {
    marginRight: 20,

    [theme.breakpoints.between(theme.breakpoints.values.tablet_landscape, theme.breakpoints.values.laptop)]: {
      marginRight: 0,
    },
  },

  link: {
    '&:link': {
      textDecoration: 'none',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    color: theme.palette.common.blue5,
  },

  name: {
    fontSize: theme.typography.pxToRem(14),
    padding: 23,
    width: 110,
  },

  logout: {
    fontSize: theme.typography.pxToRem(14),
    padding: [23, 23, 23, 40],
  },
}));

export default useStyles;
