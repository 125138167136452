import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    fontSize: theme.typography.pxToRem(40),
  },
  forbidden: {
    algin: 'center',
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useStyles;
