import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const ModalConfirmationWithMessage = props => {
  const { t } = useTranslation();
  const { onClose, onConfirm, messageLabel, title } = props;

  const [message, setMessage] = useState('');

  const handleChange = event => {
    setMessage(event.target.value);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      {!isEmpty(title) && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <form noValidate autoComplete="off">
          <TextField
            id="confirmation-message"
            label={messageLabel}
            multiline
            rows={4}
            rowsMax={6}
            value={message}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(message)} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalConfirmationWithMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  messageLabel: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

ModalConfirmationWithMessage.defaultProps = {};

export default ModalConfirmationWithMessage;
