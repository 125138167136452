import {
  useActions as useLoadActions,
  selectors as loadSelectors,
  createAdminUserNote,
  updateAdminUserNote,
  destroyAdminUserNote,
} from 'store/admin/user/userNotesSlice';

const useUserNote = () => {
  const { load: loadAdminUserNotes, reset: resetAdminUserNotes, clearLoadingError } = useLoadActions();
  const { getData, getIsLoading, getLoadingError } = loadSelectors;
  const userNotes = getData();
  const isLoading = getIsLoading();
  const loadingError = getLoadingError();

  return {
    userNotes,
    loadAdminUserNotes,
    isLoading,
    loadingError,
    clearLoadingError,
    resetAdminUserNotes,
    createAdminUserNote,
    updateAdminUserNote,
    destroyAdminUserNote,
  };
};

export default useUserNote;
