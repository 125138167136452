import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.common.blue5,
    gap: 16,
    justifyContent: 'space-between',
    padding: 24,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      flexWrap: 'wrap',
      padding: [24, 40],
    },
  },

  lessonName: {
    color: theme.palette.common.brandingBlack,

    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      color: theme.palette.common.brandingGray2,
    },
  },

  progressLinesWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    opacity: 0.4,
  },

  progressLinesWrapperCurrent: {
    [theme.breakpoints.up(theme.breakpoints.values.laptop)]: {
      opacity: 1,
    },
  },
}));

export default useStyles;
