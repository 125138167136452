import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '@material-ui/core';
import { TabList, TabContext, TabPanel } from '@material-ui/lab';

import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';

import InGroupUsers from './InGroupUsers';
import OutGroupUsers from './OutGroupUsers';

const PANEL_NAME = {
  ingroupUsers: 'ingroupUsers',
  outgroupUsers: 'outgroupUsers',
};

const GroupUsers = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(PANEL_NAME.ingroupUsers);

  const { currentUser } = useCurrentUser();

  const handleChangeTab = (event, newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <TabContext value={currentTab}>
      <TabList
        value={currentTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        aria-label="admin group tabs"
      >
        <Tab label={t('adminGroupManage.tabs.internal')} value={PANEL_NAME.ingroupUsers} />
        {UserPresenter.hasMentorAccess(currentUser) || UserPresenter.hasHrAccess(currentUser) ? (
          <Tab label={t('adminGroupManage.tabs.external')} value={PANEL_NAME.outgroupUsers} />
        ) : (
          <></>
        )}
      </TabList>
      <TabPanel value={PANEL_NAME.ingroupUsers}>
        <InGroupUsers />
      </TabPanel>
      {UserPresenter.hasMentorAccess(currentUser) || UserPresenter.hasHrAccess(currentUser) ? (
        <TabPanel value={PANEL_NAME.outgroupUsers}>
          <OutGroupUsers />
        </TabPanel>
      ) : (
        <></>
      )}
    </TabContext>
  );
};

export default GroupUsers;
