import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { map, omit } from 'ramda';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import TeacherStatsPresenter from 'presenters/TeacherStatsPresenter';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { isBlank } from 'utils/helpers';
import { teacherStatsHooks } from 'store/admin/teacherStatsSlice';
import UserPresenter from 'presenters/UserPresenter';

import SelectTeachers from 'components/Select/Teachers';

import useStyles from './styles';

const emptyValues = { createdAtGteq: '', createdAtLteq: '', teachers: [] };

const TeacherStatsPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    firstLoadTeacherStats,
    teacherStats: { items: teacherStats, loadingStatus },
  } = teacherStatsHooks.use();

  const { isIdle } = useFetchStatus(loadingStatus);

  useEffect(() => {
    firstLoadTeacherStats();
  }, []);

  const loadStats = (filters, actions) => {
    const teacherIds = map(UserPresenter.id, filters.teachers);
    const query = { ...omit(['teachers'], filters), userIdIn: teacherIds };

    firstLoadTeacherStats({ q: query }).finally(() => actions.setSubmitting(false));
  };

  const formik = useFormik({
    initialValues: emptyValues,
    onSubmit: loadStats,
  });

  const { values, handleChange, handleSubmit, isSubmitting, resetForm, setValues, setFieldValue } = formik;

  const handleReset = () => {
    setValues(emptyValues);
    resetForm(emptyValues);
  };

  const options = {
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    series: [
      {
        name: 'Brands',
        colorByPoint: true,
        data:
          !isBlank(teacherStats) &&
          teacherStats.map(teacherStat => ({
            name: UserPresenter.fullName(TeacherStatsPresenter.user(teacherStat)),
            y: TeacherStatsPresenter.count(teacherStat),
            sliced: true,
            selected: true,
          })),
      },
    ],
  };

  Highcharts.setOptions({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 400,
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item>
              <TextField
                name="createdAtGteq"
                label={t('adminTeacherStats.createdAtGteq')}
                value={values.createdAtGteq}
                variant="outlined"
                onChange={handleChange}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="createdAtLteq"
                label={t('adminTeacherStats.createdAtLteq')}
                value={values.createdAtLteq}
                variant="outlined"
                onChange={handleChange}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <SelectTeachers
                onChange={setFieldValue}
                name="teachers"
                label={t('adminTeacherStats.teachers')}
                selected={values.teachers}
                className={classes.teachersSelect}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button type="submit" disabled={isSubmitting} color="primary" variant="contained">
                {t('adminTeacherStats.apply')}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleReset} type="button" disabled={isSubmitting} color="primary" variant="outlined">
                {t('adminTeacherStats.reset')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {isIdle && <CircularProgress color="primary" />}
      {!isIdle && !isBlank(teacherStats) && <HighchartsReact highcharts={Highcharts} options={options} />}
    </>
  );
};

export default TeacherStatsPanel;
