import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: [theme.spacing(4), theme.spacing(2)],
    maxWidth: '100%',
  },
  backdrop: {
    zIndex: 999,
  },

  paper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: theme.spacing(1),

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      minWidth: 450,
      maxWidth: 700,
      paddingTop: 13,
      marginBottom: 28,
      gap: 30,
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: theme.palette.common.brandingBlack,
    margin: [0, 0, 24],
    width: '100%',
  },
}));

export default useStyles;
