import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  studentProgressDetailedCard: {
    gap: theme.spacing(3),
    margin: 0,
    width: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      gap: 0,
      padding: 40,
    },
  },

  userCourses: {
    paddingLeft: 10,
  },

  divider: {
    backgroundColor: theme.palette.common.gray9,

    height: 3,
    width: '100%',

    [theme.breakpoints.up(theme.breakpoints.values.tablet_landscape)]: {
      width: 3,
      height: 'auto',
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: theme.palette.common.blue5,
    paddingTop: 18,
    paddingLeft: 71,
  },

  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 500,
    color: theme.palette.common.brandingGray2,
    padding: [theme.spacing(2), 0],
  },

  columns: {
    maxWidth: 320,
    flexBasis: '47%',

    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      maxWidth: 'none',
    },
  },

  row: {
    paddingBottom: 11,
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `3px solid ${theme.palette.common.gray7}`,
    },

    '& .MuiTab-fullWidth': {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(19),
      textTransform: 'capitalize',
    },

    '& .Mui-selected': {
      color: theme.palette.common.black1,
      fontWeight: 600,
    },
  },

  courseProgressBlock: {
    backgroundColor: theme.palette.common.white,
    padding: [theme.spacing(4), theme.spacing(3), theme.spacing(5)],
  },
}));

export default useStyles;
