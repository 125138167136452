import React, { useState } from 'react';
import uniqueid from 'lodash.uniqueid';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from '@material-ui/core';

import appRoutes from 'routes/appRoutes';
import useCurrentUser from 'hooks/useCurrentUser';
import UserPresenter from 'presenters/UserPresenter';
import Link from 'components/RouterLink';
import DefaultAvatar from 'components/DefaultAvatar';

import useStyles from './styles';

const UserMenu = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout, currentUser } = useCurrentUser();
  const [isMenuOpened, setMenuOpened] = useState(false);
  const anchorRef = React.useRef(null);
  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const handleToggle = () => {
    setMenuOpened(!isMenuOpened);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpened(false);
  };

  const handleLogout = () => {
    logout();
  };

  const mobileMenu = (
    <Link className={classes.link} to={appRoutes.userProfilePath()}>
      <Grid container alignItems="center" spacing={2} wrap="nowrap">
        <Grid item>
          <DefaultAvatar user={currentUser} className={classes.mobileMenuAvatar} />
        </Grid>
        <Grid item>
          <Typography className={classes.fullName} color="textPrimary">
            {UserPresenter.fullName(currentUser)}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );

  return (
    <>
      {matchesMobile && mobileMenu}
      {matchesDesktop && (
        <>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            onClick={handleToggle}
            ref={anchorRef}
          >
            <DefaultAvatar user={currentUser} className={classes.avatar} />
          </IconButton>
          <Popper
            className={classes.popper}
            placement="bottom-end"
            open={isMenuOpened}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isMenuOpened} id="menu-list-grow" onClick={handleClose}>
                  {!UserPresenter.isStudent(currentUser) && (
                    <Link className={classes.link} to={appRoutes.adminUsersPath()}>
                      <MenuItem key={uniqueid(t('header.adminPanel'))}>
                        <Typography className={classes.name}>{t('header.adminPanel')}</Typography>
                      </MenuItem>
                    </Link>
                  )}

                  <Link className={classes.link} to={appRoutes.userProfilePath()}>
                    <MenuItem key={uniqueid(t('header.profile'))}>
                      <Typography className={classes.name}>{t('header.profile')}</Typography>
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout} className={classes.menuItem} key={uniqueid(t('header.logout'))}>
                    {t('header.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      )}
    </>
  );
};

UserMenu.propTypes = {};

UserMenu.defaultProps = {};
export default UserMenu;
