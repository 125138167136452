/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function deleteResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        deletingStatus: FETCH_STATUSES.idle,
        errors: null,
      },
    },
    reducers: {
      deleteResourceStart(state) {
        state[resource].deletingStatus = FETCH_STATUSES.pending;
        state[resource].errors = null;
      },
      deleteResourceSuccess(state) {
        state[resource].deletingStatus = FETCH_STATUSES.fulfilled;
      },
      deleteResourceFail(state, { payload: { errors } }) {
        state[resource].deletingStatus = FETCH_STATUSES.failed;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        deleteResource: (...params) => {
          restDispatch('deleteResourceStart');

          return repository
            .delete(...params)
            .then(() => restDispatch('deleteResourceSuccess'))
            .catch(errors => {
              restDispatch('deleteResourceFail', { errors });
              throw errors;
            });
        },
      };
    },
    abstractSelector: state => {
      return {
        resource: state[resource],
      };
    },
  };
}
