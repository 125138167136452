import { useState } from 'react';

const FETCH_STATUSES = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  failed: 'failed',
};

const fetchStatusHelpers = status => {
  const isIdle = status === FETCH_STATUSES.idle;
  const isPending = status === FETCH_STATUSES.pending;
  const isFulfilled = status === FETCH_STATUSES.fulfilled;
  const isFailed = status === FETCH_STATUSES.failed;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
  };
};

const useLoading = func => {
  const [status, setStatus] = useState(FETCH_STATUSES.idle);
  const [error, setError] = useState(null);

  const reset = () => {
    setStatus(FETCH_STATUSES.idle);
    setError(null);
  };

  const funcWithLoading = (...params) => {
    setError(null);
    setStatus(FETCH_STATUSES.pending);
    return func(...params)
      .then(result => {
        setStatus(FETCH_STATUSES.fulfilled);
        return result;
      })
      .catch(err => {
        setStatus(FETCH_STATUSES.failed);
        setError(err);
        throw err;
      });
  };

  const { isIdle, isPending, isFulfilled, isFailed } = fetchStatusHelpers(status);

  return {
    func: funcWithLoading,
    status,
    error,
    reset,
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isNotFinished: isIdle || isPending,
    isFinished: isFulfilled || isFailed,
  };
};

export default useLoading;
