import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 0,
  },

  list: {
    paddingTop: 0,
  },

  lessonItem: {
    paddingLeft: 0,
    paddingBottom: 1,
  },

  badgeGroup: {
    borderRadius: 5,
    marginBottom: 2,
  },
}));

export default useStyles;
