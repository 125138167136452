import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import useStyles from './styles';

const ScrollButton = props => {
  const { styles } = props;
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 100);
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      onClick={scrollToTop}
      size="large"
      variant="outlined"
      color="primary"
      className={clsx(classes.scrollButton, styles)}
      classes={{ startIcon: classes.startIcon }}
      startIcon={<ArrowUpwardRoundedIcon />}
      style={{ display: visible ? 'block' : 'none' }}
    />
  );
};

ScrollButton.propTypes = {
  styles: PropTypes.string,
};
ScrollButton.defaultProps = {
  styles: '',
};

export default ScrollButton;
