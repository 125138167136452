import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  paper: {
    backgroundColor: theme.palette.common.white,
    padding: 20,
  },

  formBox: {
    '& > *': {
      margin: 10,
      minWidth: 320,
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: 14,
    },
  },

  contentBox: {
    '& .rc-md-editor.full': {
      padding: [75, 10, 10],
      height: '100%',
      width: '100%',
    },
  },

  errorsContentBox: {
    '& .rc-md-editor .editor-container .sec-md .input': {
      border: `1px solid ${theme.palette.common.red}`,
      '&:hover': {
        border: `1px solid ${theme.palette.common.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.common.red}`,
      },
    },
    '& $contentLabel': {
      color: theme.palette.common.red,
    },
  },
}));

export default useStyles;
