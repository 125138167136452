import googleIcon from 'assets/icons/google-icon.png';
import edit from 'assets/icons/edit.svg';
import theory from 'assets/icons/theory.svg';
import check from 'assets/icons/check.svg';
import checkbox from 'assets/icons/checkbox.svg';
import completedLessonCheckbox from 'assets/icons/completed-lesson-checkbox.svg';
import unfinishedLessonCheckbox from 'assets/icons/unfinished-lesson-checkbox.svg';
import uncheckedDangerCheckbox from 'assets/icons/unchecked-danger-checkbox.svg';
import checkedCheckbox from 'assets/icons/checked-checkbox.svg';
import uncheckedCheckbox from 'assets/icons/unchecked-checkbox.svg';
import checkedDangerCheckbox from 'assets/icons/checked-danger-checkbox.svg';
import noneStateCheckbox from 'assets/icons/none-state-checkbox.svg';
import badgeCheck from 'assets/icons/badge-check.svg';
import notifications from 'assets/icons/Icon-notification.svg';
import externalArrow from 'assets/icons/externalArrow.svg';
import arrowRight from 'assets/icons/arrowRight.svg';
import js from 'assets/icons/vacancy-js.svg';
import node from 'assets/icons/vacancy-node.svg';
import python from 'assets/icons/vacancy-python.svg';
import react from 'assets/icons/vacancy-react.svg';
import infrastructure from 'assets/icons/vacancy-infrastructure.svg';
import elixir from 'assets/icons/vacancy-elixir.svg';
import ruby from 'assets/icons/vacancy-ruby.svg';
import vue from 'assets/icons/vacancy-vue.svg';
import pm from 'assets/icons/vacancy-pm.svg';
import qa from 'assets/icons/vacancy-qa.svg';
import flutter from 'assets/icons/vacancy-flutter.svg';

import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';
import linkedin from 'assets/icons/linkedin.svg';
import arrowSquareOut from 'assets/icons/arrowSquareOut.svg';

import notebook from 'assets/icons/notebook.svg';
import book from 'assets/icons/book.svg';
import clock from 'assets/icons/clock.svg';

import mobileMenu from 'assets/icons/mobileMenu.svg';
import logout from 'assets/icons/logout.svg';

export default {
  googleIcon,
  edit,
  theory,
  check,
  checkbox,
  completedLessonCheckbox,
  unfinishedLessonCheckbox,
  uncheckedDangerCheckbox,
  checkedCheckbox,
  uncheckedCheckbox,
  checkedDangerCheckbox,
  noneStateCheckbox,
  badgeCheck,
  notifications,
  externalArrow,
  js,
  node,
  infrastructure,
  elixir,
  python,
  react,
  ruby,
  vue,
  pm,
  qa,
  flutter,
  facebook,
  instagram,
  linkedin,
  arrowSquareOut,
  notebook,
  book,
  clock,
  mobileMenu,
  logout,
  arrowRight,
};
