import React from 'react';
import PropTypes from 'prop-types';
import uniqueid from 'lodash.uniqueid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'ramda';
import findById from 'utils/findById';

import CoursePresenter from 'presenters/CoursePresenter';
import UserCoursePresenter from 'presenters/User/CoursePresenter';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import appRoutes from 'routes/appRoutes';

import useStyles from 'layouts/containers/Header/styles';
import { Typography } from '@material-ui/core';

const ALL = 'all';

const ContextCourse = props => {
  const classes = useStyles();
  const { courses, currentCourseId } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const handleCourseChange = ({ target: { value } }) => {
    if (value && value !== ALL) {
      const newCourse = findById(courses, value);
      const lessonId = CoursePresenter.firstLessonId(newCourse);
      const path = appRoutes.courseLessonTheoriesPath(newCourse.id, lessonId);

      history.push(path);
    } else {
      history.push(appRoutes.coursesPath());
    }
  };

  if (isNil(courses) || isEmpty(courses)) return <div />;

  return (
    <div className={classes.selectSpacer}>
      <Select
        labelId="select-label"
        id="select"
        value={currentCourseId || ALL}
        className={classes.headerSelect}
        disableUnderline
        onChange={handleCourseChange}
        IconComponent={() => <ExpandMoreOutlinedIcon className="MuiSvgIcon-root MuiSelect-icon" />}
      >
        <MenuItem key={uniqueid(t('header.courses'))} value={ALL} className={classes.headerButton}>
          <Typography variant="inherit" noWrap>
            {t('header.courses')}
          </Typography>
        </MenuItem>
        {courses
          .filter(course => UserCoursePresenter.isJoined(course) || UserCoursePresenter.isCompleted(course))
          .map(course => {
            return (
              <MenuItem
                key={CoursePresenter.id(course)}
                value={CoursePresenter.id(course)}
                className={classes.headerButton}
              >
                <Typography variant="inherit" noWrap>
                  {CoursePresenter.name(course)}
                </Typography>
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
};

ContextCourse.propTypes = {
  currentCourseId: PropTypes.number,
  courses: PropTypes.arrayOf(CoursePresenter.shape()),
};

ContextCourse.defaultProps = {
  currentCourseId: null,
  courses: null,
};

export default ContextCourse;
