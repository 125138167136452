import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { getCurrentYear } from 'utils/dates';
import externalLinks from 'utils/externalLinks';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright ©
      <Link color="inherit" href={externalLinks.learnDualboot()}>
        learn.dualboot.dev
      </Link>
      &nbsp;
      {getCurrentYear()}
    </Typography>
  );
};

export default Copyright;
