import * as yup from 'yup';

const validationFields = {
  email: yup.string().email('validations.email.invalid').required('validations.email.required'),
  password: yup.string().required('validations.password.required'),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  email: '',
  password: '',
};
