import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty, find, propEq } from 'ramda';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { userTheoriesHooks } from 'store/user/theoriesSlice';
import TheoryPresenter from 'presenters/User/TheoryPresenter';
import PracticeContentSkeleton from 'components/PracticeContentSkeleton';
import MarkdownContent from 'components/MarkdownContent';

const TheoryContent = props => {
  const [selectedTheory, setSelectedTheory] = useState({});
  const { theoryId } = props;
  const {
    theories: { items: userTheories, loadingStatus },
  } = userTheoriesHooks.use();
  const { isPending } = useFetchStatus(loadingStatus);
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  useEffect(() => {
    const currTheory = find(propEq('id', theoryId))(userTheories);
    setSelectedTheory(currTheory);
  }, [theoryId, userTheories]);

  if (isEmpty(selectedTheory) || isPending) {
    return <PracticeContentSkeleton />;
  }

  if (TheoryPresenter.isLink(selectedTheory)) {
    return (
      <Button
        color="primary"
        variant={matchesLaptop ? 'contained' : 'text'}
        href={TheoryPresenter.link(selectedTheory)}
        target="_blank"
        size="large"
      >
        {t('studentLesson.read')}
      </Button>
    );
  }

  return <MarkdownContent content={TheoryPresenter.content(selectedTheory)} canBeTruncated={matchesMobile} />;
};

TheoryContent.propTypes = {
  theoryId: PropTypes.number.isRequired,
};

export default TheoryContent;
