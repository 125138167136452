/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import StudentLessonTheoriesRepository from 'repositories/StudentLessonTheoriesRepository';

import { createRestSlice } from 'utils/RestSlice';

const userTheoriesRestSlice = createRestSlice({
  resource: 'theories',
  repository: StudentLessonTheoriesRepository,
  slices: ['loadTheories'],
});

const initialState = {
  ...userTheoriesRestSlice.initialState,
};

const userTheoriesSlice = createSlice({
  name: 'userTheories',
  initialState,
  reducers: {
    ...userTheoriesRestSlice.reducers,
  },
});

export default userTheoriesSlice.reducer;

export const userTheoriesHooks = userTheoriesRestSlice.hooks(userTheoriesSlice, 'userTheories');
