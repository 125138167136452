import i18n from 'i18next';
import { isEmpty, isNil } from 'ramda';

import parseApiErrors from './parseApiErrors';

const defaultError = i18n.t('commonErrors.formServerError');

const parseFormErrors = (body, ...keys) => {
  if (isNil(body.response) || isEmpty(body.response?.data?.error)) {
    return { default: defaultError };
  }

  return parseApiErrors(body, keys);
};

export default parseFormErrors;
