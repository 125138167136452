import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { map, not } from 'ramda';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import MarkdownContent from 'components/MarkdownContent';

import UserPresenter from 'presenters/UserPresenter';
import CoursePresenter from 'presenters/CoursePresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminCourses from 'hooks/admin/useCourses';

import appRoutes from 'routes/appRoutes';
import { exportApiV1AdminCoursePath } from 'routes/apiRoutes';

import { camelize } from 'utils/strings';

import useStyles from './styles';

const Courses = props => {
  const { courses, onModalOpen, onModalOpenDestroy } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { updateCourses } = useAdminCourses();
  const { currentUser } = useCurrentUser();

  const list = map(course => ({ ...course, disabled: not(UserPresenter.hasAdminAccess(currentUser)) }), courses);
  const [sortedList, setSortedList] = useState(list);
  const [widths, setWidths] = useState([]);

  const handleChange = ({ oldIndex, newIndex }) => {
    const newList = arrayMove(sortedList, oldIndex, newIndex);
    const newOrder = newList.map((course, index) => ({ id: course.id, order: index }));
    setSortedList(newList);
    updateCourses(newOrder);
  };

  return (
    <List
      lockVertically
      beforeDrag={({ elements, index }) => {
        const cells = Array.from(elements[index].children);
        const cellsWidths = cells.map(cell => window.getComputedStyle(cell).width);
        setWidths(cellsWidths);
      }}
      values={sortedList}
      onChange={handleChange}
      renderList={({ children, props: renderListProps }) => (
        <Table aria-label="courses table">
          <TableHead>
            <TableRow>
              <TableCell align="left">#</TableCell>
              <TableCell>{t('adminCoursesTable.name')}</TableCell>
              <TableCell>{t('adminCoursesTable.description')}</TableCell>
              <TableCell>{t('adminCoursesTable.state')}</TableCell>
              <TableCell>{t('adminCoursesTable.createdAt')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...renderListProps}>{children}</TableBody>
        </Table>
      )}
      renderItem={({ value, props: renderItemProps, isDragged }) => {
        const courseId = CoursePresenter.id(value);
        const tdWidths = isDragged ? widths : [];
        const rootClasses = clsx(UserPresenter.hasAdminAccess(currentUser) && classes.cursor);

        const row = (
          <TableRow
            {...renderItemProps}
            style={{
              ...renderItemProps.style,
            }}
            classes={{ root: rootClasses }}
          >
            <TableCell style={{ width: tdWidths[0] }}>{courseId}</TableCell>
            <TableCell style={{ width: tdWidths[1] }}>
              <Link to={appRoutes.adminLessonsPath(courseId)} className={classes.link}>
                {CoursePresenter.name(value)}
              </Link>
            </TableCell>
            <TableCell style={{ width: tdWidths[2] }}>
              <MarkdownContent content={CoursePresenter.description(value)} />
            </TableCell>
            <TableCell style={{ width: tdWidths[3] }}>
              {t(`adminEditCourseModal.states.${camelize(CoursePresenter.state(value))}`)}
            </TableCell>
            <TableCell style={{ width: tdWidths[4] }}>{CoursePresenter.createdAt(value)}</TableCell>
            <TableCell style={{ width: tdWidths[5] }} align="center">
              <IconButton onClick={onModalOpen(value)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <Link target="_blank" to={`${exportApiV1AdminCoursePath(courseId)}.json`} className={classes.link}>
                <IconButton>
                  <GetAppIcon color="primary" fontSize="small" />
                </IconButton>
              </Link>
              {UserPresenter.hasAdminAccess(currentUser) && (
                <IconButton onClick={onModalOpenDestroy(value)}>
                  <DeleteIcon color="primary" fontSize="small" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        );
        return isDragged ? (
          <Table>
            <TableBody>{row}</TableBody>
          </Table>
        ) : (
          row
        );
      }}
    />
  );
};

Courses.propTypes = {
  courses: PropTypes.arrayOf(CoursePresenter.shape()).isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalOpenDestroy: PropTypes.func.isRequired,
};

export default Courses;
