import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { useTheme, useMediaQuery } from '@material-ui/core';

import usePractice from 'sharedContainers/Practice';
import PracticePresenter from 'presenters/User/PracticePresenter';
import PracticeContentSkeleton from 'components/PracticeContentSkeleton';
import MarkdownContent from 'components/MarkdownContent';

const PracticeContent = props => {
  const { lessonId, practiceId } = props;

  const { loadPractice, practice, processing: practiceProcessing, resetPractice } = usePractice();

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  useEffect(() => {
    loadPractice(lessonId, practiceId);
    return () => {
      resetPractice();
    };
  }, [practiceId]);

  if (isEmpty(practice) || practiceProcessing) {
    return <PracticeContentSkeleton />;
  }

  return <MarkdownContent content={PracticePresenter.content(practice)} canBeTruncated={matchesMobile} />;
};

PracticeContent.propTypes = {
  lessonId: PropTypes.number.isRequired,
  practiceId: PropTypes.number.isRequired,
};

export default PracticeContent;
