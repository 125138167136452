/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminTeacherStatsRepository from 'repositories/AdminTeacherStatsRepository';

import { createRestSlice } from 'utils/RestSlice';

const teacherStatsRestSlice = createRestSlice({
  resource: 'teacherStat',
  repository: AdminTeacherStatsRepository,
  slices: ['loadTeacherStats'],
});

const initialState = {
  ...teacherStatsRestSlice.initialState,
};

const teacherStatsSlice = createSlice({
  name: 'adminTeacherStats',
  initialState,
  reducers: {
    ...teacherStatsRestSlice.reducers,
  },
});

export default teacherStatsSlice.reducer;

export const teacherStatsHooks = teacherStatsRestSlice.hooks(teacherStatsSlice, 'adminTeacherStats');
