import { apiV1CurrentUserPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  loadCurrentUser() {
    const path = apiV1CurrentUserPath();
    return FetchHelpers.get(path);
  },
  update(params) {
    const path = apiV1CurrentUserPath();
    return FetchHelpers.put(path, params);
  },
};
