import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ListItem, useTheme, useMediaQuery } from '@material-ui/core';
import BadgeGroup from 'components/BadgeGroup';
import Icon from 'components/Icon';
import LessonPresenter, { BADGE_SIZES } from 'presenters/User/LessonPresenter';
import { getPracticesBadgesVariants } from 'utils/badgeVariantsSelector';

import useStyles from './styles';

const LessonItem = props => {
  const { courseLesson, lessonClasses, isCurrent } = props;
  const classes = useStyles();

  const theme = useTheme();
  const matchesLaptop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.laptop));
  const matchesMobile = !matchesLaptop;

  const userPractices = LessonPresenter.userPractices(courseLesson);

  return (
    <ListItem className={clsx(classes.listItem, classes.lessonName, lessonClasses)} divider>
      {LessonPresenter.name(courseLesson)}

      {matchesMobile && <Icon name="arrowRight" width="6" />}

      <div className={clsx(classes.progressLinesWrapper, { [classes.progressLinesWrapperCurrent]: isCurrent })}>
        <BadgeGroup
          variants={getPracticesBadgesVariants(userPractices)}
          size={BADGE_SIZES.flat}
          showTooltips={false}
          tooltipsPlacement="top"
        />
      </div>
    </ListItem>
  );
};

LessonItem.propTypes = {
  courseLesson: LessonPresenter.shape().isRequired,
  lessonClasses: PropTypes.string,
  isCurrent: PropTypes.bool,
};

LessonItem.defaultProps = {
  lessonClasses: '',
  isCurrent: false,
};

export default LessonItem;
