import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useStyles from './styles';

const ChangebleTooltip = props => {
  const { tooltipContent, isTooltipOpened, anchorEl, onToggle, placement } = props;
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      open={isTooltipOpened}
      anchorEl={anchorEl}
      placement={placement}
      className={classes.popper}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Paper elevation={3}>
        <ClickAwayListener onClickAway={onToggle}>
          <span className={classes.tooltip}>{tooltipContent}</span>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

ChangebleTooltip.propTypes = {
  tooltipContent: PropTypes.shape(),
  isTooltipOpened: PropTypes.bool,
  anchorEl: PropTypes.shape(),
  onToggle: PropTypes.func,
  placement: PropTypes.string,
};
ChangebleTooltip.defaultProps = {
  tooltipContent: null,
  isTooltipOpened: false,
  anchorEl: null,
  onToggle: null,
  placement: 'top',
};

export default ChangebleTooltip;
