import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { values, map, without } from 'ramda';

import { InputLabel, MenuItem, Select } from '@material-ui/core';

import { USER_ROLES } from 'presenters/UserPresenter';

const RoleSelect = props => {
  const { value: currentValue, onChange, disabled, allowEmpty, except } = props;
  const { t } = useTranslation();

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <InputLabel id="user-role-label">{t('adminUserPage.role')}</InputLabel>
      <Select
        disabled={disabled}
        labelId="user-role-label"
        id="user-role-select"
        value={currentValue}
        onChange={handleChange}
      >
        {allowEmpty && (
          <MenuItem key="none" value={undefined}>
            &nbsp;
          </MenuItem>
        )}
        {map(
          role => (
            <MenuItem key={role} value={role}>
              {t(`user.roles.${role}`)}
            </MenuItem>
          ),
          values(without(except, USER_ROLES)),
        )}
      </Select>
    </>
  );
};

RoleSelect.defaultProps = {
  value: '',
  disabled: false,
  allowEmpty: false,
  except: [],
};

RoleSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  except: PropTypes.arrayOf(PropTypes.string),
};

export default RoleSelect;
