import { useHistory } from 'react-router-dom';

import appRoutes from 'routes/appRoutes';

const useRedirects = () => {
  const history = useHistory();
  return {
    redirectToRootPath: () => {
      history.push(appRoutes.rootPath());
    },
    redirectToСoursesPath: () => {
      history.push(appRoutes.coursesPath());
    },
    redirectToAdminPracticesPath: (courseId, lessonId) =>
      history.push(appRoutes.adminPracticesPath(courseId, lessonId)),
    redirectToAdminPracticeNewPath: (courseId, lessonId) =>
      history.push(appRoutes.adminPracticeNewPath(courseId, lessonId)),
    redirectToAdminTheoriesPath: (courseId, lessonId) => history.push(appRoutes.adminTheoriesPath(courseId, lessonId)),
    redirectToAdminTheoryNewPath: (courseId, lessonId) =>
      history.push(appRoutes.adminTheoryNewPath(courseId, lessonId)),
  };
};

export default useRedirects;
