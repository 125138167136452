import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import Base from 'layouts/Base';
import Dashboard from 'layouts/Dashboard';
import Auth from 'layouts/Auth';

const AppRoute = props => {
  const { layout, title, ...rest } = props;
  switch (layout) {
    case 'base':
      return (
        <Base>
          <Route {...rest} />
        </Base>
      );
    case 'dashboard':
      return (
        <Route {...rest}>
          <Dashboard {...rest} />
        </Route>
      );
    case 'auth':
      return (
        <Auth>
          <Route {...rest} />
        </Auth>
      );
    default:
      return null;
  }
};

AppRoute.propTypes = {
  layout: PropTypes.oneOf(['base', 'dashboard', 'auth']),
  title: PropTypes.string,
};

AppRoute.defaultProps = {
  layout: 'dashboard',
  title: null,
};

export default AppRoute;
