import React from 'react';
import { range } from 'ramda';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const COUNT_SKELETON_CARDS = 4;

const VacanciesCardsSkeleton = () => {
  const classes = useStyles();

  return range(0, COUNT_SKELETON_CARDS).map(el => {
    return (
      <Card key={el} className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Typography variant="h5" gutterBottom classes={{ root: classes.vacancyName }}>
              <Skeleton variant="text" animation="wave" />
            </Typography>
          </div>

          <Typography variant="body2" color="textSecondary" gutterBottom classes={{ root: classes.description }}>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </Typography>
        </CardContent>
      </Card>
    );
  });
};

export default VacanciesCardsSkeleton;
