import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, map } from 'ramda';

import { Grid, CircularProgress, Typography, Button, List, ListItem, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DefaultAvatar from 'components/DefaultAvatar';
import ModalConfirmation from 'components/ModalConfirmation';
import MarkdownContent from 'components/MarkdownContent';
import NoteModal from 'components/NoteModal';

import NotePresenter from 'presenters/NotePresenter';
import UserPresenter from 'presenters/UserPresenter';

import useCurrentUser from 'hooks/useCurrentUser';
import useAdminUserNotes from 'hooks/admin/user/useUserNotes';

import useStyles from './styles';

const UserNotes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userId } = map(Number, useParams());

  const { currentUser } = useCurrentUser();
  const {
    userNotes,
    loadAdminUserNotes,
    isLoading: isUserNotesLoading,
    resetAdminUserNotes,
    createAdminUserNote,
    updateAdminUserNote,
    destroyAdminUserNote,
  } = useAdminUserNotes();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);

  useEffect(() => {
    loadAdminUserNotes(userId);
    return () => {
      resetAdminUserNotes({ userId });
    };
  }, []);

  if (isNil(userNotes) || isUserNotesLoading) {
    return <CircularProgress color="primary" />;
  }

  const handleModalOpen =
    (data = null) =>
    () => {
      setModalOpen(true);
      if (data) {
        setCurrentNote(data);
      }
    };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentNote(null);
  };

  const handleModalOpenDestroy =
    (data = null) =>
    () => {
      setModalDeleteOpen(true);
      if (data) {
        setCurrentNote(data);
      }
    };

  const handleModalDeleteClose = () => {
    setModalDeleteOpen(false);
    setCurrentNote(null);
  };

  const handleCreate = values =>
    createAdminUserNote(userId, values).then(() => {
      handleModalClose();
      loadAdminUserNotes(userId);
    });

  const handleUpdate = values =>
    updateAdminUserNote(userId, NotePresenter.id(currentNote), values).then(() => {
      handleModalClose();
      loadAdminUserNotes(userId);
    });

  const handleDestroy = async () => {
    destroyAdminUserNote(userId, NotePresenter.id(currentNote)).then(() => {
      handleModalDeleteClose();
      loadAdminUserNotes(userId);
    });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{t('notes.title')}</Typography>
        <Button color="primary" variant="outlined" onClick={handleModalOpen()}>
          {t('notes.create')}
        </Button>
      </Grid>

      {isEmpty(userNotes) && <Typography variant="body1">{t('notes.empty')}</Typography>}

      {!isEmpty(userNotes) && (
        <List>
          {map(note => {
            const author = NotePresenter.author(note);

            return (
              <ListItem key={NotePresenter.id(note)}>
                <Grid container>
                  <Grid container direction="column">
                    <MarkdownContent content={NotePresenter.message(note)} />
                    <Typography component="div" variant="body2" color="textSecondary">
                      <Grid container alignItems="center">
                        <DefaultAvatar key={UserPresenter.id(author)} user={author} className={classes.smallAvatar} />
                        {UserPresenter.fullName(author)}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid>
                    {NotePresenter.isAuthor(note, currentUser) && (
                      <>
                        <IconButton onClick={handleModalOpen(note)}>
                          <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton onClick={handleModalOpenDestroy(note)}>
                          <DeleteIcon color="primary" fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            );
          }, userNotes)}
        </List>
      )}

      <NoteModal
        note={currentNote}
        isModalOpen={isModalOpen}
        onModalClose={handleModalClose}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />

      <ModalConfirmation
        isOpen={isModalDeleteOpen}
        onClose={handleModalDeleteClose}
        onConfirm={handleDestroy}
        title={t('notes.modal.destroy.title')}
        content={t('notes.modal.destroy.content')}
      />
    </>
  );
};

export default UserNotes;
