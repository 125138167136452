import {
  apiV1AdminCoursesPath,
  importApiV1AdminCoursesPath,
  apiV1AdminCoursePath,
  updateAllApiV1AdminCoursesPath,
} from 'routes/apiRoutes';
import { toFormData } from 'utils/formUtils';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  load(params) {
    const path = apiV1AdminCoursesPath();
    return FetchHelpers.get(path, params);
  },

  show(id) {
    const path = apiV1AdminCoursePath(id);
    return FetchHelpers.get(path);
  },

  create(course) {
    const path = apiV1AdminCoursesPath();
    return FetchHelpers.post(path, { course });
  },

  import(json) {
    const path = importApiV1AdminCoursesPath();
    const formData = toFormData('course', { json });

    return FetchHelpers.postMultipartFormData(path, formData);
  },

  update(id, course) {
    const path = apiV1AdminCoursePath(id);
    return FetchHelpers.put(path, { course });
  },

  updateAll(courses) {
    const path = updateAllApiV1AdminCoursesPath();
    return FetchHelpers.put(path, { coursesAttributes: courses });
  },

  destroy(id) {
    const path = apiV1AdminCoursePath(id);
    return FetchHelpers.delete(path);
  },
};
