import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  activityCard: {
    margin: 24,
    overflow: 'visible',
    position: 'relative',
  },

  cardHeader: {
    backgroundColor: theme.palette.common.blue7,
    '& .MuiCardHeader-action': {
      flex: 'none',
      margin: 0,
      alignSelf: 'center',
    },
  },

  teachers: {
    position: 'absolute',
    right: '-10px',
    top: '-16px',
    display: 'flex',
  },

  smallAvatar: {
    marginRight: 14,
    width: 34,
    height: 34,
    border: `2px solid ${theme.palette.common.blue7}`,
  },

  avatar: {
    width: 52,
    height: 52,
    fontSize: theme.typography.pxToRem(23),
  },

  name: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  lesson: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.common.blue5,
  },

  cardContent: {
    padding: 24,
  },

  icon: {
    marginRight: 16,
  },

  practice: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
  },

  buttonReject: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginRight: 24,
    textTransform: 'none',
    width: 110,
  },

  buttonAccept: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'none',
    width: 110,
  },

  buttonRejectWithMessage: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginRight: 24,
    textTransform: 'none',
    marginLeft: 'auto',
  },

  prLinkField: {
    marginTop: 20,
    padding: 16,
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    width: 'fill-available',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.blue6,
    borderColor: theme.palette.common.blue7,
    color: theme.palette.common.blue1,
    textTransform: 'none',
    '&:hover': {
      borderColor: theme.palette.common.white,
    },
  },

  answerFor: {
    marginTop: 10,
  },

  prLinkBox: {
    width: 1100,
  },

  content: {
    display: 'none',
  },
}));

export default useStyles;
