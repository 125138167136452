import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  formBox: {
    '& > *': {
      margin: 10,
      minWidth: 320,
    },
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: 14,
    },
    '& .rc-md-editor.full': {
      padding: [75, 10, 10],
      height: '100%',
      width: '100%',
    },
  },

  state: {
    fontSize: 14,
  },

  contentLabel: {
    paddingLeft: 2,
    marginBottom: 10,
    color: theme.lighten(theme.palette.common.black, 7),
  },

  contentBox: {
    '& .rc-md-editor': {
      border: 'none',
    },
    '& .rc-md-navigation': {
      border: 'none',
      background: 'none',
    },
    '& .rc-md-editor .editor-container .sec-md .input': {
      border: `1px solid ${theme.lighten(theme.palette.common.black, 12)}`,
      overflowY: 'auto',
      '&:hover': {
        border: `1px solid black`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.common.blue1}`,
      },
    },
    '& .editor-container': {
      height: 220,
      width: '100%',
    },
    '& .full': {
      '& .editor-container': {
        height: '100%',
        width: '100%',
      },
    },
    border: `1px solid ${theme.lighten(theme.palette.common.black, 12)}`,
    borderRadius: 5,
    padding: [18.5, 14],
  },

  errorsContentBox: {
    '& .rc-md-editor .editor-container .sec-md .input': {
      border: `1px solid ${theme.palette.common.red}`,
      '&:hover': {
        border: `1px solid ${theme.palette.common.red}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.common.red}`,
      },
    },
    '& $contentLabel': {
      color: theme.palette.common.red,
    },
  },

  hidden: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}));

export default useStyles;
