import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cursor: {
    cursor: 'move',
  },

  link: {
    color: theme.palette.common.blue1,
    position: 'relative',
    padding: 10,
    '&:visited': {
      color: theme.lighten(theme.palette.common.blue1, -0.5),
    },
  },
}));

export default useStyles;
