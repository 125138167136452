/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import AdminVacanciesRepository from 'repositories/AdminVacanciesRepository';

import { createRestSlice } from 'utils/RestSlice';

const VacanciesRestSlice = createRestSlice({
  resource: 'vacancies',
  repository: AdminVacanciesRepository,
  slices: ['loadVacancies'],
});

const initialState = {
  ...VacanciesRestSlice.initialState,
};

const VacanciesSlice = createSlice({
  name: 'adminVacancies',
  initialState,
  reducers: {
    ...VacanciesRestSlice.reducers,
  },
});

export default VacanciesSlice.reducer;

export const vacanciesHooks = VacanciesRestSlice.hooks(VacanciesSlice, 'adminVacancies');
