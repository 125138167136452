import { apiV1AdminUserNotesPath, apiV1AdminUserNotePath } from 'routes/apiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  load(params) {
    const path = apiV1AdminUserNotesPath(params);
    return FetchHelpers.get(path);
  },

  show(userId, id) {
    const path = apiV1AdminUserNotePath(id, { user_id: userId });
    return FetchHelpers.get(path);
  },

  create(userId, note) {
    const path = apiV1AdminUserNotesPath({ user_id: userId });
    return FetchHelpers.post(path, { note });
  },

  update(userId, id, note) {
    const path = apiV1AdminUserNotePath(id, { user_id: userId });
    return FetchHelpers.put(path, { note });
  },

  destroy(userId, id) {
    const path = apiV1AdminUserNotePath(id, { user_id: userId });
    return FetchHelpers.delete(path);
  },
};
