import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    marginTop: 40,
  },
  link: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  textLink: {
    fontSize: theme.typography.pxToRem(16),
  },
  textButton: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },

  box: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 40,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

export default useStyles;
