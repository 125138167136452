import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const Error404 = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Typography className={classes.error}>{t('error.error404')}</Typography>
      <Typography>{t('error.notFound')}</Typography>
      <Markdown>{t('error.textHelper')}</Markdown>
    </Grid>
  );
};

export default Error404;
