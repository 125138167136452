/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';
import { concat } from 'ramda';

export default function loadAdditionalResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    reducers: {
      loadAdditionalResourcesStart(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.pending;
        state[pluralResource].error = null;
      },
      loadAdditionalResourcesSuccess(state, { payload: { items, meta } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[pluralResource].items = concat(state[pluralResource].items, items);
        state[pluralResource].meta = meta;
      },
      loadAdditionalResourcesFail(state, { payload: { error } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadAdditionalResources: params => {
          restDispatch('loadAdditionalResourcesStart');

          return repository
            .index(params)
            .then(({ data }) => restDispatch('loadAdditionalResourcesSuccess', data))
            .catch(error => {
              restDispatch('loadAdditionalResourcesFail', { error });
              throw error;
            });
        },
      };
    },
  };
}
