import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';

import { Typography, CircularProgress, TableHead, TableRow, Table, TableBody, TableCell } from '@material-ui/core';

import CoursePresenter from 'presenters/CoursePresenter';

import useAdminCourses from 'hooks/admin/useCourses';
import useAdminUserCourses from 'hooks/admin/user/useUserCourses';

import Course from './components/Course';

const UserCourses = ({ userId }) => {
  const { t } = useTranslation();

  const { courses, isLoading: isCoursesLoading, loadAdminCourses, resetAdminCourses } = useAdminCourses();
  const {
    userCourses,
    loadAdminUserCourses,
    isLoading: isUserCoursesLoading,
    resetAdminUserCourses,
    createAdminUserCourse,
    updateAdminUserCourse,
  } = useAdminUserCourses();

  const handleStartCourse = courseId => {
    createAdminUserCourse(userId, {
      courseId,
    }).then(() => loadAdminUserCourses({ user_id: userId }));
  };

  const handleJoinCourse = userCourseId => {
    updateAdminUserCourse(userId, userCourseId, {
      stateEvent: 'join',
    }).then(() => loadAdminUserCourses({ user_id: userId }));
  };

  const handleRevokeCourse = userCourseId => {
    updateAdminUserCourse(userId, userCourseId, {
      stateEvent: 'revoke',
    }).then(() => loadAdminUserCourses({ user_id: userId }));
  };

  useEffect(() => {
    loadAdminCourses();
    loadAdminUserCourses({ user_id: userId });
    return () => {
      resetAdminCourses();
      resetAdminUserCourses({ user_id: userId });
    };
  }, []);

  if (isNil(courses) || isCoursesLoading || isUserCoursesLoading) {
    return <CircularProgress color="primary" />;
  }

  return (
    <>
      <Typography variant="h5">{t('adminCoursesTable.courses')}</Typography>

      <Table aria-label="courses table">
        <TableHead>
          <TableRow>
            <TableCell align="left">#</TableCell>
            <TableCell>{t('adminCoursesTable.name')}</TableCell>
            <TableCell>{t('adminCoursesTable.state')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map(course => {
            const userCourse = userCourses.find(uc => uc.courseId === course.id);
            return (
              <Course
                key={CoursePresenter.id(course)}
                userId={userId}
                course={course}
                userCourse={userCourse}
                onStartCourse={handleStartCourse}
                onJoinCourse={handleJoinCourse}
                onRevokeCourse={handleRevokeCourse}
              />
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

UserCourses.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserCourses;
