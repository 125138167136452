import { apiV1StudentLessonTheoriesPath, apiV1StudentTheoryUserTheoriesPath } from 'routes/apiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(lessonId) {
    const path = apiV1StudentLessonTheoriesPath(lessonId);
    return FetchHelpers.get(path);
  },

  create(id) {
    const path = apiV1StudentTheoryUserTheoriesPath(id);
    return FetchHelpers.post(path);
  },

  update(id, userTheory) {
    const path = apiV1StudentTheoryUserTheoriesPath(id);
    return FetchHelpers.put(path, { userTheory });
  },
};
