import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@material-ui/lab/Alert';
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { vacanciesHooks } from 'store/vacanciesSlice';

import VacancyPresenter from 'presenters/VacancyPresenter';

import VacancyCard from './components/VacancyCard';
import VacanciesCardsSkeleton from './components/VacanciesCardsSkeleton';
import Aside from './components/Aside';

import useStyles from './styles';

const Vacancies = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.tablet_landscape));
  const matchesMobile = !matchesDesktop;

  const {
    firstLoadVacancies: loadVacancies,
    vacancies: { items: vacancies, loadingStatus, error },
  } = vacanciesHooks.use();

  useEffect(() => {
    loadVacancies();
  }, []);

  const { isIdle, isPending, isFailed, isFulfilled } = useFetchStatus(loadingStatus);

  if (isFailed) {
    return (
      <Backdrop open>
        <Alert severity="error" onClose={loadVacancies}>
          {error}
        </Alert>
      </Backdrop>
    );
  }

  const NoVacanciesCard = () => {
    return (
      <Card>
        <CardContent className={classes.noVacanciesCard}>
          <Typography variant="h5">{t('vacancies.cards.noVacancies.title')}</Typography>
          <Typography>{t('vacancies.cards.noVacancies.text')}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {t('vacancies.title')}
      </Typography>
      <Typography variant="h2" className={classes.subtitle}>
        {t('vacancies.subtitle')}
      </Typography>

      <Box className={classes.grid}>
        <Box className={classes.vacancies}>
          {(isIdle || isPending) && <VacanciesCardsSkeleton />}
          {isFulfilled && vacancies.length === 0 && <NoVacanciesCard />}
          {isFulfilled &&
            vacancies.length > 0 &&
            vacancies.map(vacancy => <VacancyCard key={VacancyPresenter.id(vacancy)} vacancy={vacancy} />)}
        </Box>
        <Aside accordionShown={matchesMobile} />
        {matchesMobile && <Divider style={{ backgroundColor: theme.palette.common.brandingGray3 }} />}
      </Box>
    </Container>
  );
};

export default Vacancies;
